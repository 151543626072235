import React from 'react';
import { Drawer as MuiDrawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

namespace Drawer {
	export type Props = {
		children: any,
		open: boolean,
		anchor?: 'bottom' | 'left' | 'right' | 'top',
		variant?: 'persistent' | 'permanent' | 'temporary',
		showCloseButton?: boolean,
		fullHeight?: boolean,
		hasBackdrop?: boolean,
		preventCloseOnBackdropClick?: boolean,
		onClose?: (() => void),
		styleOverride?: any,
		anchorSpacing?: string
	};
}

const Drawer = (props: Drawer.Props) => {
	const {
		children,
		open,
		anchorSpacing,
		anchor = 'bottom',
		variant='persistent',
		showCloseButton = false,
		fullHeight = false,
		hasBackdrop = false,
		preventCloseOnBackdropClick = false,
		onClose = () => {},
		styleOverride = {}
	} = props;

	const closeButton = (
		<div
			className='drawer-close-btn'
			onClick={() => {
				onClose();
			}}
		>
			<CloseIcon fontSize='inherit'/>
		</div>
	);

	return (
		<MuiDrawer
			open={open}
			anchor={anchor}
			variant={variant}
			onClose={preventCloseOnBackdropClick ? ()=>{} : onClose}
			style={{
				position: hasBackdrop ? 'fixed' : 'relative',
				zIndex: hasBackdrop ? 1300 : 'unset',
				borderTop: 'none',
				borderBottom: 'none',
				[anchor]: anchorSpacing || '0',
				...styleOverride
			}}
			transitionDuration={{ enter: 100, exit: 100 }}
			PaperProps={{
				elevation: 0,
				style: { height: fullHeight ? '100%' : '', borderTop: 'none', [anchor]: anchorSpacing || '0' }
			}}
			BackdropProps={{ style: { [anchor]: anchorSpacing || '0' } }}
		>
			<div className='drawer-overlay' style={{ [anchor]: anchorSpacing || '0' }}></div>
			{showCloseButton ? closeButton : null}
			{children}
		</MuiDrawer>
	);
};

export default Drawer;
import { CircularProgress , Button, Snackbar } from '@material-ui/core';
import { useState } from 'react';
import { bulkPay } from '../api/bulkPay';
import { TDR } from 'tdr-common';
import React from 'react';
import { Alert } from '@material-ui/lab';

import * as Sentry from '@sentry/react';

interface PayAllButtonProps {
	restaurant: TDR.Restaurant;
	reservations: TDR.Reservation[];
	userToken: string;
	onSubmit?: () => void;
	onComplete?: () => void;
}

export function PayAllButton({ restaurant, reservations, userToken, onSubmit, onComplete }: PayAllButtonProps) {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);


	//TODO Missing the reload after its done, style for the snackbar is also wierd.

	async function handleClick() {
		try {
			setIsSubmitting(true);
			onSubmit?.();
			await bulkPay(restaurant,reservations, userToken);
			setSuccessMessage('Successfully paid out reservations');
		}
		catch(e) {
			Sentry.captureException(e);
			setSuccessMessage('An unexcepted error occured');
		}
		finally {
			onComplete?.();
			setIsSubmitting(false);
		}
	}

	return (
		<>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClick}
				style={{ width: 100 }}
			>
				{isSubmitting ? <CircularProgress color='secondary' /> : 'Pay All'}
			</Button>
			{successMessage && (
				<Snackbar
					open={!!successMessage}
					autoHideDuration={6000}
					onClose={() => setSuccessMessage('')}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<Alert onClose={() => setSuccessMessage('')} severity="success">
						{successMessage}
					</Alert>
			  </Snackbar>
			)}
			{errorMessage && (
				<Snackbar
					open={!!successMessage}
					autoHideDuration={6000}
					onClose={() => setErrorMessage('')}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<Alert onClose={() => setErrorMessage('')} severity="error">
						{errorMessage}
					</Alert>
			  </Snackbar>
			)}
		</>
	);
}
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';
import { TDR } from 'tdr-common';

type StatusFilterReservationProps = {
	filter: TDR.Reservation.Filter;
	setFilter: React.Dispatch<React.SetStateAction<TDR.Reservation.Filter>>;
};

const { All, Booked, Upcoming, Completed, Cancelled, NoShow }= TDR.Reservation.Filter;

const StatusFilterReservation = ({ filter, setFilter }: StatusFilterReservationProps) => {
	return (
		<FormControl variant='filled' className='select-filter'>
			<InputLabel id="booking-status-filter">Booking Status</InputLabel>
			<Select
				labelId="booking-status-filter"
				value={filter}
				onChange={(evt) => setFilter(evt.target.value as TDR.Reservation.Filter)}
				IconComponent={(props) => <Box ml={1}><KeyboardArrowDownIcon {...props} style={{ color: 'rgba(255, 255, 255, 0.87)' }} /></Box>}
			>
				<MenuItem value={All}>All</MenuItem>
				<MenuItem value={Booked}>Booked</MenuItem>
				<MenuItem value={Upcoming}>Upcoming</MenuItem>
				<MenuItem value={Completed}>Completed</MenuItem>
				<MenuItem value={Cancelled}>Cancelled</MenuItem>
				<MenuItem value={NoShow}>No-Show</MenuItem>
			</Select>
		</FormControl>
	);
};

export default StatusFilterReservation;
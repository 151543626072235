import { Box, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import React from 'react';
import { TDR } from 'tdr-common';

type Props = {
	largeGroupSettings: TDR.LargeGroupSettings;
	setLargeGroupSettings: React.Dispatch<React.SetStateAction<TDR.LargeGroupSettings>>;
}

const LargeGroupSettings = ({ largeGroupSettings, setLargeGroupSettings }: Props) => {

	return (
		<>
			<Box display="flex" style={{ padding: '10px 20px', gap: '20px' }}>
				<TextField
					label={'Response time (Hours)'}
					variant='outlined'
					InputLabelProps={{ style: { color: '#7FB5A8' } }}
					value={largeGroupSettings.response || ''}
					onChange={(e) => setLargeGroupSettings({ ...largeGroupSettings, response: parseInt(e.target.value) || null })}
				/>

				<TextField
					label={'Lead time required (Hours)'}
					variant='outlined'
					InputLabelProps={{ style: { color: '#7FB5A8' } }}
					value={largeGroupSettings.creationWindow || ''}
					onChange={(e) => {
						setLargeGroupSettings({ ...largeGroupSettings, creationWindow: parseInt(e.target.value) || null });
					}}
				/>

				<TextField
					label={'Modifcation Window (Hours)'}
					variant='outlined'
					InputLabelProps={{ style: { color: '#7FB5A8' } }}
					value={largeGroupSettings.modificationWindow || null}
					onChange={(e) => {
						setLargeGroupSettings({ ...largeGroupSettings, modificationWindow: parseInt(e.target.value) || null });
					}}
				/>

				<TextField
					label={'Cancellation Window (Hours)'}
					variant='outlined'
					InputLabelProps={{ style: { color: '#7FB5A8' } }}
					value={largeGroupSettings.cancellationWindow || ''}
					onChange={(e) => {
						setLargeGroupSettings({ ...largeGroupSettings, cancellationWindow: parseInt(e.target.value) || null });
					}}
				/>
			</Box>

			<Box display='flex' style={{ padding: '10px 20px', gap: '20px' }}>
				<TextField
					label={'Refund (%)'}
					variant='outlined'
					InputLabelProps={{ style: { color: '#7FB5A8' } }}
					value={largeGroupSettings.refund || ''}
					onChange={(e) => setLargeGroupSettings({ ...largeGroupSettings, refund: parseInt(e.target.value) || null })}
				/>

				<TextField
					label={'Booking Deposit ($)'}
					variant='outlined'
					InputLabelProps={{ style: { color: '#7FB5A8' } }}
					value={largeGroupSettings.bookingDeposit?.amount || ''}
					onChange={(e) => setLargeGroupSettings({ ...largeGroupSettings, bookingDeposit: { ...largeGroupSettings.bookingDeposit, amount: parseInt(e.target.value) || null } })}
				/>

				<FormControlLabel
					control={<Checkbox checked={largeGroupSettings.bookingDeposit?.perPerson}
						onChange={(e) => setLargeGroupSettings({ ...largeGroupSettings, bookingDeposit: { ...largeGroupSettings.bookingDeposit, perPerson: e.target.checked || false } })}/>}
					label="Per Person"
				/>

				<TextField
					label={'Minimum Spend ($)'}
					variant='outlined'
					InputLabelProps={{ style: { color: '#7FB5A8' } }}
					value={largeGroupSettings.minimumSpend?.amount || ''}
					onChange={(e) => setLargeGroupSettings({ ...largeGroupSettings, minimumSpend: { ...largeGroupSettings.minimumSpend, amount: parseInt(e.target.value) || null } })}
				/>

				<FormControlLabel
					control={<Checkbox checked={largeGroupSettings.minimumSpend?.perPerson}
						onChange={(e) => setLargeGroupSettings({ ...largeGroupSettings, minimumSpend: { ...largeGroupSettings.minimumSpend, perPerson: e.target.checked || false } })}/>}
					label="Per Person"
				/>
			</Box>

			<Box display='flex' style={{ padding: '10px 20px', gap: '20px' }}>
				<TextField
					label={'Auto-Gratuity (%)'}
					variant='outlined'
					InputLabelProps={{ style: { color: '#7FB5A8' } }}
					value={largeGroupSettings.autoGratuity || ''}
					onChange={(e) => setLargeGroupSettings({ ...largeGroupSettings, autoGratuity: parseInt(e.target.value) || null })}
				/>

				<TextField
					label={'Grace Period (Minutes)'}
					variant='outlined'
					InputLabelProps={{ style: { color: '#7FB5A8' } }}
					value={largeGroupSettings.gracePeriod || ''}
					onChange={(e) => setLargeGroupSettings({ ...largeGroupSettings, gracePeriod: parseInt(e.target.value) || null })}
				/>
			</Box>

			<Box display='flex' style={{ padding: '10px 20px', gap: '20px' }}>
				<TextField
					label={'Freeform Policy'}
					multiline={true}
					variant='outlined'
					InputLabelProps={{ style: { color: '#7FB5A8' } }}
					value={largeGroupSettings.freeformPolicy || ''}
					onChange={(e) => setLargeGroupSettings({ ...largeGroupSettings, freeformPolicy: e.target.value })}
				/>
			</Box>
		</>
	);
};

export default LargeGroupSettings;

import React, { useState, useContext } from 'react';
import Drawer from './Drawer';
import { TDR } from 'tdr-common';
import Availability from './Availability';
import PaymentInformation from './PaymentInformation';
import Loading from './Loading';
import { ConfirmReservationModal } from './Modals';
import { ModalContext } from '../context/ModalContext';
import { ModifyReservationContext } from '../context/ModifyReservationContext';
import { TableContext } from '../context/TableContext';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import getPaymentMethod from '../helpers/getPaymentMethod';
import Notice from './Notice';

namespace ModifyReservation {
	export type Props = {
		open: boolean,
		onClose:()=>void,
		reservation: TDR.Reservation,
		table: TDR.Table,
		restaurant: TDR.Restaurant
	};
}

enum Stage {
	Availability,
	Payment
}

const ModifyReservation = ({ open, onClose, reservation, table, restaurant }: ModifyReservation.Props) => {
	const { openModal, closeModal } = useContext(ModalContext);
	const {
		reservationModifications,
		guests: contextGuests,
		setPaymentMethod: setContextPaymentMethod,
		confirmReservationModification,
		errorMessage,
		setErrorMessage,
		clearErrorMessage
	} = useContext(ModifyReservationContext);
	const { selectedTable } = useContext(TableContext);

	const [stage, setStage] = useState(Stage.Availability);
	const [processing, setProcessing] = useState(false);
	const [complete, setComplete] = useState(false);

	const stripe = useStripe();
	const elements = useElements();

	const { fullName } = reservation;

	const onClickProceedPaymentInformation = async () => {
		setProcessing(true);
		const paymentMethod = await getPaymentMethod(elements, stripe, fullName, reservation.email, reservation.phone);
		setProcessing(false);
		if(paymentMethod.error) {
			setErrorMessage('Error setting up payment information. Check the user’s information.');
			return;
		}
		setContextPaymentMethod(paymentMethod);
		return paymentMethod;
	};

	const openModificationConfirmationModal = (selectedDate, selectedTime, guests, paymentMethod = null) => openModal (
		<ConfirmReservationModal
			isModification={true}
			fullName={fullName}
			guests={guests}
			date={selectedDate.toDateString()}
			time={selectedTime}
			tableName={selectedTable.internalName || selectedTable.name}
			onConfirm={async () => {
				setProcessing(true);
				const res = await confirmReservationModification(reservation);
				if (reservationModifications.price > 0 && paymentMethod && res.clientSecret) {
					const confirmedCardPaymentReq = await stripe.confirmCardPayment(res.clientSecret, {
						payment_method: paymentMethod.paymentMethod.id
					});
					if (confirmedCardPaymentReq.error) {
						setErrorMessage(confirmedCardPaymentReq.error.message);
						setProcessing(false);
					}
				}
				if (res.success) {
					setComplete(true);
				}
				setProcessing(false);
			}}
			onClose={() => closeModal()}
		/>
	);

	return (
		<Drawer
			open={open}
			anchorSpacing='0'
			anchor='right'
			hasBackdrop={true}
			fullHeight={true}
			variant='temporary'
			showCloseButton={false}
			onClose={() => {
				setComplete(false);
				clearErrorMessage();
				onClose();
			}}
		>
			{processing && <Loading/>}
			{(errorMessage || complete) && (
				<Notice
					type={complete ? 'success' :'error'}
					message={complete ? 'Reservation modification complete' : errorMessage}
					onClear={complete ? () => setComplete(false) : clearErrorMessage}
				/>
			)}

			{stage === Stage.Availability && (
				<Availability
					reservation={reservation}
					table={table}
					restaurant={restaurant}
					onProceed={(price, selectedTime, selectedDate, guests) => {
						if (price > reservation.price) {
							setStage(Stage.Payment);
						}
						else {
							openModificationConfirmationModal(selectedDate, selectedTime, guests);
						}
					}}
				/>
			)}
			{stage === Stage.Payment && (
				<PaymentInformation
					reservation={reservation}
					restaurant={restaurant}
					onProceed={async () => {
						const paymentMethod = await onClickProceedPaymentInformation();
						openModificationConfirmationModal(reservationModifications.selectedDate, reservationModifications.selectedTime, contextGuests, paymentMethod);
					}}
					onCancel={() => setStage(Stage.Availability)}
				/>
			)}
		</Drawer>
	);
};

export default ModifyReservation;

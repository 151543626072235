// ReservationList.jsx
import React, { useState } from 'react';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@material-ui/lab';
import ReservationUpdateHistory from '../DisplayHistory';

const ReservationList = ({ reservations } : {reservations: any[]}) => {
	const [expandedReservationId, setExpandedReservationId] = useState(null);

	const handleReservationClick = (reservationId) => {
		if (expandedReservationId === reservationId) {
			setExpandedReservationId(null); // Collapse if already expanded
		}
		else {
			setExpandedReservationId(reservationId); // Expand the clicked reservation
		}
	};

	return (
		<div>
			{reservations.length === 0 ? (
				<p>No reservations found.</p>
			) : (
				<ul style={{ listStyleType: 'none', padding: 0 }}>
					{reservations.map((reservation) => (
						<li key={reservation.id} style={{ marginBottom: '10px' }}>
							<div
								style={{
									border: '1px solid #ccc',
									padding: '10px',
									cursor: 'pointer',
									backgroundColor:
                                        expandedReservationId === reservation.id ? '#232323' : '#232323'
								}}
								onClick={() => handleReservationClick(reservation.id)}
							>
								{/* Display Summary Details */}
								<div>
									<strong>Date/Time:</strong>{' '}
									{new Date(reservation.time.seconds * 1000).toLocaleString('en-US', {
										timeZone: reservation.timezone || 'UTC'
									})}
								</div>
								<div>
									<strong>Status:</strong> {reservation.status}
								</div>
								<div>
									<strong>Restaurant:</strong> {reservation.restaurantName}
								</div>
								<div>
									<strong>Table:</strong> {reservation.tableName || reservation.internalTableName}
								</div>
								<div>
									<strong>Group Size:</strong> {reservation.guests}
								</div>

								{/* Expandable Detailed View */}
								{expandedReservationId === reservation.id && (
									<div style={{ marginTop: '10px' }}>
										{/* Detailed Information */}
										<div>
											<strong>Price:</strong> ${reservation.price}
										</div>
										<div>
											<strong>Phone:</strong> {reservation.phone}
										</div>
										<div>
											<strong>Email:</strong> {reservation.email}
										</div>
										<div>
											<strong>Guest Notes:</strong> {reservation.guestNotes || 'None'}
										</div>
										<div>
											<strong>Items:</strong>
											<ul>
												{reservation.items.map((item, index) => (
													<li key={index}>
														{item.type === 'table' && (
															<>
																<strong>Table:</strong> {item.label}
															</>
														)}
														{item.type === 'addon' && (
															<>
																<strong>Addon:</strong> {`${item.quantity} x ${item.label} @ $${item.price.toFixed(2)}`}
															</>
														)}
													</li>
												))}
											</ul>
										</div>
										<div>
											<strong>Reservation History</strong>
											{reservation?.updateHistory?.map((update) => (
												<TimelineItem key={String(update.updatedAt)}>
													<TimelineSeparator>
														<TimelineDot />
														<TimelineConnector/>
													</TimelineSeparator>
													<TimelineContent>
														<ReservationUpdateHistory updateHistory={[update]}>
														</ReservationUpdateHistory>
													</TimelineContent>
												</TimelineItem>
											))}
										</div>
									</div>
								)}
							</div>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default ReservationList;

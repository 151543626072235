import noop from 'lodash/noop';
import pickBy from 'lodash/pickBy';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import sortBy from 'lodash/sortBy';
import identity from 'lodash/identity';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
	Box, Checkbox, CircularProgress,
	FormControlLabel, IconButton, List,
	ListItem,
	ListItemIcon, MenuItem, RootRef, Select, Tab, TextField, Tooltip
} from '@material-ui/core';
import {
	AddCircle as AddIcon, DeleteForever as DeleteIcon, DragHandle,
	Help, KeyboardArrowDown as KeyboardArrowDownIcon, Save
} from '@material-ui/icons';

import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ReactMarkdown from 'react-markdown';

import { TDR } from 'tdr-common';
import { createPolicy, retirePolicy, updatePolicy } from '../api/pricingPolicies';
import { ConditionEditor, ConditionHelpButton } from './ConditionEditor';

import { FirebaseContext } from '../context/FirebaseContext';
import DebugLogButton from './DebugLogButton';
import Modal from './Modal';


import helpDocTables from '../markdown/pricing-policies/1-tables.md';
import helpDocTaxes from '../markdown/pricing-policies/2-taxes.md';
import helpDocPayouts from '../markdown/pricing-policies/3-payouts.md';

const isLargeGroupDepositPolicy = (policy: Partial<TDR.PricingPolicy>) => policy.name === TDR.LARGE_GROUP_DEPOSIT_POLICY_NAME;

export function PricingPolicyHelpButton({ buttonAttrs = {} }: { buttonAttrs?: any }) {
	const [showHelp, setShowHelp] = useState(false);
	const [helpTab, setHelpTab] = useState('tables');
	const setTab = (evt, newTab) => setHelpTab(newTab);

	return <>

		<IconButton onClick={() => setShowHelp(!showHelp)} {...buttonAttrs}>
			<Help />
		</IconButton>

		<TabContext value={helpTab}>
			<Modal
				open={showHelp}
				onClose={() => setShowHelp(false)}
				styleOverride={{ width: '80%', height: '80%', maxWidth: '80%' }}
				title={(<Box style={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={setTab}>
						<Tab label="Tables" value="tables" />
						<Tab label="Taxes" value="taxes" />
						<Tab label="Payouts" value="payouts" />
					</TabList>
				</Box>)}
			>
				<TabPanel value="tables"><ReactMarkdown className='markdown' >{helpDocTables}</ReactMarkdown></TabPanel>
				<TabPanel value="taxes"><ReactMarkdown className='markdown' >{helpDocTaxes}</ReactMarkdown></TabPanel>
				<TabPanel value="payouts"><ReactMarkdown className='markdown' >{helpDocPayouts}</ReactMarkdown></TabPanel>
			</Modal>
		</TabContext>
	</>;
}




export function PricingPolicyEditor (
	{
		policy: basePolicy,
		restaurant,
		type = 'item',
		disabled = false,
		onChange = noop
	}: {
		policy?: Partial<TDR.PricingPolicy>,
		restaurant?: TDR.Restaurant,
		type: 'item' | 'tax' | 'payout',
		disabled?: boolean,
		onChange?: (update: Partial<TDR.PricingPolicy>) => void,
		setErrorMsg?: (e: string) => void
	}) {

	const targetType = type === 'tax' ? 'subtotal' : 'item';
	const [state, setState] = useState<Partial<TDR.PricingPolicy>>(basePolicy || {});

	const cleanState = pickBy({
		...state,
		restaurantId: restaurant?.id,
		targetType
	}, (k, v) => !(isNull(v) || isUndefined(v)));

	useEffect(() => {
		onChange(cleanState);
	}, [state]);

	function updateCharge(update: TDR.PricingPolicy.Charge, index: number) {
		const charges = state.charges || [];
		const charge = charges[index];
		if (isEqual(charge, update)) {
			return;
		}

		const newArr =cloneDeep(charges);
		if (update) {
			newArr[index] = update;
		}
		else {
			newArr.splice(index, 1);
		}
		setState({ ...state, charges: newArr });
	}

	return <Box>
		{isLargeGroupDepositPolicy(basePolicy) && disabled && (
			<h4 style={{ color: 'red' }}>This is a large group deposit that must be configured in the TABLES tab</h4>
		)}
		<Box display='flex' flexDirection='row' alignItems='flex-start'>
			<Box flexGrow={1}>
				<Box display='flex' flexDirection='row' alignItems='left'>
					<TextField
						label='Name'
						variant='outlined'
						disabled={disabled}
						style={{ maxWidth: '70%', marginLeft: '1em' }}
						value={state.name}
						onChange={(evt) => setState({ ...state, name: evt.target.value })}
					/>

					{type === 'item'
						? <FormControlLabel
							style={{ marginLeft: '1em', whiteSpace: 'nowrap' }}
							control={<Checkbox
								checked={state.override}
								disabled={disabled}
								onChange={evt => setState({ ...state, override: evt.target.checked })} />}
							label="Base Price"
						/>
						: <TextField
							label={`${capitalize(type)} Id`}
							variant='outlined'
							disabled={disabled}
							style={{ maxWidth: '70%', marginLeft: '1em', marginRight:'1em' }}
							value={state[type]}
							onChange={(evt) => setState({ ...state, [type]: evt.target.value })}
						/>}

				</Box>
				<TextField
					label='Id'
					variant='outlined'
					style={{ maxWidth: '70%', marginLeft: '1em' }}
					value={state.id}
					onClick={() => navigator.clipboard.writeText(state.id)}
					onFocus={() => (document.activeElement as HTMLElement).blur()}
				/>
			</Box>

			<Box flexGrow={1} minWidth='40%'>
				<Box display='flex' flexDirection='row' alignItems='left'>
					<em>Condition</em>
					<ConditionHelpButton buttonAttrs={{ p: 0, ml: 1 }} />
					<DebugLogButton log={cleanState} attrs={{ p: 0 }} style={{ opacity: 0.5, marginLeft: 'auto' }} />
				</Box>
				<ConditionEditor
					disabled={disabled}
					condition={state.condition || {}}
					onChange={condition => setState({ ...state, condition })}
				/>
			</Box>

		</Box>

		<Box display='flex' flexDirection='row' flexWrap='wrap'>
			{(state.charges || []).map((charge, index) => (
				<ChargeEditor
					key={index}
					disabled={disabled}
					charge={charge}
					onChange={(update) => updateCharge(update, index)}
				/>
			))}
			{!isEmpty(state.charges) ? null :
				<h4 style={{ color: 'red' }}>A PricingPolicy should include at least one charge</h4>}
			{disabled ? null : (
				<IconButton onClick={() => setState({ ...state, charges: [...state.charges, CHARGE_DEFAULTS] })}>
					<AddIcon />
				</IconButton>
			)}
		</Box>
	</Box>;

}


const CHARGE_DEFAULTS: TDR.PricingPolicy.Charge = {
	type: 'add',
	target: 'total',
	amount: 0
};


function ChargeEditor({ charge, onChange, disabled }: {
	disabled?: boolean,
	charge: Partial<TDR.PricingPolicy.Charge>,
	onChange: (charge: TDR.PricingPolicy.Charge) => void
}) {
	const [ctype, setCtype] = useState<string>(charge.type || CHARGE_DEFAULTS.type);
	const [target, setTarget] = useState<string>(charge.target || CHARGE_DEFAULTS.target);

	// bit of a pain in the ass to represent this as percentages and switch correctly between "true" values
	// round to one hundredth of a percent
	const multiplierToPercent = (amount) => Math.round(amount * 10000) / 100;
	const percentToMultiplier = (amount) => Math.round((amount / 100) * 10000) / 10000;

	const [amountStr, setAmountStr] = useState<string>(`${(charge.type?.startsWith('multiply'))
		? multiplierToPercent(charge.amount || CHARGE_DEFAULTS.amount)
		: charge.amount || CHARGE_DEFAULTS.amount
	}`);

	function build(): TDR.PricingPolicy.Charge {
		const amountNum = parseFloat(amountStr);
		const amount = ctype.startsWith('multiply') ? percentToMultiplier(amountNum) : amountNum;
		if (isNaN(amount)) {
			return null;
		}
		// remove any unspecified values, being careful not to remove `0` (which is falsey)
		return pickBy({ target, amount, type: ctype }, v => !!v || isNumber(v)) as TDR.PricingPolicy.Charge;
	}

	useEffect(() => {
		const newCharge = build();
		if (newCharge && !isEqual(charge, newCharge)) {
			onChange(newCharge);
		}
	});

	return <Box >
		<Box display='flex' flexDirection='row' bgcolor='#232323' m={1}>
			<Select
				label='calculation'
				className='select'
				variant='outlined'
				style={{ marginLeft: '1em' }}
				disabled={disabled}
				value={charge.type}
				onChange={(evt) => setCtype(evt.target.value as string)}
				IconComponent={(props) => <Box ml={1}><KeyboardArrowDownIcon {...props} style={{ color: 'rgba(255, 255, 255, 0.87)' }} /></Box>}
			>
				<MenuItem value='add'><em>add</em></MenuItem>
				<MenuItem value='per-guest'><em>add per-guest</em></MenuItem>
				<MenuItem value='multiply'><em>percent</em></MenuItem>
				<MenuItem value='multiply-subtotal'><em>percent of subtotal</em></MenuItem>
			</Select>

			<TextField
				className='input'
				label='amount'
				variant='outlined'
				style={{ marginLeft: '1em' }}
				disabled={disabled}
				value={amountStr}
				onChange={(evt) => setAmountStr(evt.target.value)}
			/>

			<Select
				label='price type'
				className='select'
				variant='outlined'
				style={{ marginLeft: '1em' }}
				disabled={disabled}
				value={charge.target}
				onChange={(evt) => setTarget(evt.target.value as string)}
				IconComponent={(props) => <Box ml={1}><KeyboardArrowDownIcon {...props} style={{ color: 'rgba(255, 255, 255, 0.87)' }} /></Box>}
			>
				<MenuItem value='total'><em>total</em></MenuItem>
				<MenuItem value='deposit'><em>deposit</em></MenuItem>
			</Select>

			{disabled ? null : (
				<IconButton onClick={() => onChange(null)}>
					<DeleteIcon />
				</IconButton>
			)}
		</Box>
	</Box>;
}




export function PricingPolicyListEditor ({ policies: basePolicies = [], type, restaurant, setErrorMsg = noop, disableReorder }:
{
	policies?: TDR.PricingPolicy[],
	type: 'item' | 'tax' | 'payout',
	restaurant?: TDR.Restaurant,
	disableReorder?: boolean,
	setErrorMsg: (s:string) => void
}) {
	const { token } = useContext(FirebaseContext);
	const [policies, setPolicies] = useState<Partial<TDR.PricingPolicy>[]>(basePolicies);
	const sortedPolicies = sortBy(policies, ['priority']);


	// okay, if things load in later, we need to refresh the list - this will stomp any changes that have been made, though
	useEffect(() => {
		setPolicies(basePolicies);
	}, [basePolicies]);

	// if policies have been changed, we need to check that their priorities are sufficiently distanced
	useEffect(() => {
		let reordered=false;
		for (let i = 1; !reordered && i < sortedPolicies.length; i++) {
			const prev = sortedPolicies[i-1];
			const next = sortedPolicies[i];
			if (next.priority - prev.priority < 1) {
				setPolicies(sortedPolicies.map((policy, index) => ({
					...policy,
					priority: (index + 1) * 1000 // (index + 1) because legacy policies are at zero, and we want them to go first
				})));
				reordered = true;
			}
		}
	}, [policies]);

	function onDragEnd({ source, destination }) {
    	if (!waiting && destination && !isEqual(destination, source)) {

			const result = Array.from(sortedPolicies);
    		const [target] = result.splice(source.index, 1);

			// if we need to rerank (and we probably DO need to re-rank), go halfway between our neighbours
			const lowerP = result[destination.index -1]?.priority || 0;
			const upperP = result[destination.index]?.priority || lowerP + 2000;
			if (target.priority <= lowerP || (isNumber(upperP) && target.priority >= upperP)) {
				target.priority = lowerP + ((upperP - lowerP) * 0.5);
			}

			result.splice(destination.index, 0, target);
    		setPolicies(result);
    	}
	}


	function getOrig (policy: Partial<TDR.PricingPolicy>) {
		return basePolicies.find(resPolicy => resPolicy.id === policy.id);
	}
	function getUpdated(resPolicy: TDR.PricingPolicy) {
		return policies.find(policy => policy.id === resPolicy.id);
	}

	const [waiting, setWaiting] = useState(false);

	async function saveAll () {
		setWaiting(true);

		const errors = {};
		const pCopy = cloneDeep(policies);

		const updates = policies.filter(policy => !isEqual(policy, getOrig(policy)));
		const deletes = basePolicies.filter(resPolicy => !getUpdated(resPolicy));

		await Promise.all([
			...updates.map(policy => {
				const isNew = !!getOrig(policy);
				const doTheThing = isNew ? updatePolicy : createPolicy;
				return doTheThing(token, policy)
					.then(response => {
						if (response.success) {
							pCopy[pCopy.findIndex(p => p.id === policy.id)] = response.result;
						}
						else {
							errors[policy.id] = `error ${isNew ? 'creating' : 'updating'} policy ${policy.id} : ${response.message}`;
						}
					}).catch(() => {
						errors[policy.id] = `error ${isNew ? 'creating' : 'updating'} policy ${policy.id} : unknown error`;
					});
		    }),
			...deletes.map(policy => {
				return retirePolicy(token, policy).then(response => {
					if (response.success) {
						pCopy[pCopy.findIndex(p => p.id === policy.id)] = null;
					}
					else {
						errors[policy.id] = `error deleting policy ${policy.id} : ${response.message}`;
					}
				}).catch(() => {
					errors[policy.id] = `error deleting policy ${policy.id} : unknown error`;
				});
			})
		]).then(() => {
			// so, at this point, everything that *could* create or update itself has, right?
			// and everything that errored has been left alone?
			// In that case, let's just update the state with whatever we've got
			setPolicies(pCopy.filter(identity));

			if (!isEmpty(errors)) {
				setErrorMsg(Object.values(errors).join('\n'));
			}

			setWaiting(false);
		});
	}



	return (
    	<>
    		<Box display='flex' flexDirection='row' px={1}>
    			<h4>Pricing Policies</h4>

    			<IconButton
					disabled={waiting}
					onClick={() => {
						const newPolicy = { id: uuid(), charges: [{ ...CHARGE_DEFAULTS }], priority: 1 };
						setPolicies([...sortedPolicies, newPolicy]);
    			}}>
    				<AddIcon />
    			</IconButton>

				<PricingPolicyHelpButton />
				<Box flexGrow='1' display='flex' flexDirection='row' justifyContent='flex-end'>
					<Tooltip title={`Save all changes${restaurant ? ' (independant of Restaurant save)' : ''}`}>
						<IconButton
							disabled={waiting}
							onClick={saveAll}>
							<Save />
							{!waiting ? null : <CircularProgress />}
						</IconButton>
					</Tooltip>
				</Box>
    		</Box>
    		<DragDropContext onDragEnd={onDragEnd}>
    			<Droppable droppableId="cutoffs">
    				{(provided ) => (
						<RootRef rootRef={provided.innerRef}>
							<List style={{ backgroundColor: 'black' }}>
    							{sortedPolicies.map((policy, index) => (
									<Draggable key={policy.id} draggableId={policy.id} index={index} isDragDisabled={waiting || disableReorder}>
    									{(provided) => (
    										<ListItem
    											ref={provided.innerRef}
    											{...provided.draggableProps}
    											style={{
    												...provided.draggableProps?.style || {},
    												backgroundColor: '#333333',
    												display: 'flex',
													alignItems: 'flex-start',
													borderBottom: '3px solid #232323'
    											}}
    										>
    											<ListItemIcon
    												{...provided.dragHandleProps}
    												style={{
    													...provided.dragHandleProps?.style || {},
    													minWidth: '0px',
														paddingTop: '1em',
														paddingBottom: '1em'
    												}}
    											>
    												<DragHandle />
    											</ListItemIcon>

    											<Box style={{ flexGrow: 1 }}>
													<PricingPolicyEditor
    													{...{ policy, restaurant, type }}
														disabled={waiting || isLargeGroupDepositPolicy(policy)}
    													onChange={update => {

															update.priority = update.priority ?? 1;

    														const id = policy.id;
    														const index = sortedPolicies.findIndex(c => c.id === id);
															const sortedPoliciesCopy = [...sortedPolicies];
    														sortedPoliciesCopy[index] = { ...update, id };

															console.log('PricingPolicyEditor.onChange', {
																policy,
																update,
																id,
																index,
																sortedPoliciesCopy
															});

															if (index !== -1 && !isEqual(sortedPoliciesCopy, sortedPolicies)) {
    															setPolicies(sortedPoliciesCopy);
    														}
    													}}
    												/>
    											</Box>

												<IconButton
													style={{
														paddingTop: '1em',
														paddingBottom: '1em'
													}}
													disabled={waiting || isLargeGroupDepositPolicy(policy)}
													onClick={() => {
														setPolicies(policies.filter(c => c.id !== policy.id));
													}}>
													<DeleteIcon />
												</IconButton>
    										</ListItem>
    									)}
    								</Draggable>
    							))}
    							{provided.placeholder}
    						</List>
    					</RootRef>
    				)}
    			</Droppable>
    		</DragDropContext>

    	</>
	);
}


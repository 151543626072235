import { TDR } from 'tdr-common';
import config from '../common/config';

export default async function realizeReservationInvoices(userToken:string, reservation: TDR.Reservation) {
	return fetch(`${config.apiHost}/realizeReservationInvoices`, {
		method: 'POST',
		headers: { 'Authorization': `Bearer ${userToken}` },
		body: reservation.id
	})
		.then(async response => response.text())
		.then(text => {
			try {
				return JSON.parse(text);
			}
			catch (err) {
				return text;
			}
		});
}

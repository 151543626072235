import React, { useState, useContext } from 'react';
import { Tabs, Tab, Box, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import AdminHeader from './AdminHeader';
import Notice from './Notice';
import { DateTime } from 'luxon';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DatePicker from './DatePicker';
import GuestNumberSelector from './GuestNumberSelector';
import AdminTimeSlotSelector from './AdminTimeSlotSelector';
import GuestSelector from './GuestSelector';
import { AdminRestaurantContext } from '../context/AdminRestaurantContext';
import { AdminReservationContext } from '../context/AdminReservationContext';
import { GuestContext } from '../context/GuestContext';
import { ModalContext } from '../context/ModalContext';
import { DeleteReservationModal, ConfirmReservationModal } from './Modals';

const AdminReservationCreate = () => {
	const { selectedAdminRestaurant } = useContext(AdminRestaurantContext);
	const { processing, errorMessage, clearErrorMessage, adminConfirmReservation } = useContext(AdminReservationContext);
	const { selectedGuest, setSelectedGuest } = useContext(GuestContext);
	const { openModal } = useContext(ModalContext);

	const history = useHistory();
	const [tab, setTab] = useState(0);

	if (!selectedAdminRestaurant) {
		return <div>Temporary Loading thingy... This will be removed once restaurant stuff is set up</div>;
	}

	const [newReservation, setNewReservation]: [any, any] = useState({
		date: DateTime.local().plus({ days: 1 }).toJSDate()
	});

	const confirmButtonDisabled = (
		processing ||
		!selectedGuest ||
		!newReservation.date || !newReservation.time || !newReservation.guests
	);

	// Parameters of the children components
	const [customGuestNumber, setCustomGuestNumber] = useState(null);
	const [selectedShift, setSelectedShift] = useState('breakfast');

	const theme = useTheme();
	const mdUp = useMediaQuery(theme.breakpoints.up('md'));

	const setIconColor = (currentTab) => tab === currentTab ? '#7FB5A8' : '';

	const shouldDisableDate = (day: DateTime) => (
		selectedAdminRestaurant.closed.weekdays.includes(day.weekdayLong) ||
		selectedAdminRestaurant.closed.dates.map((obj)=>obj.date).includes(day.toFormat('MMMM dd, yyyy'))
	);

	const goBack = () => {
		history.goBack();
		setSelectedGuest(null);
		if (errorMessage) {
			clearErrorMessage();
		}
	};

	const confirmReservation = () => openModal(
		<ConfirmReservationModal
			fullName={selectedGuest.fullName}
			guests={newReservation.guests}
			date={DateTime.fromJSDate(newReservation.date).toFormat('DDDD')}
			time={newReservation.time}
			onConfirm={async () => {
				const res = await adminConfirmReservation({
					...newReservation,
					userId: selectedGuest.userId,
					fullName: selectedGuest.fullName,
					email: selectedGuest.email,
					phoneNumber: selectedGuest.phoneNumber
				});
				if (res.success) {
					goBack();
				}
			}}
		/>
	);

	return (
		<div className='admin-reservation-create'>
			{errorMessage &&
				<Notice
					message={errorMessage}
					onClear={clearErrorMessage}
					styleOverride={{
						position: 'fixed',
						top: 0,
						right: 0
					}}
				/>
			}
			<div className='navigation'>
				<AdminHeader
					title='New Reservation'
					leftIconButton={<CloseIcon style={{ fontSize: '17px' }}/>}
					onLeftIconClick={() => openModal(<DeleteReservationModal onDeleteReservation={() => goBack()}/>)}
					rightIconButton={processing
						? <CircularProgress style={{ color: 'rgba(255, 255, 255, 0.87)' }} size={17}/>
						: <CheckIcon style={{ fontSize: '17px', color: confirmButtonDisabled ? 'rgba(255, 255, 255, 0.38)' : 'rgba(255, 255, 255, 0.87)' }}/>
					}
					onRightIconClick={confirmButtonDisabled ? null : () => confirmReservation()}
				/>
				<Tabs
					variant='fullWidth'
					value={tab}
					onChange={(evt: any, tab: number) => setTab(tab)}
					centered={true}
				>
					<Tab icon={<CalendarTodayIcon style={{ color: setIconColor(0) }} />} label={mdUp ? 'Date' : ''} />
					<Tab icon={<PeopleIcon style={{ color: setIconColor(1) }} />} label={mdUp ? 'Party Size' : ''} />
					<Tab icon={<ScheduleIcon style={{ color: setIconColor(2) }} />} label={mdUp ? 'Time' : ''} />
					<Tab icon={<PersonIcon style={{ color: setIconColor(3) }} />} label={mdUp ? 'Guest' : ''} />
				</Tabs>
			</div>
			<div className='content'>
				{tab === 0 && (
					<Box display='flex' flexDirection='column' alignItems={mdUp ? 'center' : ''} justifyContent={mdUp ? 'center' : ''}>
						<MuiPickersUtilsProvider utils={LuxonUtils}>
							<div className='centered-picker'>
								<DatePicker
									alwaysShowOpenedCalendarOnMobile={true}
									mobileDatePickerOpened={true}
									minDate={newReservation.date}
									selectedDate={newReservation.date}
									onChange={(value) => setNewReservation({ ...newReservation, date: new Date(value) })}
									shouldDisableDate={(day: DateTime) => shouldDisableDate(day)}
								/>
							</div>
						</MuiPickersUtilsProvider>
					</Box>
				)}
				{tab === 1 && (
					<GuestNumberSelector
						selected={newReservation.guests as number}
						setSelected={(guests) => setNewReservation({ ...newReservation, guests })}
						customValue={customGuestNumber}
						setCustomValue={setCustomGuestNumber}
					/>
				)}
				{tab === 2 && (
					<AdminTimeSlotSelector
						partySize={newReservation.guests || 0}
						selectedShift={selectedShift}
						setSelectedShift={setSelectedShift}
						selectedTimeSlot={newReservation.time || ''}
						setSelectedTimeSlot={(time) => setNewReservation({ ...newReservation, time })}
					/>
				)}
				{tab === 3 && (
					<GuestSelector
						newReservation={newReservation}
						setNewReservation={setNewReservation}
						confirmButtonDisabled={confirmButtonDisabled}
						confirmReservation={confirmReservation}
					/>
				)}
			</div>
		</div>
	);
};

export default AdminReservationCreate;
import config from '../common/config';

export default async function syncImages(restaurantID: string) {
	const response = await fetch(`${config.apiHost}/syncImages`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			restaurantID
		})
	});
	const result = await response.json();
	return result;

}
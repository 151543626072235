import * as Sentry from '@sentry/react';
import firebase from 'firebase';
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { TDR } from 'tdr-common';
import { NO_FILTER } from '../components/AdminInvoices';
import { DateTime } from 'luxon';

export const INVOICES_PAGE_SIZE = 20;

export const useReservations = (
	firestore: firebase.firestore.Firestore,
	restaurantId: string,
	restaurantTimezone: string,
	statusFilter: TDR.Reservation.Filter,
	yearFilter: number,
	monthFilter: number
) => {
	const [reservations, setReservations] = useState<TDR.Reservation[]>([]);
	const [loading, setLoading] = useState(true);

	const [lastDoc, setLastDoc] = useState<firebase.firestore.DocumentData | null>(null);
	const [endOfData, setEndOfData] = useState(false);

	const previousFilters = useRef<{
		status: TDR.Reservation.Filter;
		year: number;
		month: number;
	}>();


	const fetchReservations = async (startAfterDoc?: firebase.firestore.DocumentData) => {
		setLoading(true);
		try {
			let query = firestore
				.collection('Reservations')
				.where('restaurantId', '==', restaurantId);

			// Filter by year
			if (yearFilter !== NO_FILTER) {
				const startTime = DateTime.fromObject({ year: yearFilter, month: 1 }).setZone(restaurantTimezone, { keepLocalTime: true }).toJSDate();
				const endTime = DateTime.fromObject({ year: yearFilter + 1, month: 1 }).setZone(restaurantTimezone, { keepLocalTime: true }).toJSDate();
				query = query.where('time', '>=', startTime).where('time', '<', endTime);
			}

			// Filter by month
			if (monthFilter !== NO_FILTER) {
				const yearNumber = yearFilter;
				const monthNumber1Indexed = monthFilter + 1;
				const endMonthNumber1Indexed = (monthNumber1Indexed % 12) + 1;
				const endYearNumber = monthNumber1Indexed + 1 > 12 ? yearNumber + 1 : yearNumber;
				const startTime = DateTime.fromObject({ year: yearNumber, month: monthNumber1Indexed }).setZone(restaurantTimezone, { keepLocalTime: true }).toJSDate();
				const endTime = DateTime.fromObject({ year: endYearNumber, month: endMonthNumber1Indexed }).setZone(restaurantTimezone, { keepLocalTime: true }).toJSDate();
				query = query.where('time', '>=', startTime).where('time', '<', endTime);
			}

			// Filter by booking status
			if (statusFilter !== TDR.Reservation.Filter.All)	{
				query = query.where('status', '==', statusFilter);
			}

			query = query.orderBy('time', 'desc');

			if (startAfterDoc) {
				query = query.startAfter(startAfterDoc);
			}

			query = query.limit(INVOICES_PAGE_SIZE);

			const snapshot = await query.get();

			const reservationsData = snapshot.docs.map((doc) => doc.data() as TDR.Reservation);
			setReservations(prevReservations => [
				...prevReservations,
				...reservationsData
			]);

			const lastVisible = snapshot.docs[snapshot.docs.length - 1];
			setLastDoc(lastVisible);

			if (snapshot.size !== INVOICES_PAGE_SIZE) {
				setEndOfData(true);
			}
			else {
				setEndOfData(false);
			}
		}
		catch (error) {
			console.error('Error fetching reservations', { error });
			Sentry.captureException(error);
		}
		finally {
			setLoading(false);
		}
	};

	const fetchNextPage = () => {
		if (lastDoc) {
			fetchReservations(lastDoc);
		}
	};


	useEffect(() => {
		const haveFiltersChanged = !isEqual(
			{ status: statusFilter, year: yearFilter, month: monthFilter },
			previousFilters.current
		);

		// If filters have changed, reset the reservations and lastDoc state
		if (haveFiltersChanged) {
			setReservations([]);
			setLastDoc(null);
		}

		previousFilters.current = {
			status: statusFilter,
			year: yearFilter,
			month: monthFilter
		};

		fetchReservations();
	}, [restaurantId, statusFilter, yearFilter, monthFilter]);

	return {
		reservations,
		loading,
		fetchNextPage,
		endOfData
	};
};

import React, { useEffect, useState } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import BookIcon from '@material-ui/icons/Book';
import ChatIcon from '@material-ui/icons/Chat';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { TDR } from 'tdr-common';
import { TableIcon } from './CustomIcons';

namespace Reservation {
	export type Props = {
		reservation: TDR.Reservation,
	};
	export type State = {
		time: string
	};
}

const Reservation = ({ reservation }: Reservation.Props) => {
	const [time, setTime] = useState('');

	useEffect(() => {
		setTime(reservation.time.toDate().toLocaleString( undefined, { hour: 'numeric', minute: 'numeric', hour12: true }));
	}, [reservation]);

	const iconStyle = {
		width: 20,
		height: 20,
		marginTop: 20,
		marginLeft: 0,
		marginRight: 0,
		marginBottom: 20
	};

	const tableIconStyle = {
		width: 30,
		height: 30,
		margin: 0,
		alignSelf: 'center'
	};

	const statusObject = {
		'booked': {
			icon: <div className='status-icon-container' style={{ background: '#6e6e6e' }}><BookIcon style={iconStyle}/>
			</div>
		},
		'left-message': {
			icon: <div className='status-icon-container' style={{ background: '#6e6e6e' }}><ChatIcon style={iconStyle}/>
			</div>
		},
		'confirmed': {
			icon: <div className='status-icon-container' style={{ background: '#6e6e6e' }}><CheckCircleIcon
				style={iconStyle}/></div>
		},
		'late-arrival': {
			icon: <div className='status-icon-container' style={{ background: '#eca16a' }}><WatchLaterIcon
				style={iconStyle}/></div>
		},
		'partially-arrived': {
			icon: <div className='status-icon-container' style={{ background: '#b1c1c9' }}><WatchLaterIcon
				style={iconStyle}/></div>
		},
		'arrived': {
			icon: <div className='status-icon-container' style={{ background: '#7fb5a8' }}><CheckCircleIcon
				style={iconStyle}/></div>
		},
		'no-show': {
			icon: <div className='status-icon-container' style={{ background: '#6e6e6e' }}><RemoveCircleIcon
				style={iconStyle}/></div>
		},
		'cancelled': {
			icon: <div className='status-icon-container' style={{ background: '#cf6679' }}><CancelIcon style={iconStyle}/>
			</div>
		}
	};

	return (
		<div className='reservation-container'>
			{statusObject[reservation.status].icon}
			<div className='head-count '><PersonIcon className='person-icon' fontSize='small'/>
				<div className='guest-count'>{reservation.guests}</div>
			</div>
			<div className='name-and-time-container'>
				<div className='reservation-time'> {time} </div>
				<div className='guest-name'>{reservation.fullName} </div>
			</div>
			<div className='table-section'>
				<div className='table-assignment-number'> 14</div>
				<TableIcon style={tableIconStyle}/>
			</div>
		</div>
	);
};

export default Reservation;
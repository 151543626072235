
import { CardNumberElement } from '@stripe/react-stripe-js';

export default async function getPaymentMethod(elements, stripe, fullName, email, phoneNumber) {
	const cardNumberElement = elements.getElement(CardNumberElement);
	return await stripe.createPaymentMethod({
		type: 'card',
		card: cardNumberElement,
		billing_details: {
			name: fullName,
			email,
			phone: phoneNumber
		}
	});
}
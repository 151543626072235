import firebase from 'firebase/app';
import 'firebase/firestore';

export default async function updateGuest (firestore: firebase.firestore.Firestore, id: string, { tags, guestNotes }: any): Promise<void> {
	const updateObj: any = {};

	if (tags) {
		updateObj.tags = tags;
	}

	if (guestNotes || guestNotes === '') {
		updateObj.guestNotes = guestNotes;
	}

	await firestore
		.collection('Guests')
		.doc(id)
		.update(updateObj);
}
import firebase from 'firebase/app';
import 'firebase/firestore';

export default async function updateUser (firestore: firebase.firestore.Firestore, id: string, { fullName, phoneNumber, email }): Promise<void> {
	const updateObj: any = {
		updatedTime: Date.now()
	};

	if (fullName) {
		updateObj.fullName = fullName;
	}

	if (email) {
		updateObj.email = email;
	}

	if (phoneNumber) {
		updateObj.phoneNumber = phoneNumber;
	}

	await firestore
		.collection('Users')
		.doc(id)
		.update(updateObj);
}
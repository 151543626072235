import { TDR } from 'tdr-common';
import isEqual from 'lodash/isEqual';
import { createPolicy, updatePolicy } from './pricingPolicies';
import { v4 as uuid } from 'uuid';
import isEmpty from 'lodash/isEmpty';

export default async function updateLargeGroupDeposit(
	token: string,
	restaurantPolicies: TDR.PricingPolicy[],
	tableId: string,
	restaurantId: string,
	bookingDeposit: {
		amount?: number,
		perPerson?: boolean
	}
): Promise<void> {

	try {
		//TODO: certainly not the most robust thing in the world, but fine for now
		const existingPolicy = restaurantPolicies.find(policy => {
			return policy.name === TDR.LARGE_GROUP_DEPOSIT_POLICY_NAME &&
			isEqual(policy.condition, { itemId: tableId });
		});
		const charge = {
			amount: bookingDeposit.amount || 0,
			target: 'deposit',
			type: bookingDeposit.perPerson ? 'per-guest' : 'add'
		} as TDR.PricingPolicy.Charge;
		//update current deposit(s) to not apply to this table.
		const originalRestaurantDeposits =restaurantPolicies.filter(policy => {
			return policy.charges.some(charge => charge.target === 'deposit') &&
				isEmpty(policy.condition);
		});
		if(originalRestaurantDeposits.length > 0) {
			await Promise.all(originalRestaurantDeposits.map(policy => {
				return updatePolicy(token, { ...policy, condition: {
					not: { itemId: tableId }
				} });
			}));
		}
		if(existingPolicy){
			if(existingPolicy.charges?.length === 1 && !isEqual(existingPolicy.charges[0], charge)) {
				existingPolicy.charges = [charge];
				await updatePolicy(token, existingPolicy);
			}
		}
		else {
			await createPolicy(token, {
				active: true,
				charges: [charge],
				condition: { itemId : tableId },
				id: uuid(),
				name: TDR.LARGE_GROUP_DEPOSIT_POLICY_NAME,
				priority: 1,
				restaurantId: restaurantId,
				targetType: 'item'
			});
		}
	}
	catch (error) {
		console.error('Failed to update deposits', error);
	}
}

import React, { useState, useEffect, useReducer } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import toPairs from 'lodash/toPairs';
import {
	Box,
	TextField,
	IconButton as MuiIconButton,
	Tab
} from '@material-ui/core';

import { styled } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
const IconButton = styled(MuiIconButton)(spacing);

import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import {
	Help
} from '@material-ui/icons';

import ReactMarkdown from 'react-markdown';
import helpDocSyntax from '../markdown/conditions/json.md';
import helpDocTests from '../markdown/conditions/tests.md';

import Modal from './Modal';

import { TDR } from 'tdr-common';


namespace Props {
	export type ConditionEditor = {
		condition?: TDR.PricingPolicy.Condition,
		disabled?: boolean,
		onChange: (condition: TDR.PricingPolicy.Condition) => void
	}
}

function parseOrFail (str:string) {
	try {
		return JSON.parse(str);
	}
	catch (e) {
		return null;
	}
}

export function ConditionHelpButton ({ buttonAttrs={} }: {buttonAttrs?: any}) {
	const [showHelp, setShowHelp] = useState(false);
	const [helpTab, setHelpTab] = useState('general-syntax');
	const setTab = (evt, newTab) => setHelpTab(newTab);

	return <>

		<IconButton onClick={() => setShowHelp(!showHelp)} {...buttonAttrs}>
			<Help />
		</IconButton>

		<TabContext value={helpTab}>
			<Modal
				open={showHelp}
				onClose={() => setShowHelp(false)}
				styleOverride={{ width: '80%', height: '80%', maxWidth: '80%' }}
				title={(<Box style={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={setTab}>
						<Tab label="General Syntax" value="general-syntax" />
						<Tab label="Tests" value="tests" />
					</TabList>
				</Box>)}
			>
				<TabPanel value="general-syntax"><ReactMarkdown className='markdown' >{helpDocSyntax}</ReactMarkdown></TabPanel>
				<TabPanel value="tests"><ReactMarkdown className='markdown' >{helpDocTests}</ReactMarkdown></TabPanel>
			</Modal>
		</TabContext>
	</>;
}

export function ConditionEditor({ condition: initialCondition, onChange, disabled = false }: Props.ConditionEditor) {
	const [conditionJSON, setConditionJSON] = useState<string>(JSON.stringify(initialCondition || {}, null, 2));
	const [condition, setCondition] = useReducer((prev, newCond) => isEqual(prev, newCond) ? prev : newCond, {});
	const [errors, setErrors] = useState<ConditionError[]>([]);

	useEffect(() => {
		const conditionObj = parseOrFail(conditionJSON);
		const errorsArr = validationErrors(conditionObj);
		setErrors(errorsArr);
		if (isEmpty(errorsArr) &&
            !isEqual(condition, conditionObj)
		) {
			setCondition(conditionObj);
		}
	}, [conditionJSON]);

	useEffect(() => {
		onChange(condition), [condition];
	}, [condition]);

	return <Box display='flex' flexDirection='column'>
		<TextField
			multiline
			disabled={disabled}
			value={conditionJSON}
			style={{ backgroundColor: isEmpty(errors) ? '#8F8' : '#F88' }}
			inputProps={{
				style: { color: '#000', fontFamily: 'monospace', fontWeight: 'bold' },
				spellCheck: false
			}}
			onChange={(evt) => setConditionJSON(evt.target.value)}
		/>

		{errors.map(({ keys, error }, index) => <Box key={index} style={{ fontFamily: 'monospace' }}>
			{keys.join(' > ')} : {error}
		</Box>)}
	</Box>;
}

type ConditionError = {
	keys?: string[],
	error: string,
	value: any
}

const check = (error, test) =>
	(value) => {
		try {
			if (test(value)) {
				return null;
			}
		}
		catch (err) {}
		return { error, value } as ConditionError;
	};



const KeyValidators = {
	and: noop,
	or: noop,
	not: noop,
	betweenTimes: check('betweenTimes requires an array of two strings, in HH:mm format',
		x => isArray(x) && x.length === 2 && x.every(v => v.match(/^\d?\d:\d\d$/))),
	betweenGuests: check('betweenGuests requires an array of two numbers, the minimum and maximum number of guests',
		x => isArray(x) && x.length === 2 && x.every(v => typeof v === 'number')),
	daysOfWeek: check(`daysOfWeek expects an array of one or more of the following strings: ${Object.values(TDR.Weekday).map(s => `"${s}"`).join(', ')}`,
		x => isArray(x) && x.every(v => Object.values(TDR.Weekday).includes(v))),
	dates: check('Expects an array of date specifications to match, ie: \'june\', \'june 1\', \'june 1, 2010\', \'2010-06-01\', \'06-01\'',
		x => isArray(x) && x.every(v => v.match(/^(([a-zA-Z]+( \d?\d(, \d{4})?)?)|((\d{4}-)?\d{1-2}-\d{1-2}))$/))),
	betweenDates: check('betweenDates expects an array of two strings, formatted yyyy-MM-dd or MM-dd: ',
		x => isArray(x) && x.length === 2 && x.every(v => v.match(/^(\d{4}-)?\d?\d-\d?\d$/))),
	itemType: check(`itemType must be one of the following: ${Object.values(TDR.Reservation.ItemType).map(s => `"${s}"`).join(', ')}`,
		x => Object.values(TDR.Reservation.ItemType).includes(x)),
	itemId: check('itemId expects a string which is the id of the item (probably a table)',
		isString),
	tag: check('tag must be a single string, "like-this", or a list of strings, ["like", "this"]. Applies to items with any matching tag',
		x => isString(x) || (isArray(x) && x.every(v => isString(v)))),
	guestContact: check('guestContact expects an object, {}, that specifies either an email or a phone, ie {"phone": "1231231234"}',
		x => isString(x?.phone) || isString(x?.email)),
	toAccount: check(
		['toAccount expects an object, {}, that specifies one or more of the following:',
			`type: ${Object.values(TDR.Invoice.PartyType).map(s => `"${s}"`).join(', ')}`,
			`region: ${Object.values(TDR.StripeRegion).map(s => `"${s}"`).join(', ')}`,
			'id: a string containing a specific account id'
		].join('\n\t'),
		x => (Object.keys(x).every(k => ['type', 'id', 'region'].includes(k)) &&
            (!x.type || Object.values(TDR.Invoice.PartyType).includes(x.type)) &&
            (!x.region || Object.values(TDR.StripeRegion).includes(x.region)) &&
            (!x.id || isString(x.id))
		)),
	hasItem: check(
		['hasItem expects an object, {}, that specifies one or more of the following:',
			`type: ${Object.values(TDR.Reservation.ItemType).map(s => `"${s}"`).join(', ')}`,
			'id: a string containing a specific item id'
		].join('\n\t'),
		x => (Object.keys(x).every(k => ['type', 'id'].includes(k)) &&
            (!x.type || Object.values(TDR.Reservation.ItemType).includes(x.type)) &&
            (!x.id || isString(x.id))
		)),
	hasTag: check(
		'hasTag expects a quoted string, "like this", or an array of strings, ["like", "this"]',
		x => isString(x) || (isArray(x) && x.every(isString))
	)
};


function validationErrors (condition: any, keypath?:string[]) :ConditionError[] {

	if (!condition) {
		return [{ keys: [], error: 'Condition is not valid JSON', value: null }];
	}

	keypath = keypath || [];
	let errors:ConditionError[] = [];

	toPairs(condition).forEach(([key, value]) => {
		const validator = KeyValidators[key];
		const localKeyPath = [...keypath, key];
		if (!validator) {
			errors.push({ keys: localKeyPath, error: `unrecognized key '${key}'`, value });
		}

		// should be a good way to attach keypath to errors and return multiples from the validation
		// but this is more of a formality than anything else anyways, so fuck it for now
		// we'll see what this ends up getting replaced with later on
		else if (['or', 'and'].includes(key)) {
			if (!isArray(value)) {
				errors.push({ keys: localKeyPath, error: `${key} clause requires an array, [], of conditions`, value });
			}
			else {
				errors = errors.concat(validationErrors(value, localKeyPath));
			}
		}
		else {
			const error = validator(value);
			error && errors.push({ keys: localKeyPath, ...error });
		}
	});

	return errors;
}
import { TDR } from 'tdr-common';
import config from '../common/config';

async function migrateRestaurantData (userToken: string, restaurant: TDR.Restaurant): Promise<any> {

	const response = await fetch(`${config.adminApiHost}/migrate/restaurant/${restaurant.id}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${userToken}`
		},
		body: JSON.stringify(restaurant)
	});

	const responsePayload = await response.json();
	return responsePayload;
}
export default migrateRestaurantData;
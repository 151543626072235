import firebase from 'firebase';
import { TDR } from 'tdr-common';

export const updateRestaurantSettings = async (
	firestore: firebase.firestore.Firestore,
	restaurantId: string,
	restaurantSettings: TDR.Restaurant['settings']
) => {
	const docRef = firestore.collection('Restaurants').doc(restaurantId);
	await docRef.update({ settings: restaurantSettings });
};

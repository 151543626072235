import React, { useState, useContext } from 'react';
import {
	TextField,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Button,
	CircularProgress
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import ContactInformation from './ContactInformation';
import VisitInformation from './VisitInformation';
import { Guest, GuestContext } from '../context/GuestContext';
import { AdminReservationContext } from '../context/AdminReservationContext';

namespace GuestSelector {
	export type Props = {
		newReservation: any,
		setNewReservation: (newReservation: any)=>void,
		confirmButtonDisabled: boolean,
		confirmReservation: ()=>void
	};
}

const GuestSelector = ({ newReservation, setNewReservation, confirmButtonDisabled, confirmReservation }: GuestSelector.Props) => {
	const { guests, selectedGuest, setSelectedGuest, updateUser, updateGuest, createGuestAndUser } = useContext(GuestContext);
	const { processing } = useContext(AdminReservationContext);

	const [search, setSearch] = useState('');
	const [selectedGuestUserId, setSelectedGuestUserId] = useState('');
	const [guestSelectedTimeout, setGuestSelectedTimeout] = useState(null);

	return (
		<div className='guest-search'>
			<div className='list-item-selector'>
				<div className='guest-search-input'>
					<TextField
						className='search-text-field'
						placeholder='Search by name or phone number'
						variant='outlined'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: search && (
								<InputAdornment position='end'>
									<IconButton onClick={() => setSearch('')} style={{ padding: 0 }}>
										<CancelIcon style={{ fontSize: '13px' }}/>
									</IconButton>
								</InputAdornment>
							)
						}}
						value={search}
						onChange={(evt) => setSearch(evt.target.value)}
					/>
					<List>
						{search && (
							<>
								<ListItem
									divider
									button
									style={{ color: '#7FB5A8' }}
									onClick={() => {
										setSearch('');
										setSelectedGuestUserId('');
										clearTimeout(guestSelectedTimeout);
										setGuestSelectedTimeout(null);
										setSelectedGuest({} as Guest);
									}}
								>
									Add to Guestbook
								</ListItem>
								{guests
									.filter((guest) => (
										guest.fullName.toLowerCase().includes(search.toLowerCase()) || guest.phoneNumber.includes(search)
									))
									.map((guest) => {
										const selected = selectedGuestUserId === guest.userId;

										return (
											<ListItem
												key={guest.userId}
												button
												divider
												selected={selected}
												onClick={() => {
													if (selected) {
														setSelectedGuestUserId('');
														clearTimeout(guestSelectedTimeout);
														setGuestSelectedTimeout(null);
														setSelectedGuest(null);
													}
													else {
														setSelectedGuestUserId(guest.userId);
														setGuestSelectedTimeout(
															setTimeout(() => {
																setSearch('');
																setSelectedGuest({ ...guest });
															}, 1000)
														);
													}
												}}
											>
												<ListItemText>
													{guest.fullName}
												</ListItemText>
												<ListItemSecondaryAction>
													{guest.phoneNumber}
												</ListItemSecondaryAction>
											</ListItem>
										);
									})}
							</>
						)}
					</List>
				</div>
			</div>
			{selectedGuest
				&& (
					<>
						<div className='guest-profile'>
							<div>
								<ContactInformation
									guest={selectedGuest}
									onSave={(data) => {
										// if guest exists, we are doing an update.
										if (selectedGuest?.id) {
											updateUser(selectedGuest.userId, data);
										}
										else {
											createGuestAndUser({ ...data });
										}
									}}
								/>
								<div style={{ marginTop: '3em' }}>
									<VisitInformation
										guest={selectedGuest}
										updateGuest={selectedGuest?.id ? updateGuest : () => console.warn('TODO: save the tags for when creating a new guest')}
										reservation={newReservation}
										updateReservation={(id, updateObj) => {
											setNewReservation({ ...newReservation, ...updateObj });
										}}
									/>
								</div>
							</div>
						</div>
						<div className='confirm-reservation-btn'>
							<Button
								variant='contained'
								color='primary'
								disabled={confirmButtonDisabled}
								onClick={confirmReservation}
							>
								{processing && <CircularProgress size={20} color='secondary' style={{ marginRight: '10px' }}/>}
								Confirm Reservation
							</Button>
						</div>
					</>
				)}
		</div>
	);
};

export default GuestSelector;
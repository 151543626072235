import React, { useContext } from 'react';
import { Typography, TextField, InputAdornment } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { ClickableTextFieldOptionsModal } from './Modals';
import { ModalContext } from '../context/ModalContext';


namespace ModalControlledTextField {
	export type Props = {
		title?: string,
		value: string,
		onSave: (content: string)=>void,
		onDelete: ()=>void
	};
}

const ModalControlledTextField = ({ title = '', value, onSave, onDelete }: ModalControlledTextField.Props) => {
	const { openModal } = useContext(ModalContext);
	return (
		<div>
			<Typography variant='subtitle1'>{title}</Typography>
			<TextField
				className='display-only-area clickable'
				disabled={true}
				value={value}
				variant='outlined'
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<AssignmentIcon />
						</InputAdornment>
					)
				}}
				onClick={() => openModal(
					<ClickableTextFieldOptionsModal
						onClose={null}
						guestNotes={value}
						onDelete={onDelete}
						onSave={onSave}
					/>
				)}
			/>
		</div>
	);
};

export default ModalControlledTextField;
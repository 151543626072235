import firebase from 'firebase/app';
import 'firebase/firestore';
import { formatPhoneNumber } from 'tdr-common';

export default async function updateReservation(firestore: firebase.firestore.Firestore, id: string, { fullName, email, phone, guests, guestNotes, notesByRestaurant, specialOccasion }: any) {
	const updateObj: any = {};

	if (fullName) {
		updateObj.fullName = fullName;
	}

	if (email) {
		updateObj.email = email;
	}

	if (phone) {
		updateObj.phone = formatPhoneNumber(phone);
	}

	if (guests) {
		updateObj.guests = guests;
	}

	if (guestNotes || guestNotes === '') {
		updateObj.guestNotes = guestNotes;
	}

	if (notesByRestaurant || notesByRestaurant === '') {
		updateObj.notesByRestaurant = notesByRestaurant;
	}

	if (specialOccasion) {
		updateObj.specialOccasion = specialOccasion;
	}

	await firestore
		.collection('Reservations')
		.doc(id)
		.update(updateObj);
}
import { Box, Chip, IconButton, InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import { RgbaStringColorPicker } from 'react-colorful';
import { RGBA, isRGBA } from '../admin-widgets/types';
import { Clear } from '@material-ui/icons';

export interface RgbaColorFieldProps {
	value: RGBA | '';
	onChange: (color: RGBA | '') => void;
	label: string;
	disabled?: boolean;
}

export function RgbaColorField({
	value,
	label,
	disabled,
	onChange
}: RgbaColorFieldProps) {
	const [color, setColor] = React.useState<RGBA | ''>(value);
	const [showPicker, setShowPicker] = React.useState<boolean>(false);
	const [pickerFocused, setPickerFocused] = React.useState<boolean>(false);
	// TODO https://app.tablz.com/embed/mezcalero?color=000 needs to work
	return (
		<Box
			style={{
				display: 'flex',
				flexDirection: 'column-reverse',
				alignItems: 'center',
				position: 'relative'
			}}
		>
			<RgbaStringColorPicker
				color={color}
				onChange={(newColor) => {
					if (isRGBA(newColor)) {
						onChange(newColor as RGBA);
					}
					else if (newColor === '') {
						onChange(null);
					}
					setColor(newColor as RGBA);
				}}
				style={{
					height: '120px',
					width: showPicker || pickerFocused ? '120px' : 0,
					opacity: showPicker || pickerFocused ? 1 : 0,
					position: 'absolute',
					right: 0,
					transition: 'all 0.2s ease-in-out',
					zIndex: 2
				}}
				onFocus={() => setPickerFocused(true)}
				onBlur={() => setPickerFocused(false)}
			/>
			<TextField
				disabled={disabled}
				label={label}
				variant='outlined'
				InputLabelProps={{ shrink: true }}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<Chip style={{ backgroundColor: color }}/>
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton onClick={() => {
								setColor('');
								onChange('');
							}}>
								<Clear />
							</IconButton>
						</InputAdornment>
					)
				}}
				onChange={(e) => {
					if (isRGBA(e.target.value)) {
						onChange(e.target.value as RGBA);
					}
					else if (e.target.value === '') {
						onChange('');
					}
					setColor(e.target.value as RGBA);
				}}
				value={color || ''}
				onFocus={() => setShowPicker(true)}
				onBlur={() => setShowPicker(false)}
			/>
		</Box>
	);
}

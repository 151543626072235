import React from 'react';
import { Select as MuiSelect, MenuItem, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


namespace Select {
	export type Props = {
		value: any,
		onChange:(evt: any)=>void,
		startAdornment?: JSX.Element,
		classes?: string,
		options: {label: string, value: any, isSelected?: boolean}[],
		style?: any
	};
}

const Select = ({ value, onChange, startAdornment, options, classes = '', style = {} }: Select.Props) => {
	const theme = useTheme();
	const native = useMediaQuery(theme.breakpoints.down('md'));

	const defaultMenuItemBackground = 'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212';
	return (
		<MuiSelect
			style={style}
			native={native}
			className={`select ${classes}`}
			displayEmpty
			variant='outlined'
			value={value}
			onChange={(evt: any) => {
				onChange(evt);
				// Need to blur the element so that the border around it disappear upon element being selected
				if (native) {
					evt.target?.blur?.();
				}
				else {
					// In order to manually blur non-native select boxes, we need to do a setTimeout
					// due to the possibility that MenuItem still has the focus. If this is not done
					// our manual blur will not work.
					setTimeout(() => {
						(document.activeElement as any).blur();
					}, 0);
				}
			}}
			startAdornment={startAdornment}
			IconComponent={(props) => <KeyboardArrowDownIcon {...props} style={{ color: 'rgba(255, 255, 255, 0.87)' }}/>}
		>
			{options.map((option, index) => (
				native
					? <option key={index} value={option.value}>{option.label}</option>
					: (
						<MenuItem
							key={index}
							value={option.value}
							style={{
								background: option.isSelected
									? 'rgba(255, 255, 255, 0.87)'
									: (!option.value ? defaultMenuItemBackground : ''),
								color: option.isSelected ? '#121212' : ''
							}}
						>
							{option.label}
						</MenuItem>
					)
			))}
		</MuiSelect>
	);
};

export default Select;
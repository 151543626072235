import React, { createContext } from 'react';
import merge from 'lodash/merge';
import { BrowserRouter as Router, Switch, Route, useRouteMatch, RouteChildrenProps, RouteComponentProps } from 'react-router-dom';
import { ProviderProps } from '../common/ProviderProps';

export namespace URLContext {
	export type Value = {
		restaurantSlug: string,
		tableSlug: string,
		view: string,

		reservationId: string
	};
}

const contextValue: URLContext.Value = {
	restaurantSlug: null,
	tableSlug: null,
	view: null,
	reservationId: null
};
export const URLContext = createContext<URLContext.Value>(contextValue);
URLContext.displayName = 'URL Context';

export const URLProvider = ({ children }: ProviderProps) => {
	return (
		<Router>
			<Switch>
				<Route
					path='/reservations/:reservationId?'
					exact
					component={(routeProps: RouteComponentProps) => (
						<Container {...routeProps}>
							{children}
						</Container>
					)}
				/>
				<Route
					path='/(restaurants)?/:restaurantSlug?/:tableSlug?/:view?'
					exact
					component={(routeProps: RouteComponentProps) => (
						<Container {...routeProps}>
							{children}
						</Container>
					)}
				/>
			</Switch>
		</Router>
	);
};

const Container = ({ children }: ProviderProps & RouteChildrenProps) => {
	const { params } = useRouteMatch<URLContext.Value>();
	merge(contextValue, params);

	return (
		<URLContext.Provider value={params}>
			{children}
		</URLContext.Provider>
	);
};
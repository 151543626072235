import {
	Container
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useState } from 'react';

import { PricingPolicyContext } from '../context/PricingPolicyContext';

import Notice from './Notice';
import { PricingPolicyListEditor } from './PricingPolicyEditor';


const AdminPricingPolicies = () => {
	const { taxPolicies, payoutPolicies } = useContext(PricingPolicyContext);
	const [errorMsg, setErrorMsg] = useState<string>('');


	return (
		<Container maxWidth='xl'>
			{!isEmpty(errorMsg) && <Notice message={errorMsg} onClear={() => setErrorMsg('')} />}

			<h2 style={{ marginTop: '44px' }}>Taxes</h2>
			<PricingPolicyListEditor
				policies={taxPolicies}
				type='tax'
				disableReorder={true}
				setErrorMsg={setErrorMsg}
			/>

			<h2>Payouts</h2>
			<PricingPolicyListEditor
				policies={payoutPolicies}
				type='payout'
				disableReorder={true}
				setErrorMsg={setErrorMsg}
			/>

		</Container>
	);
};

export default AdminPricingPolicies;



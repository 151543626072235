
import { Box, Checkbox, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import {
	AddCircle as AddIcon,
	NotInterested as NotInterestedIcon
} from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../context/FirebaseContext';

type Props = {
	dates: string[],
	onChange: (dates: string[], description: string) => void
	reload?: () => void,
	allRestaurants?: boolean
};

export const DatesDescription = ({ dates, onChange, reload, allRestaurants = false }: Props) => {
	const [description, setDescription] = useState('');
	const [selectedDates, setSelectedDates] = useState([]);
	const [loading, setLoading] = useState(false);
	const { firestore } = useContext(FirebaseContext);

	const batchUpdate = async (all: boolean, scope?: string) => {
		setLoading(true);
		console.log('loading');
		const collectionRef = firestore.collection('Restaurants');
		const query = all ? collectionRef : collectionRef.where('stripeRegion', '==', scope);
		const documents = await query.get();
		const writeBatch = firestore.batch();

		for (const doc of documents.docs) {
			const restaurant = doc.data();

			const newData = {
				closed: {
					...restaurant.closed,
					dates: restaurant.closed.dates.map((obj) => (selectedDates.includes(obj.date) ? {
						date: obj.date,
						description: description
					} : obj))
				}
			};

			writeBatch.update(collectionRef.doc(doc.id), newData);
		}
		await writeBatch.commit();
		console.log('done');
		reload();
		setLoading(false);
		setDescription('');
	};

	return(
		<Box display='flex' flexDirection='row' alignItems='center' style={{ width: '100vw' }}>
			<div>Closed Dates Description</div>
			<FormControl style={{ marginRight: '10px', flexBasis: '30%' }}>
        		<InputLabel>Dates</InputLabel>
				<Select
					input={<OutlinedInput label="Dates" />}
					multiple
					value={selectedDates}
					onChange={(e)=>setSelectedDates(e.target.value as string[])}
					renderValue={(selected) => (selected as string[]).join(', ')}
				>
					{dates.map((date) => (
						<MenuItem key={date} value={date}>
							<Checkbox checked={selectedDates.indexOf(date) > -1} />
							<ListItemText primary={date} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<TextField
				value={description}
				onChange={(e)=>setDescription(e.target.value)}
				style={{ flexBasis: '50%' }}
			/>
			<Box display='flex' flexDirection='row' alignItems='center' style={{ flexWrap: 'wrap' }}>
				<div style={{ display:'flex', flexDirection:'row', width:'170px' }}>
					<div>THIS RESTAURANT</div>
					<IconButton onClick={() => {
						onChange(selectedDates, description);
						setDescription('');
					}}>
						<AddIcon />
					</IconButton>
				</div>
				{ allRestaurants &&
					<>
						<div style={{ display:'flex', flexDirection:'row', width:'170px' }}>
							<div> ALL USA RESTAURANTS</div>
							<IconButton disabled={loading} onClick={() => batchUpdate(false, 'usa')}>
								{loading ? <NotInterestedIcon /> : <AddIcon /> }
							</IconButton>
						</div>
						<div style={{ display:'flex', flexDirection:'row', width:'170px' }}>
							<div> ALL CANADIAN RESTAURANTS</div>
							<IconButton disabled={loading} onClick={() => batchUpdate(false, 'can')}>
								{loading ? <NotInterestedIcon /> : <AddIcon /> }
							</IconButton>
						</div>
						<div style={{ display:'flex', flexDirection:'row', width:'170px' }}>
							<div> ALL RESTAURANTS</div>
							<IconButton disabled={loading} onClick={() => batchUpdate(true)}>
								{loading ? <NotInterestedIcon /> : <AddIcon /> }
							</IconButton>
						</div>
					</>
				}
			</Box>
		</Box>
	);
};
import React, { useState, useEffect, useContext, createContext } from 'react';
import { TDR } from 'tdr-common';
import { ProviderProps } from '../common/ProviderProps';
import { FirebaseContext } from './FirebaseContext';
import { RestaurantContext } from './RestaurantContext';


export namespace PricingPolicyContext {
	export type Value = {
		taxPolicies: TDR.PricingPolicy[],
		retiredTaxPolicies: TDR.PricingPolicy[],
		payoutPolicies: TDR.PricingPolicy[],
		retiredPayoutPolicies: TDR.PricingPolicy[],
		restaurantPolicies: TDR.PricingPolicy[],
		retiredRestaurantPolicies: TDR.PricingPolicy[]
	};
}

export const PricingPolicyContext = createContext<PricingPolicyContext.Value>({
	taxPolicies: [],
	retiredTaxPolicies: [],
	payoutPolicies: [],
	retiredPayoutPolicies: [],
	restaurantPolicies: [],
	retiredRestaurantPolicies: []
});

PricingPolicyContext.displayName = 'PricingPolicy';

export const PricingPolicyProvider = ({ children }: ProviderProps) => {

	const { initialized, firestore } = useContext(FirebaseContext);
	const { selectedRestaurant } = useContext(RestaurantContext);

	const [taxPolicies, setTaxPolicies] = useState<TDR.PricingPolicy[]>([]);
	const [retiredTaxPolicies, setRetiredTaxPolicies] = useState<TDR.PricingPolicy[]>([]);

	const [restaurantPolicies, setRestaurantPolicies] = useState<TDR.PricingPolicy[]>([]);
	const [retiredRestaurantPolicies, setRetiredRestaurantPolicies] = useState<TDR.PricingPolicy[]>([]);

	const [payoutPolicies, setPayoutPolicies] = useState<TDR.PricingPolicy[]>([]);
	const [retiredPayoutPolicies, setRetiredPayoutPolicies] = useState<TDR.PricingPolicy[]>([]);

	useEffect(() => {
		if (!initialized) {
			setTaxPolicies([]);
			return;
		}

		const instance = Math.floor(Math.random() * 1000);

		const unsub = (firestore.collection('PricingPolicies')
			.where('active', '==', true)
			.orderBy('tax') // don't actually care about the order - just want to force the attr to exist
			.onSnapshot((snapshot) => {
				const policies = snapshot.docs.map(doc => doc.data() as TDR.PricingPolicy);
				setTaxPolicies(policies);
			}));

		return () => {
			console.log('TAX POLICY UNSUB', instance);
			unsub();
			setTaxPolicies([]);
		};

	}, [initialized]);



	useEffect(() => {
		if (!initialized) {
			setRetiredTaxPolicies([]);
			return;
		}

		const unsub = (firestore.collection('PricingPolicies')
			.where('active', '==', false)
			.where('replacedBy', '==', null)
			.orderBy('tax') // don't actually care about the order - just want to force the attr to exist
			.onSnapshot((snapshot) => {
				const policies = snapshot.docs.map(doc => doc.data() as TDR.PricingPolicy);
				setRetiredTaxPolicies(policies);
			}));

		return () => {
			unsub();
			setRetiredTaxPolicies([]);
		};

	}, [initialized]);







	useEffect(() => {
		if (!initialized) {
			setPayoutPolicies([]);
			return;
		}

		const instance = Math.floor(Math.random() * 1000);

		const unsub = (firestore.collection('PricingPolicies')
			.where('active', '==', true)
			.orderBy('payout') // don't actually care about the order - just want to force the attr to exist
			.onSnapshot((snapshot) => {
				const policies = snapshot.docs.map(doc => doc.data() as TDR.PricingPolicy);
				setPayoutPolicies(policies);
			}));

		return () => {
			console.log('PAYOUT POLICY UNSUB', instance);
			unsub();
			setPayoutPolicies([]);
		};

	}, [initialized]);



	useEffect(() => {
		if (!initialized) {
			setRetiredPayoutPolicies([]);
			return;
		}

		const unsub = (firestore.collection('PricingPolicies')
			.where('active', '==', false)
			.where('replacedBy', '==', null)
			.orderBy('payout') // don't actually care about the order - just want to force the attr to exist
			.onSnapshot((snapshot) => {
				const policies = snapshot.docs.map(doc => doc.data() as TDR.PricingPolicy);
				setRetiredPayoutPolicies(policies);
			}));

		return () => {
			unsub();
			setRetiredPayoutPolicies([]);
		};

	}, [initialized]);







	useEffect(() => {
		if (!initialized || !selectedRestaurant) {
			setRestaurantPolicies([]);
			return;
		}

		const unsub = (firestore.collection('PricingPolicies')
			.where('restaurantId', '==', selectedRestaurant.id)
			.where('active', '==', true)
			.onSnapshot((snapshot) => {
				const policies = snapshot.docs.map(doc => doc.data() as TDR.PricingPolicy);
				setRestaurantPolicies(policies);
			}));

		return () => {
			unsub();
			setRestaurantPolicies([]);
		};

	}, [initialized, selectedRestaurant]);


	useEffect(() => {
		if (!initialized || !selectedRestaurant) {
			setRetiredRestaurantPolicies([]);
			return;
		}

		const unsub = (firestore.collection('PricingPolicies')
			.where('restaurantId', '==', selectedRestaurant.id)
			.where('active', '==', false)
			.where('replacedBy', '==', null)
			.onSnapshot((snapshot) => {
				const policies = snapshot.docs.map(doc => doc.data() as TDR.PricingPolicy);
				setRetiredRestaurantPolicies(policies);
			}));

		return () => {
			unsub();
			setRetiredRestaurantPolicies([]);
		};

	}, [initialized, selectedRestaurant]);



	const valueObj: PricingPolicyContext.Value = {
		taxPolicies,
		retiredTaxPolicies,
		payoutPolicies,
		retiredPayoutPolicies,
		restaurantPolicies,
		retiredRestaurantPolicies
	};

	return (
		<PricingPolicyContext.Provider value={valueObj}>
			{children}
		</PricingPolicyContext.Provider>
	);
};




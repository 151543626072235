import firebase from 'firebase';
import { TDR } from 'tdr-common';
import * as Sentry from '@sentry/react';

export default async function getFeatureFlagMap (
	firestore: firebase.firestore.Firestore
): Promise<Record<string, TDR.FeatureFlags>> {
	try {
		const collection = firestore.collection('FeatureFlags');
		const snapshot = await collection.get();

		const restaurantToFeatureFlagsMap: Record<string, TDR.FeatureFlags> = {};

		snapshot.forEach((doc) => {
			const featureFlags = doc.data() as TDR.FeatureFlags;
			restaurantToFeatureFlagsMap[featureFlags.restaurantId] = featureFlags;
		});

		return restaurantToFeatureFlagsMap;
	}
	catch (error) {
		console.error('Error fetching FeatureFlags', error);
		Sentry.captureException(error);
	}
}
import React from 'react';
import isUndefined from 'lodash/isUndefined';
import { IconButton as MuiIconButton, Tooltip } from '@material-ui/core';
import { Code } from '@material-ui/icons';


import { styled } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
const IconButton = styled(MuiIconButton)(spacing);


function str (x) {
	try {
		return JSON.stringify(x, null, 2);
	}
	catch (err) {
		return x.toString();
	}
}


export default function DebugLogButton ({ tooltip, log, style, attrs={} }: {tooltip?: boolean, log: any, style?: React.CSSProperties, attrs?:any}) {

	tooltip = isUndefined(tooltip) ? true : tooltip;

	const button = <IconButton
		{...attrs}
		style={style}
		onClick={() => {
			navigator.clipboard.writeText(str(log));
			console.log(log);
		}}
	>
		<Code />
	</IconButton>;

	return (tooltip
		? <Tooltip title={str(log)} PopperProps={{ className: 'debug-log-popper' }}>{button}</Tooltip>
		: button
	);
}
import { TDR } from 'tdr-common';
import config from '../common/config';

export default async function modifyReservation(reservation: Partial<TDR.Reservation>) {
	console.warn('Modifying reservation...', reservation);
	const response = await fetch(`${config.apiHost}/modifyReservation`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reservation)
	});

	return response.json();
}
import React, { useState, useContext, useEffect } from 'react';
import isNull from 'lodash/isNull';
import { Divider, Switch, Typography, Button, Checkbox, TextField, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, List, Box } from '@material-ui/core';
import {
	KeyboardArrowRight as KeyboardArrowRightIcon,
	Check
} from '@material-ui/icons';
import { ModalContext } from '../context/ModalContext';
import Modal from './Modal';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DatePicker from './DatePicker';
import { capitalize, to12HourTimeFormat } from 'tdr-common';

namespace Modals {
	export type Props = {
		open?: boolean,
		redirectLink?: string,
		callNumber?: string,
		onClose?: () => void
	};
	export type DateShiftProps = {
		onClose?: () => void,
		onSave?: () => void,
		selectedDate: string,
		selectedShifts: any
	}
	export type CallGuestProps = {
		onClose?: () => void,
		guestName: string,
		guestPhoneNumber: string
	}
	export type ClickableTextProps = {
		onClose?: () => void,
		onDelete?: () => void,
		onSave?: (content: string) => void,
		guestNotes: string
	}
	export type ConfirmProps = {
		onClose?: () => void,
		onConfirm?: () => void,
		title?: string,
		text?: string
	}
}

const Modals = () => {
	const { modal } = useContext(ModalContext);
	return (
		<>
			{!isNull(modal) && modal}
		</>
	);
};

export default Modals;


export const TagSelector = ({
	title,
	availableOptions = [],
	selectedOptions = [],
	icon,
	onSave,
	onClose
}: { title: string, availableOptions: any[], selectedOptions: any[], icon: JSX.Element, onSave: (tags: string[]) => void|Promise<any>, onClose: ()=>void}) => {
	const { closeModal } = useContext(ModalContext);
	const titleSlug = title.toLowerCase().replace(/\s/gu, '');
	const [mutableSelectedOptions, setMutableSelectedOptions] = useState(selectedOptions);

	const onSelect = (option) => {
		if (mutableSelectedOptions.includes(option)) {
			const mutableSelectedOptionsCopy = [...mutableSelectedOptions];
			mutableSelectedOptionsCopy.splice(mutableSelectedOptionsCopy.indexOf(option), 1);
			setMutableSelectedOptions([...mutableSelectedOptionsCopy]);
		}
		else {
			setMutableSelectedOptions([...mutableSelectedOptions, option]);
		}
	};

	return (
		<Modal
			onClose={onClose}
			title='Select Tags'
			rightSideIcon={<Check />}
			onClickRightIcon={() => {
				onSave(mutableSelectedOptions);
				closeModal();
			}}
			shouldBeDrawerOnMobile={true}
		>
			<div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
				<Typography variant='subtitle1'>{title}</Typography>
				<List className='tag-selector'>
					{availableOptions.map((option, index) => {
						const isLastItem: boolean = (index === (availableOptions.length - 1));
						return (
							<ListItem key={`${titleSlug}-${index}`} button divider={!isLastItem} onClick={() => onSelect(option.id)}>
								<ListItemIcon style={{ minWidth:'2.5em' }}>
									{icon}
								</ListItemIcon>
								<ListItemText id={option.id} primary={option.label} />
								<ListItemSecondaryAction>
									<Checkbox
										checked={mutableSelectedOptions.includes(option.id)}
										onChange={() => onSelect(option.id)}
										name={option.label}
									/>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
				</List>
			</div>
		</Modal>
	);
};

export const ClickableTextFieldOptionsModal = ({ onClose, onDelete, onSave, guestNotes }: Modals.ClickableTextProps) => {
	const { closeModal } = useContext(ModalContext);
	const [editMode, setEditMode] = useState(false);
	const [content, setContent] = useState(guestNotes);
	const notesTitle = editMode ? 'Description' : 'Edit Note';

	return (
		<Modal onClose={onClose} title={notesTitle} shouldBeDrawerOnMobile={true}>
			{editMode
				? (
					<div>
						<TextField
							multiline
							maxRows={4}
							value={content}
							onChange={evt => setContent(evt.target.value)}
							variant="outlined"
						/>
						<Button
							variant='contained'
							color='primary'
							onClick={() => {
								onSave(content);
								closeModal();
							}}
						>
							Save Changes
						</Button>
					</div>
				)
				: (
					<>
						<Button variant='contained' color='primary' onClick={() => setEditMode(true)}>Edit</Button>
						<Button
							variant='contained'
							color='secondary'
							onClick={() => {
								onDelete();
								closeModal();
							}}
						>
							Delete Note
						</Button>
					</>
				)}
		</Modal>
	);
};

export const DateShiftModal = ({ onClose, onSave, selectedDate, selectedShifts }: Modals.DateShiftProps) => {
	const [date, setDate] = useState(new Date(selectedDate));
	const [shifts, setShifts] = useState(selectedShifts);
	const [selectAll, setSelectAll] = useState(false);

	useEffect(() => {
		let allSelected = true;
		Object.values(shifts).forEach((val) => {
			if (val === false) {
				allSelected = false;
			}
		});
		setSelectAll(allSelected);
	}, [shifts]);

	const handleShiftChange = (event) => {
		setShifts({ ...shifts, [event.target.name]: event.target.checked });
	};
	const handleShiftClick = (key) => {
		setShifts({ ...shifts, [key]: !shifts[key] });
	};
	const handleAllShiftsChange = (event) => {
		setSelectAll(event.target.checked);
		let tempShifts = shifts;
		Object.keys(shifts).forEach((key) => {
			tempShifts = { ...tempShifts, [key]: event.target.checked };
		});
		setShifts(tempShifts);
	};

	return (
		<Modal onClose={onClose} shouldBeDrawerOnMobile={true}>
			<div className='date-shift-selector-modal'>
				<div className='desktop-shift'>
					{Object.entries(shifts).map(([key, value]) => {
						return (
							<Button
								key={key}
								className='shift-select-button'
								style={value ? { background: '#7FB5A8' } : {}}
								onClick={() => handleShiftClick(key)}
							>
								{key}
							</Button>
						);
					})
					}
				</div>
				<div className='calendar'>
					<MuiPickersUtilsProvider utils={LuxonUtils}>
						<DatePicker
							alwaysShowOpenedCalendarOnMobile={true}
							mobileDatePickerOpened={true}
							selectedDate={date}
							onChange={(value) => setDate(new Date(value))}
						/>
					</MuiPickersUtilsProvider>
				</div>
				<div className='mobile-shift'>
					<List>
						<ListItem key='select-all-switch' button className={'shift-select-checkbox'} divider={false}>
							<ListItemText id='select-all' primary='Select All' className='checkbox-label-text'/>
							<ListItemSecondaryAction>
								<Switch checked={selectAll} onChange={handleAllShiftsChange} name='selectAllSwitch'/>
							</ListItemSecondaryAction>
						</ListItem>
						{Object.entries(shifts).map(([key, value]) => {
							const val = Boolean(value);
							return (
								<ListItem key={key} button className={'shift-select-checkbox'} divider={false}>
									<ListItemText id={key} primary={capitalize(key)} className='checkbox-label-text'/>
									<ListItemSecondaryAction>
										<Checkbox checked={val} onChange={handleShiftChange} name={key}
												  color='secondary'/>
									</ListItemSecondaryAction>
								</ListItem>
							);
						})
						}
					</List>
				</div>
				<div className='save-button-container'>
					<Button className='button' onClick={onSave}>Save Changes</Button>
				</div>
			</div>
		</Modal>
	);
};

export const CallGuest = ({ guestName, guestPhoneNumber, onClose }: Modals.CallGuestProps) => (

	<Modal onClose={onClose}>
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<Typography variant='h6'>Call {guestName}</Typography>
			<Typography variant='body2'>{guestPhoneNumber}</Typography>
			<Button className='borderless-btn' href={`tel:${guestPhoneNumber}`} size='small'>
				Proceed <KeyboardArrowRightIcon/>
			</Button>
		</div>
	</Modal>
);

export const CapacityExceededModal = ({ onClose }: Modals.Props) => (
	<Modal onClose={onClose}>
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<Typography variant='h6'>Capacity Exceeded</Typography>
			<Typography variant='body2'>The timeslot you have selected is currently unavailable due to its capacity limit. Please select another timeslot.</Typography>
		</div>
	</Modal>
);

export const DeleteReservationModal = ({ onClose, onDeleteReservation }: any) => {
	const { closeModal } = useContext(ModalContext);
	return (
		<Modal onClose={onClose}>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Typography variant='h6'>Delete Reservation?</Typography>
				<Typography variant='body2'>You are about to discard the progress you’ve made with this reservation.</Typography>
				<Button
					className='borderless-btn'
					size='small'
					onClick={() => {
						onDeleteReservation();
						closeModal();
					}}
				>
					Delete Reservaton <KeyboardArrowRightIcon/>
				</Button>
			</div>
		</Modal>
	);
};


export const ConfirmReservationModal = ({ onClose, onConfirm, isModification = false, fullName, guests, date, time, tableName }: any) => {
	const { closeModal } = useContext(ModalContext);
	return (
		<Modal onClose={onClose}>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Typography variant='h6'>Confirm Reservation{isModification ? ' Modification' : '' }?</Typography>
				<div>
					<Divider style={{ margin: '10px 0' }}/>
					<Typography variant='subtitle1'>Summary</Typography>
					{tableName && <Typography variant='body1'><strong>Table:</strong> {tableName}</Typography>}
					<Typography variant='body1'><strong>Guests:</strong> {guests}</Typography>
					<Typography variant='body1'><strong>Date:</strong> {date}</Typography>
					<Typography variant='body1'><strong>Time:</strong> {time}</Typography>
					<Divider style={{ margin: '10px 0' }}/>
				</div>
				<Typography variant='body2'>
					You are about to confirm <strong>{fullName}’s</strong> reservation for party of <strong>{guests}</strong> for <strong>{date}</strong> at <strong>{to12HourTimeFormat(time)}</strong>. Do you wish to complete the customer’s {isModification ? 'modification' : 'booking'}?
				</Typography>
				<small>*This will apply any applicable refunds or extra charges to the customer.</small>
				<Button
					className='borderless-btn'
					size='small'
					data-cy='confirm-reservation-modal-btn'
					onClick={() => {
						onConfirm();
						closeModal();
					}}
				>
					Confirm <KeyboardArrowRightIcon/>
				</Button>
			</div>
		</Modal>
	);
};


export const Confirm = ({ onClose, onConfirm, title, text }: Modals.ConfirmProps) => {
	const { closeModal } = useContext(ModalContext);
	return (
		<Modal onClose={onClose}>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Box style={{ display: 'flex', flexDirection: 'row' }}>
					<Typography variant='h6'>{title || 'Confirm'}</Typography>
				</Box>

				{text && <Typography variant='body2'>{text}</Typography>}

				<Button
					className='borderless-btn'
					size='small'
					onClick={() => {
						onConfirm();
						closeModal();
					}}
				>
					Confirm <KeyboardArrowRightIcon />
				</Button>
			</div>
		</Modal>);
};
import React, { useState, useEffect, useContext } from 'react';
import { Typography, TextField, Button, InputAdornment, Switch, FormControlLabel } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import MailIcon from '@material-ui/icons/Mail';
import { CallGuest } from './Modals';
import Notice from './Notice';
import { ModalContext } from '../context/ModalContext';
import { Guest } from '../context/GuestContext';

namespace ContactInformation {
	export type Props = {
		guest: Guest,
		onSave: (data: any) => void
	};
}

const ContactInformation = ({ guest, onSave }: ContactInformation.Props) => {
	const { openModal } = useContext(ModalContext);

	const [editMode, setEditMode] = useState(false);
	const [fullName, setFullName] = useState(guest.fullName || '');
	const [phoneNumber, setPhoneNumber] = useState(guest.phoneNumber || '');
	const [email, setEmail] = useState(guest.email || '');
	const [sendStandardBookingConfirmation, setSendStandardBookingConfirmation] = useState(!!guest.sendStandardBookingConfirmation);

	const [errorMessage, setErrorMessage] = useState('');
	const textFieldStyle = {
		margin: 0
	};
	const inputStyle = {
		height: '50px'
	};

	useEffect(() => {
		setFullName(guest.fullName || '');
		setPhoneNumber(guest.phoneNumber || '');
		setEmail(guest.email || '');
		setSendStandardBookingConfirmation(!!guest.sendStandardBookingConfirmation);
	}, [guest]);

	return (
		<div className='contact-information'>
			{errorMessage && (
				<Notice
					type='error'
					message={errorMessage}
					onClear={() => setErrorMessage('')}
				/>
			)}
			<div className='title-and-cta'>
				<Typography variant='subtitle1'>Contact Information</Typography>
				<Button
					className='borderless-btn'
					style={{ height: 'auto' }}
					onClick={() => {
						try {
							if (editMode) {
								onSave({ fullName, phoneNumber, email });
							}
							setEditMode(!editMode);
						}
						catch(err) {
							console.warn('Error:', err);
							setErrorMessage('Error saving user');
						}
					}}
				>
					{editMode ? 'Save' : 'Edit'}
				</Button>
			</div>

			<div className='text-field-group'>
				<TextField
					disabled={!editMode}
					style={textFieldStyle}
					inputProps={{ style: inputStyle }}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<PersonIcon />
							</InputAdornment>
						)
					}}
					value={fullName}
					onChange={(evt) => setFullName(evt.target.value)}
				/>
				<TextField
					disabled={!editMode}
					style={textFieldStyle}
					inputProps={{ style: inputStyle }}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<PhoneForwardedIcon />
							</InputAdornment>
						)
					}}
					value={phoneNumber}
					onChange={(evt) => setPhoneNumber(evt.target.value)}
					onClick={() => !editMode && guest?.phoneNumber && openModal(<CallGuest guestName={guest.fullName} guestPhoneNumber={guest.phoneNumber} onClose={null}/>)}
				/>
				<TextField
					disabled={!editMode}
					style={textFieldStyle}
					inputProps={{ style: inputStyle }}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<MailIcon />
							</InputAdornment>
						)
					}}
					value={email}
					onChange={(evt) => setEmail(evt.target.value)}
					onClick={() => {
						if (!editMode && guest?.email) {
							window.location.href = `mailto:${email}`;
						}
					}}
				/>

				<FormControlLabel
					label='Send standard booking confirmation'
					labelPlacement='end'
					control={
						<Switch
							color='primary'
							value={sendStandardBookingConfirmation}
							onClick={() => setSendStandardBookingConfirmation(!sendStandardBookingConfirmation)}
						/>}
				/>
			</div>
		</div>
	);
};

export default ContactInformation;
import { TDR } from 'tdr-common';

// apply an onSnapshot docChange to an array of Reservation Communications, in place
export function applyCommsDocChange(
	comms: TDR.Reservation.Communication[],
	change
): TDR.Reservation.Communication[] {
	const changeData: TDR.Reservation.Communication = change.doc.data();
	let commIndex = comms.findIndex((resComm) => resComm.id === change.doc.id);

	// dumb hack to correct the fact that legacy reservationCommunications don't know their own IDs
	if (commIndex === -1) {
		commIndex = comms.findIndex(
			(resComm) => !resComm.id && resComm.body === changeData.body
		);
	}

	if (change.type === 'removed') {
		if (commIndex !== -1) {
			comms.splice(commIndex, 1);
		}
	}
	else {
		if (commIndex === -1) {
			comms.push(change.doc.data());
		}
		else {
			comms[commIndex] = change.doc.data();
		}
	}

	return comms;
}

import React, { useState, useEffect, useContext, createContext } from 'react';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import { modifyReservation, getInvoiceById } from '../api';
import { TDR, generateInvoice, dateAndTimeStrToDateTime } from 'tdr-common';
import { ProviderProps } from '../common/ProviderProps';
import { RestaurantContext } from './RestaurantContext';
import { TableContext } from './TableContext';
import { FirebaseContext } from './FirebaseContext';
import { DateTime } from 'luxon';
import luxonToFirebaseTimestamp from '../helpers/luxonToFirebaseTimestamp';

export namespace ModifyReservationContext {
	export type Value = {
		selectedDate: Date,
		setSelectedDate: (date: Date) => void,
		selectedTime: string,
		setSelectedTime: (time: string) => void,
		guests: number,
		setGuests: (guest: number) => void,

		reservationModifications?: any,
		paymentMethod: any,
		setPaymentMethod: (paymentMethod: any) => void,

		confirmReservationModification: (reservation: Partial<TDR.Reservation>) => Promise<any>,
		setModifiedReservationPrice: any,
		errorMessage: string,
		setErrorMessage: (errorMessage: string) => void,
		clearErrorMessage: () => void
	};
}

export const ModifyReservationContext = createContext<ModifyReservationContext.Value>({
	selectedDate: null,
	setSelectedDate: noop,
	selectedTime: null,
	setSelectedTime: noop,
	guests: null,
	setGuests: noop,

	reservationModifications: null,
	paymentMethod: null,
	setPaymentMethod: noop,

	confirmReservationModification: () => null,
	setModifiedReservationPrice: () => null,
	errorMessage: '',
	setErrorMessage:noop,
	clearErrorMessage: noop
});

ModifyReservationContext.displayName = 'Modify Reservation Context';

export const ReservationProvider = ({ children }: ProviderProps) => {
	const { firestore } = useContext(FirebaseContext);
	const { selectedRestaurant } = useContext(RestaurantContext);
	const { selectedTable } = useContext(TableContext);

	const [selectedDate, setSelectedDate] = useState<Date>(DateTime.local().plus({ days: 1 }).toJSDate());
	const [selectedTime, setSelectedTime] = useState<string>(null);
	const [guests, setGuests] = useState<number>(null);

	// States
	const [errorMessage, setErrorMessage] = useState('');
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [reservationModifications, setReservationModifications]: [any, any] = useState({});

	const zone = selectedRestaurant?.timezone;
	const currency = selectedRestaurant?.currency || TDR.DEFAULT_CURRENCY;

	// Reset the guests when the table changes
	useEffect(() => {
		if (selectedTable) {
			if (!guests) {
				setGuests(selectedTable.minGuests);
			}
			else {
				if (guests > selectedTable.maxGuests) {
					setGuests(selectedTable.maxGuests);
				}
				else if (guests < selectedTable.minGuests) {
					setGuests(selectedTable.minGuests);
				}
			}
		}
	}, [selectedTable]);

	const setModifiedReservationPrice = async (pricingPolicies, pricedTimeSlots, reservation, _reservationModifications) => {
		const { selectedTime, selectedDate } = _reservationModifications;
		const updatedTime = luxonToFirebaseTimestamp(dateAndTimeStrToDateTime(selectedDate, selectedTime, zone));
		let price = null;

		if ( isEmpty(pricingPolicies)) {
			price = (pricedTimeSlots || {})[selectedTime]?.regularPrice;
		}
		else {
			const items = [...reservation.items];
			const tableItemIndex = items.findIndex((item) => item.type === 'table');
			items[tableItemIndex] = {
				id: selectedTable?.id,
				label: selectedTable?.name,
				type: TDR.Reservation.ItemType.Table,
				tags: selectedTable?.tags
			};
			const mergedReservations = {
				...reservation,
				..._reservationModifications,
				time: updatedTime,
				guests,
				selectedTable,
				tableId: selectedTable?.id,
				tableName: selectedTable?.name,
				tableSlug: selectedTable?.slug,
				items
			};
			console.log('Pricing policies', pricingPolicies);
			console.log('Merged reservations', mergedReservations);
			const reservationInvoice = await getInvoiceById(firestore, reservation.invoices[0]);
			const newInvoice = generateInvoice(currency, pricingPolicies, mergedReservations, reservationInvoice);
			price = newInvoice.total;
		}
		setReservationModifications({
			..._reservationModifications,
			price,
			time: updatedTime
		});

		return price;
	};

	const confirmReservationModification = (reservation: Partial<TDR.Reservation>) => {
		const updateObj: any = {
			id: reservation.id,
			epoch: luxonToFirebaseTimestamp(dateAndTimeStrToDateTime(selectedDate, selectedTime, zone)).seconds
		};

		if (selectedTable.id !== reservation.tableId) {
			updateObj.tableId = selectedTable?.id;
			updateObj.tableName = selectedTable?.name;
			updateObj.tableSlug = selectedTable?.slug;

			const items = [...reservation.items];
			const tableItemIndex = items.findIndex((item) => item.type === 'table');
			items[tableItemIndex] = {
				id: selectedTable?.id,
				label: selectedTable?.name,
				type: TDR.Reservation.ItemType.Table,
				tags: selectedTable?.tags
			};
			updateObj.items = items;
		}

		if (guests !== reservation.guests) {
			updateObj.guests = guests;
		}

		if (reservationModifications.price === 0 || reservationModifications.price) {
			updateObj.price = reservationModifications.price;
		}

		const message = 'There was an error updating the reservation.';

		return modifyReservation(updateObj)
			.then((res) => {
				console.warn('[Modified] Res', res);
				if (!res.success) {
					console.warn('unsuccessful modification response:', res);
					setErrorMessage(message);
				}
				return res;
			})
			.catch((err) => {
				console.warn('Err', err);
				setErrorMessage(message);
			});
	};

	const valueObj: ModifyReservationContext.Value = {
		selectedDate, setSelectedDate,
		selectedTime, setSelectedTime,
		guests, setGuests,

		reservationModifications,
		paymentMethod,
		setPaymentMethod,

		confirmReservationModification,
		setModifiedReservationPrice,
		errorMessage,
		setErrorMessage,
		clearErrorMessage: () => setErrorMessage('')
	};

	return (
		<ModifyReservationContext.Provider value={valueObj}>
			{children}
		</ModifyReservationContext.Provider>
	);
};

import { Typography } from '@material-ui/core';
import React from 'react';
import Modal from './Modal';
import { ModalProps } from './ReservationAccordion';

const ModalExpiredBooking = ({
	open,
	onClose
}: ModalProps) => (
	<Modal open={open} onClose={onClose}>
		<Typography variant='h6'>Reservation is in the past</Typography>
		<Typography variant='body1'>
      This reservation has already past. You will need to create a new one if
      the date needs to be edited.
		</Typography>
	</Modal>
);

export default ModalExpiredBooking;
import chroma from 'chroma-js';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import slice from 'lodash/slice';
import findLastIndex from 'lodash/findLastIndex';
import findIndex from 'lodash/findIndex';
import findLast from 'lodash/findLast';
import reduce from 'lodash/reduce';
import padStart from 'lodash/padStart';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';

import {
	Box, Container,
	IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField
} from '@material-ui/core';
import {
	InsertInvitation
} from '@material-ui/icons';

import ReactTooltip from 'react-tooltip';

import { approximateReservation, computeBaseTimeSlots, DEFAULT_RESERVATION_TIME_BUFFER, formatMonetaryValue, generateInvoice, legacyTimeslotsToPricingPolicies, parseTimeNoAPM, TDR, toLuxon } from 'tdr-common';
import { PricingPolicyContext } from '../context/PricingPolicyContext';
import { RestaurantContext } from '../context/RestaurantContext';
import { TableContext } from '../context/TableContext';
import RestaurantSelector from './RestaurantSelector';

const allTimes = [
	'00:00', '00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45', '03:00', '03:15', '03:30', '03:45',
	'04:00', '04:15', '04:30', '04:45', '05:00', '05:15', '05:30', '05:45', '06:00', '06:15', '06:30', '06:45', '07:00', '07:15', '07:30', '07:45',
	'08:00', '08:15', '08:30', '08:45', '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45',
	'12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45',
	'16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45',
	'20:00', '20:15', '20:30', '20:45', '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45'
];

type PricedSlot = Partial<TDR.TimeSlot> & {
	time: string,
	invoice: Partial<TDR.Invoice>,
	reservation: Partial<TDR.Reservation>,
	override?: TDR.Override,
	pricingPolicies?: TDR.PricingPolicy[]
}
type PricedDate = {
	[t: string]: PricedSlot
} & {
	date: DateTime
}

export default function TableVis () {
	const { selectedRestaurant: restaurant } = useContext(RestaurantContext);
	const { selectedTable: table, getTablesByRestaurant } = useContext(TableContext);

	const { restaurantPolicies, taxPolicies } = useContext(PricingPolicyContext);
	const [pricingPolicies, setPricingPolicies] = useState<TDR.PricingPolicy[]>([]);
	const [tables, setTables] = useState<TDR.Table[] | null>(null);


	useEffect(() => {
		if (restaurant) {
			getTablesByRestaurant(restaurant.id).then(tables => {
				setTables(tables);
			});
		}
		else {
			setTables([]);
		}
	}, [restaurant]);

	useEffect(() => {
		setPricedDates([]);
		if (restaurant && table) {
			const legacyPolicies = legacyTimeslotsToPricingPolicies(restaurant, tables);
			const restaurantTaxPolicies = taxPolicies.filter(({ tax }) => restaurant.taxes?.includes(tax));

			setPricingPolicies([
				...(legacyPolicies || []),
				...(restaurantPolicies || []),
				...(restaurantTaxPolicies || [])
			]);
		}
	}, [restaurant, table, restaurantPolicies, taxPolicies]);

	const [guests, setGuests] = useState(1);

	const [dates] = useState<DateTime[]>([]);
	const [pricedDates, setPricedDates] = useState<PricedDate[]>([]);

	function extendDates () {
		for (let i = 0; i < 7; i++) {
			const lastDate = last(dates) || toLuxon(DateTime.local(), restaurant?.timezone).minus({ days: 2 });
			const nextDate = lastDate.plus({ days: 1 });
			dates.push(nextDate);
			if (!isEmpty(pricingPolicies)) {
				pricedDates.push(extDate(restaurant, table, pricingPolicies, guests, nextDate));
			}
		}

		if (!isEmpty(pricingPolicies)) {
			setPricedDates([...pricedDates]);
		}
	}

	useEffect(() => {
		extendDates();
		extendDates();
	}, []);

	const firstNonEmpty = findIndex(allTimes, timeStr => pricedDates.some(pdate => pdate[timeStr]?.available));
	const lastNonEmpty = findLastIndex(allTimes, timeStr => pricedDates.some(pdate => pdate[timeStr]?.available));

	// non empty times, rounded out to the hour
	const nonEmptyTimes = isEmpty(pricedDates) ? [] : slice(
		allTimes,
		firstNonEmpty - (firstNonEmpty % 4),
		(lastNonEmpty - (lastNonEmpty % 4) + 4)
	);

	useEffect(() => {
		if (!isEmpty(pricingPolicies)) {
			const newPricedDates = dates.map(date => extDate(restaurant, table, pricingPolicies, guests, date));
			setPricedDates(newPricedDates);
		}
	}, [pricingPolicies, guests]);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	return <Container maxWidth='xl' style={{
		height: '100%'
	}}>
		<Box display='flex' flexDirection='row' width='100%' marginTop={'44px'}>
			<Box display='flex' flexGrow={1}>
				<h2>Table Visualization</h2>

				<IconButton
					disabled={!table || !restaurant}
					onClick={() => {
						extendDates();
					}}>
					<InsertInvitation
						data-tip="Look further into the future"
					/>
				</IconButton>

				<TextField
					label='Guests'
					variant='outlined'
					style={{ width: '4em', marginLeft: '1em' }}
					value={guests}
					type='number'
					onChange={(evt) => setGuests(parseInt(evt.target.value))}
				/>
			</Box>

			<RestaurantSelector />
		</Box>

		<Box style={{ height: '100%', width: '100%' }} display='flex' flexDirection='row' alignItems="stretch" >

			<Box>
				<TableSelector tables={tables} />
			</Box>

			<Box style={{ overflowY: 'auto', maxHeight: '35em' }}>
				<Table className='table-viz'>

					{!table ? null : <>
						<TableHead>
							<TableRow>
								<TableCell>{/* spacer for the day of the week marker */}</TableCell>
								{nonEmptyTimes.filter(t => t.endsWith('00')).map(hour => (
									<TableCell colSpan={4} key={hour} className='first-of-hour'>
					   					<span>{hour}</span>
									</TableCell>
								))}
							</TableRow>
						</TableHead>

						<TableBody>
							{!(table && restaurant) ? null : pricedDates.map((pricedDate, i) => (
								<DateRow
									key={`${pricedDate.date.weekNumber}-${pricedDate.date.day}`}
									pricedDate={pricedDate}
									times={nonEmptyTimes}
									firstRow={i===0}
								/>
							))}
						</TableBody>
					</>}
				</Table>

				<ReactTooltip className='price-popper'/>
			</Box>
		</Box>

	</Container>;
}


function overrideApplies (override: TDR.Override, table:TDR.Table, date:DateTime) :boolean {
	if (!override.targetIds.includes(table.id)) {
		return false;
	}
	const { conditions } = override;
	return (
		conditions?.weekdays.includes(date.weekdayLong) ||
		conditions?.dates.includes(date.toFormat('MMMM dd, yyyy'))
	);
}

function getSlotOverride (restaurant:TDR.Restaurant, table: TDR.Table, slot: PricedSlot, date:DateTime) :TDR.Override {
	return findLast(restaurant.overrides, override => override.values?.defaultTimeSlots[slot.time] && overrideApplies(override, table, date));
}

function extDate (restaurant: TDR.Restaurant, table: TDR.Table, pricingPolicies: TDR.PricingPolicy[], guests: number, date: DateTime): PricedDate {
	if (!date || !restaurant || !table) {
		return { date } as PricedDate;
	}

	const currency = restaurant?.currency || TDR.DEFAULT_CURRENCY;
	const timeslots = computeBaseTimeSlots(date, restaurant, table);

	return reduce(timeslots, (pdate, slot, timeStr) => {
		const reservation = approximateReservation(date.set(parseTimeNoAPM(timeStr)), table, guests);
		const invoice = slot.available && generateInvoice(currency, pricingPolicies, reservation);

		// I need to pull a list of the pricingPolicies that were used out of the invoice
		// so I can justify the price that we've decided on

		const pricedSlot:PricedSlot = { ...slot, time: timeStr, invoice, reservation };
		pricedSlot.override = getSlotOverride(restaurant, table, pricedSlot, date);
		pdate[timeStr] = pricedSlot;
		return pdate;
	}, { date } as PricedDate);
}


function DateRow({ pricedDate, times, firstRow }: { pricedDate: PricedDate, times: string[], firstRow: boolean }) {
	const { date } = pricedDate;
	const sunday = date.weekday === 7;
	const dateFormat = (
		(firstRow || sunday || date.day === 1) ? 'MMM dd EEEEE' :
			'EEEEE');

	return <>
		<TableRow className={sunday ? 'sunday' : ''}>
			 <TableCell className='weekday'>{date.toFormat(dateFormat)}</TableCell>

			{times.map(timeStr => {
				const slot = pricedDate[timeStr];
				const guests = slot?.reservation?.guests;
				const { available, invoice } = slot || {};
				let breakdown:string = null;

				if (invoice) {
					const subtotal = formatMonetaryValue(invoice?.subtotal || 0, invoice.currency);
					const tax = formatMonetaryValue((invoice?.total || 0) - (invoice?.subtotal || 0), invoice.currency);
					const total = formatMonetaryValue(invoice?.total || 0, invoice.currency);
					const tlen = total.length;

					breakdown = [
						slot.service,
						slot.seating || slot.timeLabel,
						`duration: ${slot.resDuration || DEFAULT_RESERVATION_TIME_BUFFER.minutesAfter} min`,
						`subtotal: ${padStart(subtotal, tlen)}`,
						guests && slot.isPerGuest && `(${formatMonetaryValue(invoice.subtotal / guests, invoice.currency)}/guest)`,
						`tax:      ${padStart(tax, tlen)}`,
						`total:    ${total}`,
						slot.override && `(${slot.override.name})`
					].filter(x => x).join('\n');
				}

				return <TableCell
					className={`timeslot ${timeStr.endsWith('00') ? 'first-of-hour' : ''}`}
					data-tip={breakdown}
					style={{ backgroundColor: available ? priceColor(invoice?.total || 0).css() : null }}
					key={timeStr}
				/>;
			})}
		</TableRow>
	</>;
}

function TableSelector ({ tables }: {tables: TDR.Table[]}) {
	const { selectedTable, setSelectedTable } = useContext(TableContext);

	// this is WAY more trouble than it should have been,
	// but I can't receive keyboard events on an element if it isn't focused
	useEffect(() => {
		const nextTable = (evt) => {
			if (evt.key === ' ') {
				const nextIndex = (tables.findIndex(t => t.id === selectedTable?.id) + 1) % tables.length;
				setSelectedTable(tables[nextIndex]);
			}
		};
		window.addEventListener('keydown', nextTable, { passive: true });
		return () => window.removeEventListener('keydown', nextTable);
	}, [tables, selectedTable, setSelectedTable]);

	return <Box display='flex' flexDirection='column' mr={2}>
		{(tables || []).map(table => (
			<Box
				key={table.id}
				px={1}
				py={2}
				alignItems='center'
				onClick={() => setSelectedTable(table)}
				bgcolor={selectedTable?.id === table.id ? '#333' : '#121212'}
				style={{
					cursor: 'pointer',
					opacity: table.disabled ? 0.25 : 1
				}}
			>
				{table.internalName || table.name}
			</Box>
		))}
	</Box>;
}


const priceColor = chroma.scale([
	chroma(128, 128, 128),	//   0.00
	chroma(0, 30, 80), 		//   0.01
	chroma(0, 200, 130),	//  15.00
	chroma(0, 220, 30),		//  30.00
	chroma(240, 170, 0),	//  60.00
	chroma(240, 0, 140),	// 120.00
	chroma(123, 0, 255),	// 240.00
	chroma(255, 255, 255)	// 480.00+
]).domain([
	0, 0.01, 15, 30, 60, 120, 240, 480
]).mode('lab');


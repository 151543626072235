import firebase from 'firebase';
import { DateTime } from 'luxon';

export function timeStr(timestamp: firebase.firestore.Timestamp, zone: string) {
	if (!timestamp?.seconds) {
		return null;
	}
	const time = DateTime.fromSeconds(timestamp.seconds).setZone(zone);

	return time.toFormat('LLL d t ZZZZ');
}

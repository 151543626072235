import {
	Button, Container, TextField
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { loginWithEmailAndPassword } from '../context/FirebaseContext';

const Login = () => {
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	return (
		<Container maxWidth='sm'>
			<div className='input-row'>
				<TextField
					label='Email'
					placeholder='Email'
					variant='outlined'
					value={email}
					onChange={(evt) => setEmail(evt.target.value)}
				/>
			</div>
			<div className='input-row'>
				<TextField
					label='Password'
					type='password'
					placeholder='Password'
					variant='outlined'
					value={password}
					onChange={(evt) => setPassword(evt.target.value)}
					onKeyPress={(evt) => (evt.key === 'Enter') ? loginWithEmailAndPassword(email, password) : null}
				/>
			</div>
			<div>
				<Button
					variant='contained'
					color='primary'
					className='next'
					disabled={isEmpty(email) || isEmpty(password)}
					onClick={() => loginWithEmailAndPassword(email, password)}
				>
					Login
				</Button>
			</div>
		</Container>
	);
};

export default Login;
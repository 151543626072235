import noop from 'lodash/noop';
import React, { createContext, useContext, useState } from 'react';
import { TDR } from 'tdr-common';
import { ProviderProps } from '../common/ProviderProps';
import { FirebaseContext } from './FirebaseContext';

export namespace TableContext {
	export type Value = {
		// tablesByRestaurant: { [restaurantId: string]: TDR.Table[] },
		// tables: TDR.Table[],
		getTableById: (tableId: string) => Promise<TDR.Table | null>,
		getTablesByRestaurant: (restaurantId: string) => Promise<TDR.Table[]>
		selectedTable: TDR.Table,
		setSelectedTable: (table: TDR.Table)=>void
		// loading: boolean,
	};
}

export const TableContext = createContext<TableContext.Value>({
	// tablesByRestaurant: {},
	getTableById: () => null,
	getTablesByRestaurant: () => null,
	selectedTable: null,
	setSelectedTable: noop
	// loading: false
});
TableContext.displayName = 'Table Context';

export const TableProvider = ({ children }: ProviderProps) => {
	// const { selectedRestaurantId } = useContext(RestaurantContext);
	const { firestore } = useContext(FirebaseContext);

	// const [tablesByRestaurant, setTablesByRestaurant] = useState<{ [rid: string]: TDR.Table[] }>({});
	// const [tables, setTables] = useState([]);
	const [selectedTable, setSelectedTable] = useState(null);
	// const [loading, setLoading] = useState(false);

	const getTableById = async (tableId: string) =>{
		return firestore.collection('Tables').doc(tableId).get().then(snapshot => {
			return snapshot.data() as TDR.Table;
		});
	};

	const getTablesByRestaurant = async (restaurantID: string) => {
		return firestore.collection('Tables').where('restaurantId', '==', restaurantID).get().then(snapshot => {
			return snapshot.docs.map(doc => doc.data() as TDR.Table);
		}).then(tables => tables.filter(table => !table.deleted)).then(tables => tables.sort((A, B) => A.disabled ? 1 : B.disabled ? -1 : (A.displayOrder ?? 999999) - (B.displayOrder ?? 999999)));
	};

	// useEffect(() => {
	// 	if (!initialized) {
	// 		return;
	// 	}
	// 	const unsub = firestore.collection('Tables').onSnapshot(snap => {
	// 		const allTables = snap.docs.map(doc => doc.data() as TDR.Table).filter(table => !table.deleted);
	// 		const byRestaurant = mapValues(
	// 			groupBy(allTables, t => t.restaurantId),
	// 			tables => tables.sort((A, B) => A.disabled ? 1 : B.disabled ? -1 : (A.displayOrder ?? 999999) - (B.displayOrder ?? 999999))
	// 		);

	// 		setTablesByRestaurant(byRestaurant);
	// 		setLoading(false);
	// 	});

	// 	return () => {
	// 		setTablesByRestaurant({});
	// 		setTables([]);
	// 		unsub();
	// 	};
	// }, [initialized]);


	// useEffect(() => {
	// 	if (!initialized) {
	// 		return;
	// 	}

	// 	setSelectedTable(null);
	// 	if (isEmpty(tablesByRestaurant) || isEmpty(selectedRestaurantId)) {
	// 		setTables([]);
	// 	}
	// 	else {
	// 		setTables(tablesByRestaurant[selectedRestaurantId] || []);
	// 	}
	// }, [tablesByRestaurant, selectedRestaurantId]);


	const valueObj: any = {
		// tablesByRestaurant,
		// tables,
		getTableById,
		getTablesByRestaurant,
		selectedTable,
		setSelectedTable
		// loading
	};

	return (
		<TableContext.Provider value={valueObj}>
			{children}
		</TableContext.Provider>
	);
};

import { querySnapshotToArray } from 'tdr-common';

export default async function getPromoCodes (firestore, restaurantId) {
	const promoCodes = querySnapshotToArray(
		await firestore.collection('PromoCodes')
			.where('restaurantId', '==', restaurantId)
			.get()
	);
	return promoCodes;
}

import React, { useState, useEffect, useContext, createContext } from 'react';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import { ProviderProps } from '../common/ProviderProps';
import {
	createGuestAndUser as _createGuestAndUser,
	updateGuest as _updateGuest,
	updateUser as _updateUser
} from '../api';
import { FirebaseContext } from './FirebaseContext';
import { AdminReservationContext } from './AdminReservationContext';
import { AdminRestaurantContext } from './AdminRestaurantContext';

//just dumping this here
export type User = {
	id?: string,
	fullName?: string,
	phoneNumber?: string,
	email?: string,
	updateTime?: number,
	sessionTime?: number,
	createdTime?: number,
	token?: any[]
}

export type Guest = User & {
	id: string,
	userId: string,
	restaurantId: string,
	notes?: string,
	tags?: string[],
	sendStandardBookingConfirmation?: boolean
}

export namespace GuestContext {
	// Note: the any types are just here until we set up the guest type!!
	export type Value = {
		guests: Guest[],
		setGuests: (guests: Guest[])=>void,
		selectedGuest: Guest,
		setSelectedGuest: (guest: Guest)=>void,
		selectedGuestReservations: any,
		loading: false,
		createGuestAndUser: (guestAndUserData: Guest)=>Promise<{success: boolean}>,
		updateUser: (id: string, data: any)=>Promise<{success: boolean}>,
		updateGuest: (id: string, data: any)=>Promise<{success: boolean}>
	};
}

export const GuestContext = createContext<GuestContext.Value>({
	guests: [],
	setGuests: noop,
	selectedGuest: null,
	selectedGuestReservations: [],
	setSelectedGuest: noop,
	loading: false,
	createGuestAndUser: () => Promise.resolve({ success: false }),
	updateUser: () => Promise.resolve({ success: false }),
	updateGuest: () => Promise.resolve({ success: false })
});
GuestContext.displayName = 'Guest Context';

export const GuestProvider = ({ children }: ProviderProps) => {
	const { selectedReservation, reservations, updateReservation } = useContext(AdminReservationContext);
	const { initialized, firestore } = useContext(FirebaseContext);
	const { selectedAdminRestaurant } = useContext(AdminRestaurantContext);

	const [guests, setGuests] = useState([]);
	const [selectedGuest, setSelectedGuest] = useState(null);
	const [selectedGuestReservations, setSelectedGuestReservations] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (isEmpty(guests) && initialized && selectedAdminRestaurant) {
			(async () => {
				setLoading(true);
				firestore.collection('Guests')
					// Note: we only want guests associated to a particular restaurant
					// Basically for whatever account the logged in person may belong to.
					.where('restaurantId', '==', selectedAdminRestaurant.id)
					.onSnapshot(async (snapshot) => {
						let guests: any[] = [];
						snapshot.forEach((doc) => {
							guests.push(doc.data() as any);
						});

						// Get user objects for all the restaurants guests
						if (guests.length) {
							guests = await Promise.all(
								guests.map((guest) => (
									firestore.collection('Users')
										.where('id', '==', guest.userId)
										.get()
										.then((querySnapshot) => {
											// Note: because there is a 1 to 1 association between guest and user
											// there will always be only one guest associated to a user id
											const doc = querySnapshot.docs[0];
											const user = doc?.data();
											// The order here matters, since the id of the object MUST be the guest's id.
											return {
												...user,
												...guest
											};
										})
								))
							);
						}
						setGuests(guests);
					});

				setLoading(false);
			})();
		}
	}, [initialized, selectedAdminRestaurant]);


	// Set selected guest based on the reservation id
	useEffect(() => {
		if (selectedReservation && guests.length) {
			setSelectedGuest(guests.find((guest) => guest.userId === selectedReservation.userId));
			setSelectedGuestReservations(reservations.filter((reservation) => reservation.userId === selectedReservation.userId));
		}

	}, [selectedReservation, guests]);

	const createGuestAndUser = async (guestAndUserData) => {
		try {
			if (!guestAndUserData.restaurantId) {
				guestAndUserData.restaurantId = selectedAdminRestaurant.id;
			}

			const createdGuest = await _createGuestAndUser(firestore, guestAndUserData);

			// Update the guest list and selected guest with the newly created one
			setGuests([...guests, guestAndUserData]);
			setSelectedGuest(createdGuest);
			return {
				success: true
			};
		}
		catch (err) {

		}
		return {
			success: false
		};
	};

	const updateGuestsState = (id, data) => {
		const index = guests.findIndex((guest) => guest.id === id);
		const updatedGuests = [...guests];
		updatedGuests[index] = {
			...updatedGuests[index],
			...data,
			id: updatedGuests[index].id
		};

		setGuests([...updatedGuests]);
	};

	const updateUser = async (id, data) => {
		try {
			updateGuestsState(id, data);
			await _updateUser(firestore, id, data);

			const mustUpdateReservations = Object.keys(data).some((key) => key === 'fullName' || key === 'email' || key === 'phoneNumber');

			if (mustUpdateReservations) {
				const updatedReservations = reservations.filter((reservation) => reservation.userId === id);
				await Promise.all(
					updatedReservations.map((reservation) => (
						updateReservation(reservation.id, { fullName: data.fullName, phone: data.phoneNumber, email: data.email })
					))
				);
			}

			return {
				success: true
			};
		}
		catch (err) {
			console.warn('TODO: do something with the error', err);
		}

		return {
			success: false
		};
	};

	const updateGuest = async (id, data) => {
		try {
			updateGuestsState(id, data);
			await _updateGuest(firestore, id, data);

			return {
				success: true
			};
		}
		catch (err) {
			console.warn('TODO: do something with the error', err);
		}
		return {
			success: false
		};
	};

	const valueObj: any = {
		guests,
		selectedGuest, setSelectedGuest,
		selectedGuestReservations,
		loading,
		createGuestAndUser,
		updateUser,
		updateGuest
	};

	return (
		<GuestContext.Provider value={valueObj}>
			{children}
		</GuestContext.Provider>
	);
};

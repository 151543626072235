import { Button, FormControlLabel, Switch } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { TDR, customerInvoice, isInThePast } from 'tdr-common';
import { getInvoiceById } from '../api';
import refundInvoice from '../api/refundInvoice';
import { FirebaseContext } from '../context/FirebaseContext';

const allStatuses = [
	TDR.Reservation.Status.Booked,
	TDR.Reservation.Status.Confirmed,
	TDR.Reservation.Status.Completed,
	TDR.Reservation.Status.NoShow,
	TDR.Reservation.Status.Cancelled
];

const SetStatusButtons = ({
	reservation,
	close
}: {
	reservation: TDR.Reservation;
	close: () => void;
}) => {
	if (!reservation) {
		return null;
	}

	const validStatuses = [];

	if (!isInThePast(reservation)) {
		if (reservation.status !== TDR.Reservation.Status.Cancelled) {
			validStatuses.push(TDR.Reservation.Status.Cancelled);
		}

		if (reservation.status === TDR.Reservation.Status.Booked) {
			validStatuses.push(TDR.Reservation.Status.Confirmed);
		}
	}
	else if (
		![
			TDR.Reservation.Status.Cancelled,
			TDR.Reservation.Status.Completed,
			TDR.Reservation.Status.NoShow
		].includes(reservation.status)
	) {
		validStatuses.push(
			TDR.Reservation.Status.NoShow,
			TDR.Reservation.Status.Completed
		);
	}

	const [selectedStatus, setSelectedStatus] = useState(null);
	const [disableStatusSms, setDisableStatusSms] = useState(false);
	const [refund, setRefund] = useState(false);
	const [loading, setLoading] = useState(false);
	const { token } = useContext(FirebaseContext);

	useEffect(() => {
		setSelectedStatus(null);
	}, [disableStatusSms]);

	const { firestore }: any = useContext(FirebaseContext);
	function confirm() {
		setLoading(true);
		if (refund) {
			Promise.all(
				(reservation?.invoices || []).map((invId) =>
					getInvoiceById(firestore, invId)
				)
			)
				.then(customerInvoice)
				.then((invoice) => {
					if (!invoice) {
						console.error('invoice not found');
						setLoading(false);
					}
					else {
						refundInvoice(token, invoice, invoice.subtotal, invoice.deposit)
							.then((res) => {
								if (!res.success) {
									console.error(res);
									setLoading(false);
								}
								else {
									console.log('invoice refunded');
									if (selectedStatus) {
										firestore
											.collection('Reservations')
											.doc(reservation.id)
											.update({
												status: selectedStatus,
												disableStatusSms: disableStatusSms
											});
										setLoading(false);
										close();
									}
								}
							})
							.catch((err) => {
								console.error(err);
								setLoading(false);
							});
					}
				});
		}
		else if (selectedStatus) {
			firestore.collection('Reservations').doc(reservation.id).update({
				status: selectedStatus,
				disableStatusSms: disableStatusSms
			});
			setLoading(false);
			close();
		}
	}

	return (
		<>
			<div className='set-status-buttons'>
				{(disableStatusSms ? allStatuses : validStatuses).map((status) => (
					<Button
						key={status}
						size='small'
						style={{
							width: 'revert',
							height: 'revert',
							opacity: validStatuses.includes(status) ? 1 : 0.6
						}}
						variant={status === selectedStatus ? 'contained' : 'outlined'}
						onClick={() => setSelectedStatus(status)}
					>
						{status}
					</Button>
				))}
			</div>

			<div className='confirm-status-button'>
				<Button
					size='small'
					variant='contained'
					disabled={!selectedStatus || loading}
					style={{
						width: 'revert',
						height: 'revert'
					}}
					onClick={confirm}
				>
					{loading ? 'confirming...' : 'Confirm Update'}
				</Button>
				<FormControlLabel
					label='Refund'
					labelPlacement='start'
					style={{ float: 'right' }}
					control={
						<Switch
							color='primary'
							value={refund}
							onClick={() => setRefund(!refund)}
						/>
					}
				/>

				<FormControlLabel
					label='Force Status (disables SMS)'
					labelPlacement='start'
					style={{ float: 'right' }}
					control={
						<Switch
							color='primary'
							value={!disableStatusSms}
							onClick={() => setDisableStatusSms(!disableStatusSms)}
						/>
					}
				/>
			</div>
		</>
	);
};

export default SetStatusButtons;
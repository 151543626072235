/* eslint-disable max-lines-per-function */
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	FormControlLabel,
	TextField
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import config from '../../common/config';
import { RestaurantContext } from '../../context/RestaurantContext';
import { CopyCodeBlock } from '../CopyCodeBlock';
import { useStyles } from './AdminWidgets';
import { DirectLinkSearchParam } from './types';

export const DirectLinkConfig = () => {
	const { selectedRestaurant } = useContext(RestaurantContext);
	const classes = useStyles();

	const defaultAppURL = new URL(selectedRestaurant?.slug, config.appLink);
	const [directLink, setDirectLink] = useState(defaultAppURL.toString());

	const updateDirectLinkParam = (
		key: DirectLinkSearchParam,
		value: string | boolean
	): void => {
		const newURI = new URL(directLink);
		if (!value) {
			newURI.searchParams.delete(key);
		}
		else {
			newURI.searchParams.set(key, encodeURIComponent(value.toString()));
		}

		setDirectLink(newURI.toString());
	};

	return (
		<Accordion classes={{ root: classes.root }}>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				classes={{ content: classes.summaryContent }}
			>
				<h1 className={classes.heading}>Direct Tablz Link</h1>
				<p className={classes.subheading}>
          Generate a direct link to our 3d reservation portal
				</p>
			</AccordionSummary>
			<AccordionDetails
				style={{ display: 'flex', flexDirection: 'column' }}
			>
				{directLink && (
					<CopyCodeBlock text={directLink} />
				)}

				<Alert severity='warning'>
					These values are not stored in our db. Once you leave this page
					your generated url will be lost.
				</Alert>

				<FormControlLabel
					control={
						<Checkbox
							onChange={(evt) =>
								updateDirectLinkParam('skip', evt.target.checked)
							}
							color='primary'
						/>
					}
					label='Skip landing page'
				/>

				<TextField
					InputLabelProps={{ shrink: true }}
					label='UTM Source'
					onChange={(evt) => {
						updateDirectLinkParam('utm_source', evt.target.value);
					}}
				/>
				<TextField
					InputLabelProps={{ shrink: true }}
					label='UTM Medium'
					onChange={(evt) => {
						updateDirectLinkParam('utm_medium', evt.target.value);
					}}
				/>
				<TextField
					InputLabelProps={{ shrink: true }}
					label='UTM Campaign'
					onChange={(evt) => {
						updateDirectLinkParam('utm_campaign', evt.target.value);
					}}
				/>
			</AccordionDetails>
		</Accordion>
	);
};
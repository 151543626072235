import { Box, FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';
import { TDR } from 'tdr-common';
import PillList from './PillList';

type DietaryOption =
  | 'vegan'
  | 'vegetarian'
  | 'lactose-free'
  | 'gluten-free'
  | 'nut-free';

type Props = {
	activeRestaurant: TDR.Restaurant;
	setActiveRestaurant: React.Dispatch<React.SetStateAction<TDR.Restaurant>>;
};

const DietaryOptions: React.FC<Props> = ({
	activeRestaurant,
	setActiveRestaurant
}) => {
	const allOptions: DietaryOption[] = [
		'vegan',
		'vegetarian',
		'lactose-free',
		'gluten-free',
		'nut-free'
	];

	return (
		<>
			<Box className="pill-toggle-box">
				<PillList
					mode="toggle"
					items={allOptions}
					selected={activeRestaurant.dietaryRestrictions?.restrictions || []}
					onChange={(restrictions: string[]) => {
						setActiveRestaurant(
							Object.assign({}, activeRestaurant, {
								dietaryRestrictions: {
									...activeRestaurant.dietaryRestrictions,
									restrictions: restrictions
								}
							})
						);
					}}
				/>
			</Box>

			<FormControlLabel
				label="Show disclaimer instructing guest to call restaurant to confirm dietary restrictions"
				style={{ marginLeft: '2em' }}
				control={
					<Switch
						color='primary'
						checked={activeRestaurant.dietaryRestrictions?.showDisclaimer || false}
						onChange={(event) => {
							setActiveRestaurant({
								...activeRestaurant,
								dietaryRestrictions: {
									...activeRestaurant.dietaryRestrictions,
									showDisclaimer: event.target.checked
								}
							});
						}}
					/>
				}
			/>
		</>
	);
};

export default DietaryOptions;

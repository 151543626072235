import firebase from 'firebase';
import { TDR, querySnapshotToArray } from 'tdr-common';
import { v4 as uuid } from 'uuid';

export default async function updateRestaurantFeatureFlag(
	firestore: firebase.firestore.Firestore,
	restaurantId: string,
	flag: TDR.FeatureKey,
	value: TDR.FeatureValue
): Promise<void> {
	const collection = firestore.collection('FeatureFlags');
	const query = collection.where('restaurantId', '==', restaurantId);
	const featureFlags = await query.get();

	// check if the restaurant already has flags
	if (!featureFlags.empty) {
		const id: string = querySnapshotToArray(featureFlags)?.[0].id;
		await collection.doc(id).update({ [flag]: value });
	}

	// if not, make a new document
	else {
		const id = uuid();
		const document = {
			id: id,
			restaurantId: restaurantId,
			[flag]: value
		};
		await collection.doc(id).set(document);
	}
}
import React from 'react';
import Pill from './Pill';

namespace PillContainer {
	export type Props = {
		items: any[],
		labelKey: string,
		onClick: (any) => void,
		selectedItem?: string,
		keyPrefix?: string,
		formatLabelFn?: (label: string) => string
	};
}

const PillContainer = ({ items, labelKey, onClick, selectedItem = '', keyPrefix = '', formatLabelFn = (label) =>label }: PillContainer.Props) => {

	return (
		<div className='pill-container'>
			<div>
				{items.map(((item, index) => (
					<Pill
						key={`${keyPrefix}-${index}`}
						onClick={() => onClick(item)}
						selected={item[labelKey] === selectedItem}
					>
						{formatLabelFn(item[labelKey])}
					</Pill>
				)))}
			</div>
		</div>
	);
};

export default PillContainer;
import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { TDR } from 'tdr-common';
import { getOpenTableId } from '../../helpers/getOpenTableId';
import { RgbaColorField } from '../rgba-color-field/RgbaColorField';
import { HexColorField } from '../hex-color-field/HexColorField';

const openTableColorOptions = [
	{ name: 'Standard', value: '1' },
	{ name: 'Neutral', value: '2' },
	{ name: 'Gold', value: '3' },
	{ name: 'Green', value: '4' },
	{ name: 'Blue', value: '5' },
	{ name: 'Red', value: '6' },
	{ name: 'Teal', value: '7' }
];

type OpenTableSettingsProps = {
	formData: TDR.Restaurant['settings'];
	setFormData: React.Dispatch<React.SetStateAction<TDR.Restaurant['settings']>>;
	updatePreviewParam: (key: string, value: string | boolean) => void;
};

export const OpenTableSettings = ({ formData, setFormData, updatePreviewParam }: OpenTableSettingsProps) => {

	const missingOpenTableId = formData.alternativePlatform?.vendor === TDR.AlternativePlatform.OpenTable && !formData.widget?.opentable?.rid;

	useEffect(() => {
		if (formData?.alternativePlatform?.href) {
			const rid = getOpenTableId(formData?.alternativePlatform?.href);
			updatePreviewParam('ot-rid', rid);
			setFormData({
				...formData,
				widget: {
					...formData.widget,
					opentable: {
						...formData.widget.opentable,
						rid: rid
					}
				}
			});
		}
	}, []);

	return (
		<Box display='flex' flexDirection='column'>
			<h2>OpenTable Widget Settings</h2>

			<TextField
				required
				error={missingOpenTableId}
				label='OT Restaurant Id'
				name='rid'
				value={formData.widget?.opentable?.rid || ''}
				onChange={(evt) => {
					updatePreviewParam('ot-rid', evt.target.value);
					setFormData({
						...formData,
						widget: {
							...formData.widget,
							opentable: {
								...formData.widget.opentable,
								rid: evt.target.value
							}
						}
					});
				}}
				variant='outlined'
				helperText={missingOpenTableId ? 'An opentable id is required to use their widget' : ''}
			/>

			<FormControl>
				<InputLabel id='ot-color'>Color</InputLabel>
				<Select
					name='color'
					value={formData.widget?.opentable?.color || 1}
					onChange={(evt) => {
						updatePreviewParam('ot-color', evt.target.value as string);
						setFormData({
							...formData,
							widget: {
								...formData.widget,
								opentable: {
									...formData.widget.opentable,
									color: evt.target.value as TDR.OpenTableColorValue
								}
							}
						});
					}}
					labelId='ot-color'
					variant='filled'
				>
					{openTableColorOptions.map(({ name, value }) => (
						<MenuItem key={value} value={value}>
							{name}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<RgbaColorField
				value={formData.widget?.opentable?.bg}
				label='Background Color & Opacity'
				onChange={(color) => {
					updatePreviewParam('ot-bg', color);
					setFormData({
						...formData,
						widget: {
							...formData.widget,
							opentable: {
								...formData.widget?.opentable,
								bg: color
							}
						}
					});
				}}
			/>

			<HexColorField
				value={formData.widget?.opentable?.borderColor}
				label="Border Color"
				onChange={(color) => {
					updatePreviewParam('ot-borderColor', color);
					setFormData({
						...formData,
						widget: {
							...formData.widget,
							opentable: {
								...formData.widget?.opentable,
								borderColor: color
							}
						}
					});
				}}
			/>

			<FormControlLabel
				control={
					<Checkbox
						name='openInNewTab'
						checked={formData.widget?.opentable?.newTab || false}
						onChange={(evt) => {
							updatePreviewParam('ot-newtab', evt.target.checked);
							setFormData({
								...formData,
								widget: {
									...formData.widget,
									opentable: {
										...formData.widget?.opentable,
										newTab: evt.target.checked
									}
								}
							});
						}}
					/>
				}
				label='Open in new tab'
			/>

			<FormControlLabel
				control={
					<Checkbox
						name='darkMode'
						checked={formData.widget?.opentable?.dark || false}
						onChange={(evt) => {
							updatePreviewParam('ot-dark', evt.target.checked);
							setFormData({
								...formData,
								widget: {
									...formData.widget,
									opentable: {
										...formData.widget?.opentable,
										dark: evt.target.checked
									}
								}
							});
						}}
					/>
				}
				label='Use dark mode'
			/>
		</Box>
	);
};

import { querySnapshotToArray } from 'tdr-common';

export default async function getInvoiceById (firestore, invoiceId) {
	const invoice = querySnapshotToArray(
		await firestore.collection('Invoices')
			.where('id', '==', invoiceId)
			.limit(1)
			.get()
	);
	return invoice?.[0];
}
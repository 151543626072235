import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import { TDR } from 'tdr-common';

namespace GuestVisitHistory {
	export type Props = {
		guestReservations: TDR.Reservation[]
	};
}

const countReservationsByStatus = (guestReservations) => {
	let upcoming = 0;
	let visits = 0;
	let cancellations = 0;
	let noShows = 0;
	guestReservations.forEach((reservation) => {
		switch(reservation.status) {
			case TDR.Reservation.Status.NoShow:
				noShows ++;
				break;
			case TDR.Reservation.Status.Cancelled:
				cancellations ++;
				break;
			case TDR.Reservation.Status.Completed:
				visits ++;
				break;
			case TDR.Reservation.Status.Booked:
			case TDR.Reservation.Status.Confirmed:
				upcoming ++;
				break;
		}
	});

	return {
		upcoming, visits, cancellations, noShows
	};
};

const GuestVisitHistory = ({ guestReservations }: GuestVisitHistory.Props) => {

	const { upcoming, visits, cancellations, noShows } = countReservationsByStatus(guestReservations);

	return (
		<div className='guest-visit-history'>
			<Typography variant='subtitle1'>Guest Visit History</Typography>
			<div>
				<Divider light flexItem orientation='vertical'/>
				<div className='status-history'>
					<Typography>Upcoming:</Typography>
					<Typography>{upcoming}</Typography>
				</div>
				<Divider light flexItem orientation='vertical'/>
				<div className='status-history'>
					<Typography>Visits:</Typography>
					<Typography>{visits}</Typography>
				</div>
				<Divider light flexItem orientation='vertical'/>
				<div className='status-history'>
					<Typography>Cancellations:</Typography>
					<Typography>{cancellations}</Typography>
				</div>
				<Divider light flexItem orientation='vertical'/>
				<div className='status-history'>
					<Typography>No-Shows:</Typography>
					<Typography>{noShows}</Typography>
				</div>
				<Divider light flexItem orientation='vertical'/>
			</div>
		</div>
	);
};

export default GuestVisitHistory;
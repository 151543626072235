import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

export const CopyButton = ({ text }: {text: string}) => {
	const [copied, setCopied] = useState(false);

	return (
		<button style={{ all: 'unset', cursor: 'pointer' }}>
			<CopyToClipboard text={text} onCopy={() => setCopied(true)}>
				{copied ? <FileCopyIcon /> : <FileCopyOutlinedIcon />}
			</CopyToClipboard>
		</button>
	);
};

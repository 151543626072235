import { Box, Button, Typography } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import { TDR } from 'tdr-common';
import BookingDetailsCard from './BookingDetailsCard';

type AdminActionSuccessProps = {
	title: React.ReactNode;
	reservation: TDR.Reservation;
	modifiedReservation?: TDR.Reservation;
	onClose: () => void;
}
const AdminActionSuccess = ({ title, reservation, modifiedReservation, onClose }: AdminActionSuccessProps) => {
	return (
		<>
			<Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
				<Typography variant='h6'>Success!</Typography>

				<CheckCircleIcon style={{ color: 'rgb(0, 171, 85)', width: '88px', height: '88px' }} />

				<Typography color='textPrimary' variant='body1' align='center'>
        	{title}
				</Typography>

				<BookingDetailsCard reservation={reservation} />

				{!!modifiedReservation && (
					<>
						<Typography color='textPrimary' variant='body1' align='center'>
              Modified to
						</Typography>

						<ArrowDownwardIcon />

						<BookingDetailsCard reservation={modifiedReservation} />
					</>
				)}
			</Box>

			<Button onClick={onClose}>Close</Button>
		</>
	);
};

export default AdminActionSuccess;
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { TDR } from 'tdr-common';


export default async function changeImagePosition(currentPos: number, newPos: number, table: TDR.Table) {

	const media = table.media || [];
	if(Math.max(currentPos, newPos) > media?.length) {
		return false;
	}
	media.splice(newPos, 0, media.splice(currentPos, 1)[0]);
	await firebase.firestore().collection('Tables').doc(table.id).update({
		media
	});
	return true;

}

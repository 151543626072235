import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyButton } from './CopyButton';

type CopyCodeBlockProps = {
	text: string;
}

export const CopyCodeBlock = ({ text }: CopyCodeBlockProps) => {
	return (
		<div style={{ position: 'relative' }}>
			<SyntaxHighlighter language='html' style={atomOneDark} wrapLongLines>
				{text}
			</SyntaxHighlighter>

			<div style={{ position: 'absolute', right: '16px', top: '50%', transform: 'translateY(-50%)' }}>
				<CopyButton text={text} />
			</div>
		</div>
	);
};

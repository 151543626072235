import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const Loading = () => {
	return (
		<Box zIndex={9999999} data-cy='loading-overlay'>
			<Box className='backdrop' zIndex={9999999} style={{
				opacity: 0.8
			}}></Box>
			<Box className='centered-content-block' zIndex={99999999}>
				<CircularProgress />
			</Box>
		</Box>
	);
};

export default Loading;
import firebase from 'firebase/app';
import 'firebase/firestore';
import { v4 as uuid } from 'uuid';
import { DateTime } from 'luxon';
import luxonToFirebaseTimestamp from '../helpers/luxonToFirebaseTimestamp';
import { TDR, querySnapshotToArray, parseTimeComponents, adminSortStr, formatPhoneNumber } from 'tdr-common';

export default async function legacyCreateReservation(
	firestore: firebase.firestore.Firestore,
	userId: string,
	fullName: string,
	phoneNumber: string,
	email: string,
	date: Date,
	time: string,
	guests: number,
	guestNotes: string,
	invoiceId: string,
	price: number,
	selectedTable: TDR.Table,
	selectedRestaurant: TDR.Restaurant,
	notesByRestaurant: string,
	specialOccasion: string[]
) {
	const { hour, minute } = parseTimeComponents(time);
	const reservationTime = DateTime.fromObject({
		year: date.getFullYear(),
		month: date.getMonth() + 1, // +1 because getMonth is the INDEX, not the MONTH (ie, january is 0, not 1)
		day: date.getDate(),
		hour,
		minute
	});

	const reservation = {
		id: uuid(),
		userId,
		tableId: selectedTable?.id || '',
		tableSlug: selectedTable?.slug || '',
		tableName: selectedTable?.name || '',
		restaurantId: selectedRestaurant.id,
		restaurantName: selectedRestaurant.name,
		time: luxonToFirebaseTimestamp(reservationTime),
		status: TDR.Reservation.Status.Booked,
		guests,
		guestNotes: guestNotes || '',
		notesByRestaurant: notesByRestaurant || '',
		createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
		fullName,
		phone: formatPhoneNumber(phoneNumber),
		email,
		invoices: invoiceId ? [invoiceId] : [],
		price,
		specialOccasion: specialOccasion || []
	};

	reservation['adminSortOrder'] = adminSortStr(reservation, []);

	return querySnapshotToArray(await firestore
		.collection('Reservations')
		.doc(reservation.id)
		.set(reservation)
	);
}
import React, { useContext, useState } from 'react';
import { Typography } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StarIcon from '@material-ui/icons/Star';
import WarningIcon from '@material-ui/icons/Warning';
import { TDR } from 'tdr-common';
import { TagSelector } from './Modals';
import Pill from './Pill';
import ModalControlledTextField from './ModalControlledTextField';
import { ModalContext } from '../context/ModalContext';
import { AdminRestaurantContext } from '../context/AdminRestaurantContext';
import { Guest } from '../context/GuestContext';

namespace VisitInformation {
	export type Props = {
		guest: Guest,
		updateGuest: (id: string, data: any)=>void
		reservation: TDR.Reservation,
		updateReservation: (id: string, data: any)=>void
	};
}

const TagPillContainer = ({ children }: { children: any }) => (
	<div className='tag-pill-container'>
		<div>
			{children}
		</div>
	</div>
);

const VisitInformation = ({ guest, updateGuest, reservation, updateReservation }: VisitInformation.Props) => {
	const { openModal } = useContext(ModalContext);
	const { selectedAdminRestaurant } = useContext(AdminRestaurantContext);

	// TODO: instead of putting this here, remove it and put on a higher level
	// as close to the root as we can probably...
	if (!selectedAdminRestaurant) {
		return <div>Loading...</div>;
	}

	const [tags, setTags] = useState(guest.tags || []);
	const { tags: { specialRelationship, specialOccasion, dietaryRestrictions } } = selectedAdminRestaurant || {};

	return (
		<div>
			<div>
				<Typography variant='subtitle1'>Special Relationship</Typography>
				<TagPillContainer>
					{specialRelationship.map((tag) => (
						tags.includes(tag.id) ? (
							<Pill
								key={tag.id}
								className='tag-pill'
							>
								<FavoriteIcon fontSize='small' style={{ color: '#B6534D' }}/>{tag.label}
							</Pill>
						) : null
					))}
					<Pill
						className='tag-pill'
						selected={true}
						onClick={() => openModal(
							<TagSelector
								title='Special Relationship'
								availableOptions={specialRelationship}
								selectedOptions={tags}
								icon={<FavoriteIcon fontSize='small' style={{ color: '#B6534D' }}/>}
								onSave={(tags) => {
									setTags([...tags]);
									updateGuest(guest.id, { tags });
								}}
								onClose={null}
							/>
						)}
					>
						+ Add tags
					</Pill>
				</TagPillContainer>
			</div>
			<div>
				<Typography variant='subtitle1'>Dietary Restrictions</Typography>
				<TagPillContainer>
					{dietaryRestrictions.map((tag) => (
						tags.includes(tag.id) ? (
							<Pill
								key={tag.id}
								className='tag-pill'
							>
								<WarningIcon fontSize='small' style={{ color: '#CFA63C' }}/>{tag.label}
							</Pill>
						) : null
					))}
					<Pill
						className='tag-pill'
						selected={true}
						onClick={() => openModal(
							<TagSelector
								title='Dietary Restrictions'
								availableOptions={dietaryRestrictions}
								selectedOptions={tags}
								icon={<WarningIcon fontSize='small' style={{ color: '#CFA63C' }}/>}
								onSave={(tags) => {
									setTags([...tags]);
									updateGuest(guest.id, { tags });
								}}
								onClose={null}
							/>
						)}
					>
						+ Add tags
					</Pill>
				</TagPillContainer>
			</div>
			<div>
				<Typography variant='subtitle1'>Special Occasion</Typography>
				<TagPillContainer>
					{specialOccasion.map((tag) => (
						reservation.specialOccasion?.includes(tag.id) ? (
							<Pill
								key={tag.id}
								className='tag-pill'
							>
								<StarIcon fontSize='small' style={{ color: '#CFA63C' }}/>{tag.label}
							</Pill>
						) : null
					))}
					<Pill
						className='tag-pill'
						selected={true}
						onClick={() => openModal(
							<TagSelector
								title='Special Occasion'
								availableOptions={specialOccasion}
								selectedOptions={reservation.specialOccasion}
								icon={<StarIcon fontSize='small' style={{ color: '#CFA63C' }}/>}
								onSave={(specialOccasion) => {
									updateReservation(reservation.id, { specialOccasion });
								}}
								onClose={null}
							/>
						)}
					>
						+ Add tags
					</Pill>
				</TagPillContainer>
			</div>
			<ModalControlledTextField
				title='Guest Requests'
				value={reservation.guestNotes || ''}
				onDelete={()=> updateReservation(reservation.id, { guestNotes: '' })}
				onSave={(guestNotes)=> updateReservation(reservation.id, { guestNotes })}
			/>
			<div style={{ marginTop: 48 }}>
				<ModalControlledTextField
					title='Visit Notes'
					value={reservation.notesByRestaurant || ''}
					onDelete={()=> updateReservation(reservation.id, { notesByRestaurant: '' })}
					onSave={(notesByRestaurant)=> updateReservation(reservation.id, { notesByRestaurant })}
				/>
			</div>

		</div>
	);
};

export default VisitInformation;
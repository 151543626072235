import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { TextField, InputAdornment, useMediaQuery } from '@material-ui/core';
import {
	CalendarToday as CalendarTodayIcon,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons';

namespace DatePicker {
	export type Props = {
		selectedDate: any,
		onChange: any,
		minDate?: DateTime,
		maxDate?: DateTime,
		alwaysShowOpenedCalendarOnMobile?: boolean,
		mobileDatePickerOpened?: boolean,
		setMobileDatePickerOpened?: (v: boolean) => void,
		shouldDisableDate?: (date: DateTime) => boolean
	};
}

const defaultMaxDaysAhead = 1000;


const DatePicker = ({ selectedDate, mobileDatePickerOpened, setMobileDatePickerOpened = () => { }, onChange, minDate = DateTime.local(), maxDate, shouldDisableDate = () => false, alwaysShowOpenedCalendarOnMobile = false }: DatePicker.Props) => {
	const theme = useTheme();
	const mediumScreenAndUp = useMediaQuery(theme.breakpoints.up('md'));

	if (!maxDate) {
		maxDate = minDate.plus({ days: defaultMaxDaysAhead });
	}

	const showCalendar = mediumScreenAndUp || mobileDatePickerOpened;
	return (
		<>
			{!alwaysShowOpenedCalendarOnMobile && !mediumScreenAndUp
				&& <TextField
					style={{ caretColor: 'transparent', marginBottom: mobileDatePickerOpened ? 16 : 'initial' }}
					value={DateTime.fromISO(selectedDate.toISOString()).toFormat('MMMM dd, yyyy')}
					onClick={() => setMobileDatePickerOpened(!mobileDatePickerOpened)}
					variant='outlined'
					InputProps={{
						readOnly: true,
						startAdornment: (
							<InputAdornment position='start'>
								<CalendarTodayIcon style={{ width: '1.25em', height: '1.25em' }} />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position='end'>
								{mobileDatePickerOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</InputAdornment>
						)
					}}
				/>}
			{showCalendar && <MuiDatePicker
				disablePast
				disableToolbar
				autoOk
				variant='static'
				format='MMMM dd, yyyy'
				open={mobileDatePickerOpened}
				minDate={minDate}
				maxDate={maxDate}
				value={selectedDate}
				PopoverProps={{
					anchorReference: 'none',
					style: {
						transform: 'translateX(10%) translateY(10%)'
					}
				}}
				shouldDisableDate={shouldDisableDate}
				onChange={onChange}
			/>}
		</>
	);
};

export default DatePicker;
import LuxonUtils from '@date-io/luxon';
import { Box, Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { TDR } from 'tdr-common';
import { proposeLargeGroupModification } from '../api/largeGroup';
import AdminActionError from './AdminActionError';
import AdminActionSuccess from './AdminActionSuccess';
import Modal from './Modal';
import { AdminActionStep, LargeGroupActionProps } from './ModalConfirmApproval';
import { ModalProps } from './ReservationAccordion';

const MINIMUM_GUEST_RESPONSE_TIME = 1;
const DEFAULT_GUEST_RESPONSE_TIME = 12;

const getDateTimeFromReservation = (reservation: TDR.Reservation) => {
	return DateTime.fromSeconds(reservation.time.seconds).setZone(reservation.timezone);
};

const ModalProposeTime = ({ open, onClose, reservation }: ModalProps & LargeGroupActionProps) => {
	const [proposedDate, setProposedDate] = useState(getDateTimeFromReservation(reservation));
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(AdminActionStep.INITIAL);
	const [guestResponseTime, setGuestResponseTime] = useState(DEFAULT_GUEST_RESPONSE_TIME);
	const [guestResponseTimeError, setGuestResponseTimeError] = useState(false);
	const [error, setError] = useState('');
	const [modifiedReservation, setModifiedReservation] = useState<TDR.Reservation>(null);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setGuestResponseTime(+event.target.value);
		setGuestResponseTimeError(false);
	};

	const handleDateChange = (date: DateTime | null) => {
		if (date) {
			setProposedDate(date.setZone(reservation.timezone));
		}
	};

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();

		if (guestResponseTime < MINIMUM_GUEST_RESPONSE_TIME || Number.isNaN(guestResponseTime)) {
			setGuestResponseTimeError(true);
		}
		else {
			setLoading(true);
			proposeLargeGroupModification(reservation, proposedDate, guestResponseTime).then(r => {
				setLoading(false);
				if(r.success) {
					setModifiedReservation(r.reservation);
					setStep(AdminActionStep.SUCCESS);
				}
				else {
					setError(r.message);
					setStep(AdminActionStep.ERROR);
				}
			});
		}
	};

	// when modal opens, reset form values and step
	useEffect(() => {
		if (open) {
			setProposedDate(getDateTimeFromReservation(reservation));
			setGuestResponseTime(DEFAULT_GUEST_RESPONSE_TIME);
			setStep(AdminActionStep.INITIAL);
		}
	}, [open]);

	const components = {
		[AdminActionStep.INITIAL]: (
			<>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '16px',
						paddingBottom: '20px'
					}}
				>
					<Typography variant='h6'>Propose New Time</Typography>

					<Typography color='textPrimary' variant='body1'>
            Select an alternative time to propose to the guest for this booking.
            Guest will be notified by email.
					</Typography>
				</Box>

				<Box
					component='form'
					onSubmit={handleSubmit}
					style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
				>
					<MuiPickersUtilsProvider utils={LuxonUtils}>
						<KeyboardDatePicker
							value={proposedDate}
							onChange={handleDateChange}
						/>

						<KeyboardTimePicker
							value={proposedDate}
							onChange={handleDateChange}
							minutesStep={15}
						/>
					</MuiPickersUtilsProvider>

					<TextField
						type='number'
						label='Guest response time (hours)'
						value={guestResponseTime}
						onChange={handleInputChange}
						error={guestResponseTimeError}
						helperText={
							guestResponseTimeError ? `Must be minimum ${MINIMUM_GUEST_RESPONSE_TIME} hour` : ''
						}
					/>

					<Button type='submit' variant='contained' disabled={loading}>
						{loading ? <CircularProgress /> : 'Submit'}
					</Button>

					<Button onClick={onClose} disabled={loading}>
            Cancel
					</Button>
				</Box>
			</>
		),
		[AdminActionStep.SUCCESS]: (
			<AdminActionSuccess
				title={<>Successfully proposed alternative time to guest.</>}
				reservation={reservation}
				modifiedReservation={modifiedReservation}
				onClose={onClose}
			/>
		),
		[AdminActionStep.ERROR]: (
			<AdminActionError
				title={<>Failed to propose alternative time to guest.</>}
				error={error}
				reservation={reservation}
				onClose={onClose}
			/>
		)
	};

	return (
		<Modal open={open} onClose={onClose}>
			{components[step]}
		</Modal>
	);
};

export default ModalProposeTime;
import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import PillList from './PillList';
import DatesPicker from './DatesPicker';
import { allWeekdays } from './WeekdaysPicker';
import { TDR } from 'tdr-common';
import { FirebaseContext } from '../context/FirebaseContext';
import { RestaurantContext } from '../context/RestaurantContext';
import { DatesDescription } from './DatesDescription';

type Props = {
	activeRestaurant: TDR.Restaurant,
	setActiveRestaurant: any,
}
export const ClosedDates = ({ activeRestaurant, setActiveRestaurant }: Props) => {
	const { firestore } = useContext(FirebaseContext);
	const { selectedRestaurantId } = useContext(RestaurantContext);
	return(
		<>
			<h4>Closed:</h4>
			<Box className='pill-toggle-box'>
				<PillList
					mode='toggle'
					items={allWeekdays}
					selected={activeRestaurant.closed.weekdays}
					onChange={(weekdays: string[]) => {
						setActiveRestaurant(Object.assign({}, activeRestaurant, {
							closed: {
								...activeRestaurant.closed,
								weekdays: weekdays
							}
						}));
					}}
				/>
			</Box>

			<Box className='pill-toggle-box'>
				<DatesPicker
					dates={activeRestaurant.closed.dates}
					reload ={async () => {
						const restaurant = await firestore.collection('Restaurants').doc(selectedRestaurantId).get();
						setActiveRestaurant(restaurant.data() as TDR.Restaurant);
					}
					}
					allRestaurants = {true}
					onChange={(dates: string[]) => {
						setActiveRestaurant(Object.assign({}, activeRestaurant, {
							closed: {
								...activeRestaurant.closed,
								dates: dates.map((date) => ({
									date: date,
									description: activeRestaurant.closed.dates.filter((obj)=>obj.date === date)[0]?.description || ''
								}))
							}
						}));
					}}
				/>
			</Box>

			<Box className='pill-toggle-box'>
				<DatesDescription
					dates={activeRestaurant.closed.dates.map((obj)=>obj.date)}
					reload ={async () => {
						const restaurant = await firestore.collection('Restaurants').doc(selectedRestaurantId).get();
						setActiveRestaurant(restaurant.data() as TDR.Restaurant);
					}}
					allRestaurants = {true}
					onChange={(dates: string[], description: string) => {
						setActiveRestaurant(Object.assign({}, activeRestaurant, {
							closed: {
								...activeRestaurant.closed,
								dates: activeRestaurant.closed.dates.map((obj) => (dates.includes(obj.date) ? {
									date: obj.date,
									description: description
								} : obj))
							}
						}));
					}}
				/>
			</Box>
		</>
	);
};
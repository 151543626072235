import React, { useState, createContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { ProviderProps } from '../common/ProviderProps';
import config from '../common/config';
export namespace FirebaseContext {
	export type Value = {
		initialized: boolean,
		firestore: firebase.firestore.Firestore,
		userId: string,
		email: string,
		token: string,
		analytics: any // TODO: figure it proper typing!
	};
}

export const FirebaseContext = createContext<FirebaseContext.Value>({
	initialized: false,
	firestore: null,
	userId: null,
	email: null,
	token: null,
	analytics: null
});
FirebaseContext.displayName = 'Firebase';

export const FirebaseProvider = ({ children }: ProviderProps) => {
	const [firebaseState, setFirebaseState] = useState<FirebaseContext.Value>({
		initialized: false,
		firestore: null,
		userId: null,
		email: null,
		token: null,
		analytics: null
	});

	const onFirebaseAuthStateChanged = async (user: firebase.User) => {
		if (user) {
			localStorage.setItem('firebaseUserWasPreviouslysignedIn', '1');
			const email = user.email ?? null;

			setFirebaseState({
				...firebaseState,
				userId: user.uid,
				email,
				token: await user.getIdToken().catch((err) => {
					console.log('getIdToken error', err);
					return null;
				}),
				initialized: true,
				firestore: firebase.firestore()
			});
		}

		else {
			localStorage.removeItem('firebaseUserWasPreviouslysignedIn');
		}
	};

	if (firebase.apps.length === 0) {
		const { longPolling } = config.firebase;

		firebase.initializeApp(config.firebase);
		firebase.firestore().settings({ experimentalForceLongPolling: longPolling, merge: true });

		firebase.auth().onAuthStateChanged(onFirebaseAuthStateChanged);

	}

	return (
		<FirebaseContext.Provider value={firebaseState}>
			{children}
		</FirebaseContext.Provider>
	);
};

export const loginWithEmailAndPassword = async (email: string, password: string) => {
	await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
	firebase.auth().signInWithEmailAndPassword(email, password)
		.catch(() => { });
};
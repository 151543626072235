import React from 'react';
import Pill from './Pill';
import filter from 'lodash/filter';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import identity from 'lodash/identity';
import { Close } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

namespace PillList {
	export type Props = {
		items: any[],
		labelKey?: string,
		onChange: (selected: any[]) => void,
		selected?: any[],
		keyPrefix?: string,
		formatLabelFn?: (label: string) => string,
		mode: 'toggle' | 'remove'
	};
}

const PillList = ({ mode, items, labelKey, onChange, selected=[], keyPrefix='', formatLabelFn=identity }: PillList.Props) => {
	return (
		<div className={`pill-container ${mode}`}>
			<div>
				{items.map(((item, index) => {
					const target = labelKey ? item[labelKey] : item;
					const isSelected = selected.includes(item);
					if(target?.hasOwnProperty('description')){
						return <Tooltip title={target.description} key={`${keyPrefix}-${index}`}>
							{/* tooltip doesn't work unless pill is wrapped in a div?
								to use the tooltip, pass in an array of objects with the label and description properties
							*/}
							<div>
								<Pill
									className='tag-pill'
									key={`${keyPrefix}-${index}`}
									onClick={mode !== 'toggle' ? null :
										() => onChange(isSelected
											? filter(selected, s => !isEqual(s, item))
											: uniqWith([...selected, item], isEqual)
										)}
									selected={mode === 'toggle' && isSelected}
								>
									{formatLabelFn(target.label)}
									{mode !== 'remove' ? null :
										<Close onClick={() => onChange(filter(items.map((obj) => obj.label), s => !isEqual(s, target?.label)))} />}
								</Pill>
							</div>
						</Tooltip>;
					}
					else{
						return <Pill
							className='tag-pill'
							key={`${keyPrefix}-${index}`}
							onClick={mode !== 'toggle' ? null :
								() => onChange(isSelected
									? filter(selected, s => !isEqual(s, item))
									: uniqWith([...selected, item], isEqual)
								)}
							selected={mode === 'toggle' && isSelected}
						>
							{formatLabelFn(target)}
							{mode !== 'remove' ? null :
								<Close onClick={() => onChange(filter(items, s => !isEqual(s, item)))} />}
						</Pill>;
					}
				}))}
			</div>
		</div>
	);
};

export default PillList;
import React from 'react';

interface DisplayHistoryProps {
	updateHistory: any[];
}

const ReservationUpdateHistory: React.FC<DisplayHistoryProps> = ({ updateHistory }) => {
	const renderField = (label: string, value: string | undefined) => {
		if (value !== 'N/A') {
			return (
				<p>
					<strong>{label}:</strong> {value}
				</p>
			);
		}
		else {
			return null;
		}
	};

	return (
		<div>
			{updateHistory && updateHistory.filter(entry => !!entry).map((update, index) => (
				<div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
					{update.changesMade && renderField('Changes Made', update.changesMade.join(', '))}
					{update.guestNotes && renderField('Guest Notes', update.guestNotes)}
					{update.notesByRestaurant && renderField('Notes By Restaurant', update.notesByRestaurant)}
					{update.largeGroupOptions?.largeGroupStatus && renderField('Large Group Status', update.largeGroupOptions.largeGroupStatus)}
					{update.phone && renderField('Phone', update.phone)}
					{update.price !== undefined && renderField('Price', `$${update.price}`)}
					{update.guests && renderField('Guests', update.guests.toString())}
					{update.tableId && renderField('Table ID', update.tableId)}
					{update.fullName && renderField('Full Name', update.fullName)}
					{update.time && (typeof update.time?.toDate === 'function') && renderField('Time', update.time.toDate().toLocaleString())}
					{update.tableName && renderField('Label', update.tableName)}
					{update.tableType && renderField('Type', update.tableType)}
					{update.email && renderField('Email', update.email)}
					{update.status && renderField('Status', update.status)}
					{update.updatedAt && (typeof update.updatedAt?.toDate === 'function') && renderField('Updated At', update.updatedAt.toDate().toLocaleString())}
				</div>
			))}
		</div>
	);
};

export default ReservationUpdateHistory;

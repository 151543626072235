import { Button, ButtonGroup } from '@material-ui/core';
import React from 'react';
import { TDR } from 'tdr-common';

const { AdminActionRequired, GuestActionRequired, Approved, Denied } = TDR.Reservation.LargeGroupStatus;

const LARGE_GROUP_STATUS_TEXT = {
	[AdminActionRequired]: 'Admin action',
	[GuestActionRequired]: 'Guest action',
	[Approved]: 'Approved',
	[Denied]: 'Denied'

};

const LargeGroupStatus = ({
	reservation,
	setIsConfirmApprovalOpen,
	setIsConfirmDenialOpen,
	setIsProposeTimeOpen,
	setSelectedReservation
}: {
	reservation: TDR.Reservation,
	setIsConfirmApprovalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setIsConfirmDenialOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setIsProposeTimeOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedReservation: React.Dispatch<React.SetStateAction<TDR.Reservation>>;
}) => {

	const handleOpenApprovalConfirmation = (event: React.MouseEvent) => {
		event.stopPropagation();
		setSelectedReservation(reservation);
		setIsConfirmApprovalOpen(true);
	};

	const handleOpenDenialConfirmation = (event: React.MouseEvent) => {
		event.stopPropagation();
		setSelectedReservation(reservation);
		setIsConfirmDenialOpen(true);
	};

	const handleOpenProposal = (event: React.MouseEvent) => {
		event.stopPropagation();
		setSelectedReservation(reservation);
		setIsProposeTimeOpen(true);
	};

	if (reservation.largeGroupOptions.largeGroupStatus === TDR.Reservation.LargeGroupStatus.AdminActionRequired) {
		return (
			<ButtonGroup>
				<Button size='small' variant='contained' color='primary' onClick={handleOpenApprovalConfirmation}>
          Approve
				</Button>

				<Button size='small' variant='outlined' onClick={handleOpenDenialConfirmation}>
          Deny
				</Button>
				{!reservation.pendingChanges &&
							<Button size='small' variant='contained' onClick={handleOpenProposal}>
        						  Propose time
							</Button>
				}
			</ButtonGroup>
		);
	}
	else {
		return <Button size='small'>{LARGE_GROUP_STATUS_TEXT[reservation.largeGroupOptions.largeGroupStatus]}</Button>;
	}
};

export default LargeGroupStatus;
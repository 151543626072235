// GuestSearch.jsx
import React, { useContext, useState } from 'react';
import GuestList from './GuestList';
import { FirebaseContext } from '../../context/FirebaseContext';
import searchGuests from '../../api/searchGuests';

const GuestSearch = () => {
	const { firestore } = useContext(FirebaseContext);
	const [searchString, setSearchString] = useState('');
	const [guests, setGuests] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleSearch = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const results = await searchGuests(firestore, searchString);
			setGuests(results);
		}
		catch (error) {
			console.error('Error searching guests:', error);
		}

		setLoading(false);
	};

	return (
		<div>
			<h2>Search Guests</h2>
			<h3>(case sensitive for now)</h3>
			<form onSubmit={handleSearch}>
				<input
					type="text"
					placeholder="Enter name, email, or phone"
					value={searchString}
					onChange={(e) => setSearchString(e.target.value)}
				/>
				<button type="submit" disabled={loading}>
					{loading ? 'Searching...' : 'Search'}
				</button>
			</form>

			<GuestList guests={guests} />
		</div>
	);
};

export default GuestSearch;

import React from 'react';
import { TDR } from 'tdr-common';
import GuestVisitHistory from './GuestVisitHistory';
import PastVisitNotes from './PastVisitNotes';

namespace VisitHistory {
	export type Props = {
		guestReservations?: TDR.Reservation[]
		showPastVisitNotes?: boolean
	};
}

const VisitHistory = ({ guestReservations, showPastVisitNotes = true }: VisitHistory.Props) => {

	const pastGuestReservations = guestReservations.filter((reservation) => (
		reservation.status !== TDR.Reservation.Status.Confirmed &&
		reservation.status !== TDR.Reservation.Status.Booked
	));

	return (
		<div className='visit-history'>
			<GuestVisitHistory guestReservations={guestReservations}/>
			{showPastVisitNotes && pastGuestReservations.length ? <PastVisitNotes pastGuestReservations={pastGuestReservations}/> : null}
		</div>
	);
};

export default VisitHistory;
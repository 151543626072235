import { Box, Chip, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import React from 'react';
import { HexColorPicker } from 'react-colorful';
import { HEX, isHEX } from '../admin-widgets/types';

export interface HexColorFieldProps {
	value: HEX | '';
	onChange: (color: HEX | '') => void;
	label: string;
	disabled?: boolean;
}

export function HexColorField({
	value,
	label,
	disabled,
	onChange
}: HexColorFieldProps) {
	const [color, setColor] = React.useState<HEX | ''>(value);
	const [showPicker, setShowPicker] = React.useState<boolean>(false);
	const [pickerFocused, setPickerFocused] = React.useState<boolean>(false);
	//https://app.tablz.com/embed/mezcalero?color=000 needs to work
	return (
		<Box
			style={{
				display: 'flex',
				flexDirection: 'column-reverse',
				alignItems: 'center',
				position: 'relative'
			}}
		>
			<HexColorPicker
				color={color}
				onChange={(newColor) => {
					if (isHEX(newColor)) {
						onChange(newColor as HEX);
					}
					else if (!newColor) {
						onChange(null);
					}
					setColor(newColor as HEX);
				}}
				style={{
					height: '120px',
					width: showPicker || pickerFocused ? '120px' : 0,
					opacity: showPicker || pickerFocused ? 1 : 0,
					position: 'absolute',
					right: 0,
					transition: 'all 0.2s ease-in-out',
					zIndex: 2
				}}
				onFocus={() => setPickerFocused(true)}
				onBlur={() => setPickerFocused(false)}
			/>
			<TextField
				disabled={disabled}
				label={label}
				variant='outlined'
				InputLabelProps={{ shrink: true }}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<Chip style={{ backgroundColor: color }}/>
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton onClick={() => {
								setColor('');
								onChange('');
							}}>
								<Clear />
							</IconButton>
						</InputAdornment>
					)
				}}
				onChange={(e) => {
					if (isHEX(e.target.value)) {
						onChange(e.target.value as HEX);
					}
					else if (e.target.value === '') {
						onChange('');
					}
					setColor(e.target.value as HEX);
				}}
				value={color}
				onFocus={() => setShowPicker(true)}
				onBlur={() => setShowPicker(false)}
			/>
		</Box>
	);
}

import React, { useContext, useEffect, useState } from 'react';
import { TDR } from 'tdr-common';
import { RestaurantContext } from '../context/RestaurantContext';
import { TableContext } from '../context/TableContext';
import ReservationAccordion from './ReservationAccordion';

type ReservationsListProps = {
	reservations: TDR.Reservation[];
	setSelectedReservation: React.Dispatch<React.SetStateAction<TDR.Reservation>>;
	setIsModifyStatusOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setIsConfirmApprovalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setIsConfirmDenialOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setIsProposeTimeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReservationsList = ({ reservations, setSelectedReservation, setIsModifyStatusOpen, setIsConfirmApprovalOpen, setIsConfirmDenialOpen, setIsProposeTimeOpen }: ReservationsListProps ) => {
	const { getTablesByRestaurant, getTableById } = useContext(TableContext);
	const { restaurants, selectedRestaurant } = useContext(RestaurantContext);

	const [tables, setTables] = useState<TDR.Table[] | null>(null);

	const getRestaurant = (id: string) => (restaurants || []).find((restaurant) => restaurant.id === id);
	const getTable = (id: string) => tables.find((table) => table?.id === id);

	useEffect(() => {
		if(!(reservations?.length > 0)) {
			setTables(null);
		}
		else if (selectedRestaurant?.id) {
			getTablesByRestaurant(selectedRestaurant.id).then(tables => {
				setTables(tables);
			});
		}
		else {
			const tableSet = new Set<string>();
			reservations.forEach(r => {
				tableSet.add(r.tableId);
			});
			Promise.all(Array.from(tableSet)?.map(getTableById) ?? []).then((maybeTables: TDR.Table[] | null) => {
				setTables(maybeTables);
			});
		}
	}, [selectedRestaurant, reservations]);



	return <>
		{tables !== null && reservations
			.filter(
				(reservation) => reservation.status !== TDR.Reservation.Status.Pending
			)
			.map((reservation) => (
				<ReservationAccordion
					key={reservation.id}
					restaurant={getRestaurant(reservation.restaurantId)}
					table={getTable(reservation.pendingChanges?.tableId || reservation.tableId)}
					{...{
						reservation,
						setSelectedReservation,
						setIsModifyStatusOpen,
						setIsConfirmApprovalOpen,
						setIsConfirmDenialOpen,
						setIsProposeTimeOpen
					}}
				/>
			))}
	</>;
};

export default ReservationsList;
import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-drag-reorder';
import { TDR } from 'tdr-common';

import { Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Link, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import firebase from 'firebase/app';
import 'firebase/firestore';
import changeImagePosition from '../api/changeImagePosition';
import deleteMedia from '../api/deleteMedia';
import uploadImages from '../api/uploadImages';
import config from '../common/config';

namespace ImageRow {
	export type Props = {
		media: TDR.MediaContent,
		handleDelete: (media: TDR.MediaContent) => void
	}
}


const ImageRow = ({ media, handleDelete }: ImageRow.Props) => {

	// eg: http://0.0.0.0:9199/download/storage/v1/b/3dr/o/images%2FTables%2Ftable-1%2Fgobbo.png?generation=1622046087325&alt=media
	// this may need to change if we update how we store and access our files - for now, it seems correct
	// const imageName = (new URL(media.url)).pathname.split('%2F').pop();
	const url = new URL(media.path, config.cdnHost).toString();
	return (

		<Card className={'image-card'}>
			<div className={'info-container'}>
				{media.type === TDR.MediaType.VIDEO ?
					<CardMedia
						component={'video'}
						src={url}
						className={'preview'}
					/> :
					<CardMedia
						image={url}
						className={'preview'}
					/>
				}
				<CardContent>
					<Link href={url}>{media.path}</Link>
				</CardContent>

				<CardActions>
					<Button onClick={() => {
						handleDelete(media);
					}} className={'delete-btn'}>
						<DeleteIcon fontSize='small'/>
					</Button>
				</CardActions>
			</div>

		</Card>
	);
};


namespace ImageManager {
	export type Props = {
		table: TDR.Table,
		updateTable: (table: TDR.Table) => void,
	}
}
const ImageManager = ({ table, updateTable }: ImageManager.Props) => {
	const [image, setImage] = useState(null);
	const [imageName, setImageName] = useState('');
	const [updatedTable, setUpdatedTable] = useState(table);
	const [uploading, setUploading] = useState(false);
	const [deleting, setDeleting] = useState(false);

	const handleAdd = e => {
		if(e.target.files[0]){
			const file = e.target.files[0];
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				setImage(reader.result);
				setImageName(file.name);
			};
		}
	};

	async function fetchTableData () {
		const doc = await firebase.firestore().collection('Tables').where('id', '==', table.id).limit(1).get();
		const updated = doc.docs[0].data() as TDR.Table;
		updateTable(updated);
		setUpdatedTable(updated);
	}
	useEffect(() => {
		fetchTableData();
	}, []);

	const handleUpload = async () => {
		setUploading(true);

		uploadImages(image, imageName, 'Tables', table.id).then(() => {
			setImage(null);
			fetchTableData().then(() => {
				setUploading(false);
			});
		});
	};

	const handleDelete = async (media: TDR.MediaContent) => {
		setDeleting(true);
		deleteMedia(media, table).then(() => {
			fetchTableData().then(() => {
				setDeleting(false);
			});
		});

	};
	const updateImagePosition = async (currentPos: number, newPos: number) => {
		setDeleting(true);
		changeImagePosition(currentPos, newPos, table).then(() => {
			fetchTableData().then(() => {
				setDeleting(false);
			});
		});
	};

	return (
		<div className={'image-manager'}>
			<div className={'header'}>
				<Typography variant={'h6'}>Image Manager</Typography>
				<input
					type={'file'}
					multiple={false}
					onChange={handleAdd}
				/>
				<Button
					onClick={handleUpload}
					className = 'upload-btn'
					disabled={uploading}
				>
					{ uploading? <CircularProgress color={'secondary'}/> : <Typography variant={'subtitle2'}> Upload </Typography>}

				</Button>
			</div>

			<div className={'list-container'}>
				{updatedTable.media?.length >= 1 && <>{deleting || uploading ? <CircularProgress/> :
					<Draggable onPosChange={updateImagePosition}>
						{updatedTable.media?.map((media) => {
							return <ImageRow key={media.path} media={media} handleDelete={handleDelete} />;
						})}
					</Draggable>

				}</>}

			</div>
		</div>
	);
};

export default ImageManager;

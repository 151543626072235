import React, { useState, useEffect, useContext, useReducer, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import last from 'lodash/last';
import find from 'lodash/find';
import findLast from 'lodash/findLast';
import findLastIndex from 'lodash/findLastIndex';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import toPairs from 'lodash/toPairs';
import first from 'lodash/first';
import chunk from 'lodash/chunk';
import clone from 'lodash/clone';
import omitBy from 'lodash/omitBy';
import has from 'lodash/has';
import range from 'lodash/range';
import isNumber from 'lodash/isNumber';
import flatMap from 'lodash/flatMap';
import identity from 'lodash/identity';
import values from 'lodash/values';
import reverse from 'lodash/reverse';
import {
	Box,
	Switch,
	Button,
	IconButton,
	Checkbox,
	TextField,
	Table,
	TableRow,
	TableCell,
	TableBody,
	MenuItem,
	Select,
	FormControlLabel,
	FormControl,
	InputLabel
} from '@material-ui/core';
import {
	AlarmOff as ClockBlock,
	Edit
} from '@material-ui/icons';
import { DEFAULT_RESERVATION_TIME_BUFFER, TDR, timeSlotsToServices, formatMonetaryValue, toNearestCent } from 'tdr-common';
import Modal from './Modal';
import { ModalContext } from '../context/ModalContext';
import { Autocomplete } from '@material-ui/lab';
import { RestaurantContext } from '../context/RestaurantContext';

namespace TimeSlotsEditor {
	export type Props = {
		timeSlots: TDR.TimeSlotMap,
		onChange: (timeSlots: TDR.TimeSlotMap) => void
	};
}

const allTimes = [
	'00:00', '00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45', '03:00', '03:15', '03:30', '03:45',
	'04:00', '04:15', '04:30', '04:45', '05:00', '05:15', '05:30', '05:45', '06:00', '06:15', '06:30', '06:45', '07:00', '07:15', '07:30', '07:45',
	'08:00', '08:15', '08:30', '08:45', '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45',
	'12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45',
	'16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45',
	'20:00', '20:15', '20:30', '20:45', '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45'
];

const hoursOnly = allTimes.filter(t => t.endsWith('00'));
const hourEndsOnly = allTimes.filter(t => t.endsWith('45'));

function hourBounds (timeSlots:TDR.TimeSlotMap) {
	if (isEmpty(timeSlots)) {
		return ['16:00', '22:45'];
	}
	const times = keys(timeSlots).sort();
	const from = times[0];
	const to = last(times);

	return [
		findLast(hoursOnly, hour => hour <= from) || '00:00',
		find(hourEndsOnly, hour => hour > to) || '23:45'
	];
}

const TimeSlotsEditor = ({ timeSlots, onChange }: TimeSlotsEditor.Props) => {
	const { selectedRestaurant: restaurant } = useContext(RestaurantContext);

	const defaultTimeSlot = {
		regularPrice: 0,
		available: true,
		priceTier: 0,
		service: null,
		seating: null
	};

	const [defaultFrom, defaultUntil] = hourBounds(timeSlots);
	const [showTimeFrom, setShowTimeFrom] = useState(defaultFrom);
	const [showTimeUntil, setShowTimeUntil] = useState(defaultUntil);
	const timeRows = chunk(allTimes.filter(t => t >= showTimeFrom && t <= showTimeUntil), 4);


	// this could be wrapped into a single useReducer, but I'm not sure it's _that_ much better
	const [selecting, setSelecting] = useState(false);
	const [_selectStart, setSelectStart] = useState<string>(null);
	const [_selectEnd, setSelectEnd] = useState<string>(null);
	const [selectStart, selectEnd] = (
		!_selectStart ? [null, null] :
			!_selectEnd ? [_selectStart, null] :
				[_selectStart, _selectEnd].sort()
	);
	const inRange = (timeStr) => selectStart && timeStr >= selectStart && selectEnd && timeStr <= selectEnd;
	const selected = !selecting && selectStart && selectEnd &&
		allTimes.filter(inRange).reduce((tslots, time) => {
			tslots[time] = timeSlots[time] || clone(defaultTimeSlot);
			return tslots;
		}, {});

	const services = timeSlotsToServices([timeSlots]);

	const { openModal, closeModal } = useContext(ModalContext);

	return (
		<Box className={'timeslot-editor'}>

			<Box className={'toolbar'}>
				<Box className='left'>
					<Select
						value={showTimeFrom}
						label="From"
						onChange={e => setShowTimeFrom(e.target.value as string)}
					>
						{hoursOnly.filter(h => h < showTimeUntil).map(hour =>
							<MenuItem key={hour} value={hour}>{hour}</MenuItem>)}
					</Select>
					<Select
						value={showTimeUntil}
						label="Until"
						onChange={e => setShowTimeUntil(e.target.value as string)}
					>
						{hourEndsOnly.filter(h => h > showTimeFrom).map(hour =>
							<MenuItem key={hour} value={hour}>{hour.replace('45', '00')}</MenuItem>)}
					</Select>
				</Box>

				<Box className='center'>
				</Box>

				<Box className='right'>
					<IconButton
						disabled={!selected}
						onClick={() => onChange(omitBy(timeSlots, (slot, time) => inRange(time)))}
					>
						<ClockBlock />
					</IconButton>

					<IconButton
						disabled={!selected}
						onClick={() => openModal(
							<TimeSlotModal
								onClose={closeModal}
								slots={selected}
								services={services}
								onSave={(slots) => onChange({
									...timeSlots,
									...slots
								})}
							/>)}
					>
						<Edit />
					</IconButton>
				</Box>
			</Box>

			<Table className={'timeslots-display'}>
				<TableBody>
					<TableRow>
						<TableCell></TableCell>
						<TableCell className='minute-label'>:00</TableCell>
						<TableCell className='minute-label'>:15</TableCell>
						<TableCell className='minute-label'>:30</TableCell>
						<TableCell className='minute-label'>:45</TableCell>
					</TableRow>
					{timeRows.map((hourStrs, i) => (
						<TableRow key={i}>
							<TableCell className='hour-label'>{hourStrs[0].split(':')[0]}:</TableCell>
							{hourStrs.map(time => {
								const slot = timeSlots[time];
								const hasSlot = has(timeSlots, time);

								// bleah, legacy is fun
								const blockedSlot = hasSlot && !slot;
								const blockedSlotLegacy = slot && !slot.available;

								const duration = slot?.resDuration || restaurant.timeBuffer?.minutesAfter || DEFAULT_RESERVATION_TIME_BUFFER.minutesAfter;

								const highlightClass = (!inRange(time) ? '' : selecting ? 'selecting' : 'selected');
								const slotClass = [
									'slot',
									!hasSlot ? 'unspecified' : '',
									!slot ? 'unavailable' : ''
								].join(' ');

								return <TableCell key={time} className={highlightClass}
									onClick={() => {
										if (selecting) {
											setSelectEnd(time);
											setSelecting(false);
										}
										else {
											setSelectStart(time);
											setSelectEnd(time);
											setSelecting(true);
										}
									}}
									onMouseOver={() => {
										if (selecting) {
											setSelectEnd(time);
										}
									}}
									onDoubleClick={() => {
										setSelectStart(null);
										setSelectEnd(null);
										setSelecting(false);
									}}
								>
									<Box className={slotClass}>
										{!hasSlot ? null :
											blockedSlot ? '✘' : blockedSlotLegacy ? '✘ (legacy, messes up the dots)' :
												<> ✔
													<Box className='summary'>
														<span className='row'>
															{!slot.service ? null : <span>{slot.service}</span>}
															<span>{slot.seating || slot.timeLabel}</span>
															<span></span>
														</span>
														<span className='row'>
															<span>
																{formatMonetaryValue(slot.regularPrice, restaurant?.currency)}
																{slot.isPerGuest ? ' /guest' : ''}
															</span>
															<span>{range(slot.priceTier).map(() => '$').join('')}</span>
															<span>{duration} min</span>
														</span>
													</Box>
												</>}
									</Box>
								</TableCell>;
							})}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Box>
	);
};



namespace TimeSlotModal {
	export type Props = {
		slots: TDR.TimeSlotMap,
		services?: TDR.Service[],
		onClose: () => void,
		onSave: (slots: TDR.TimeSlotMap) => void
	};
}

const CURVE_ROUND_TARGETS = ['0.01', '0.25', '0.50', '1.00', '2.50', '5.00', '10.00'];

const TimeSlotModal = ({ slots, services, onClose, onSave }: TimeSlotModal.Props) => {
	services = services || [];
	const times = keys(slots).sort();
	const orderedSlots = sortBy(toPairs(slots), ([time ]) => time);

	const [updatedSlot, update] = useReducer<React.Reducer<TDR.TimeSlot, [string, any]>>((slot, [key, value]) => {
		return { ...slot, [key]: value };
	}, cloneDeep(orderedSlots[0][1]));

	const initialPrices = orderedSlots.map(([, slot]) => slot.regularPrice || 0);
	const [slotPrices, setSlotPrices] = useState<number[]>(initialPrices);

	const [priceMaxStr, setPriceMaxStr] = useState(`${(Math.max(...initialPrices) || 4) * 1.25}`);
	const [priceMax, setPriceMax] = useState<number>();
	useEffect(() => {
		const newMax = parseFloat(priceMaxStr);
		if (isNumber(newMax)) {
			setPriceMax(newMax);
		}
	}, [priceMaxStr]);

	console.log({ priceMaxStr, priceMax, parsed: parseFloat(priceMaxStr) });

	const [roundToStr, setRoundTo] = useState(getRoundTo(values(slots).map(slot => slot.regularPrice)).toFixed(2));
	const roundTo = parseFloat(roundToStr) || 0.25;

	const [useService, setUseService] = useState(isEmpty(updatedSlot.timeLabel));

	const serviceNames = uniq([...TDR.DEFAULT_SERVICE_NAMES, ...services.map(s => s.name)]).filter(identity);
	const seatingNames = uniq(useService
		? [...TDR.DEFAULT_SEATING_NAMES, ...flatMap(services, s => s.seatings.map(s => s.name))]
		: flatMap(slots, slot => slot.timeLabel)
	).filter(identity);

	const disable = !updatedSlot?.available;
	const disablePriceTier = disable || slotPrices.every(price => price === 0);

	useEffect(() => {
		if (disablePriceTier && updatedSlot.priceTier !== 0) {
			update(['priceTier', 0]);
		}
		else if (!disablePriceTier && updatedSlot.priceTier === 0) {
			update(['priceTier', 1]);
		}
	}, [slotPrices]);

	return (
		<Modal
			title={
				<Box>
					{`Editing ${first(times)} - ${last(times)}`}
					<FormControlLabel
						label='Available'
						labelPlacement="end"
						style={{ marginLeft: '2em' }}

						control={<Switch
							color='primary'
							checked={updatedSlot.available}
							onChange={e => update(['available', e.target.checked])}
						/>}
					/>
				</Box>
			}
			styleOverride={{ minWidth: '30em' }}
			onClose={() => onClose()}
		>
			<Box className='timeslot-editor-modal'>

				<Box display={'flex'} flexDirection='row'>
					<Autocomplete
						freeSolo
						disabled={!useService || disable}
						options={serviceNames}
						value={updatedSlot.service || ''}
						onInputChange={(e, value) => update(['service', value || ''])}
						renderInput={(params) => <TextField {...params} variant="outlined" label="Service" />}
					/>

					<Autocomplete
						freeSolo
						disabled={disable}
						options={seatingNames}
						value={(useService ? updatedSlot.seating : updatedSlot.timeLabel) || ''}
						onInputChange={(e, value) => update([useService ? 'seating' : 'timeLabel', value || ''])}
						renderInput={(params) => <TextField {...params} variant="outlined" label={useService ? 'Seating' : 'Timeslot Label'} />}
					/>

					<Checkbox
						checked={useService}
						onChange={e => setUseService(e.target.checked)}
					/>
				</Box>

				<TextField
					label='Res Duration (minutes)'
					disabled={disable}
					placeholder='Res Duration'
					variant='outlined'
					value={updatedSlot.resDuration ?? DEFAULT_RESERVATION_TIME_BUFFER.minutesAfter}
					onChange={e => update(['resDuration', parseInt(e.target.value || '0')])}
				/>

				<SlotPriceEditor
					{...{
						max: priceMax || 5,
						prices: initialPrices,
						roundTo,
						onChange: setSlotPrices
					}}
				/>

				<Box className='price-editor'>
					<TextField
						label='Max'
						disabled={disable}
						className='curve-input'
						variant='outlined'
						value={priceMaxStr}
						error={!isNumber(parseFloat(priceMaxStr))}
						onChange={e => setPriceMaxStr(e.target.value)}
					/>

					<FormControl>
						<InputLabel variant="standard" htmlFor="uncontrolled-native">
							to nearest $
						</InputLabel>
						<Select
							value={roundToStr}
							onChange={e => setRoundTo(e.target.value as string)}
						>
							{CURVE_ROUND_TARGETS.map(target =>
								<MenuItem key={target} value={target}>$ {target}</MenuItem>)}
						</Select>
					</FormControl>


					<FormControl>
						<InputLabel variant="standard" htmlFor="uncontrolled-native">
							Cost Display
						</InputLabel>
						<Select
							disabled={disablePriceTier}
							value={disablePriceTier ? '0' : `${updatedSlot.priceTier || 1}`}
							onChange={e => update(['priceTier', parseInt(e.target.value as string || '0')])}
						>
							<MenuItem value={0} disabled={true}>Free</MenuItem>
							<MenuItem value={1}>$</MenuItem>
							<MenuItem value={2}>$$</MenuItem>
							<MenuItem value={3}>$$$</MenuItem>
						</Select>
					</FormControl>

					<FormControl>
						<InputLabel variant="standard" htmlFor="uncontrolled-native">
							Price Type
						</InputLabel>
						<Select
							value={updatedSlot.isPerGuest ? 'per-guest' : 'add'}
							onChange={e => update(['isPerGuest', e.target.value === 'per-guest'])}
						>
							<MenuItem value={'add'}>flat</MenuItem>
							<MenuItem value={'per-guest'}>per-guest</MenuItem>
						</Select>
					</FormControl>

				</Box>

				<Button
					variant='contained'
					color='primary'
					onClick={() => {

						const updatedSlots = orderedSlots.reduce((agg, [time ], index) => {
							agg[time] = updatedSlot.available && {
								...updatedSlot,
								regularPrice: round(slotPrices[index], roundTo),
								service: useService ? updatedSlot.service : null,
								seating: useService ? updatedSlot.seating : null
							};
							return agg;
						}, {});

						onSave(updatedSlots);
						onClose();
					}}
				>
					Save
				</Button>
			</Box>
		</Modal>
	);
};

export default TimeSlotsEditor;


function round (price, toNearest) {
	return toNearestCent(Math.round(price/toNearest) * toNearest);
}

function getRoundTo (prices: number[]) :number {

	// friendly default for a common special case
	if (prices.every(price => price === 0)) {
		return 1;
	}

	const candidates = reverse(CURVE_ROUND_TARGETS).map(parseFloat);

	for (const price of prices) {
		while (price % candidates[0] !== 0) {
			candidates.shift();
			if (isEmpty(candidates)) {
				break;
			}
		}
	}

	return candidates[0] || 0.01;
}


namespace SlotPriceEditor {
	export type Props = {
		prices: number[],
		min?: number,
		max?: number,
		roundTo: number,
		onChange: (prices: number[]) => void
	};
}

type Point = {
	x: number,
	y: number
}

const coordTranslator = new DOMPoint();
function svgEventPoint (svg: SVGSVGElement, evt: React.MouseEvent<SVGSVGElement, MouseEvent>) {
	coordTranslator.x = evt.clientX;
	coordTranslator.y = evt.clientY;
	return coordTranslator.matrixTransform(svg.getScreenCTM().inverse());
}


// given a list of prices, back-fill some reasonable line segment that could have produced them
// do this by identifying points where the slope changes, and giving them a new segment
function deriveNormalizedSegments (prices:number[]) :Point[] {
	const slotCount = prices.length;

	const points = prices.reduce((points, price, index, prices) => {

		// skip the first and last, they get special handling
		if (index !== 0 && index !== slotCount - 1) {
			const prev = prices[index-1];
			const next = prices[index+1];

			// compare the slope to the last price, with the slope to the next price: if they're different, add a point
			if (price - prev !== next - price) {
				// plus 0.5 so I hit the center, not the left edge
				// height - Y because svgs have an inverted y axis
				points.push({ x: index + 0.5, y: price });
			}
		}
		return points;
	}, []);

	// okay, now I need to add the first and last points - these aren't centered over the slot, so they need a different approach
	// project the line from prices[1] through prices[0], to x=0 (remember, prices are on the halves, x=0.5, x=1.5, etc)
	// equation of a line, y = mx + b, m is the slope, b is the y-intercept, y is a price, and x is the center of it's slot
	// I want the intercept, SO
	// 		y = mx + b
	// 		b = y - mx  ; where y = prices[0], x = 0.5
	//					; where m, slope, = rise/run = (prices[1] - prices[0]) / (1.5 - 0.5) = prices[1] - prices[0]
	// 		b = prices[0] - ((prices[1] - prices[0]) * 0.5)
	//		b = prices[0] - prices[1]/2 + prices[0]/2
	// 		b = (prices[0] * 1.5) + (prices[1] * 0.5)

	const firstPointY = prices[0] * 1.5 - prices[1] * 0.5;
	const lastPointY = prices[slotCount-1] * 1.5 - prices[slotCount -2] * 0.5;

	points.unshift({ x: 0, y: firstPointY ?? prices[0] });
	points.push({ x: slotCount + 1, y: lastPointY ?? prices[0] });

	return points;
}



// this is hardcoded to a 3:1 aspect ratio, because the whole admin console is throwaway code
const SlotPriceEditor = ({ max, prices, roundTo, onChange }: SlotPriceEditor.Props) => {

	const height = max + 1;
	const width = height * 3;
	const slotCount = prices.length;
	const slotWidth = width / slotCount;

	const [points, setPoints] = useState<Point[]>(deriveNormalizedSegments(prices));

	const sortedPoints = sortBy(points, ['x']);
	if (!isEmpty(sortedPoints)) {
		sortedPoints[0].x = 0;
		last(sortedPoints).x = prices.length;
	}

	const roundedSlotValue = (slot:number, points?:Point[]) => {
		points = points ?? sortedPoints;
		const slotX = (slot + 0.5); // +0.5 because I want the center of the slot, not the left edge

		// find the points to either side of the slot center
		const pointIndex = findLastIndex(points, p => p.x < slotX);
		const A = points[pointIndex];
		const B = points[pointIndex + 1];

		// where between these points is the slot?
		const slotPosBetweenPoints = (slotX - A.x) / (B.x - A.x);

		// what is the height of the line at that position?
		const price = ((B.y - A.y) * slotPosBetweenPoints) + A.y;

		return Math.max(round(price, roundTo), 0);
	};

	const [activePointIndex, setActivePointIndex] = useState(null);
	const dragging = isNumber(activePointIndex);
	const ref = useRef<SVGSVGElement>(null);

	return <>
		<svg
			ref={ref}
			className='price-line-editor'
			onDragStart={() => false}

			viewBox={`0 0 ${width} ${height}`}
			style={{ width: '100%' }}

			onDoubleClick={evt => {
				const pos = svgEventPoint(ref.current, evt);
				const newPoints = sortBy([...points, { x: pos.x / slotWidth, y: height - pos.y - 1 }], ['x']);
				setPoints(newPoints);
				onChange(range(slotCount).map(s => roundedSlotValue(s, newPoints)));
			}}

			onMouseUp={!dragging ? null : () => {
				setActivePointIndex(null);
				setPoints(sortedPoints);
				onChange(range(slotCount).map(s => roundedSlotValue(s, sortedPoints)));
			}}

			onMouseMove={!dragging ? null : evt => {

				// if there are no buttons pressed, we moused out and released
				if (!evt.buttons) {
					setActivePointIndex(null);
					setPoints(sortedPoints);
					onChange(range(slotCount).map(s => roundedSlotValue(s, sortedPoints)));
					return;
				}

				const pos = svgEventPoint(ref.current, evt);
				points[activePointIndex] = { x: pos.x / slotWidth, y: height - pos.y - 1 };
				setPoints([...points]);
			}}
		>

			{range(slotCount).map(slot => {
				const price = roundedSlotValue(slot);

				return <g className='timeslot' key={slot}>
					<rect
						className='background'
						x={(slot * slotWidth) + (slotWidth * 0.05)} rx='2%'
						y={0}
						width={slotWidth * 0.9}
						height={height}
						fill=''
					/>

					<rect
						className='snapped-price'
						x={(slot * slotWidth) + (slotWidth * 0.05)} rx='2%'
						y={height - 1 - price}
						width={slotWidth * 0.9}
						height={price}
						fill=''
					/>

					<text
						x={(slot + 0.6) * slotWidth}
						y={height * 1.04}
						style={{ fontSize: `${height * 0.1}px` }}
						transform={`rotate(270, ${(slot + 0.5) * slotWidth}, ${height})`}
					>
						${price.toFixed(2)}
					</text>

					<text
						x={(slot + 0.6) * slotWidth}
						y={0}
						style={{ fontSize: `${height * 0.1}px` }}
						transform={`rotate(90, ${(slot + 0.4) * slotWidth}, 0)`}
					>
						${price.toFixed(2)}
					</text>

				</g>;
			})}

			{sortedPoints.map((A, index) => {
				const B = sortedPoints[index + 1];
				if (!B) {
					return null;
				}

				return <line className='line'
					x1={A.x * slotWidth} y1={height - A.y - 1}
					x2={B.x * slotWidth} y2={height - B.y - 1}
					key={index}
				/>;
			})}

			{sortedPoints.map((point, i) => {
				return <circle
					cx={point.x * slotWidth} cy={height - point.y - 1} r='3%'

					onMouseDown={() => {
						setActivePointIndex(i);
					}}

					onDoubleClick={evt => {
						points.splice(i, 1);
						setPoints([...points]);
						onChange(range(slotCount).map(s => roundedSlotValue(s, points)));
						evt.stopPropagation();
					}}

					key={i}
				/>;
			})}

		</svg>
	</>
	;
};

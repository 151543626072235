import {
	Box,
	Button,
	Card,
	CardContent,
	Select,
	CircularProgress,
	InputLabel,
	MenuItem,
	TextField,
	Typography
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { denyLargeGroupBooking } from '../api/largeGroup';
import { FirebaseContext } from '../context/FirebaseContext';
import AdminActionError from './AdminActionError';
import AdminActionSuccess from './AdminActionSuccess';
import Modal from './Modal';
import { AdminActionStep, LargeGroupActionProps } from './ModalConfirmApproval';
import { ModalProps } from './ReservationAccordion';
import { TDR } from 'tdr-common';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const ModalConfirmDenial = ({ open, onClose, reservation }: ModalProps & LargeGroupActionProps) => {
	const { firestore } = useContext(FirebaseContext);
	const [step, setStep] = useState(AdminActionStep.INITIAL);
	const [loading, setLoading] = useState(false);
	const [emailReason, setEmailReason] = useState('');
	const [internalReason, setInternalReason] = useState<TDR.InternalDenialReason>(null);
	const [reasonError, setReasonError] = useState(false);
	const [error, setError] = useState('');

	const handleEmailReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmailReason(event.target.value);
		setReasonError(false);
	};
	const handleInternalReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInternalReason(event.target.value as TDR.InternalDenialReason);
	};

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();

		if (emailReason === '') {
			setReasonError(true);
		}
		else {
			setLoading(true);
			denyLargeGroupBooking(firestore, reservation, emailReason, internalReason).then(r => {
				setLoading(false);

				if (r.success) {
					setStep(AdminActionStep.SUCCESS);
				}
				else {
					setError(r.message);
					setStep(AdminActionStep.ERROR);
				}
			});
		}
	};

	// when modal opens, reset form values and step
	useEffect(() => {
		if (open) {
			setInternalReason(null);
			setEmailReason('');
			setStep(AdminActionStep.INITIAL);
		}
	}, [open]);

	const components = {
		[AdminActionStep.INITIAL]: (
			<>
				<Box style={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingBottom: '20px' }}>
					<Typography variant='h6'>Deny Booking Request</Typography>

					<Typography color='textPrimary' variant='body1'>
						This will remove the reservation from our system and free up the table
						and timeslot. The guest will be notified by email with the reason
						provided below.
					</Typography>
				</Box>

				<Box
					component='form'
					onSubmit={handleSubmit}
					style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
				>
					<InputLabel
						id="currencyLabel"
						style={{ margin: 'auto', paddingRight: '0.5em' }}
					>
                			Denial Reason
					</InputLabel>
					<Select
						value={internalReason}
						labelId="currencyLabel"
						className="select"
						variant="outlined"
						IconComponent={(props) => (
							<Box ml={1}>
								<KeyboardArrowDownIcon
									{...props}
									style={{ color: 'rgba(255, 255, 255, 0.87)' }}
								/>
							</Box>
						)}
						onChange={handleInternalReasonChange}
					>
						{Object.values(TDR.InternalDenialReason).map((internalReason: TDR.InternalDenialReason) => (
							<MenuItem key={internalReason} value={internalReason}>
								{internalReason}
							</MenuItem>
						))}
					</Select>
					<TextField
						multiline
						label='Guest Email Reason'
						placeholder='Provide the reason for denying the request, the guest will see this in the email'
						value={emailReason}
						onChange={handleEmailReasonChange}
						error={reasonError}
						helperText={error ? 'Required' : ''}
					/>

					<Typography color='textPrimary' variant='body1'>
            Message preview:
					</Typography>

					<Card variant='elevation'>
						<CardContent>
							<p>
                Unfortunately, <b>{reservation.restaurantName}</b> cannot
                accommodate your request for <b>{reservation.tableName}</b> at
                this time due to <b>{!!emailReason ? emailReason : '{{REASON}}'}</b> and
                your booking has been cancelled. We&apos;re sorry we
                couldn&apos;t seat you, but hopefully we can in the near future!
							</p>
						</CardContent>
					</Card>

					<Button type='submit' variant='contained' disabled={loading}>
						{loading ? <CircularProgress /> : 'Deny'}
					</Button>

					<Button onClick={onClose} disabled={loading}>Cancel</Button>
				</Box>
			</>
		),
		[AdminActionStep.SUCCESS]: (
			<AdminActionSuccess
				title={<>Successfully denied booking request. The reservation has been cancelled and the timeslot is now available.</>}
				reservation={reservation}
				onClose={onClose}
			/>
		),
		[AdminActionStep.ERROR]: (
			<AdminActionError
				title={<>There was a problem denying this request.</>}
				error={error}
				reservation={reservation}
				onClose={onClose}
			/>
		)
	};

	return (
		<Modal open={open} onClose={onClose}>
			{components[step]}
		</Modal>
	);
};

export default ModalConfirmDenial;
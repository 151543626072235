import React, { useState, useEffect, useContext, createContext } from 'react';
import * as Sentry from '@sentry/react';
import noop from 'lodash/noop';
import { TDR } from 'tdr-common';
import { ProviderProps } from '../common/ProviderProps';
import { FirebaseContext } from './FirebaseContext';

import getPromoCodes from '../api/getPromoCodes';
import upsertPromoCode from '../api/upsertPromoCode';
import deletePromoCode from '../api/deletePromoCode';
import config from '../common/config';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

export namespace RestaurantContext {
	export type Value = {
		restaurants: TDR.Restaurant[],
		setRestaurants: (restaurants: TDR.Restaurant[]) => void,
		allRestaurants: TDR.Restaurant[],
		allPartnerships: TDR.Partnership[],
		selectedRestaurant: TDR.Restaurant,
		selectedRestaurantId: string,
		setSelectedRestaurantId: (id: string) => void,
		promoCodes: TDR.PromoCode[],
		addCode: (id: string, promoCode: Partial<TDR.PromoCode>) => void,
		deleteCode: (id: string) => void,
		loading: boolean,
		timezone: string,
		currency: TDR.Currency,
		stripeRegion: TDR.StripeRegion
	};
}

export const RestaurantContext = createContext<RestaurantContext.Value>({
	allRestaurants: [],
	allPartnerships: [],
	restaurants: [],
	setRestaurants: () => {},
	selectedRestaurantId: null,
	setSelectedRestaurantId: noop,
	promoCodes: [],
	addCode: noop,
	deleteCode: noop,
	selectedRestaurant: null,
	loading: false,
	timezone: null,
	currency: TDR.DEFAULT_CURRENCY,
	stripeRegion: TDR.DEFAULT_STRIPE_REGION
});
RestaurantContext.displayName = 'Restaurant Context';


export const RestaurantProvider = ({ children }: ProviderProps) => {
	const { initialized, firestore } = useContext(FirebaseContext);
	const [selectedRestaurantId, setSelectedRestaurantId] = useState<string>(null);
	const [allRestaurants, setAllRestaurants] = useState<TDR.Restaurant[]>([]);
	const [restaurants, setRestaurants] = useState<TDR.Restaurant[]>([]);
	const [allPartnerships, setPartnerships] = useState<TDR.Partnership[]>([]);
	const [selectedRestaurant, setSelectedRestaurant] = useState<TDR.Restaurant>(null);
	const [loading, setLoading] = useState(false);
	const [promoCodes, setPromoCodes] = useState([]);

	const [stripeRegion, setStripeRegion] = useState<TDR.StripeRegion>(null);
	const [stripeObj, setStripeObj] = useState(null);


	useEffect(() => {
		const stripeConf = config.stripe[stripeRegion];
		if (stripeConf) {
			loadStripe(stripeConf.pub_key, { stripeAccount: stripeConf.id }).then(setStripeObj);
		}
	}, [stripeRegion]);


	useEffect(() => {

		if (!initialized) {
			return;
		}
		firestore.collection('Partnerships').get().then((querySnapshot) => {
			const partnerships = [];
			querySnapshot.forEach((doc) => {
				partnerships.push({ ...doc.data(), partnerId: doc.id });
			});

			setPartnerships(partnerships);
		}).catch((err)=>{
			Sentry.captureException('Error getting Partnerships collection', err);
		});

	}, [initialized]);

	const reloadPromoCodes = () => {
		getPromoCodes(firestore, selectedRestaurantId).then(r => {
			setPromoCodes(r);
		});
	};

	const addCode = (id: string, promoCode: Partial<TDR.PromoCode>) => {
		promoCode.restaurantId = selectedRestaurantId;
		upsertPromoCode(firestore, id, promoCode).then(reloadPromoCodes);
	};
	const deleteCode = (id: string) => {
		deletePromoCode(firestore, id).then(reloadPromoCodes);
	};

	useEffect(() => {

		console.log('RestaurantProvider UseEffect', initialized);
		if (!initialized) {
			return;
		}

		setLoading(true); // this really only trips the once

		/*
			Grab restaurants from firestore and set them in state.
			Originally, we were using the 'onSnapshot' method, but it was causing
			all restaurants to be re-rendered on every change erase a admins ongoing work.
		*/
		firestore.collection('Restaurants').get().then((rDoc) => {
			const allRestaurants = rDoc.docs.map(doc => doc.data() as TDR.Restaurant)
				.sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1);

			setAllRestaurants(allRestaurants);
			setRestaurants(allRestaurants.filter(r => !r.hiddenInAdmin));
			setLoading(false); // let's call one load 'loaded', even though we're watching
		}).catch((err) => {
			Sentry.captureException('Error getting restaurants', err);
			console.error('Error getting restaurants', err);
			setLoading(false);
		});


		return () => {
			setRestaurants([]);
		};

	}, [initialized]);

	// Update selectedRestaurant along with its id:
	useEffect(() => {
		if (restaurants && selectedRestaurantId) {
			setSelectedRestaurant(restaurants.find((restaurant) => restaurant.id === selectedRestaurantId));
			reloadPromoCodes();
		}
	}, [restaurants, selectedRestaurantId]);


	useEffect(() => {
		const region = selectedRestaurant && (selectedRestaurant?.stripeRegion || TDR.DEFAULT_STRIPE_REGION);
		if (region !== stripeRegion) {
			setStripeRegion(region);
		}
	}, [selectedRestaurant]);

	const valueObj: RestaurantContext.Value = {
		allRestaurants,
		allPartnerships,
		restaurants,
		setRestaurants,
		selectedRestaurantId,
		setSelectedRestaurantId,
		addCode,
		deleteCode,
		promoCodes,
		selectedRestaurant,
		loading,
		timezone: selectedRestaurant?.timezone ,
		currency: selectedRestaurant?.currency || TDR.DEFAULT_CURRENCY,
		stripeRegion: stripeRegion
	};

	return (
		<RestaurantContext.Provider value={valueObj}>
			<Elements stripe={stripeObj}>
				{children}
			</Elements>
		</RestaurantContext.Provider>
	);
};
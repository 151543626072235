
const matterport: any = {
	sdkVersion: '3.5',
	sdkKey: '751c857ee3374fad8646b721053e2d4f'
};

const production = {
	deployment: 'production',
	containerized: false,
	autofill_forms: false,
	addOnsUrl: 'https://medusajs-79y.pages.dev/',

	apiHost: 'https://us-central1-threedres.cloudfunctions.net',
	appLink: 'https://app.tablz.com',
	cdnHost: 'https://cdn.tablz.net',
	widgetUrl: 'https://cdn.tablz.net/wc',
	adminApiHost: 'https://api.tablz.com/admin',
	firebase: {
		apiKey: 'AIzaSyCa47ud9AmmPnve3nVGcMdYNNdkSc5rL7g',
		authDomain: 'threedres.firebaseapp.com',
		databaseURL: 'https://threedres.firebaseio.com',
		projectId: 'threedres',
		storageBucket: 'cdn.tablz.net',
		messagingSenderId: '662023710191',
		appId: '1:662023710191:web:0a46492aae6783405a421a',
		measurementId: 'G-89Z81GRHZR',
		longPolling: false,
		emulators: {
			firestore: null,
			storage: null,
			auth: null
		}
	},
	analytics: {
		writeKey: 'WVKVOFz4jyaie8zBYeBoD7sw7Sc946Ql',
		enable: true
	},
	sentry: {
		enable: true,
		dsn: 'https://47585bd6a5724b14b064239f665b7bfc@o301373.ingest.sentry.io/5683408',
		environment: 'production'
	},
	matterport: matterport,
	stripe: {
		can: {
			pub_key: 'pk_live_51IxNyBAGhKmUXc8yhhOYMPDlCJcQUTsokOKdBW4P7hBCposyf9CjPnpKCDgEe82wrpQZhPb9NKjU8VSYZmkVsrat00y4YMrS1Z',
			id: 'acct_1ITqW7JWPa4TTWgM'
		},
		usa: {
			pub_key: 'pk_live_51JtkHqAy16LJuVrBj8PkYxjJp4gvplSNTuFnFanzEZkbLIlay1amSRjj6Qu1mPr7hzXPsYDIBLJITYNFwsVFum0o00Nqg9G321',
			id: 'acct_1Ji6OZD3giMXQXty'
		}
	}
};

const staging = {
	deployment: 'staging',
	containerized: false,
	autofill_forms: true,

	addOnsUrl: 'https://medusajs-79y.pages.dev/',
	// ??? firestore says that the sparkplan has cloud functions,
	// but the web interface says to upgrade the plan?
	apiHost: 'https://us-central1-threedres-staging.cloudfunctions.net',
	appLink: 'https://app.staging.tablz.com',
	cdnHost: 'https://cdn.tablz.net/',
	widgetUrl: 'https://cdn.tablz.net/staging/wc',
	adminApiHost: 'https://api.staging.tablz.com/admin',
	firebase: {
		apiKey: 'AIzaSyBNopPb2Pl0yhB264xz7br-cj9c01DEG-4',
		authDomain: 'threedres-staging.firebaseapp.com',
		projectId: 'threedres-staging',
		storageBucket: 'cdn-staging.tablz.net',
		messagingSenderId: '436872751445',
		appId: '1:436872751445:web:cee7e083a24d81881127dc',
		measurementId: 'G-D7JN6JKC1B',
		longPolling: false,
		emulators: {
			firestore: null,
			storage: null,
			auth: null
		}
	},
	analytics: {
		writeKey: 'k6ZVL8sAxxpkUxnaPSFU7ZXfzZcIybB3',
		enable: true
	},
	sentry: {
		enable: false,
		dsn: '',
		environment: 'staging'
	},
	matterport: matterport,
	stripe: {
		can: {
			pub_key: 'pk_test_51IxNyBAGhKmUXc8yzEHo9jCOCEddt39dsjl9W9NJj3r79CXftv3uQppfa6cdFf9bVTZ2PcHlN7aBG73pjYowWQ7q00P6hxUkFL',
			id: 'acct_1IxNyBAGhKmUXc8y'
		},
		usa: {
			pub_key: 'pk_test_51JtkHqAy16LJuVrBr8GXjzIAlcT1ntnf9SCYRAbsDnaaqZPfer9WxW7l4z6d6vaLRuCZVYoqfFWxcs4ccvSyJ4Ay00B963vcWv',
			id: 'acct_1Ji6OZD3giMXQXty'
		}
	}
};

const test = {
	deployment: 'test',
	containerized: false,
	autofill_forms: true,
	apiHost: 'https://us-central1-threedres-test.cloudfunctions.net',
	appLink: 'https://threedres-test.web.app',
	cdnHost: 'https://cdn.tablz.net/',
	addOnsUrl: '',
	adminApiHost: '',
	widgetUrl: '',
	firebase: {
		apiKey: 'AIzaSyDo7xIbXK05zS3cX0xGfgZoEg4zP7BScq8',
		authDomain: 'threedres-test.firebaseapp.com',
		projectId: 'threedres-test',
		storageBucket: 'threedres-test.appspot.com',
		messagingSenderId: '809372833554',
		appId: '1:809372833554:web:ceda97b8cbdaa5a62c45cb',
		longPolling: false
	  },
	sentry: {
		enable: false,
		dsn: '',
		environment: 'test'
	},
	analytics: {
		writeKey: '',
		enable: false
	},
	matterport: {
		...matterport,
		assetOrigin: 'https://threedres-test.web.app'
	},
	stripe: {
		can: {
			pub_key: 'pk_test_51IxNyBAGhKmUXc8yzEHo9jCOCEddt39dsjl9W9NJj3r79CXftv3uQppfa6cdFf9bVTZ2PcHlN7aBG73pjYowWQ7q00P6hxUkFL'
		},
		usa: {
			pub_key: 'pk_test_51JtkHqAy16LJuVrBr8GXjzIAlcT1ntnf9SCYRAbsDnaaqZPfer9WxW7l4z6d6vaLRuCZVYoqfFWxcs4ccvSyJ4Ay00B963vcWv'
		}
	}
};

const emulators = {
	deployment: 'development',
	containerized: false,
	autofill_forms: true,

	apiHost: 'http://localhost:5001/local/us-central1',
	appLink: 'http://localhost:8080',
	cdnHost: 'https://cdn.tablz.net/',
	addOnsUrl: '',
	adminApiHost: '',
	widgetUrl: '',
	firebase: {
		appId: 'emulators-app-id',
		projectId: 'local',
		// firebase.auth() complains unless apiKey is defined and not an empty string
		// but there don't seem to be any real requirements beyond that, if you're using emulators
		apiKey: 'very-obviously-not-a-real-api-key',
		longPolling: false,
		storageBucket: '3dr',
		emulators: {
			firestore: { host: 'localhost', port: 8001 },
			storage: { host: 'localhost', port: 9199 },
			auth: { host: 'localhost', port: 9099 }
		}
	},
	analytics: {
		writeKey: 'k6ZVL8sAxxpkUxnaPSFU7ZXfzZcIybB3',
		enable: true
	},
	sentry: {
		enable: false,
		dsn: '',
		environment: 'emulator'
	},
	matterport: matterport.sdkKey,
	stripe: {
		can: {
			pub_key: 'pk_test_51IxNyBAGhKmUXc8yzEHo9jCOCEddt39dsjl9W9NJj3r79CXftv3uQppfa6cdFf9bVTZ2PcHlN7aBG73pjYowWQ7q00P6hxUkFL',
			id: 'acct_1ITqW7JWPa4TTWgM'
		},
		usa: {
			pub_key: 'pk_test_51JtkHqAy16LJuVrBr8GXjzIAlcT1ntnf9SCYRAbsDnaaqZPfer9WxW7l4z6d6vaLRuCZVYoqfFWxcs4ccvSyJ4Ay00B963vcWv',
			id: 'acct_1JO4OqFBBScH3mfM'
		}
	}
};

const docker = {
	deployment: 'development',
	containerized: true,
	autofill_forms: true,

	apiHost: 'http://firebase:5001/test-emulators/us-central1',
	appLink: 'http://app:4001',
	cdnHost: 'https://cdn.tablz.net/',
	addOnsUrl: '',
	adminApiHost: '',
	widgetUrl: '',
	firebase: {
		appId: 'emulators-app-id',
		projectId: 'docker-emulators',
		// firebase.auth() complains unless apiKey is defined and not an empty string
		// but there don't seem to be any real requirements beyond that, if you're using emulators
		apiKey: 'very-obviously-not-a-real-api-key',
		longPolling: true,
		storageBucket: '3dr',
		emulators: {
			firestore: { host: 'firebase', port: 8001 },
			storage: { host: 'firebase', port: 9199 },
			auth: { host: 'firebase', port: 9099 }
		}
	},
	sentry: {
		enable: false,
		dsn: '',
		environment: 'docker'
	},
	analytics: {
		writeKey: undefined,
		enable: false
	},
	matterport: matterport,
	stripe: {
		can: {
			pub_key: 'pk_test_51ITqW7JWPa4TTWgMLJWLsvKefcrVjks6d1yE6p9FvlekmoleXXe5zUkkYCjNL0ciZs1Du7gHI3BejRqgMHrHWQkT00Qvm0ZA5c',
			id: 'acct_1ITqW7JWPa4TTWgM'
		},
		usa: {
			pub_key: 'pk_test_51JO4OqFBBScH3mfM5pAIAXYVu9HltJfyl7UiZHcX5khD9SjqkTSRKwTIuJh21O3GAPf7hmeyNTItfB4qg2pF7n2f00J7Wf07H4',
			id: 'acct_1JO4OqFBBScH3mfM'
		}
	}
};

const dockerExternal = {
	deployment: 'development',
	containerized: true,
	autofill_forms: false,

	apiHost: 'http://localhost:5001/docker-emulators/us-central1',
	appLink: 'http://localhost:4001',
	cdnHost: 'https://cdn.tablz.net/',
	addOnsUrl: '',
	widgetUrl: '',
	adminApiHost: '',
	firebase: {
		appId: 'emulators-app-id',
		projectId: 'docker-emulators',
		// firebase.auth() complains unless apiKey is defined and not an empty string
		// but there don't seem to be any real requirements beyond that, if you're using emulators
		apiKey: 'very-obviously-not-a-real-api-key',
		longPolling: true,
		storageBucket: '3dr',
		emulators: {
			firestore: { host: 'localhost', port: 8001 },
			storage: { host: 'localhost', port: 9199 },
			auth: { host: 'localhost', port: 9099 }
		}
	},
	analytics: {
		writeKey: undefined,
		enable: false
	},
	sentry: {
		enable: false,
		dsn: '',
		environment: 'docker'
	},
	matterport: matterport,
	stripe: {
		can: {
			pub_key: 'pk_test_51ITqW7JWPa4TTWgMLJWLsvKefcrVjks6d1yE6p9FvlekmoleXXe5zUkkYCjNL0ciZs1Du7gHI3BejRqgMHrHWQkT00Qvm0ZA5c',
			id: 'acct_1ITqW7JWPa4TTWgM'
		},
		usa: {
			pub_key: 'pk_test_51JO4OqFBBScH3mfM5pAIAXYVu9HltJfyl7UiZHcX5khD9SjqkTSRKwTIuJh21O3GAPf7hmeyNTItfB4qg2pF7n2f00J7Wf07H4',
			id: 'acct_1JO4OqFBBScH3mfM'
		}
	}
};


// in order for this to work correctly, the env var has to be set during build
// webpack runs a preprocessor, which will replace `process.env` with a map (don't use `process?.env`, which doesn't work)
export const PRODUCTION = process.env.TARGET_FIREBASE === 'production';
export const STAGING = process.env.TARGET_FIREBASE === 'staging';
export const TEST = process.env.TARGET_FIREBASE === 'test';
export const DOCKER = process.env.TARGET_FIREBASE === 'docker';
export const DOCKER_EXTERNAL = process.env.TARGET_FIREBASE === 'docker-external';

const exportedConfig =
	PRODUCTION ? production :
		STAGING ? staging :
			TEST ? test :
				DOCKER ? docker :
					DOCKER_EXTERNAL ? dockerExternal :
						emulators;

console.log(exportedConfig);

export default exportedConfig;
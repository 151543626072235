import React from 'react';
import {
	Box,
	Switch
} from '@material-ui/core';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

namespace WeekdaysPicker {
	export type Props = {
		label: string,
		weekdays: string[],
		onChange: (weekdays: string[]) => void
	};
}

export const allWeekdays = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday'
];

const WeekdaysPicker = ({ label, weekdays, onChange }: WeekdaysPicker.Props) => {

	return (
		<Box display='flex' flexDirection='column' width='100%' position='relative'>
			<label style={{
				transform: 'translate(0px, -12px) scale(0.75)',
				top: 0,
				left: 0,
				position: 'absolute',
				color: '#7FB5A8'
			}}>{label}</label>
			<Box display='flex' flexDirection='column' width='100%' bgcolor='#333' p={2}>
				{allWeekdays.map((weekday) => (
					<Box display='flex' flexDirection='row' alignItems='center' width='100%' key={weekday} py={1}>
						<Box mr='auto'>{weekday}</Box>
						<Box>
							<Switch
								checked={weekdays.includes(weekday)}
								onChange={() => {
									if (weekdays.includes(weekday)) {
										onChange(weekdays.filter(closedWeekday => closedWeekday !== weekday));
									}
									else {
										onChange([
											...weekdays,
											weekday
										]);
									}
								}}
								color='primary'
							/>
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default WeekdaysPicker;
/* eslint-disable max-lines-per-function */
import {
	Box,
	Button, Select, Switch, Container, FormControlLabel, IconButton, TextField,
	Typography,
	MenuItem,
	Checkbox,
	CircularProgress, InputLabel
} from '@material-ui/core';
import {
	AddCircle as AddIcon, Close,
	DeleteForever as DeleteIcon, FileCopy, KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { captureException } from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import without from 'lodash/without';
import isNumber from 'lodash/isNumber';
import MpSdk, { Mattertag, tagPos, Vector3 } from 'matterport';
import React, { useContext, useEffect, useState } from 'react';
import slugify from 'slugify';
import { SECONDS_IN_AN_HOUR, TDR } from 'tdr-common';
import { v4 as uuid } from 'uuid';
import { FirebaseContext } from '../context/FirebaseContext';
import { MatterportContext } from '../context/MatterportContext';
import { ModalContext } from '../context/ModalContext';
import { RestaurantContext } from '../context/RestaurantContext';
import { TableContext } from '../context/TableContext';
import DebugLogButton from './DebugLogButton';
import ImageManager from './ImageManager';
import Matterport from './Matterport';
import { Confirm } from './Modals';
import RestaurantSelector from './RestaurantSelector';
import { getRestaurantFeatureFlags } from '../api';
import LargeGroupSettings from './LargeGroupSettings';
import getLargeGroupSettings from '../api/getLargeGroupSettings';
import updateLargeGroupSettings from '../api/updateLargeGroupSettings';
import updateLargeGroupCutoffs from '../api/updateLargeGroupCutoffs';
import updateLargeGroupDeposit from '../api/updateLargeGroupDeposit';
import { PricingPolicyContext } from '../context/PricingPolicyContext';

const AdminTables = () => {

	const { openModal } = useContext(ModalContext);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { selectedRestaurant, selectedRestaurantId } = useContext(RestaurantContext);
	const { getTablesByRestaurant } = useContext(TableContext);

	const { firestore, token } = useContext(FirebaseContext);
	const { restaurantPolicies } = useContext(PricingPolicyContext);
	const [activeTable, setActiveTable] = useState<TDR.Table>(null);
	const [tables, setTables] = useState<TDR.Table[] | null>(null);
	const [unsaved, setUnsaved] = useState<boolean>(false);
	const [allTableTags, setAllTableTags] = useState<string[]>([]);
	const [isInternalNameCustom, setIsInternalNameCustom] = useState<boolean>(!!(activeTable && activeTable.name !== activeTable.internalName));
	const debouncedSetActiveTable = debounce(setActiveTable, 250);

	const { origTagData, modelId, setModelId, sdk, tagData } = useContext(MatterportContext);
	const [tagsWithoutTables, setTagsWithoutTables] = useState<Mattertag.MattertagData[]>([]);

	const tablePos = activeTable?.pos;
	const [fuckingRebuildMyEditor, setFuckingRebuildMyEditor] = useState(Math.random());
	const tableTag = origTagData.find(tag => tag.label === activeTable?.name);
	const tableTagPos = tableTag && {
		x: tableTag.anchorPosition.x + tableTag.stemVector.x,
		y: tableTag.anchorPosition.y + tableTag.stemVector.y,
		z: tableTag.anchorPosition.z + tableTag.stemVector.z
	};

	useEffect(() => {
		if (selectedRestaurant) {
			getTablesByRestaurant(selectedRestaurant.id).then(tables => {
				setTables(tables);
			});
		}
		else {
			setTables(null);
		}
	}, [selectedRestaurant]);


	useEffect(() => {
		if (tables !== null) {
			const tableIndex = tables.findIndex(table => table.id === activeTable?.id);
			setUnsaved(activeTable && !isEqual(tables[tableIndex], activeTable));
			setAllTableTags(uniq(flatMap(tables, table => table.tags || [])));
		}
		else {
			setAllTableTags([]);
		}
	}, [tables]);

	const getTable = (id: string): TDR.Table => {
		return cloneDeep(tables?.find(table => table.id === id) ?? null);
	};

	function setDisplayOrder (orderedTables: TDR.Table[]) {
		Promise.all(orderedTables.map(async (table, index) => {
			if (table.displayOrder !== index) {
				const legacyDoc = firestore.collection('Tables').doc(table.slug);
				const doc = firestore.collection('Tables').doc(table.id);
				legacyDoc.get().then(legacy => legacy.exists ? legacyDoc : doc)
					.then(doc => doc.update({ displayOrder: index }))
					.then(() => {
						if (table.id === activeTable?.id) {
							setActiveTable({ ...activeTable, displayOrder: index });
						}
					});
			}
		})).then(() => setTables(orderedTables));
	}

	function confirmSetDisplayOrder (orderedTables: TDR.Table[]) {
		if (unsaved) {
			openModal(<Confirm
				title='Unsaved changes will be lost'
				text={['This is a sloppy feature on a soon-to-be-replaced product.',
				      `The changes you've made to ${activeTable?.name} will be lost unless you save them before reordering the tables.`,
					  'Click \'confirm\' to reorder anyways, or close this dialog to go back.'].join('\n')}
				onConfirm={() => {
					setActiveTable(null);
					setDisplayOrder(orderedTables);
				}}
			/>);
		}
		else {
			setDisplayOrder(orderedTables);
		}
	}

	useEffect(() => {
		if (selectedRestaurant) {
			setModelId(selectedRestaurant.model);
		}
	}, [selectedRestaurant]);

	useEffect(() => {
		if (origTagData && tables) {
			setTagsWithoutTables(origTagData.filter(tag => !(tables || []).some(table => table.name === tag.label)));
		}
	}, [origTagData, modelId, tables]);

	useEffect(() => {
		if(sdk && activeTable) {
			setOneTag(sdk, activeTable.pos);
		}
	}, [sdk, activeTable, tagData, fuckingRebuildMyEditor]);

	useEffect(() => {
		setIsInternalNameCustom(!!(activeTable && activeTable.name !== activeTable.internalName));
	}, [activeTable]);

	async function saveTable() {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { media, ...updatedTable } = activeTable;
		// this can be removed after legacy data is migrated
		const legacy = (await firestore.collection('Tables').doc(updatedTable.slug).get()).exists;
		firestore.collection('Tables').doc(legacy ? updatedTable.slug : updatedTable.id).update(updatedTable);
	}

	async function deleteTable () {

		openModal(<Confirm
			title='Delete Table'
			text="This can be undone, but it's a pain in the ass. If you're not sure, consider disabling instead."
			onConfirm={async () => {
				// this can be removed after legacy data is migrated
				if (activeTable) {
					const legacy = (await firestore.collection('Tables').doc(activeTable.slug).get()).exists;
					firestore.collection('Tables').doc(legacy ? activeTable.slug : activeTable.id).update({ deleted: true });
					setActiveTable(null);
				}
			}}
		/>);
	}


	const [featureFlags, setFeatureFlags ] = useState({
		flipLandingModal: false,
		patio: false,
		educationalComponents: false,
		newCheckout: false
	});


	useEffect(() => {
		selectedRestaurantId && getRestaurantFeatureFlags(firestore, selectedRestaurantId).then(
			(ffs) => setFeatureFlags({
				flipLandingModal: ffs.flipLandingModal || false,
				patio: ffs.patio || false,
				educationalComponents: ffs.educationalComponents || false,
				newCheckout: ffs.newCheckout || false
			})
		);
	}, [selectedRestaurantId]);

	const [largeGroupSettings, setLargeGroupSettings] = useState<TDR.LargeGroupSettings>(null);
	useEffect(() => {
		const fetchLargeGroupSettings = async () => {
			try {
				const settings = await getLargeGroupSettings(firestore, activeTable.id);
				setLargeGroupSettings(settings);
			}
			catch (error) {
				console.error('Error fetching large group settings:', error);
			}
		};

		if (!activeTable) {
			return;
		}

		fetchLargeGroupSettings();
	}, [activeTable]);

	const updateTable = (activeTable: TDR.Table) => setTables(tables.map(t => {
		if (t.id === activeTable.id) {
			return activeTable;
		}
		else {
			return t;
		}
	}));

	const handleSubmit = async () => {
		setIsSubmitting(true);
		updateTable(activeTable);
		try {
			await saveTable();

			setTables(tables.map(t => {
				if (t.id === activeTable.id) {
					return activeTable;
				}
				else {
					return t;
				}
			}));

			if (activeTable.supportLargeGroup) {
				await updateLargeGroupSettings(firestore, largeGroupSettings);
				await updateLargeGroupDeposit(
					token,
					restaurantPolicies,
					activeTable.id,
					selectedRestaurantId,
					largeGroupSettings.bookingDeposit
				);
				await updateLargeGroupCutoffs(
					firestore,
					selectedRestaurantId,
					activeTable.id,
					{
						create: {
							margin: largeGroupSettings.creationWindow * SECONDS_IN_AN_HOUR
						},
						update: {
							margin: largeGroupSettings.modificationWindow * SECONDS_IN_AN_HOUR
						},
						cancel: {
							margin: largeGroupSettings.cancellationWindow * SECONDS_IN_AN_HOUR
						},
						condition: {
							hasItem: {
								id: activeTable.id,
								type: TDR.Reservation.ItemType.Table
							}
						}
					});
			}
		}
		catch (error) {
			console.error({ error });
		}
		finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Container maxWidth='xl' style={{
			height: '100%'
		}}>
			<Box display='flex' flexDirection='row' width='100%' marginTop={'44px'}>
				<Box display='flex' flexGrow={1}>
					<h2>Tables</h2>
				</Box>

				<RestaurantSelector />
			</Box>
			<Box display='flex' flexDirection='row' width='100%' height='100%'>
				<Box display='flex' flexDirection='column' mr={2}>
					{tables !== null && tables.map((table, index) => (
						<Box
							display='flex'
							flexDirection='row'
							key={table.id}
							px={1}
							py={2}
							bgcolor={activeTable?.id === table.id ? '#333' : '#121212'}
							style={{ opacity: table.disabled ? 0.25 : 1 }}
						>
							<Box
								flexGrow={2}
								display='flex'
								alignItems='center'
								onClick={() => {
									setActiveTable(null);
									setTimeout(() => setActiveTable(getTable(table.id)));
								}}
								style={{ cursor: 'pointer' }}
							>
								{`${table?.internalName} (${table?.name})`}
							</Box>

							<Box display='flex' flexDirection='column'>
								{index === 0 ? null :
									<button style={{ backgroundColor:'transparent', border: 'none', cursor:'pointer' }} onClick={() => {
										const tablesCopy = cloneDeep(tables);
										tablesCopy.splice(index, 1);
										tablesCopy.splice(index - 1, 0, table);
										confirmSetDisplayOrder(tablesCopy);
									}}>
										<KeyboardArrowUpIcon />
									</button>}
								{tables === null || index === tables.length - 1 ? null :
									<button style={{ backgroundColor:'transparent', border: 'none', cursor: 'pointer' }} onClick={() => {
										const tablesCopy = cloneDeep(tables);
										tablesCopy.splice(index, 1);
										tablesCopy.splice(index + 1, 0, table);
										confirmSetDisplayOrder(tablesCopy);
									}}>
										<KeyboardArrowDownIcon />
									</button>}
							</Box>
						</Box>
					))}



					<h2>Tags</h2>

					{tagsWithoutTables.map(tag => (
						<Box
							display='flex'
							flexDirection='row'
							alignItems='center'
							key={tag.label}>
							<Typography>{tag.label}</Typography>
							<IconButton onClick={() => {
								const id = uuid();
								firestore.collection('Tables').doc(id).set({
									id,
									slug: slugify(tag.label, { lower: true, strict: true }),
									name: tag.label,
									internalName: tag.label,
									description: '',
									defaultTimeSlots: {},
									minGuests: 2,
									maxGuests: 4,
									restaurantId: selectedRestaurantId,
									pos: tagPos(tag),
									supportLargeGroup: false
								} as TDR.Table);
							}}><AddIcon /></IconButton>
						</Box>
					))}

					{modelId &&isEmpty(origTagData) && <Matterport style={{ height: 150, width: 150, opacity: 0 }} />}
				</Box>

				{!isNull(activeTable) &&
					<Box flexGrow={1} height='100%'>

						<Box display='flex' flexDirection='row'>
							<TextField
								label='Table Name'
								placeholder='Table Name'
								variant='outlined'
								InputLabelProps={{ style: { color: '#7FB5A8' } }}
								defaultValue={activeTable?.name ?? ''}
								onChange={(evt) => debouncedSetActiveTable({
									...activeTable,
									name: evt.target.value,
									internalName: isInternalNameCustom? activeTable.internalName: evt.target.value
								})}
							/>

							<TextField
								label='Table Id (click to copy)'
								variant='outlined'
								style={{ marginLeft: '1em' }}
								value={activeTable?.id}
								onClick={() => {
									activeTable?.id && navigator.clipboard.writeText(activeTable.id);
								}}
								onFocus={() => (document.activeElement as HTMLElement).blur() }
							/>

							<DebugLogButton log={activeTable} style={{ opacity: 0.5 }} />
						</Box>
						<FormControlLabel
							control={<Checkbox checked={isInternalNameCustom} onChange={() => {
								setIsInternalNameCustom(!isInternalNameCustom);
							}} name="custom-internal-name" />}
							label="Custom Internal Table Name"
						/>
						<TextField
							label='Internal Table Name'
							placeholder='Internal Table Name'
							variant='outlined'
							disabled={!isInternalNameCustom}
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							defaultValue={activeTable?.internalName ?? ''}
							onChange={(evt) => debouncedSetActiveTable({
								...activeTable,
								internalName: evt.target.value
							})}
						/>
						<TextField
							label='Table Type (e.g. table, cabana)'
							placeholder='table type'
							variant='outlined'
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							defaultValue={activeTable?.label ?? 'table'}
							onChange={(evt) => debouncedSetActiveTable({
								...activeTable,
								label: evt.target.value
							})}
						/>

						<Box>
							<FormControlLabel
								label='Disabled'
								control={<Switch
									color='primary'
									checked={activeTable.disabled}
									onChange={(evt) => {
										setActiveTable({
											...activeTable,
											disabled: evt.target.checked
										});
									}}
								/>}
							/>
							<FormControlLabel
								label='Delete'
								control={<IconButton onClick={deleteTable}> <DeleteIcon /> </IconButton>}
							/>
						</Box>

						<TextField
							label='Table Description'
							placeholder='Table Description'
							variant='outlined'
							multiline={true}
							InputLabelProps={{ style: { color: '#7FB5A8' } }}
							value={activeTable?.description ?? ''}
							onChange={(evt) => setActiveTable({
								...activeTable,
								description: evt.target.value
							})}
						/>

						<TagsEditor value={activeTable.tags} knownTags={allTableTags} onChange={(tags) => setActiveTable({ ...activeTable, tags })} />

						<Box display='flex' flexDirection='row'>
							<TextField
								label='Max Guests'
								placeholder='Max Guests'
								variant='outlined'
								InputLabelProps={{ style: { color: '#7FB5A8' } }}
								value={activeTable?.maxGuests ?? 4}
								onChange={(evt) => setActiveTable({
									...activeTable,
									maxGuests: parseInt(evt.target.value)
								})}
							/>
							<TextField
								label='Min Guests'
								placeholder='Min Guests'
								variant='outlined'
								style={{ marginLeft: '1em' }}
								InputLabelProps={{ style: { color: '#7FB5A8' } }}
								value={activeTable?.minGuests ?? 2}
								onChange={(evt) => setActiveTable({
									...activeTable,
									minGuests: parseInt(evt.target.value)
								})}
							/>
						</Box>
						<h2>Table Tags</h2>
						<Box display='flex' flexDirection='row'>
							{Object.values(TDR.Attributes.AttributeType).map((val) => {
								return <Box key={`${val}-box`} display='flex' flexDirection='column' style={{ width: '200px', marginRight: '28px' }}>
									<InputLabel key={`${val}-label`} style={{ textTransform: 'capitalize' }}>
										{val}
									</InputLabel>
									<Select
										value={activeTable?.attributes?.[val]?.[0] || ''}
										labelId={`${val}-label`}
										className="select"
										variant="outlined"
										key={`${val}-select`}
										IconComponent={(props) => (
											<Box ml={1}>
												<KeyboardArrowDownIcon
													{...props}
													style={{ color: 'rgba(255, 255, 255, 0.87)' }}
												/>
											</Box>
										)}
										onChange={(evt) => {
											setActiveTable({
												...activeTable,
												attributes: { ...activeTable.attributes, [val]: [evt.target.value] }
											});
										}}
									>
										<MenuItem value=''><em>None</em></MenuItem>
										{Object.values(TDR.Attributes.OptionConfig[val]).map((attributeValue: TDR.Attributes.OptionValue) => (
											<MenuItem key={attributeValue} value={attributeValue}>
												{attributeValue}
											</MenuItem>
										))}
									</Select>
								</Box>;
							})
							}
						</Box>


						<Box display='flex' flexDirection='row'>
							<TextField
								label={<span>Cluster Size <span className='xs-text'>(reservations per time-slot)</span></span>}
								variant='outlined'
								style={{ maxWidth: '15em' }}
								InputLabelProps={{ style: { color: '#7FB5A8' } }}
								value={activeTable?.clusterSize}
								onChange={(evt) => setActiveTable({
									...activeTable,
									clusterSize: parseInt(evt.target.value) || null
								})}
							/>
							<Box style={{ display: 'flex', alignItems: 'center', marginLeft: '1em' }}>
								{(activeTable?.clusterSize && activeTable?.clusterSize > 1)
									? <Typography>Cluster <span style={{ fontWeight: 'bold' }}>ENABLED</span></Typography>
									: <Typography>not a cluster</Typography>
								}
							</Box>
						</Box>
						{ featureFlags?.patio && <Box>
							<FormControlLabel
								label='Is Patio (enable max 1 table per restaurant)'
								control={<Switch
									color='primary'
									checked={activeTable?.isPatio}
									onChange={(evt) => {
										setActiveTable({
											...activeTable,
											isPatio: evt.target.checked
										});
									}}
								/>}
							/>
						</Box> }
						<Box display='flex' flexDirection='row' marginBottom={'40px'}>
							Available Partnerships:
							<Select value={activeTable.partnershipName} style={{ width: '150px', maxWidth: '300px', marginLeft: '20px' }} onChange={(e)=> setActiveTable({ ...activeTable, partnershipName: e.target.value as string })}>
								<MenuItem value={null}>None</MenuItem>
								{selectedRestaurant?.partnerships?.map((partner, index)=>(
									<MenuItem value={partner.name} key={index}>{partner.name}</MenuItem>
								))}
							</Select>
							 {activeTable?.partnershipName &&
							 	<Select value={activeTable.partnershipDiscountType} style={{ width: '120px', marginLeft: '40px' }} onChange={(e)=> setActiveTable({ ...activeTable, partnershipDiscountType: e.target.value as 'flat' | 'percent' })}>
							 		<MenuItem value={'flat'}>Flat</MenuItem>
							 		<MenuItem value={'percent'}>Percent</MenuItem>
							 	</Select>
							 }
							{ activeTable?.partnershipName &&
							 <TextField
							  	label={`Discount ${activeTable?.partnershipDiscountType === 'flat' ? '$' : '%'}`}
							  	style={{ width: '150px', maxWidth: '300px', marginLeft: '20px' }}
							  	variant='outlined'
							 	InputLabelProps={{ style: { color: '#7FB5A8' } }}
							  	value={activeTable.partnershipDiscountAmount}
						        onChange={(e) => setActiveTable({ ...activeTable, partnershipDiscountAmount: e.target.value as any as number })}
							    error={(!activeTable.partnershipDiscountAmount) || (activeTable.partnershipDiscountType === 'percent' && activeTable.partnershipDiscountAmount > 100) }
							  />
							}
						</Box>

						<Box display='flex' flexDirection='row' alignItems='center'>
							<FormControlLabel
								value="start"
								style={{ marginRight: '1em', whiteSpace: 'nowrap' }}
								control={<Switch
									checked={activeTable.supportLargeGroup}
									onChange={(evt) =>
										setActiveTable({
											...activeTable,
											supportLargeGroup: evt.target.checked
										})
									}
									color='primary'
								/>}
								label='Large Group Booking'
								labelPlacement="start"
							/> </Box>

						{activeTable?.supportLargeGroup && largeGroupSettings
							&& <LargeGroupSettings largeGroupSettings={largeGroupSettings} setLargeGroupSettings={setLargeGroupSettings} />}

						<Box style={{ fontFamily: 'monospace', display: 'flex', flexDirection: 'row' }}>
							<Box>
								<Box style={{ display: 'flex', alignItems: 'center' }}>
									Table&nbsp;
									<Pos pos={tablePos} onChange={pos => setActiveTable({ ...activeTable, pos })} sdk={sdk} key={fuckingRebuildMyEditor} />
								</Box>
								<Box style={{ display: 'flex', alignItems: 'center', opacity: isEqual(tableTagPos, tablePos) ? 0.5 : 1 }}>
									MpTag&nbsp;
									<Pos pos={tableTagPos} disabled />
									<IconButton
										disabled={!tableTagPos || isEqual(tableTagPos, tablePos)}
										onClick={() => {
											setActiveTable({ ...activeTable, pos: tableTagPos });
											clearTags(sdk).then(() => {
												setFuckingRebuildMyEditor(Math.random()); // what was the correct way to do this?
											});
										}}>
										<FileCopy />
									</IconButton>
								</Box>
							</Box>
							<Box>
								<Matterport style={{ filter: `brightness(${selectedRestaurant?.brightness ?? 1}) contrast(${selectedRestaurant?.contrast ?? 1})` }} />
							</Box>
						</Box>

						<Button
							variant='contained'
							color='primary'
							className='next'
							disabled={isNull(activeTable) || isSubmitting}
							onClick={handleSubmit}
						>
							{isSubmitting ? <CircularProgress /> : 'Save'}
						</Button>

						<ImageManager
							table={activeTable}
							updateTable={updateTable}
						/>
					</Box>
				}

			</Box>
		</Container>
	);
};


function TagsEditor ({ value, knownTags, onChange }: {value?: string[], knownTags?: string[], onChange?: (tags:string[]) => void }) {
	const [text, setText] = useState<string>('');
	const [tags, setTags] = useState<string[]>(value || []);

	function addTag(instantText?:string) {
		setText('');
		const updated = uniq([...tags, instantText || text]).sort();
		if (!isEqual(updated, tags)) {
			setTags(updated);
			onChange(updated);
		}
	}

	function removeTag(tag:string) {
		const updated = without(tags, tag);
		if (!isEqual(updated, tags)) {
			setTags(updated);
			onChange(updated);
		}
	}

	return <Box display='flex' flexDirection='row' alignItems='center'>

		<Autocomplete
			freeSolo={true}
			options={knownTags}
			value={text}
			onInputChange={(e, value) => setText(value || '')}
			onChange={(e, value) => addTag(value)}
			onKeyUp={(event) => {
				event.key === 'Enter' && addTag();
			}}
			renderInput={(params) => <TextField {...params}
				style={{ minWidth: '15em' }}
				variant="outlined"
				label='New Tag (press Enter to add)' />}
		/>

		{tags.map(tag => <Box key={tag}
			className={'pill tag-pill'}
			display='flex'
			flexDirection='row'
			justifyContent='space-between'
			alignItems='center'
			mx={0.5}
			pl={2} pr={0}
		>
			<Typography>{tag}</Typography>
			<IconButton
				edge='end'
				onClick={() => {
					removeTag(tag);
				}}>
				<Close />
			</IconButton>
		</Box>)}
	</Box>;
}



function Pos({ pos, onChange, disabled, sdk }: { pos: Vector3, disabled?: boolean, onChange?: (p: Vector3) => void, sdk?: MpSdk}) {
	if (!pos) {
		return <span>No Position</span>;
	}

	const style = { width: '4em' };

	const [x, setx] = useState(pos.x.toFixed(3));
	const [y, sety] = useState(pos.y.toFixed(3));
	const [z, setz] = useState(pos.z.toFixed(3));

	useEffect(() => {
		if (sdk) {
			const [xn, yn, zn] = [x, y, z].map(parseFloat);
			if (isNumber(xn) && isNumber(yn) && isNumber(zn)) {
				setOneTag(sdk, { x: xn, y:yn, z: zn });
			}
		}
	}, [x, y, z]);

	function update () {
		const np = { x: parseFloat(x), y: parseFloat(y), z: parseFloat(z) };
		if (isNumber(np.x) && isNumber(np.y) && isNumber(np.z) && !isEqual(np, pos)) {
			onChange(np);
		}
	}

	return <Box style={{ display: 'flex', alignItems: 'center' }}>
		x:&nbsp;
		<TextField
			disabled={disabled}
			style={style}
			value={x}
			onChange={(evt) => setx(evt.target.value)}
			onBlur={update}
		/>&nbsp;

		y:&nbsp;
		<TextField
			disabled={disabled}
			style={style}
			value={y}
			onChange={(evt) => sety(evt.target.value)}
			onBlur={update}
		/>&nbsp;

		z:&nbsp;
		<TextField
			disabled={disabled}
			style={style}
			value={z}
			onChange={(evt) => setz(evt.target.value)}
			onBlur={update}
		/>&nbsp;
	</Box>;
}

function clearTags(sdk: MpSdk) {
	return sdk.Mattertag.getData()
		.then(
			origTags => {
				sdk.Mattertag.remove(origTags.map(t => t.sid));
			}).catch((e)=>{
			captureException(e);
		});
}

function setOneTag (sdk:MpSdk, tag: Vector3) {

	return clearTags(sdk)
		.then(() => sdk.Mattertag.add({
			anchorPosition: tag,
			stemVector: { x: 0, y: 0, z: 0 }
		}))
		.then(([sid]) => {
			sdk.Mattertag.preventAction(sid, { opening: true });
			sdk.Mattertag.navigateToTag(sid, sdk.Mattertag.Transition.FLY);
		});
}

export default AdminTables;
import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	TextField
} from '@material-ui/core';
import React from 'react';
import { TDR, WIDGET_DEFAULT_DESC, WIDGET_DEFAULT_TITLE } from 'tdr-common';
import { HexColorField } from '../hex-color-field/HexColorField';
import { RgbaColorField } from '../rgba-color-field/RgbaColorField';

type TablzWidgetSettingsProps = {
	formData: TDR.Restaurant['settings'];
	setFormData: React.Dispatch<React.SetStateAction<TDR.Restaurant['settings']>>;
	updatePreviewParam: (key: string, value: string | boolean) => void;
	selectedRestaurant: TDR.Restaurant;
	descriptionError: boolean;
	titleError: boolean;
	setTitleError: (boolean) => void;
	setDescriptionError: (boolean) => void;
};

export const TablzWidgetSettings = ({
	formData,
	setFormData,
	updatePreviewParam,
	selectedRestaurant,
	descriptionError,
	titleError,
	setTitleError,
	setDescriptionError
}: TablzWidgetSettingsProps) => {
	return (
		<>
			<h2>Tablz Widget Settings</h2>
			<FormControl>
				<FormLabel>CTA click destination</FormLabel>
				<FormControlLabel
					control={
						<Checkbox
							checked={formData.widget?.tablz?.skipLanding}
							onChange={(evt) => {
								updatePreviewParam('skipLanding', evt.target.checked);
								setFormData({
									...formData,
									widget: {
										...formData.widget,
										tablz: {
											...formData.widget?.tablz,
											skipLanding: evt.target.checked
										}
									}
								});
							}}
						/>
					}
					label="Skip landing page on CTA click"
				/>
			</FormControl>
			<TextField
				label="Title"
				placeholder={WIDGET_DEFAULT_TITLE}
				variant="outlined"
				value={formData.widget?.tablz?.title}
				InputLabelProps={{ shrink: true }}
				inputProps={{ pattern: '[A-Za-z ]*' }}
				onChange={(evt) => {
					if (evt.target.validity.valid) {
						setTitleError(false);
					}
					else {
						setTitleError(true);
					}
					updatePreviewParam('p-title', evt.target.value);
					setFormData({
						...formData,
						widget: {
							...formData.widget,
							tablz: {
								...formData.widget?.tablz,
								title: evt.target.value
							}
						}
					});
				}}
				error={titleError}
				helperText={titleError ? 'Special characters not allowed' : ''}
			/>
			<TextField
				disabled={!selectedRestaurant}
				label="Description"
				placeholder={WIDGET_DEFAULT_DESC}
				variant="outlined"
				value={formData.widget?.tablz?.description}
				InputLabelProps={{ shrink: true }}
				inputProps={{ pattern: '[A-Za-z ]*' }}
				multiline
				maxRows={3}
				onChange={(evt) => {
					if (evt.target.validity.valid) {
						setDescriptionError(false);
					}
					else {
						setDescriptionError(true);
					}
					updatePreviewParam('p-description', evt.target.value);
					setFormData({
						...formData,
						widget: {
							...formData.widget,
							tablz: {
								...formData.widget?.tablz,
								description: evt.target.value
							}
						}
					});
				}}
				error={descriptionError}
				helperText={descriptionError ? 'Special characters not allowed' : ''}
			/>
			<TextField
				disabled={!selectedRestaurant}
				label="Font Name"
				variant="outlined"
				InputLabelProps={{ shrink: true }}
				value={formData.widget?.tablz?.font}
				onChange={(evt) => {
					updatePreviewParam('p-font', evt.target.value);
					setFormData({
						...formData,
						widget: {
							...formData.widget,
							tablz: {
								...formData.widget?.tablz,
								font: evt.target.value
							}
						}
					});
				}}
			/>
			<TextField
				disabled={!selectedRestaurant}
				label="Call To Action Label"
				variant="outlined"
				InputLabelProps={{ shrink: true }}
				value={formData.widget?.tablz?.label}
				onChange={(evt) => {
					updatePreviewParam('p-label', evt.target.value);
					setFormData({
						...formData,
						widget: {
							...formData.widget,
							tablz: {
								...formData.widget?.tablz,
								label: evt.target.value
							}
						}
					});
				}}
			/>
			<Box
				display="flex"
				style={{
					flexWrap: 'wrap',
					justifyContent: 'space-between'
				}}
			>
				{/* TODO: add a preset value to match opentable color */}
				<HexColorField
					value={formData.widget?.tablz?.color}
					label="CTA Button Color"
					onChange={(color) => {
						updatePreviewParam('p-color', color);
						setFormData({
							...formData,
							widget: {
								...formData.widget,
								tablz: {
									...formData.widget?.tablz,
									color: color
								}
							}
						});
					}}
				/>

				<HexColorField
					value={formData.widget?.tablz?.labelColor}
					label="CTA Text Color"
					onChange={(color) => {
						updatePreviewParam('p-labelColor', color);
						setFormData({
							...formData,
							widget: {
								...formData.widget,
								tablz: {
									...formData.widget?.tablz,
									labelColor: color
								}
							}
						});
					}}
				/>

				<RgbaColorField
					value={formData.widget?.tablz?.bg}
					label="Overlay Color & Opacity"
					onChange={(color) => {
						updatePreviewParam('p-bg', color);
						setFormData({
							...formData,
							widget: {
								...formData.widget,
								tablz: {
									...formData.widget?.tablz,
									bg: color
								}
							}
						});
					}}
				/>

				<HexColorField
					value={formData.widget?.tablz?.borderColor}
					label="Border Color"
					onChange={(color) => {
						updatePreviewParam('p-borderColor', color);
						setFormData({
							...formData,
							widget: {
								...formData.widget,
								tablz: {
									...formData.widget?.tablz,
									borderColor: color
								}
							}
						});
					}}
				/>
			</Box>
		</>
	);
};

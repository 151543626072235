import { Box, Container } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { TDR } from 'tdr-common';

import { AdminReservationContext } from '../context/AdminReservationContext';
import { RestaurantContext } from '../context/RestaurantContext';
import { TableContext } from '../context/TableContext';
import { ReservationQuery, applyLargeGroupFilter, applyLargeGroupStatusFilter, applyRestaurantFilter, applyStatusFilter } from '../helpers/queryFilters';
import Modal from './Modal';
import ModalConfirmApproval from './ModalConfirmApproval';
import ModalConfirmDenial from './ModalConfirmDenial';
import ModalProposeTime from './ModalProposeTime';
import Notice from './Notice';
import PageNav from './PageNav';
import ReservationsList from './ReservationsList';
import ReservationDetails from './ReservationDetails';
import RestaurantSelector from './RestaurantSelector';
import SetStatusButtons from './SetStatusButtons';
import StatusFilterLargeGroup from './StatusFilterLargeGroup';
import StatusFilterReservation from './StatusFilterReservation';

const AdminBookingRequests = () => {
	const { getTablesByRestaurant, getTableById } = useContext(TableContext);
	const { restaurants, selectedRestaurantId } = useContext(RestaurantContext);
	const { reservations, setQueryFilterFn, page, setPage } = useContext(AdminReservationContext);

	const [bookingStatusFilter, setBookingStatusFilter] = useState(TDR.Reservation.Filter.All);
	const [largeGroupStatusFilter, setLargeGroupStatusFilter] = useState<TDR.Reservation.LargeGroupStatus | 'all'>(TDR.Reservation.LargeGroupStatus.AdminActionRequired);
	const [selectedReservation, setSelectedReservation] = useState<TDR.Reservation>(null);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [tables, setTables] = useState<TDR.Table[] | null>(null);

	// MODALS
	const [isModifyStatusOpen, setIsModifyStatusOpen] = useState(false);
	const [isConfirmApprovalOpen, setIsConfirmApprovalOpen] = useState(false);
	const [isConfirmDenialOpen, setIsConfirmDenialOpen] = useState(false);
	const [isProposeTimeOpen, setIsProposeTimeOpen] = useState(false);

	const getRestaurant = (id: string) => (restaurants || []).find((restaurant) => restaurant?.id === id);
	const getTable = (id: string) => tables?.find((table) => table?.id === id);

	const handleCloseModifyStatus = () => {
		setSelectedReservation(null);
		setIsModifyStatusOpen(false);
	};


	useEffect(() => {
		if(!(reservations?.length > 0)) {
			setTables(null);
		}
		else if (selectedRestaurantId) {
			getTablesByRestaurant(selectedRestaurantId).then(tables => {
				setTables(tables);
			});
		}
		else {
			const tableSet = new Set<string>();
			reservations.forEach(r => {
				tableSet.add(r.tableId);
			});
			Promise.all(Array.from(tableSet)?.map(getTableById) ?? []).then((maybeTables: TDR.Table[] | null) => {
				setTables(maybeTables);
			});
		}
	}, [selectedRestaurantId, reservations]);




	useEffect(() => {
		const queryFilterFn = (query: ReservationQuery) =>
			applyStatusFilter(
				applyLargeGroupFilter(
					applyLargeGroupStatusFilter(
						applyRestaurantFilter(query, selectedRestaurantId),
						largeGroupStatusFilter
					)
				),
				bookingStatusFilter
			);

		setQueryFilterFn(queryFilterFn);
	}, [selectedRestaurantId, largeGroupStatusFilter, bookingStatusFilter]);

	return (
		<Container maxWidth='xl' id='reservation-admin-panel'>
			<Box py={2} marginTop={'32px'}>
				<Box display='flex' justifyContent="space-between" width='100%'>
					<Box display='flex'>
						<h2>Booking Requests</h2>
					</Box>

					<Box display='flex' flexGrow={1} justifyContent='center'>
						<PageNav page={page} setPage={setPage} />
					</Box>

					<Box display='flex' alignItems='center'>
						<RestaurantSelector allRestaurantsSelection={true} />
						<StatusFilterReservation filter={bookingStatusFilter} setFilter={setBookingStatusFilter} />
						<StatusFilterLargeGroup filter={largeGroupStatusFilter} setFilter={setLargeGroupStatusFilter} />
					</Box>
				</Box>

				{errorMessage && (
					<Notice message={errorMessage} onClear={() => setErrorMessage('')} />
				)}

				<ReservationsList
					{...{
						reservations,
						setIsConfirmApprovalOpen,
						setIsConfirmDenialOpen,
						setIsModifyStatusOpen,
						setIsProposeTimeOpen,
						setSelectedReservation
					}}/>

				<Box display='flex' flexGrow={1} justifyContent='center' width='100%'>
					<PageNav page={page} setPage={setPage} />
				</Box>

				{selectedReservation && (
					<>
						<ModalConfirmApproval
							reservation={selectedReservation}
							open={isConfirmApprovalOpen}
							onClose={() => setIsConfirmApprovalOpen(false)}
						/>

						<ModalConfirmDenial
							reservation={selectedReservation}
							open={isConfirmDenialOpen}
							onClose={() => setIsConfirmDenialOpen(false)}
						/>

						<ModalProposeTime
							reservation={selectedReservation}
							open={isProposeTimeOpen}
							onClose={() => setIsProposeTimeOpen(false)}
						/>

						<Modal
							open={isModifyStatusOpen}
							onClose={handleCloseModifyStatus}
						>
							<ReservationDetails
								reservation={selectedReservation}
								restaurant={getRestaurant(selectedReservation?.restaurantId)}
								table={getTable(selectedReservation?.tableId)}
							/>

							<SetStatusButtons
								reservation={selectedReservation}
								close={handleCloseModifyStatus}
							/>
						</Modal>
					</>
				)}
			</Box>
		</Container>
	);
};

export default AdminBookingRequests;

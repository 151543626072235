import React, { CSSProperties } from 'react';

export const TableIcon = ({ style }: {style: CSSProperties}) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" focusable='false' xmlns="http://www.w3.org/2000/svg" className='MuiSvgIcon-root' style={style}>
		<rect y="6" width="2" height="8" rx="1" fill="white" fillOpacity="0.87"/>
		<rect x="18" y="6" width="2" height="8" rx="1" fill="white" fillOpacity="0.87"/>
		<rect x="14" y="18" width="2" height="8" rx="1" transform="rotate(90 14 18)" fill="white" fillOpacity="0.87"/>
		<rect x="14" width="2" height="8" rx="1" transform="rotate(90 14 0)" fill="white" fillOpacity="0.87"/>
		<rect x="4" y="4" width="12" height="12" rx="1" fill="white" fillOpacity="0.87"/>
	</svg>
);

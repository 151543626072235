import React, { useContext } from 'react';
import { Dialog, IconButton, Typography } from '@material-ui/core';
import { ModalContext } from '../context/ModalContext';
import Drawer from './Drawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';

namespace Modal {
	export type Props = {
		title?: string | JSX.Element,
		rightSideIcon?: JSX.Element,
		children: any,
		open?: boolean,
		onClose?: (() => void),
		onClickRightIcon?: (() => void),
		shouldBeDrawerOnMobile?: boolean,
		styleOverride?: any,
		fullScreen?: boolean
	};
}

const Modal = ({
	title = null,
	rightSideIcon = null,
	children,
	open = null,
	onClose = null,
	onClickRightIcon = null,
	styleOverride = {},
	shouldBeDrawerOnMobile = false,
	fullScreen = false
}: Modal.Props) => {
	const { modalOpen, closeModal } = useContext(ModalContext);

	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		shouldBeDrawerOnMobile && smDown
			? (
				<Drawer
					variant='temporary'
					showCloseButton={title ? false : true}
					hasBackdrop={true}
					open={open ?? modalOpen}
					onClose={onClose ?? closeModal}
					styleOverride={styleOverride}
				>
					{title
						? (
							<div className='drawer-header'>
								<IconButton className='drawer-close-btn' onClick={onClose ?? closeModal}>
									<CloseIcon />
								</IconButton>
								{React.isValidElement(title)
									? title
									: <Typography variant='h6'>{title}</Typography>}
								{rightSideIcon && (
									<IconButton className='right-btn' onClick={onClickRightIcon}>
										{rightSideIcon}
									</IconButton>
								)}
							</div>
						)
						: {}
					}
					<div className='drawer-content' style={{ marginTop: title ? '4.0625em' : '1.5em' }}>
						{children}
					</div>
				</Drawer>
			)
			: (
				<Dialog
					open={open ?? modalOpen}
					onClose={onClose ?? closeModal}
					PaperProps={{
						className: 'modal-paper-component',
						style: styleOverride
					}}
					fullScreen={fullScreen}
				>
					{title
						? (
							<div className='modal-header'>
								<IconButton className='modal-close-btn' onClick={onClose ?? closeModal}>
									<CloseIcon />
								</IconButton>

								{React.isValidElement(title)
									? title
									: <Typography variant='h6'>{title}</Typography>}

								{rightSideIcon && (
									<IconButton className='right-btn' onClick={onClickRightIcon}>
										{rightSideIcon}
									</IconButton>
								)}
							</div>
						)
						: (
							<IconButton className='modal-close-btn' onClick={onClose ?? closeModal}>
								<CloseIcon />
							</IconButton>
						)
					}
					<div className='modal-content' style={{ marginTop: title ? '4.375em' : '1.875em' }}>
						{children}
					</div>
				</Dialog>
			)
	);
};

export default Modal;


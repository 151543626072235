import {
	Box,
	Button, CircularProgress, Container, FormControl,
	InputLabel,
	Link, MenuItem, Select as MuiSelect, Select, Table,
	TableBody,
	TableCell,
	TableHead, TableRow, TextField, Tooltip
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { NotInterested } from '@material-ui/icons';
import * as Sentry from '@sentry/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import React, { useContext, useEffect, useState } from 'react';
import { MONTHS, TDR, formatMonetaryValue, generateNoShowFeeInvoice, toLuxon } from 'tdr-common';

import { payInvoice } from '../api';
import chargeNoShow from '../api/noshowFee';
import realizeReservationInvoices from '../api/realizeReservationInvoices';
import refundInvoice from '../api/refundInvoice';
import config from '../common/config';
import { FirebaseContext } from '../context/FirebaseContext';
import { PricingPolicyContext } from '../context/PricingPolicyContext';
import { RestaurantContext } from '../context/RestaurantContext';
import { useInvoices } from '../hooks/useInvoices';
import { useReservations } from '../hooks/useReservations';
import Notice from './Notice';
import RestaurantSelector from './RestaurantSelector';
import firebase from 'firebase';
import { PayAllButton } from './PayAllButton';

export const NO_FILTER = -1;

const TODAY = new Date();
const CURRENT_YEAR = TODAY.getFullYear();
const CURRENT_MONTH = TODAY.getMonth();

type NoticeMsgFn = {
	setNoticeMessage: (msg: string) => void;
	setNoticeType: (type: string) => void;
}

type ReservationInvoicesProps = {
	reservation: TDR.Reservation;
	restaurant: TDR.Restaurant;
	token: string;
} & NoticeMsgFn

type InvoiceRowProps = {
	invoice: TDR.Invoice;
	restaurant: TDR.Restaurant;
	token: string;
} & NoticeMsgFn

type NoShowFeePanelProps = {
	invoices:TDR.Invoice[];
	disclaimer: string;
	token:string;
	reservationId: string;
	firestore: firebase.firestore.Firestore;
	onNoShowFeeCharged?: () => Promise<void>;
} & NoticeMsgFn


const moneyStyle = {
	fontFamily: 'monospace',
	fontWeight: 'bold',
	textAlign: 'right'
} as CSSProperties;

const getIfStr = (x, ...path) => {
	const str = get(x, path);
	return isString(str) ? str : null;
};

function getMessageStr (obj) {
	return (
		(isString(obj) && obj) ||
		getIfStr(obj, 'message') ||
		getIfStr(obj, 'json', 'message') ||
		getIfStr(obj, 'error') ||
		getIfStr(obj, 'error', 'message') ||
		null
	);
}

const getYearFilterRange = () => {
	const yearFilterRange: number[] = [];

	for (let year = CURRENT_YEAR; year > 2020; year--) {
		yearFilterRange.push(year);
	}

	return yearFilterRange;
};

const AdminInvoices = () => {
	const ReservationStatus = TDR.Reservation.Filter;

	const { token, firestore } = useContext(FirebaseContext);
	const { selectedRestaurantId, selectedRestaurant } = useContext(RestaurantContext);

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [yearFilter, setYearFilter] = useState(CURRENT_YEAR);
	const [monthFilter, setMonthFilter] = useState(CURRENT_MONTH);
	const [reservationStatusFilter, setReservationStatusFitler] = useState<TDR.Reservation.Filter>(ReservationStatus.Completed);

	const [noticeMessage, setNoticeMessage] = useState<string>('');
	const [noticeType, setNoticeType] = useState<string>('error');

	const {
		reservations,
		fetchNextPage,
		endOfData,
		loading
	} = useReservations(
		firestore,
		selectedRestaurantId,
		selectedRestaurant?.timezone,
		reservationStatusFilter,
		yearFilter,
		monthFilter
	);

	return (
		<Container maxWidth='xl'>
			<Box py={2} marginTop={'32px'}>

				<Box display='flex' flexDirection='row' width='100%'>
					<Box display='flex'>
						<h2>Invoices</h2>
					</Box>

					<Box display='flex' flexGrow={1} justifyContent='center'>
					</Box>

					{selectedRestaurant && [ReservationStatus.Completed, ReservationStatus.Cancelled, ReservationStatus.NoShow].includes(reservationStatusFilter) ? (
						<PayAllButton
							restaurant={selectedRestaurant}
							reservations={reservations}
							userToken={token}
							onSubmit={() => setIsSubmitting(true)}
							onComplete={() => setIsSubmitting(false)}
						/>
					) : null}

					<RestaurantSelector />

					<FormControl variant='filled' className='select-filter'>
						<InputLabel id="booking-status-filter">Booking Status</InputLabel>
						<MuiSelect
							labelId='booking-status-filter'
							value={reservationStatusFilter}
							onChange={(evt) => setReservationStatusFitler(evt.target.value as TDR.Reservation.Filter)}
						>
							<MenuItem value={ReservationStatus.All}>All</MenuItem>
							<MenuItem value={ReservationStatus.Completed}>Completed</MenuItem>
							<MenuItem value={ReservationStatus.NoShow}>No-Show</MenuItem>
							<MenuItem value={ReservationStatus.Cancelled}>Cancelled</MenuItem>
						</MuiSelect>
					</FormControl>

					<FormControl variant='filled' className='select-filter'>
						<InputLabel id="booking-date-year">Year</InputLabel>
						<MuiSelect
							labelId='booking-date-year'
							value={yearFilter}
							onChange={(evt) => {
								const value = Number(evt.target.value);

								// When year is set to All, also set month to All
								if (value === NO_FILTER) {
									setMonthFilter(NO_FILTER);
								}
								setYearFilter(value);
							}}
						>
							<MenuItem value={NO_FILTER}>All</MenuItem>
							{getYearFilterRange().map((year) => (
								<MenuItem key={year} value={year}>{year}</MenuItem>
							))}
						</MuiSelect>
					</FormControl>

					<FormControl variant='filled' className='select-filter'>
						<InputLabel id="booking-date-month">Month</InputLabel>
						<MuiSelect
							labelId='booking-date-month'
							value={monthFilter}
							onChange={(evt) => setMonthFilter(Number(evt.target.value))}
							disabled={yearFilter === NO_FILTER}
						>
							<MenuItem value={NO_FILTER}>All</MenuItem>
							{MONTHS.map((month, index) => (
								<MenuItem key={month.name} value={index}>{month.name}</MenuItem>
							))}
						</MuiSelect>
					</FormControl>
				</Box>

				{noticeMessage && <Notice message={noticeMessage} type={noticeType} onClear={() => setNoticeMessage('')}/>}
				{isSubmitting && <CircularProgress />}
				{!isSubmitting &&
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Booking Details</TableCell>
							<TableCell>Invoice Type</TableCell>
							<TableCell>{/* currency */}</TableCell>
							<TableCell style={{ textAlign: 'right' }}>Paid</TableCell>
							<TableCell style={{ textAlign: 'right' }}>Refunded</TableCell>
							<TableCell style={{ textAlign: 'right' }}>Total</TableCell>
							<TableCell style={{ textAlign: 'right' }}>BreakDown</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>{/* button */}</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{reservations.length === 0 ?(
							 <NoData />
						) : (
							reservations.map(res => (
								<ReservationInvoices
									key={res.id}
									reservation={res}
									restaurant={selectedRestaurant}
									setNoticeMessage={setNoticeMessage}
									setNoticeType={setNoticeType}
									token={token}
								/>
							))
						)}
					</TableBody>
				</Table>}

				{endOfData ? null : (
					<Button onClick={fetchNextPage} disabled={loading}>
						{loading ? <CircularProgress /> : 'Load More'}
					</Button>
				)}
			</Box>
		</Container>
	);
};


const ReservationInvoices = ({
	reservation,
	restaurant,
	token,
	setNoticeMessage,
	setNoticeType
}: ReservationInvoicesProps ) => {
	const { firestore } = useContext(FirebaseContext);
	const [realizing, setRealizing] = useState(false);
	const { invoices, fetchInvoices, setInvoices } = useInvoices(firestore, reservation.id);

	const resTime = reservation && toLuxon(reservation.time, reservation.timezone);

	const canChargeNoShow = reservation.status === TDR.Reservation.Status.NoShow && !isEmpty(reservation.restaurantFeeDisclaimer);

	const reservationCell = (
		<TableCell rowSpan={invoices.length} style={{ verticalAlign: 'top', ...(reservation ? {} : { backgroundColor: 'red' }) }}>
			{reservation && (
				<>
					<Link
						href={`${config.appLink}/booking/${reservation.id}`}
						target='_blank'
					>
						{reservation.restaurantName} {reservation.internalTableName || reservation.tableName} {resTime.toFormat('ff')}
						<span style={{ marginLeft: '0.25em' }}>({reservation.status})</span>
					</Link>
					<br/>
					{reservation.fullName}
				</>
			)
			}
			<br/>

			<Button
				disabled={realizing}
				variant='contained'
				style={{ height: 'auto', padding: '0.1em', margin: '0em', width: 'auto' }}
				onClick={() => {
					setRealizing(true);
					realizeReservationInvoices(token, reservation)
						.then(async res => {
							if (!res.success) {
								console.error(res);
								setNoticeType('error');
								setNoticeMessage(getMessageStr(res) || 'unknown error occured');
							}
							else{
								const newInvoices = await fetchInvoices();
								setInvoices(newInvoices);
								setNoticeType('success');
								setNoticeMessage('Successfully realized invoice');
							}
						})
						.catch(err => {
							console.error(err);
							setNoticeType('error');
							setNoticeMessage(getMessageStr(err) || 'unknown error occured');
						})
						.finally(() => {
							setRealizing(false);
						});
				}}
			>
				{realizing ? <CircularProgress size='1.75em' /> : 'Realize'}
			</Button>
			<br/>

			{!canChargeNoShow ? null :
				<NoShowFeePanel
					firestore={firestore}
					reservationId={reservation.id}
					disclaimer={reservation.restaurantFeeDisclaimer}
					invoices={invoices}
					token={token}
					setNoticeMessage={setNoticeMessage}
					setNoticeType={setNoticeType}
					onNoShowFeeCharged={async () => {
						const newInvoices = await fetchInvoices();
						setInvoices(newInvoices);
					}}
				/>}

		</TableCell>
	);

	return (
		<>
			{invoices.map((invoice, notFirst) => (
				<TableRow key={invoice.id}>
					{!notFirst && reservationCell}
					<InvoiceCells {...{ token, invoice, restaurant, setNoticeMessage, setNoticeType }} />
				</TableRow>
			))}
		</>
	);
};


const InvoiceCells = function ({ token, invoice, restaurant, setNoticeMessage, setNoticeType }: InvoiceRowProps) {
	const inOrOut = (invoice.fromAccount.type === 'tk' ? -1 : +1);
	const positiveTotal = 0 <= (invoice.total * inOrOut);
	const positivePayable = 0 <= (invoice.payable * inOrOut);
	const modified = !!invoice.modifiedBy;

	const extMoneyStyle = {
		opacity: modified ? 0.4 : 1,
		...moneyStyle
	};

	let totalToolTip = '';
	let breakDown = {};

	if(invoice.type === 'payout') {
		const stripeFees = invoice.lineItems.filter(item => item.total < 0).reduce((accum, currentValue) => accum + currentValue.total, 0) || 0;
		const tableFee = invoice.lineItems.filter(item => item.total > 0).reduce((accum, currentValue) => accum + currentValue.total, 0) || 0;

		if(invoice.payoutBreakdown) {
			const { noShowTotal, taxTotal, foodAndBeverageCredit, deposit } = invoice.payoutBreakdown;
			totalToolTip = 'Table Fee: ' + formatMonetaryValue(tableFee ?? 0, invoice.currency) + ', No Show Fee: ' + formatMonetaryValue(noShowTotal ?? 0, invoice.currency) + ', Tax: ' + formatMonetaryValue(taxTotal ?? 0, invoice.currency) + ', Food & Beverage Credit: ' + formatMonetaryValue(foodAndBeverageCredit ?? 0, invoice.currency) + ', Deposit: ' + formatMonetaryValue(deposit ?? 0, invoice.currency) + ', Stripe Fees: ' + formatMonetaryValue(stripeFees ?? 0, invoice.currency);
		}
		else {
			const deposit = invoice.total - tableFee - stripeFees;
			totalToolTip = 'Table Fee: ' + formatMonetaryValue(tableFee, invoice.currency) + ', Deposit: ' + formatMonetaryValue(Math.abs(deposit), invoice.currency) + ', Stripe Fees: ' + formatMonetaryValue(stripeFees, invoice.currency);
		}
		breakDown = {
			table: tableFee,
			'stripe': stripeFees,
			tax: invoice?.payoutBreakdown?.taxTotal,
			'no-show': invoice?.payoutBreakdown?.noShowTotal,
			deposit: invoice?.payoutBreakdown?.deposit,
			'add-on': invoice?.payoutBreakdown?.foodAndBeverageCredit
		};
	}
	else {
		totalToolTip = formatMonetaryValue(invoice.subtotal, invoice.currency);
	}

	return <>
		<TableCell>
			{invoice.type || `->${invoice.toAccount.type}`}
		</TableCell>

		<TableCell>
			{invoice?.currency?.toUpperCase() || TDR.Currency.CAD.toUpperCase()}
		</TableCell>

		<TableCell style={{ textAlign: 'right' }}>
			<span style={{ color: positivePayable ? '#8F8' : '#F88', ...extMoneyStyle }}>
				{formatMonetaryValue(invoice.payable, invoice.currency)}
			</span>
		</TableCell>

		<TableCell style={{ textAlign: 'right' }}>
			<span style={{ ...extMoneyStyle }}>
				{formatMonetaryValue(invoice.refunded, invoice.currency)}
			</span>
		</TableCell>

		<TableCell style={{ textAlign: 'right' }}>
			<Tooltip title={totalToolTip} placement="left">
				<span style={{ color: positiveTotal ? '#8F8' : '#F88', ...extMoneyStyle }}>
					{formatMonetaryValue(invoice.total, invoice.currency)}
				</span>
			</Tooltip>
		</TableCell>
		{invoice.type === 'payout' ? (
			<TableCell>
				{Object.keys(breakDown).map((key, index) => {
					if(breakDown[key] !== 0) {
						return <div key={index}>
							{key}: {formatMonetaryValue(breakDown[key], invoice.currency)}
						</div>;

					}
				}
				)}
			</TableCell>
		) : (
			<TableCell>
			</TableCell>
		)}
		<TableCell>
			{invoice.status}
		</TableCell>

		<TableCell>
			{invoice.toAccount.type === 'restaurant' && <PayInvoiceButton {...{ token, invoice, restaurant, setNoticeMessage, setNoticeType }} />}
			{invoice.toAccount.type === 'tk' && <RefundInvoiceButton {...{ token, invoice, restaurant, setNoticeMessage, setNoticeType }} />}
		</TableCell>
	</>;
};



const PayInvoiceButton = function ({ token, invoice, restaurant, setNoticeMessage, setNoticeType }: InvoiceRowProps) {
	const canTransfer = (
		invoice.toAccount.type === 'restaurant' &&
			invoice.status === 'pending' &&
			restaurant?.accountId &&
			invoice.total > 0
	);

	const [sendingTransfer, setSendingTransfer] = useState(invoice.status === 'in-transit');

	if (!canTransfer) {
		return <span>{isEmpty(restaurant?.accountId) && 'missing stripe acct'}</span>;
	}

	return <Button
		disabled={sendingTransfer}
		variant='contained'
		style={{ height: 'auto', padding: '0.1em', margin: '0em' }}
		data-cy={`invoice-payout-btn ${invoice.id}`}
		onClick={() => {
			setSendingTransfer(true);
			payInvoice(token, invoice)
				.then(res => {
					if (!res.success) {
						setSendingTransfer(false);
						console.error(res);
						setNoticeType('error');
						setNoticeMessage(getMessageStr(res) || 'unknown error occured');
					}
				})
				.catch(err => {
					setSendingTransfer(false);
					console.error(err);
					setNoticeType('error');
					setNoticeMessage(getMessageStr(err) || 'unknown error occured');
				});
		}}
	>
		{sendingTransfer && <CircularProgress size='1.75em' />}
			Pay
	</Button>;
};

const RefundInvoiceButton = function ({ token, invoice, setNoticeMessage, setNoticeType }: InvoiceRowProps) {

	const canRefund = (
		invoice.toAccount.type === 'tk' &&
		invoice.status !== 'refunded' && invoice.status !== 'pending' && // TODO: any reason we can't just say invoice.status === 'paid' here?
		invoice.total > 0 &&
		!invoice.modifiedBy
	);

	if (!canRefund) {
		return null;
	}

	const [status, setStatus] = useState<('closed' | 'enteringAmount' | 'submitting' | 'refunded')>('closed');
	const closed = status === 'closed';
	const enteringAmount = status === 'enteringAmount';
	const waiting = status === 'submitting';
	const refunded = status === 'refunded';

	const [depositAmountStr, setDepositAmountStr] = useState('');
	const [subtotalAmountStr, setSubtotalAmountStr] = useState('');
	useEffect(() => {
		if(subtotalAmountStr !== ''){
			setSubtotalAmount(parseFloat(subtotalAmountStr));
		}
		else {
			setSubtotalAmount(0);
		}
	}, [subtotalAmountStr]);

	useEffect(() => {
		if(depositAmountStr !== '') {
			setDepositAmount(parseFloat(depositAmountStr));
		}
		else {
			setDepositAmount(0);
		}
	}, [depositAmountStr]);

	const [subtotalAmount, setSubtotalAmount] = useState(0);
	const [depositAmount, setDepositAmount] = useState(0);
	const validAmount = isNumber(subtotalAmount) && isNumber(depositAmount) && depositAmount + subtotalAmount > 0;

	return <>
	 <Button
			disabled={waiting || (enteringAmount && !validAmount) || refunded}
			variant='contained'
			style={{ height: 'auto', padding: '0.1em', margin: '0em' }}
			data-cy={`invoice-payout-btn ${invoice.id}`}
			onClick={() => {

				if (closed) {
					setStatus('enteringAmount');
				}

				else if (enteringAmount) {
					setStatus('submitting');
					refundInvoice(token, invoice, subtotalAmount, depositAmount)
						.then(res => {
							if (!res.success) {
								setStatus('closed');
								console.error(res);
								Sentry.captureException(new Error(res.message));
								setNoticeType('error');
								setNoticeMessage(getMessageStr(res) || 'unknown error occured');
							}
							else {
								setStatus('refunded');
							}
						})
						.catch(err => {
							Sentry.captureException(err);
							setStatus('closed');
							console.error(err);
							setNoticeType('error');
							setNoticeMessage(getMessageStr(err) || 'unknown error occured');
						});
				}
			}}
		>
			{closed ? 'Refund' :
			 enteringAmount ? 'Send' :
			 waiting ? <CircularProgress size='1.75em' /> :
			 refunded ? 'Refunded' :
			 null}
		</Button>

		{(!closed && !refunded) &&
			<>
				<TextField
					className='input'
					label={`max ${formatMonetaryValue(invoice.subtotal, invoice.currency)} + fees`}
					variant='outlined'
					style={moneyStyle}
					disabled={waiting}
					value={subtotalAmountStr}
					onChange={(evt) => setSubtotalAmountStr(evt.target.value)}
				/>
				<TextField
					className='input'
					label={`max ${formatMonetaryValue(invoice.deposit, invoice.currency)} `}
					variant='outlined'
					style={moneyStyle}
					disabled={waiting}
					value={depositAmountStr}
					onChange={(evt) => setDepositAmountStr(evt.target.value)}
				/>
			</>
		}
	</>;
};


const NoShowFeePanel = ({ onNoShowFeeCharged, disclaimer, invoices, token, setNoticeMessage, setNoticeType }: NoShowFeePanelProps ) => {

	const allCandidates = sortBy(
		uniqBy(invoices.filter(inv => inv.toAccountType === TDR.Invoice.PartyType.TK), 'paymentMethodId'),
		['currency', 'paymentMethodId']
	);
	const [candidate, setCandidate] = useState(allCandidates[0]);

	// const { rest } = useContext(RestaurantContext);
	const { restaurants } = useContext(RestaurantContext);
	const restaurant = restaurants.find(r => r.id === invoices[0]?.restaurantId);

	if (!restaurant) {
		return null;
	}

	const { taxPolicies: allTaxPolicies } = useContext(PricingPolicyContext);
	const taxPolicies = allTaxPolicies.filter(policy => restaurant.taxes.includes(policy.tax));

	const [amountStr, setAmount] = useState('');
	const amount = parseFloat(amountStr);

	const valid = (isNumber(amount) && amount > 0);
	const [waiting, setWaiting] = useState(false);

	return <>
		{disclaimer}

		<Box className='noshow-fee-panel'>
			NoShow Fee:

			<TextField
				label='Amount'
				variant='outlined'
				value={amountStr}
				error={!isNumber(amount) || amount <= 0}
				onChange={(evt) => setAmount(evt.target.value)}
			/>

			{allCandidates.length <= 1 ? null :
				<Select
					value={allCandidates.indexOf(candidate)}
					label="Card"
					onChange={e => setCandidate(allCandidates[parseInt(e.target.value as string)])}
				>
					{allCandidates.map((candidate, index) =>
						<MenuItem key={index} value={index}>Card {index+1} (§ {candidate.currency} 🗺 {candidate.toAccount.region})</MenuItem>)}
				</Select>}

			<Button
				disabled={waiting || !valid}
				variant='contained'
				style={{ height: 'auto', padding: '0.1em', margin: '0em', width: 'auto' }}
				onClick={async () => {
					setWaiting(true);
					const invoice = generateNoShowFeeInvoice(candidate, amount, taxPolicies);

					chargeNoShow(token, invoice)
						.then(async res => {
							if (!res.success) {
								setNoticeType('error');
								setNoticeMessage(res.error?.raw?.message || 'unknown error occured');
							}
							else {
								await onNoShowFeeCharged?.();
								setNoticeType('success');
								setNoticeMessage('Successfully Charged no show fee');
							}
						})
						.catch(err => {
							console.error(err);
							setNoticeType('error');
							setNoticeMessage(getMessageStr(err) || 'unknown error occured');
						})
						.finally(() => setWaiting(false));
				}}
			>
				{waiting
					? <CircularProgress size='1.75em' />
					: 'Charge'}
			</Button>

		</Box>
	</>;
};

const NoData = () => (
	<TableRow>
		<TableCell colSpan={8} style={{ textAlign: 'center' }}>
			<NotInterested />
			<p>No Data For Selected Filters</p>
		</TableCell>
	</TableRow>
);

export default AdminInvoices;


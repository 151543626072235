import React, { useState, useEffect, useContext, createContext } from 'react';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import { TDR } from 'tdr-common';
import { ProviderProps } from '../common/ProviderProps';
import { FirebaseContext } from './FirebaseContext';

export namespace AdminRestaurantContext {
	export type Value = {
		selectedAdminRestaurant: TDR.Restaurant,
		setSelectedAdminRestaurant: (restaurant: TDR.Restaurant) => void,
		loading: boolean
	};
}

export const AdminRestaurantContext = createContext<AdminRestaurantContext.Value>({
	setSelectedAdminRestaurant: noop,
	selectedAdminRestaurant: null,
	loading: false
});
AdminRestaurantContext.displayName = 'Admin Restaurant Context';


export const AdminRestaurantProvider = ({ children }: ProviderProps) => {
	const { initialized, firestore, userId } = useContext(FirebaseContext);
	const [restaurants, setAdminRestaurants] = useState([]);
	const [selectedAdminRestaurant, setSelectedAdminRestaurant] = useState<TDR.Restaurant>(null);
	const [loading, setLoading] = useState(false);

	const local = process.env.TARGET_FIREBASE === 'emulators' || window.location.href.includes('localhost');

	useEffect(() => {
		let dispose = null;
		if (!local) {
			console.warn('This is set to only be used locally! :(');
			return;
		}

		if (isEmpty(restaurants) && initialized) {
			(async () => {
				setLoading(true);

				dispose = firestore.collection('Restaurants')
					.where('managerIds', 'array-contains', userId)
					.onSnapshot((snapshot) => {
						const restaurants: TDR.Restaurant[] = [];
						snapshot.forEach((doc) => {
							restaurants.push(doc.data() as TDR.Restaurant);
						});
						setAdminRestaurants(restaurants);
						setSelectedAdminRestaurant(restaurants[0]);

						if (!restaurants.length) {
							console.warn(`Your user id is set to ${userId}.`);
							console.warn('None of the restaurants currently have that set to their "managerIds" array');
							console.warn('In order to fix this and proceed with development: ');
							console.warn(`1- copy: ${userId}`);
							console.warn(`2- chose one of the generated restaurants and set on it an array of "managerIds" with the one entry containing ${userId}`);
						}
					});

				setLoading(false);
			})();
		}

		return () => {
			dispose?.();
		};
	}, [initialized]);

	const valueObj: AdminRestaurantContext.Value = {
		selectedAdminRestaurant,
		setSelectedAdminRestaurant,
		loading
	};

	return (
		<AdminRestaurantContext.Provider value={valueObj}>
			{children}
		</AdminRestaurantContext.Provider>
	);
};
import React, { useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
	Box, IconButton, MenuItem, Select, TextField
} from '@material-ui/core';
import {
	AddCircle as AddIcon, DeleteForever as DeleteIcon, KeyboardArrowDown as KeyboardArrowDownIcon, Save
} from '@material-ui/icons';

import { TDR } from 'tdr-common';

import { RestaurantContext } from '../context/RestaurantContext';


const PromoCode = ({ promoCode }: {
	promoCode: TDR.PromoCode
}) => {
	const { addCode, deleteCode } = useContext(RestaurantContext);
	const [code, setCode] = useState(promoCode.code);
	const [discountAmountStr, setDiscountAmountStr] = useState(String(promoCode.discountAmount));
	const [discountType, setDiscountType] = useState(promoCode.discountType);
	//const [maximumDiscountAmount, setMaximumDiscountAmount] = useState(promoCode.maximumDiscountAmount);



	return(<Box bgcolor='#333333' padding='12px' margin='4px'>
		<Box display='flex' flexDirection='row' m={1}>
			<TextField
				className='input uppercase'
				label='promo code'
				variant='outlined'
				style={{ marginLeft: '1em', maxWidth: '200px' }}
				value={code}
				onChange={(evt) => setCode((evt.target.value.toUpperCase()))}
			/>

			<IconButton
				onClick={ () => {
					addCode(promoCode.id, { code, discountType, discountAmount: parseFloat(discountAmountStr) });
				}}
			>
				<Save />
			</IconButton>
			<IconButton
				onClick={ () => {
					deleteCode(promoCode.id);
				}}
			>
				<DeleteIcon />
			</IconButton>

		</Box>
		<Box display='flex' flexDirection='row' bgcolor='#232323' m={1}>
			<Select
				label='discount type'
				className='select'
				variant='outlined'
				style={{ marginLeft: '1em' }}
				value={discountType}
				onChange={(evt) => setDiscountType(evt.target.value as 'flat' | 'percent')}
				IconComponent={(props) => <Box ml={1}><KeyboardArrowDownIcon {...props} style={{ color: 'rgba(255, 255, 255, 0.87)' }} /></Box>}
			>
				<MenuItem value='flat'><em>flat</em></MenuItem>
				<MenuItem value='percent'><em>percent</em></MenuItem>
			</Select>

			<TextField
				className='input'
				label='amount'
				variant='outlined'
				style={{ marginLeft: '1em' }}
				value={discountAmountStr}
				onChange={(evt) => setDiscountAmountStr(evt.target.value)}
			/>



			{/*<TextField*/}
			{/*	className='input'*/}
			{/*	label='max discount amount'*/}
			{/*	variant='outlined'*/}
			{/*	type='number'*/}
			{/*	style={{ marginLeft: '1em' }}*/}
			{/*	value={maximumDiscountAmount}*/}
			{/*	onChange={(evt) => setMaximumDiscountAmount(Number(evt.target.value))}*/}
			{/*/>*/}

		</Box>

	</Box>);
};


const PromoCodeEditor = () => {

	const { promoCodes: contextCodes, selectedRestaurantId } = useContext(RestaurantContext);
	const [promoCodes, setPromoCodes] = useState(contextCodes);

	const genNewPromoCode = () => {
		const newCode = {
			id: uuid(),
			code: '',
			discountAmount: 0,
			discountType: 'flat' as 'flat' | 'percent',
			restaurantId: selectedRestaurantId
		};
		setPromoCodes([newCode, ...promoCodes]);
	};


	return (<Box>
		<Box display='flex' flexDirection='row' bgcolor='#232323' m={1}>
			<div>PROMO CODES</div>

			<IconButton
				onClick={genNewPromoCode}
			>
				<AddIcon />
			</IconButton>
		</Box>

		{(promoCodes || []).map((code) => (
			<PromoCode key={JSON.stringify(code)} promoCode={code} />
		))}
	</Box>);

};

export default PromoCodeEditor;

import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


namespace Notice {
	export type Props = {
		message: string,
		onClear?: (()=>void),
		styleOverride?: any,
		type?: string
	};
}

const Notice = ({ message, onClear, styleOverride = {}, type = 'error' }: Notice.Props) => {

	const validTypes: string[] = ['error', 'success'];
	if (!validTypes.includes(type)) {
		console.warn('Invalid type. Use one of the following:', validTypes.join());
	}

	console.log('NOTICE MESSAGE', message);
	message = message.toString();


	const [hidden, hideError] = useState(false);

	return (
		<div className={`notice ${type}`}
			style={{ display: hidden ? 'none' : '', ...styleOverride }}
			data-cy='notice'
		>
			<Typography variant='body1'>
				{message}
			</Typography>

			<Typography variant='body1'>
				{onClear && (
					<CloseIcon fontSize='inherit' onClick={() => {
						hideError(true);
						onClear();
					}}/>
				)}
			</Typography>
		</div>
	);
};

export default Notice;
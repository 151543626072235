import {
	Box, Button,
	IconButton, TextField,
	Typography
} from '@material-ui/core';
import {
	AddCircle as AddIcon, Help, KeyboardArrowDown,
	KeyboardArrowUp
} from '@material-ui/icons';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import debounce from 'lodash/debounce';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import React, { useState } from 'react';
import { TDR } from 'tdr-common';
import { v4 as uuid } from 'uuid';
import DatesPicker from './DatesPicker';
import Modal from './Modal';
import TimeSlotsEditor from './TimeSlotsEditor';
import { allWeekdays } from './WeekdaysPicker';

import ReactMarkdown from 'react-markdown';
import helpDocOverrides from '../markdown/restaurant-overrides.md';
import PillList from './PillList';

namespace OverridesEditor {
	export type Props = {
		label: string,
		tables: TDR.Table[],
		overrides: TDR.Override[],
		onChange: (overrides: TDR.Override[]) => void
	};
}

const defaultOverride: TDR.Override = {
	id: null,
	name: 'New Override',
	description: 'Description for New Override',
	conditions: {
		dates: [],
		weekdays: []
	},
	topic: 'Tables',
	targetIds: [],
	values: {
		defaultTimeSlots: {}
	}
};

const OverridesEditor = ({ tables, overrides, onChange }: OverridesEditor.Props) => {
	overrides = cloneDeep(overrides); // let's be sure not to fuck up external state
	const [activeOverride, setActiveOverride] = useState<TDR.Override>(null);
	const debouncedsetActiveOverride = debounce(setActiveOverride, 250);


	const [showHelp, setShowHelp] = useState(false);

	const tableTags = uniq(flatMap(tables, table => table.tags || [])).sort();

	return (
		<Box display='flex' flexDirection='row' width='100%'>
			<Box display='flex' flexDirection='column' mr={2}>
				<Box display='flex' flexDirection='row'>
					<Box display='flex' flexGrow={1}></Box>
					<IconButton
						onClick={() => {
							const newOverride = cloneDeep(defaultOverride);
							newOverride.id = uuid();
							onChange([
								...(overrides || []),
								newOverride
							]);
							setActiveOverride(newOverride);
						}}
					>
						<AddIcon />
					</IconButton>
				</Box>

				{(overrides || []).map((override, index) => (
					<Box
						key={`override-${index}`}
						display='flex'
						flexDirection='row'
						justifyContent='space-between'
						px={1}
						py={2}
						alignItems='center'
						width='100%'
						onClick={() => {
							if (activeOverride?.id !== override.id) {
								setActiveOverride(null);
								setTimeout(() => setActiveOverride(override));
							}
						}}
						bgcolor={activeOverride?.id === override.id ? '#333' : '#121212'}
						style={{
							cursor: 'pointer'
						}}
					>
						{override.name}

						<Box display='flex' flexDirection='column'>
							{index === 0 ? null :
								<KeyboardArrowUp onClick={() => {
									overrides.splice(index, 1);
									overrides.splice(index - 1, 0, override);
									onChange(overrides);
								}} />}
							{index === overrides.length -1 ? null :
								<KeyboardArrowDown onClick={() => {
									overrides.splice(index, 1);
									overrides.splice(index + 1, 0, override);
									onChange(overrides);
								}}/>}
						</Box>
					</Box>
				))}
			</Box>

			{!isNull(activeOverride) &&
				<Box flexGrow={1} height='100%'>
					<Box display='flex' flexDirection='row'>
						<Box display='flex' flexGrow={1} mr={1}>
							<TextField
								label='Override Name'
								placeholder='Override Name'
								variant='outlined'
								defaultValue={activeOverride?.name ?? ''}
								onChange={(evt) => debouncedsetActiveOverride({
									...activeOverride,
									name: evt.target.value
								})}
							/>
						</Box>
						<Box display='flex' flexGrow={3}>
							<TextField
								label='Override Description'
								placeholder='Override Description'
								variant='outlined'
								defaultValue={activeOverride?.description ?? ''}
								onChange={(evt) => debouncedsetActiveOverride({
									...activeOverride,
									description: evt.target.value
								})}
							/>
						</Box>
						<IconButton
							onClick={() => setShowHelp(true)}>
							<Help />
						</IconButton>
					</Box>


					<Box className='pill-toggle-box'>
						Tables:
						<PillList
							mode='toggle'
							items={tables}
							labelKey={'internalName'}
							selected={activeOverride.targetIds.map(id => tables.find(t => t.id === id)).filter((selectedTable) => {
								return selectedTable !== undefined;
							 })}
							onChange={(tables) => setActiveOverride({
								...activeOverride,
								targetIds: tables.map(table => table?.id)
							})}
						/>
					</Box>
					<hr/>

					{isEmpty(tableTags) ? null : <>
						<Box className='pill-toggle-box'>
							Table Tags:
							<PillList
								mode='toggle'
								items={tableTags}
								selected={activeOverride.targetTags}
								onChange={(tags) => setActiveOverride({
									...activeOverride,
									targetTags: tags
								})}
							/>
						</Box>
						<hr/>
					</>}

					<Box className='pill-toggle-box'>
						Weekdays:
						<PillList
							mode='toggle'
							items={allWeekdays}
							selected={activeOverride.conditions.weekdays}
							onChange={(weekdays) => setActiveOverride({
								...activeOverride,
								conditions: {
									...activeOverride.conditions,
									weekdays
								}
							})}
						/>
					</Box>
					<hr/>

					<Box className='pill-toggle-box'>
						Dates:
						<DatesPicker
							dates={activeOverride?.conditions?.dates}
							onChange={(dates) => setActiveOverride({
								...activeOverride,
								conditions: {
									...activeOverride.conditions,
									dates
								}
							})}
						/>
					</Box>

					<TimeSlotsEditor
						timeSlots={activeOverride.values?.defaultTimeSlots || {}}
						onChange={(timeSlots) => setActiveOverride({
							...activeOverride,
							values: {
								...activeOverride.values,
								defaultTimeSlots: timeSlots
							}
						})}
					/>

					<Box display='flex' flexDirection='row' alignItems='center'>
						<Box mr={2}>
							<Button
								variant='contained'
								color='primary'
								className='next'
								onClick={() => {
									// console.log(activeOverride);
									const index = overrides.findIndex(o => o.id === activeOverride.id);
									overrides[index] = activeOverride;
									onChange(overrides);
								}}
							>
								Save Override
							</Button>
						</Box>

						<Box mr={2}>
							<Button
								variant='contained'
								color='secondary'
								className='next'
								onClick={() => {
									onChange([
										...overrides.filter(override => override.id !== activeOverride.id)
									]);
									setActiveOverride(null);
								}}
							>
								Delete Override
							</Button>
						</Box>

						<Box>
							<Button
								variant='contained'
								color='default'
								className='next'
								onClick={() => {
									const newOverride = {
										...activeOverride,
										id: uuid(),
										name: `${activeOverride.name} (copy)`
									};
									onChange([
										...overrides,
										newOverride
									]);
								}}
							>
								Duplicate Override
							</Button>
						</Box>

						<Typography style={{ marginLeft: '1em' }}>
							(Remember to save the RESTAURANT as well)
						</Typography>

					</Box>
					<Modal
						open={showHelp}
						onClose={() => setShowHelp(false)}
						styleOverride={{ width: '80%', height: '80%', maxWidth: '80%' }}
						title={'Restaurant Overrides'}
					>
						<ReactMarkdown className='markdown'>{helpDocOverrides}</ReactMarkdown>
					</Modal>
				</Box>
			}
		</Box>
	);
};

export default OverridesEditor;

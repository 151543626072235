import firebase from 'firebase/app';
import 'firebase/firestore';

export default async function getReservationsForGuest(
	firestore: firebase.firestore.Firestore,
	guestEmail: string
) {


	const reservationsRef = firestore.collection('Reservations');

	// Construct the query with multiple equality conditions
	let query: firebase.firestore.Query = reservationsRef;
	query = query.where('email', '==', guestEmail).orderBy('createdAt', 'desc');

	// Execute the query
	const snapshot = await query.get();

	// Extract reservation data
	const reservations = snapshot.docs.map(doc => ({
		id: doc.id,
		...doc.data()
	}));
	reservations.map(reservation => {
		console.log(JSON.stringify(reservation));
	});

	return reservations;
}
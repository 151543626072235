import firebase from 'firebase';
import { TDR } from 'tdr-common';
import config from '../common/config';
import { DateTime } from 'luxon';



export async function approveLargeGroupBooking( firestore: firebase.firestore.Firestore, userToken, reservation: TDR.Reservation) {
	try {
		const resDoc = firestore.collection('Reservations').doc(reservation.id);

		await resDoc.update({
			...reservation,
			...reservation.pendingChanges,
			pendingChanges: null,
			largeGroupOptions: {
				largeGroupStatus: TDR.Reservation.LargeGroupStatus.Approved
			}
		});

		return { success: true };
	}

	catch (e) {
		console.error('Failed to approve booking request', { e });
		return {
			success: false,
			message: e.message
		};
	}
}

export async function denyLargeGroupBooking(firestore: firebase.firestore.Firestore, reservation: TDR.Reservation, reason: string, internalReason: TDR.InternalDenialReason) {
	try {
	 	await fetch(`${config.apiHost}/adminDenyRequest`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ reservation, reason, internalReason })
		});
		 return { success: true };
	}
	catch (e) {
		console.error('Failed to deny booking request', { e });
		return {
			success: false,
			message: e.message
		};
	}
}


export async function proposeLargeGroupModification(reservation: TDR.Reservation, newDate: DateTime, expirationHours: number) {
	try {
		const updateObj = {
			id: reservation.id,
			largeGroupOptions: {
				largeGroupStatus: TDR.Reservation.LargeGroupStatus.GuestActionRequired,
				expirationHours: expirationHours
			},
			time: firebase.firestore.Timestamp.fromDate(newDate.toJSDate())
		};
		return adminProposeLargeGroupModification(updateObj);
	}
	catch (e) {
		console.error('Failed to propose modification', { e });
		return {
			success: false,
			message: e.message
		};
	}
}

async function adminProposeLargeGroupModification(reservation: Partial<TDR.Reservation<firebase.firestore.Timestamp>>) {
	console.warn('Modifying reservation...', reservation);
	const response = await fetch(`${config.apiHost}/adminProposeLargeGroupModification`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reservation)
	});

	return response.json();
}


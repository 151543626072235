import React, { useState } from 'react';
import { List, ListItem, TextField } from '@material-ui/core';
import { getArrayFromRange } from 'tdr-common';


namespace GuestNumberSelector {
	export type Props = {
		selected: number,
		setSelected: (value: number)=>void,
		customValue: number,
		setCustomValue: (value: number)=>void,
	};
}

const GuestNumberSelector = ({ selected, setSelected, customValue, setCustomValue }: GuestNumberSelector.Props) => {
	const [editCustomPartySize, setEditCustomPartySize] = useState(false);
	const guestNumberArray = getArrayFromRange(1, 8);

	return (
		<div className='list-item-selector'>
			<List>
				{guestNumberArray.map((guestNumber) => (
					<ListItem
						key={guestNumber}
						button
						divider
						selected={guestNumber === selected}
						onClick={() => {
							setSelected(guestNumber === selected ? null : guestNumber);
							setEditCustomPartySize(false);
						}}
					>
						{guestNumber} {guestNumber > 2 ? 'Guests' : 'Guest'}
					</ListItem>
				))}
				<ListItem
					selected={selected === 9}
					style={{ padding: editCustomPartySize ? '' : '16px 24px' }}
					onClick={() => {
						setSelected(null);
						setEditCustomPartySize(true);
					}}
				>
					{editCustomPartySize
						? (
							<TextField
								type='number'
								value={customValue || ''}
								onChange={(evt) => setCustomValue(Number(evt.target.value))}
								onBlur={() => {
									setEditCustomPartySize(false);
									setSelected(9);
								}}
							/>
						)
						: (customValue ? `${customValue} Guests` : 'Enter custom party size')
					}
				</ListItem>
			</List>
		</div>
	);
};

export default GuestNumberSelector;
import React, { useContext, useRef, useReducer, CSSProperties } from 'react';
import MpSdk, { connect } from 'matterport';
import '../styles/Matterport.scss';
import toString from 'lodash/toString';
import isFunction from 'lodash/isFunction';
import config from '../common/config';
import { MatterportContext } from '../context/MatterportContext';

const MatterportArea = ({ style }: { style?: CSSProperties }) => {
	const { setSdk, modelId } = useContext(MatterportContext);

	// allow a forced refresh
	const [lastRefresh] = useReducer(() => {
		return (new Date()).getTime();
	}, (new Date()).getTime());

	const matterportFrame = useRef<HTMLIFrameElement>(null);

	return (
		<iframe
			ref={matterportFrame}
			id={`mpframe-${modelId}`}
			className='matterport'
			frameBorder='0'
			allowFullScreen
			style={style}
			allow='xr-spatial-tracking'
			src={`https://my.matterport.com/show?${new URLSearchParams({
				m: modelId,
				hr: toString(0),
				qs: toString(1),
				title: toString(0),
				play: toString(1),
				newtags: '0',
				log: '0',
				// this is the only way to force the old tags implementation https://matterport.github.io/showcase-sdk/earlyaccess_home.html#get-the-early-access-bundle-sdk
				applicationKey: config.matterport.sdkKey,
				last_refresh: toString(lastRefresh)	// change src with a harmless param to force a reload
			}).toString()}`}
			onLoad={async () => {
				let listeners: [string, () => void][] = [];

				const sdk: MpSdk = await connect(matterportFrame.current, config.matterport.sdkKey, config.matterport.sdkVersion);
				const originalOn = sdk.on;
				const originalOff = sdk.off;

				(sdk as any).on = (eventName: any, listener) => {
					listeners.push([eventName, listener]);
					originalOn(eventName, listener);
				};

				(sdk as any).off = (eventName: string, listener) => {
					const matchingIndicices: any = isFunction(listener)
						? ([en, l], i) => en === eventName && l === listener && i
						: ([en], i) => en === eventName && i;

					listeners.map(matchingIndicices)
						.filter(Boolean)
						.forEach((i: number) => originalOff(...(listeners.splice(i, 1)[0])));
				};

				(sdk as any).clearListeners = () => {
					listeners.forEach(([en, listener]) => originalOff(en, listener));
					listeners = [];
				};

				setSdk(sdk);
			}}
		/>
	);

};

export default MatterportArea;



/*
url params that can be passed to matterport (that I've identified so far)
	play - automatically start

these seem to be manual toggles for what's otherwise set through the editor settings
	dh - dollhouse
	fp - floorplan
	hr - highlight reel
	f - floors

these, I'm not sure if they're even toggles
	is_vr
	mls
	vr
	gt
	emb
	tourcta
	help
	ts
	qs
	enable_social_sharing
	perfInterval
	brand
	title
	quickstart - ??? This sounds neat
	is_public
*/
import firebase from 'firebase/app';
import 'firebase/firestore';
import { TDR } from 'tdr-common';
import realizeReservationInvoices from './realizeReservationInvoices';
import payInvoice from './payInvoice';

const fetchInvoices = async (reservationId) => {
	const snapshot = await firebase.firestore()
		.collection('Invoices')
		.where('reservationId', '==', reservationId)
		.get();

	return snapshot.docs.map((doc) => doc.data() as TDR.Invoice);
};

export async function bulkPay(restaurant: TDR.Restaurant, reservations: TDR.Reservation[], userToken: string) {
	if (!restaurant?.accountId ) {
		throw new Error('Stripe is not setup');
	}

	return Promise.all(reservations.map(async r => {
		await realizeReservationInvoices(userToken, r);
		const invoices = await fetchInvoices(r.id);

		const payouts = invoices.filter(i => i.type === 'payout' && i.status === 'pending' && i.total > 0);

		for (const invoice of payouts) {
			await payInvoice(userToken, invoice);
		}
	}));
}
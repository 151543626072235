import React, { useContext, useState } from 'react';
import getReservationsForGuest from '../../api/getReservationsForGuest';
import { FirebaseContext } from '../../context/FirebaseContext';
import ReservationList from './ReservationList';

const GuestList = ({ guests } : {guests: any[]}) => {
	const { firestore } = useContext(FirebaseContext);
	const [selectedGuest, setSelectedGuest] = useState(null);
	const [reservations, setReservations] = useState([]);
	const [loadingReservations, setLoadingReservations] = useState(false);

	const handleGuestClick = async (guest) => {
		setSelectedGuest(guest);
		setLoadingReservations(true);

		try {
			const results = await getReservationsForGuest(firestore, guest.email);
			console.log(results);
			setReservations(results);
		}
		catch (error) {
			console.error('Error fetching reservations:', error);
		}

		setLoadingReservations(false);
	};

	return (
		<div>
			<h3>Guest Results</h3>
			{guests.length === 0 ? (
				<p>No guests found.</p>
			) : (
				<ul>
					{guests.map((guest) => (
						<li key={guest.id} style={{ cursor: 'pointer' }} onClick={() => handleGuestClick(guest)}>
							{guest.firstName} {guest.lastName} - {guest.email} - {guest.phone}
						</li>
					))}
				</ul>
			)}

			{selectedGuest && (
				<div>
					<h4>Reservations for {selectedGuest.firstName} {selectedGuest.lastName}</h4>
					{loadingReservations ? (
						<p>Loading reservations...</p>
					) : (
						<ReservationList reservations={reservations} />
					)}
				</div>
			)}
		</div>
	);
};

export default GuestList;
import { Box, IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import React from 'react';

const PageNav = ({ page, setPage }: {
	page: number;
	setPage: (p: number) => void;
}) => (
	<Box
		className='page-nav'
		display='flex'
		flexDirection='column'
		justifyContent='center'
	>
		<Box>
			<IconButton
				aria-label='first-page'
				disabled={page === 0}
				onClick={() => setPage(0)}
			>
				<FirstPageIcon />
			</IconButton>

			<IconButton
				aria-label='previous-page'
				disabled={page === 0}
				onClick={() => setPage(Math.max(0, page - 1))}
			>
				<ChevronLeftIcon />
			</IconButton>

      page {page + 1}

			<IconButton aria-label='next-page' onClick={() => setPage(page + 1)}>
				<ChevronRightIcon />
			</IconButton>

			{/* a minor concession to intuition - from page 1 to page 5 is +4 */}
			<IconButton
				aria-label='skip-five-pages'
				onClick={() => setPage(page === 0 ? 4 : page + 5)}
			>
				<DoubleArrowIcon />
			</IconButton>
		</Box>
	</Box>
);

export default PageNav;

import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { TDR, toLuxon } from 'tdr-common';

const BookingDetailsCard = ({ reservation }: {reservation: TDR.Reservation}) => {
	if (!reservation) {
		return null;
	}

	return (
		<Card style={{ borderRadius: '12px', alignSelf: 'stretch' }}>
			<CardContent style={{ textAlign: 'center' }}>
				<Typography variant='body1' color='textPrimary'>{reservation.fullName}, {reservation.guests} guests</Typography>
				<Typography variant='body1' color='textPrimary'>{reservation.tableName}, {reservation.restaurantName}</Typography>
				<Typography variant='body2' color='textPrimary'>{toLuxon(reservation.time, reservation.timezone).toFormat('fff')}</Typography>
				<Typography variant='subtitle1' color='textPrimary'>Total: ${reservation.price.toFixed(2)}</Typography>
			</CardContent>
		</Card>
	);
};

export default BookingDetailsCard;
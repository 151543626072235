import { TDR } from 'tdr-common';
import firebase from 'firebase';

type LargeGroupStatus = TDR.Reservation.LargeGroupStatus;
export type ReservationQuery = firebase.firestore.Query<TDR.Reservation>;

const applyRestaurantFilter = (query: ReservationQuery, id: string | null) => {
	if (id !== 'none' && id !== null) {
		return query.where('restaurantId', '==', id);
	}
	return query;
};

const applyStatusFilter = (query: ReservationQuery, status: TDR.Reservation.Filter) => {
	if (status === TDR.Reservation.Filter.All) {
		return query;
	}
	else if (status === TDR.Reservation.Filter.Upcoming) {
		return query.where('status', 'in', [TDR.Reservation.Status.Booked, TDR.Reservation.Status.Confirmed]);
	}
	return query.where('status', '==', status);
};

const applyLargeGroupStatusFilter = (query: ReservationQuery, status: LargeGroupStatus | 'all') => {
	if (status === 'all') {
		return query;
	}
	return query.where('largeGroupOptions.largeGroupStatus', '==', status);
};

const applyLargeGroupFilter = (query: ReservationQuery) => {
	return query.where('isLargeGroup', '==', true);
};

export {
	applyRestaurantFilter,
	applyStatusFilter,
	applyLargeGroupFilter,
	applyLargeGroupStatusFilter
};
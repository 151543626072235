import firebase from 'firebase/app';
import 'firebase/firestore';

export default async function searchGuests(firestore: firebase.firestore.Firestore, searchString: string) {

	const guestsRef = firestore.collection('Guests');

	const fields = ['email', 'firstName', 'lastName', 'phone'];
	const promises = [];

	for (const field of fields) {
		if(field === 'phone') {
			const query = guestsRef
				.where(`${field}`, '>=', '+1' + searchString)
				.where(`${field}`, '<=', '+1' + searchString + '\uf8ff');

			promises.push(query.get());
		}
		else {
			const query = guestsRef
				.where(`${field}`, '>=', searchString)
				.where(`${field}`, '<=', searchString + '\uf8ff');

			promises.push(query.get());
		}
	}

	const snapshotResults = await Promise.all(promises);

	const guestsMap = new Map();

	snapshotResults.forEach(snapshot => {
		snapshot.forEach(doc => {
			if (!guestsMap.has(doc.id)) {
				guestsMap.set(doc.id, doc.data());
			}
		});
	});

	const guests = Array.from(guestsMap.entries()).map(([id, data]) => ({ id, ...data }));

	return guests;
}
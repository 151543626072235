import { Box, Container } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { TDR } from 'tdr-common';

import { AdminReservationContext } from '../context/AdminReservationContext';
import { RestaurantContext } from '../context/RestaurantContext';
import { TableContext } from '../context/TableContext';
import { ReservationQuery, applyRestaurantFilter, applyStatusFilter } from '../helpers/queryFilters';
import Modal from './Modal';
import Notice from './Notice';
import PageNav from './PageNav';
import ReservationAccordion from './ReservationAccordion';
import ReservationDetails from './ReservationDetails';
import RestaurantSelector from './RestaurantSelector';
import SetStatusButtons from './SetStatusButtons';
import StatusFilterReservation from './StatusFilterReservation';

const AdminReservations = () => {
	const { getTablesByRestaurant, getTableById } = useContext(TableContext);
	const { restaurants, selectedRestaurantId } = useContext(RestaurantContext);
	const { reservations, setQueryFilterFn, page, setPage } = useContext(AdminReservationContext);

	const [filter, setFilter] = useState(TDR.Reservation.Filter.All);
	const [selectedReservation, setSelectedReservation] = useState<TDR.Reservation>(null);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [tables, setTables] = useState<TDR.Table[] | null>(null);

	// MODALS
	const [isModifyStatusOpen, setIsModifyStatusOpen] = useState(false);

	const getRestaurant = (id: string) => (restaurants || []).find(restaurant => restaurant.id === id) ?? { name: 'legacy' } as TDR.Restaurant;
	const getTable = (id: string) => tables?.find(table => {
		return table?.id === id;
	});

	useEffect(() => {
		if(!(reservations?.length > 0)) {
			setTables([]);
		}
		else if (selectedRestaurantId && selectedRestaurantId !== 'none') {
			getTablesByRestaurant(selectedRestaurantId).then(tables => {
				setTables(tables);
			});
		}
		else {
			const tableSet = new Set<string>();
			reservations.forEach(r => {
				tableSet.add(r.tableId);
			});
			Promise.all(Array.from(tableSet)?.map(getTableById) ?? []).then((maybeTables: TDR.Table[] | null) => {
				setTables(maybeTables);
			});
		}
	}, [selectedRestaurantId, reservations]);

	const handleCloseModifyStatus = () => {
		setSelectedReservation(null);
		setIsModifyStatusOpen(false);
	};

	useEffect(() => {
		const queryFilterFn = (query: ReservationQuery) =>
			applyStatusFilter(
				applyRestaurantFilter(query, selectedRestaurantId),
				filter
			);

		setQueryFilterFn(queryFilterFn);

	}, [selectedRestaurantId, filter]);

	return (
		<Container maxWidth='xl' id='reservation-admin-panel'>
			<Box py={2} marginTop={'32px'}>
				<Box display='flex' justifyContent="space-between" width='100%'>
					<Box display='flex'>
						<h2>Reservations</h2>
					</Box>

					<Box display='flex' flexGrow={1} justifyContent='center'>
						<PageNav page={page} setPage={setPage} />
					</Box>

					<Box display='flex'>
						<RestaurantSelector allRestaurantsSelection={true} />
						<StatusFilterReservation filter={filter} setFilter={setFilter} />
					</Box>
				</Box>

				{errorMessage && (
					<Notice message={errorMessage} onClear={() => setErrorMessage('')} />
				)}

				{tables && reservations && reservations.filter(res => !res.isLargeGroup).map((res) => (
					<ReservationAccordion
						key={res.id}
						reservation={res}
						restaurant={getRestaurant(res.restaurantId)}
						table={getTable(res.tableId)}
						setSelectedReservation={setSelectedReservation}
						setIsModifyStatusOpen={setIsModifyStatusOpen}
					/>
				))}

				<Box display='flex' flexGrow={1} justifyContent='center' width='100%'>
					<PageNav page={page} setPage={setPage} />
				</Box>

				{selectedReservation && (
					<Modal
						open={isModifyStatusOpen}
						onClose={handleCloseModifyStatus}
					>
						<ReservationDetails
							reservation={selectedReservation}
							restaurant={getRestaurant(selectedReservation?.restaurantId)}
							table={getTable(selectedReservation?.tableId)}
						/>

						<SetStatusButtons
							reservation={selectedReservation}
							close={handleCloseModifyStatus}
						/>
					</Modal>
				)}
			</Box>
		</Container>
	);
};

export default AdminReservations;

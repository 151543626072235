import React, { useState, useEffect } from 'react';
import Reservation from './Reservation';
import firebase from 'firebase';

import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TDR } from 'tdr-common';

namespace ReservationList {
	export type Props = {
		reservations: Partial<TDR.Reservation<firebase.firestore.Timestamp>>[],
		group: TDR.Reservation.Group;
	};

	export type State = {
		totalGuestCount: number
	};
}

const GroupedReservations = ({ reservations, group }: ReservationList.Props) => {
	const [totalGuestCount, setTotalGuestCount] = useState(0);

	const numberOfReservations = reservations.length;

	useEffect(() => {
		setTotalGuestCount(reservations.reduce((totalGuestCount, reservation) => totalGuestCount += reservation.guests, 0));
	}, [reservations]);

	return (
		<Accordion className='reservation-list' defaultExpanded={true}>
			<AccordionSummary className='dropdown-bar' expandIcon={<ExpandMoreIcon fontSize='large'/>}>
				<div className='status-text'>{group}</div>
				<div className='res-counts'>
					<PeopleIcon className='people-icon' fontSize='small'/>
					<div className='people-text'> {numberOfReservations}</div>
					<PersonIcon className='person-icon' fontSize='small'/>
					<div className='person-text'>{totalGuestCount}</div>
				</div>
			</AccordionSummary>
			<AccordionDetails className='accordian-content'>
				{reservations.map((reservation) => <Reservation key={reservation.id} reservation={reservation as TDR.Reservation}/>)}
			</AccordionDetails>
		</Accordion>
	);
};

export default GroupedReservations;
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';
import { TDR } from 'tdr-common';

type StatusFilterLargeGroupProps = {
	filter: TDR.Reservation.LargeGroupStatus | 'all';
	setFilter: React.Dispatch<React.SetStateAction<TDR.Reservation.LargeGroupStatus | 'all'>>;
};

const { AdminActionRequired, GuestActionRequired, Approved, Denied } = TDR.Reservation.LargeGroupStatus;

const StatusFilterLargeGroup = ({
	filter,
	setFilter
}: StatusFilterLargeGroupProps) => {
	return (
		<FormControl variant='filled' className='select-filter'>
			<InputLabel id="request-status-filter">Request Status</InputLabel>
			<Select
				labelId='request-status-filter'
				value={filter}
				onChange={(evt) => setFilter(evt.target.value as TDR.Reservation.LargeGroupStatus || 'all')}
				IconComponent={(props) => <Box ml={1}><KeyboardArrowDownIcon {...props} style={{ color: 'rgba(255, 255, 255, 0.87)' }} /></Box>}
			>
				<MenuItem value={'all'}>All</MenuItem>
				<MenuItem value={AdminActionRequired}>Admin action required</MenuItem>
				<MenuItem value={GuestActionRequired}>Guest action required</MenuItem>
				<MenuItem value={Approved}>Approved</MenuItem>
				<MenuItem value={Denied}>Denied</MenuItem>
			</Select>
		</FormControl>

	);
};

export default StatusFilterLargeGroup;

import './styles/app.scss';
import './styles/admin.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import config from './common/config';
import { URLProvider } from './context/URLContext';
import { FirebaseProvider } from './context/FirebaseContext';
import { RestaurantProvider } from './context/RestaurantContext';
import { TableProvider } from './context/TableContext';
import { ReservationProvider } from './context/ModifyReservationContext';
import { AdminReservationProvider } from './context/AdminReservationContext';
import { AdminRestaurantProvider } from './context/AdminRestaurantContext';
import { GuestProvider } from './context/GuestContext';
import { ModalProvider } from './context/ModalContext';

import AdminApp from './AdminApp';
import { PricingPolicyProvider } from './context/PricingPolicyContext';


if (config.sentry.enable) {
	Sentry.init({
		...config.sentry,
		release: process.env.RELEASE_VERSION
	});
}

const appContainer = document.createElement('div');
appContainer.id = 'admin-app';

document.body.appendChild(appContainer);


ReactDOM.render(
	<URLProvider>
		<FirebaseProvider>
			<RestaurantProvider>
				<TableProvider>
					<ReservationProvider>
						<AdminRestaurantProvider>
							<PricingPolicyProvider>
								<AdminReservationProvider>
									<GuestProvider>
										<ModalProvider>
											<AdminApp />
										</ModalProvider>
									</GuestProvider>
								</AdminReservationProvider>
							</PricingPolicyProvider>
						</AdminRestaurantProvider>
					</ReservationProvider>
				</TableProvider>
			</RestaurantProvider>
		</FirebaseProvider>
	</URLProvider>
	, appContainer);
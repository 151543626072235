import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { AdminReservationContext } from '../context/AdminReservationContext';
import Modal from './Modal';
import { ModalProps } from './ReservationAccordion';

const ModalEditGuest = ({
	open,
	onClose,
	reservation
}: ModalProps & {
	reservation: any;
}) => {
	const { updateReservation } = useContext(AdminReservationContext);
	const [fullName, setFullName] = useState(reservation.fullName || '');
	const [email, setEmail] = useState(reservation.email || '');
	const [phone, setPhone] = useState(reservation.phone || '');
	const [hasEdits, setHasEdits] = useState(false);

	const [saving, setSaving] = useState(false);

	useEffect(() => {
		const isChanged: boolean =
      fullName !== reservation.fullName ||
      phone !== reservation.phone ||
      email !== reservation.email;

		const notEmpty: boolean = fullName && phone && email;

		setHasEdits(isChanged && notEmpty);
	}, [fullName, email, phone]);

	const resetForm = () => {
		setFullName(reservation.fullName || '');
		setEmail(reservation.email || '');
		setPhone(reservation.phone || '');
	};

	return (
		<Modal
			open={open}
			onClose={() => {
				resetForm();
				onClose();
			}}
		>
			<Typography variant='h6'>Edit Reservation Guest</Typography>
			<div className='input-row'>
				<TextField
					placeholder='Full Name'
					variant='outlined'
					value={fullName}
					onChange={(evt) => setFullName(evt.target.value)}
				/>
			</div>
			<div className='input-row'>
				<TextField
					placeholder='Email'
					variant='outlined'
					value={email}
					onChange={(evt) => setEmail(evt.target.value)}
				/>
			</div>
			<div className='input-row'>
				<TextField
					placeholder='Mobile Phone Number'
					variant='outlined'
					value={phone}
					onChange={(evt) => setPhone(evt.target.value)}
				/>
			</div>
			<Button
				variant='contained'
				color='primary'
				disabled={saving || !hasEdits}
				onClick={() => {
					setSaving(true);
					return updateReservation(reservation.id, { fullName, email, phone })
						.then(() => {
							setSaving(false);
							onClose();
						})
						.catch((err) => {
							setSaving(false);
							console.warn('ERROR', err);
						});
				}}
			>
				{saving && (
					<>
						<CircularProgress size={14} color='secondary' />
            &nbsp;
					</>
				)}{' '}
        Save
			</Button>
		</Modal>
	);
};

export default ModalEditGuest;
import firebase from 'firebase';
import { TDR, querySnapshotToArray } from 'tdr-common';

export default async function getRestaurantFeatureFlags (firestore: firebase.firestore.Firestore, restaurantId: string): Promise<TDR.FeatureFlags> {
	const featureFlags = querySnapshotToArray(
		await firestore.collection('FeatureFlags')
			.where('restaurantId', '==', restaurantId)
			.limit(1)
			.get()
	)?.[0];
	return featureFlags;
}

import config from '../common/config';
import { TDR } from 'tdr-common';


type PolicyResponse = {
	success: boolean,
	result?: TDR.PricingPolicy,
	message?: string,
	debug?: {
		id?: string,
		policy?: TDR.PricingPolicy,
		update?: TDR.PricingPolicy,
		prevPolicyData?: TDR.PricingPolicy,
		newestVariant?: TDR.PricingPolicy,
		// catchall
		[s: string]: any
	}
}

function policyEndpoint(token: string, method: string, body: any, params?: { [k: string]: string }) {

	const encodedParams = (params
		? `?${new URLSearchParams(params).toString()}`
		: '');

	return fetch(`${config.apiHost}/pricingPolicies${encodedParams}`, {
		method,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: JSON.stringify(body)
	}).then(
		res => res.json() as Promise<PolicyResponse>
	).then(res => {
		console.log('PricingPolicy response', res);
		return res;
	});
}

export function createPolicy(token: string, policy: Partial<TDR.PricingPolicy>) {
	return policyEndpoint(token, 'POST', policy);
}

export function updatePolicy(token: string, policy: Partial<TDR.PricingPolicy>) {
	return policyEndpoint(token, 'PUT', policy);
}

export function retirePolicy(token: string, policy: Partial<TDR.PricingPolicy>) {
	return policyEndpoint(token, 'DELETE', policy);
}

export function exhumePolicy(token: string, policy: Partial<TDR.PricingPolicy>) {
	return policyEndpoint(token, 'DELETE', policy, { exhume: 'true' });
}

import React, { useContext } from 'react';
import { Typography, Button, Divider } from '@material-ui/core';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { ModifyReservationContext } from '../context/ModifyReservationContext';
import { TDR, formatMonetaryValue } from 'tdr-common';

namespace PaymentInformation {
	export type Props = {
		reservation: Partial<TDR.Reservation>,
		onProceed: ()=>any,
		onCancel: ()=>any,
		restaurant: TDR.Restaurant
	};
}

const PaymentInformation = ({ onProceed, onCancel, reservation, restaurant }: PaymentInformation.Props) => {
	const { reservationModifications } = useContext(ModifyReservationContext);

	return (
		<>
			<div className='super-admin-timeslot-modification'>
				<Typography variant='body1'>Note: Avoid having to put in payment information for the customer and instead try to walk them through the B2C modification flow.</Typography>
				<Typography variant='h6'>Payment Information</Typography>
				<div className='card-element-container' >
					<CardNumberElement
						options={{
							placeholder: 'Card Number',
							style: {
								base: {
									fontSize: '16px',
									color: 'white'
								},
								invalid: {
									color: '#F44336'
								}
							}
						}}
					/>
				</div>
				<div className='input-row'>
					<div className='input-row-item'>
						<div className='card-element-container'>
							<CardCvcElement
								options={{
									placeholder: 'CVC',
									style: {
										base: {
											fontSize: '16px',
											color: 'white'
										},
										invalid: {
											color: '#F44336'
										}
									}
								}}/>
						</div>
					</div>
					<div className='input-row-item'>
						<div className='card-element-container'>
							<CardExpiryElement
								options={{
									placeholder: 'MM/YY',
									style: {
										base: {
											fontSize: '16px',
											color: 'white'
										},
										invalid: {
											color: '#F44336'
										}
									}
								}}/>
						</div>
					</div>
				</div>
				<div className='payment-summary'>
					<Typography variant='h6'>Summary</Typography>
					<div className='summary-item'>
						<Typography variant='body1'><strong>New Charge:</strong> {formatMonetaryValue(reservationModifications.price, restaurant?.currency)}</Typography>
					</div>
					<div className='summary-item'>
						<Typography variant='body1'><strong>Old Charge:</strong> {formatMonetaryValue(reservation.price || 0, restaurant?.currency)}</Typography>
					</div>
					<Divider/>
					<Typography variant='body1' style={{ fontSize: '1.5em' }}><strong>Total:</strong> {formatMonetaryValue(reservationModifications.price - reservation.price, restaurant?.currency)}</Typography>
				</div>
				<Button
					variant='contained'
					color='primary'
					data-cy='proceed-btn'
					onClick={onProceed}
				>Proceed</Button>
				<Button
					variant='contained'
					color='secondary'
					onClick={onCancel}
				>
					Cancel
				</Button>
			</div>
		</>
	);
};

export default PaymentInformation;
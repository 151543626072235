import { Typography } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PersonIcon from '@material-ui/icons/Person';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React from 'react';
import { capitalize, formatTimeFromJSDate, MONTHS, TDR } from 'tdr-common';
import { TableIcon } from './CustomIcons';

namespace ReservationInformation {
	export type Props = {
		selectedReservation: TDR.Reservation
	};
}

const ReservationInformation = ({ selectedReservation }: ReservationInformation.Props) => {
	const { status, tableName, time, guests } = selectedReservation;

	const iconStyle = {
		flex: 1,
		width: 20,
		height: 20
	};
	// TODO: It looks like the labels have been updated.
	// Make sure to check these.
	const statusObject = {
		'booked': {
			icon: <CheckCircleIcon style={iconStyle}/>,
			color: ''
		},
		'confirmed': {
			icon: <CheckCircleIcon style={iconStyle}/>,
			color: ''
		},
		'reminded': {
			icon: <CheckCircleIcon style={iconStyle}/>,
			color: ''
		},
		'completed': {
			icon: <CheckCircleIcon style={iconStyle}/>,
			color: ''
		},
		'no-show': {
			icon: <RemoveCircleIcon style={iconStyle}/>,
			color: ''
		},
		'cancelled': {
			icon: <CancelIcon style={{ color: '#C16C7A', ...iconStyle }}/>,
			color: '#C16C7A'
		}
	};

	const date = time.toDate();
	const dateString = `${MONTHS[date.getMonth()].shortName} ${date.getDate()}`;
	const timeString = formatTimeFromJSDate(date);

	return (
		<div className='callout-container'>
			<div className='callout'>
				{statusObject[status].icon}
				<Typography variant='body2' style={{ flex: 1, color: statusObject[status].color }}>{capitalize(status)}</Typography>
			</div>
			<div className='callout'>
				<TableIcon style={iconStyle}/>
				<Typography variant='body2' style={{ flex: 1, color: statusObject[status].color }}>{tableName}</Typography>
			</div>

			<div className='callout'>
				<CalendarTodayIcon style={iconStyle}/>
				<Typography variant='body2' style={{ flex: 1 }}>{dateString}</Typography>
			</div>
			<div className='callout'>
				<ScheduleIcon style={iconStyle}/>
				<Typography variant='body2' style={{ flex: 1 }}>{timeString}</Typography>
			</div>
			<div className='callout'>
				<PersonIcon style={iconStyle}/>
				<Typography variant='body2' style={{ flex: 1 }}>{guests} Guests</Typography>
			</div>
		</div>
	);
};

export default ReservationInformation;
import { Box, Button, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import { TDR } from 'tdr-common';
import BookingDetailsCard from './BookingDetailsCard';

type AdminActionErrorProps = {
	title: React.ReactNode;
	reservation: TDR.Reservation;
	error?: string;
	onClose: () => void;
}
const AdminActionError = ({ title, error, reservation, onClose }: AdminActionErrorProps) => {
	return (
		<>
			<Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
				<Typography variant='h6'>Oops!</Typography>

				<ErrorIcon style={{ color: 'rgb(183, 29, 24)', width: '88px', height: '88px' }} />

				<Typography variant='body1' color="textPrimary" align='center'>
					{title}
				</Typography>

				<BookingDetailsCard reservation={reservation} />

				{!!error && (
					<Typography variant='body1' style={{ color: 'rgb(183, 29, 24)' }}>{error}</Typography>
				)}

				<Typography variant='body1' color="textPrimary">
          ID: <b>{reservation.id}</b>
				</Typography>
			</Box>

			<Button onClick={onClose}>Close</Button>
		</>
	);
};

export default AdminActionError;

import React, { useState, useContext } from 'react';
import { Typography, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { TDR } from 'tdr-common';
import ChatIcon from '@material-ui/icons/Chat';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ModalControlledTextField from './ModalControlledTextField';
import { AdminReservationContext } from '../context/AdminReservationContext';

namespace PastVisitNotes {
	export type Props = {
		pastGuestReservations: TDR.Reservation[]
	};
}

const PastVisitNotes = ({ pastGuestReservations }: PastVisitNotes.Props) => {
	const { updateReservation } = useContext(AdminReservationContext);
	const [hiddenNotesById, setHiddenNotesById] = useState({});

	return (
		<div className='past-visit-notes'>
			<Typography variant='subtitle1'>Past Visit Notes</Typography>
			{pastGuestReservations.map((reservation) => {
				const { id, guestNotes, notesByRestaurant, time } = reservation;
				const date = time.toDate();
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const day = date.getDate();
				const dateString = `${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${year.toString().slice(-2)}`;
				const isHidden = hiddenNotesById[id];

				return (
					<div key={id} className='notes'>
						<div className='common-header clickable' onClick={() => setHiddenNotesById({ ...hiddenNotesById, [id]: !isHidden })}>
							<Typography variant='subtitle1'>{dateString}</Typography>
							<IconButton aria-label='expand' style={{ paddingRight: 0 }}>
								{isHidden ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
							</IconButton>
						</div>
						{!isHidden && (
							<div>
								<TextField
									className='display-only-area'
									disabled={true}
									multiline={true}
									rows={3}
									value={guestNotes}
									variant='outlined'
									InputProps={{
										startAdornment: (
											<InputAdornment position='start' style={{ marginBottom: 40 }}>
												<ChatIcon />
											</InputAdornment>
										)
									}}
								/>
								<ModalControlledTextField
									value={notesByRestaurant}
									onDelete={()=> updateReservation(reservation.id, { notesByRestaurant: '' })}
									onSave={(notes)=> updateReservation(reservation.id, { notesByRestaurant: notes })}
								/>
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default PastVisitNotes;
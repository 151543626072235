import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { TDR } from 'tdr-common';
import { approveLargeGroupBooking } from '../api/largeGroup';
import { FirebaseContext } from '../context/FirebaseContext';
import AdminActionError from './AdminActionError';
import AdminActionSuccess from './AdminActionSuccess';
import BookingDetailsCard from './BookingDetailsCard';
import Modal from './Modal';
import { ModalProps } from './ReservationAccordion';

export type LargeGroupActionProps = {
	reservation: TDR.Reservation,
}

export enum AdminActionStep {
	INITIAL = 'initial',
	SUCCESS = 'success',
	ERROR = 'error'
}

const ModalConfirmApproval = ({ open, onClose, reservation }: ModalProps & LargeGroupActionProps ) => {
	const { firestore, token } = useContext(FirebaseContext);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [step, setStep] = useState(AdminActionStep.INITIAL);

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		setLoading(true);

		approveLargeGroupBooking(firestore, token, reservation).then(r => {
			setLoading(false);

			if(r.success) {
				setStep(AdminActionStep.SUCCESS);
			}
			else {
				setError(r.message);
				setStep(AdminActionStep.ERROR);
			}
		});
	};

	// when modal opens, reset step
	useEffect(() => {
		if (open) {
			setStep(AdminActionStep.INITIAL);
		}
	}, [open]);

	const components = {
		[AdminActionStep.INITIAL]: (
			<>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '16px'
					}}
				>
					<Typography variant='h6' align='center'>
            Approve Booking Request
					</Typography>

					<Typography color='textPrimary' variant='body1' align='center'>
            Approving this request will notify the guest that their booking is
            confirmed and <b>their credit card will be charged.</b>
					</Typography>
					{reservation.pendingChanges && 			<Typography variant='body1' align='center'>
          			Original Booking:
					</Typography>}
					<BookingDetailsCard reservation={reservation} />
					{reservation.pendingChanges &&
						<>
							<Typography variant='body1' align='center'>
          			Requested Booking:
							</Typography>
							<BookingDetailsCard reservation={{ ...reservation, ...reservation.pendingChanges }}/>
						</>
					}
				</Box>

				<Button variant='contained' disabled={loading} onClick={handleSubmit}>
					{loading ? <CircularProgress /> : 'Approve'}
				</Button>

				<Button onClick={onClose} disabled={loading}>
          Cancel
				</Button>
			</>
		),
		[AdminActionStep.SUCCESS]: (
			<AdminActionSuccess
				title={<>Successfully approved booking request.</>}
				reservation={reservation}
				onClose={onClose}
			/>
		),
		[AdminActionStep.ERROR]: (
			<AdminActionError
				title={<>There was a problem approving this request. The guest <b>has not been charged.</b></>}
				error={error}
				reservation={reservation}
				onClose={onClose}
			/>
		)
	};

	return (
		<Modal open={open} onClose={onClose}>
			{components[step]}
		</Modal>
	);
};

export default ModalConfirmApproval;
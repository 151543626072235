import React from 'react';
import { Button } from '@material-ui/core';


namespace Pill {
	export type Props = {
		children?: any,
		className?: string,
		color?: 'default' | 'inherit' | 'primary' | 'secondary',
		styleOverride?: any,
		selected?: boolean,
		onClick?: (()=>void)
	};
}

const Pill = ({ children, className = '', color = 'secondary', selected = false, onClick = () => {}, styleOverride = {} }: Pill.Props) => {
	return (
		<Button
			className={`pill ${className}`}
			style={{
				color: selected ? '#7FB5A8' : '#FFFFFF',
				border: `1px solid ${selected ? '#7FB5A8' : 'rgba(255, 255, 255, 0.12)'}`,
				...styleOverride
			}}
			variant='contained'
			color={color}
			onClick={onClick}
		>
			{children}
		</Button>
	);
};

export default Pill;
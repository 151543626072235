import firebase from 'firebase';
import { TDR, querySnapshotToArray } from 'tdr-common';
import { v4 as uuid } from 'uuid';

export default async function getLargeGroupSettings (firestore: firebase.firestore.Firestore, tableId: string): Promise<TDR.LargeGroupSettings> {
	const settings = querySnapshotToArray(
		await firestore.collection('LargeGroupSettings')
			.where('tableId', '==', tableId)
			.limit(1)
			.get()
	)?.[0];
	if(!settings){
		return {
			id: uuid(),
			tableId: tableId
		};
	}
	return settings;
}
import { TDR } from 'tdr-common';
import config from '../common/config';

export default async function refundInvoice(userToken:string, invoice:TDR.Invoice, subtotalAmount: number, depositAmount: number) {
	return fetch(`${config.apiHost}/refundInvoice`, {
		method: 'POST',
		headers: { 'Authorization': `Bearer ${userToken}` ,
			'Content-Type': 'application/json; charset=UTF-8' },
		body: JSON.stringify({ invoice, subtotalAmount, depositAmount })
	})
		.then(async response => response.text())
		.then(text => {
			try {
				return JSON.parse(text);
			}
			catch (err) {
				return text;
			}
		});
}

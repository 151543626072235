import { createTheme } from '@material-ui/core/styles';

const defaultTheme = createTheme();
const TDRTheme = createTheme({
	palette: {
		type: 'dark',
		primary: {
			main: '#7FB5A8',
			contrastText: 'rgba(255, 255, 255, 0.87)'
		},
		secondary: {
			main: '#333333',
			contrastText: 'rgba(255, 255, 255, 0.87)'
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
		background: {
			default: '#121212'
		},
		text: {
			primary: 'rgba(255, 255, 255, 0.87)',
			secondary: 'rgba(255, 255, 255, 0.87)'
		},
		action: {
			disabledBackground: '#6E6E6E',
			disabled: 'rgba(255, 255, 255, 0.38)'
		}
	},
	typography: {
		fontFamily: [
			'Source Sans Pro'
		].join(',')
	},
	shape: {
		borderRadius: 2
	},
	overrides: {
		MuiSwitch:{
			switchBase: {
				'&$checked': {
					color: 'rgba(255, 255, 255, 0.87)!important'
				}
			}
		},
		MuiLink: {
			root: {
				color: 'rgba(255, 255, 255, 0.6)',
				cursor: 'pointer'
			}
		},
		MuiButton: {
			root: {
				fontSize: '0.875em',
				fontWeight: 600,
				width: '100%',
				height: '56px',
				textTransform: 'uppercase',
				margin: '0.625em 0',
				[defaultTheme.breakpoints.up('md')]: {
					height: '56px'
					// width: '384px'
				}
			},
			outlined: {
				border: '1px solid rgba(255, 255, 255, 0.6)'
			}
		},
		MuiTabs: {
			indicator : {
				backgroundColor: '#7FB5A8'
			}
		},
		MuiTab: {
			root: {
				'&$selected': {
					color: '#7FB5A8'
				}
			}
		},
		MuiTypography: {
			h1: {
				fontFamily: 'Source Serif Pro',
				fontSize: '3.5em',
				[defaultTheme.breakpoints.up('md')]: {
					fontSize: '4.25em'
				}
			},
			h2: {
				fontFamily: 'Source Serif Pro',
				fontSize: '2.75em',
				[defaultTheme.breakpoints.up('md')]: {
					fontSize: '3.25em'
				}
			},
			h3: {
				fontFamily: 'Source Serif Pro',
				fontSize: '2.25em',
				[defaultTheme.breakpoints.up('md')]: {
					fontSize: '2.75em'
				}
			},
			h4: {
				fontSize: '2em',
				[defaultTheme.breakpoints.up('md')]: {
					fontSize: '2.5em'
				}
			},
			h5: {
				fontFamily: 'Source Serif Pro',
				fontSize: '1.75em',
				[defaultTheme.breakpoints.up('md')]: {
					fontSize: '2.25em'
				}
			},
			h6: {
				fontFamily: 'Source Serif Pro',
				fontSize: '1.375em',
				[defaultTheme.breakpoints.up('md')]: {
					fontSize: '2em'
				}
			},
			subtitle1: {
				fontFamily: 'Source Serif Pro',
				fontSize: '1.0625em',
				[defaultTheme.breakpoints.up('md')]: {
					fontSize: '1.375em'
				}
			},
			body1: {
				fontSize: '1em',
				color: 'rgba(255, 255, 255, 0.6)',
				[defaultTheme.breakpoints.up('md')]: {
					fontSize: '1.125em',
					lineHeight: '135%'
				}
			},
			body2: {
				color: 'rgba(255, 255, 255, 0.6)',
				fontSize: '0.875em',
				fontWeight: 400,
				fontStyle: 'normal',
				[defaultTheme.breakpoints.up('md')]: {
					fontSize: '1em'
				}
			}
		},
		MuiSvgIcon: {
			root: {
				color: 'rgba(255, 255, 255, 0.87)',
				marginRight: '0.3125em',
				marginBottom: '-0.125em'
			}
		},
		MuiDivider: {
			root: {
				backgroundColor: '#6E6E6E'
			},
			vertical: {
				backgroundColor: '#6E6E6E'
			},
			middle: {
				margin: '1.5em 0',
				marginLeft: 0,
				marginRight: 0
			}
		},
		MuiTextField: {
			root: {
				width: '100%',
				margin: '10px 0',
				color: 'rgba(255, 255, 255, 0.87)',
				backgroundColor: '#333333',
				borderRadius: 2
			}
		},
		MuiToolbar: {
			root: {
				backgroundColor: '#121212'
			}
		},
		MuiPaper: {
			root: {
				backgroundColor: '#121212',
				borderRadius: 2
			}
		},
		MuiMenu: {
			paper: {
				background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212'
			}
		},
		MuiList: {
			root: {
				background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212',
				boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
				borderRadius: '2px'
			},
			padding: {
				paddingTop: 0,
				paddingBottom: 0
			}
		},
		MuiListItem: {
			root: {
				'&$selected': {
					backgroundColor: '#7FB5A8',
					'&:hover': {
						backgroundColor: '#7FB5A8'
					}
				},
				'&$disabled': {
					backgroundClip: 'unset',
					backgroundColor: 'rgba(255, 255, 255, 0.38)',
					'&:hover': {
						backgroundColor: 'rgba(255, 255, 255, 0.38)'
					}
				}
			},
			button: {
				padding: '16px 24px'
			},
			divider: {
				borderBottom: '0.75px solid rgba(255, 255, 255, 0.12)'
			}
		},
		// @ts-ignore
		MuiPickersBasePicker: {
			pickerView: {
				background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
				// TODO: Need to target the more specific screen size, for really small phones
				// we need to set it a max, or else it breaks the screen
				[defaultTheme.breakpoints.up('xs')]: {
					// minWidth: 0,
					maxWidth: '100%'
				},
				[defaultTheme.breakpoints.up('md')]: {
					minWidth: 380
				}
			}
		},
		MuiPickersStaticWrapper: {
			staticWrapperRoot: {
				minWidth: '100%',
				backgroundColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
				borderRadius: 2,
				width: ''
			}
		},
		MuiPickersCalendarHeader: {
			switchHeader: {
				[defaultTheme.breakpoints.only('sm')]: {
					width: '50%',
					transform: 'translate(50%)'
				}
			},
			daysHeader: {
				justifyContent: 'space-evenly',
				[defaultTheme.breakpoints.only('sm')]: {
					width: '50%',
					transform: 'translate(50%)'
				}
			},
			dayLabel: {
				color: 'white'
			},
			transitionContainer: {
				height: 12,
				overflow: 'visible'
			},
			iconButton: {
				backgroundColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212'
			}
		},
		MuiPickersCalendar: {
			transitionContainer: {
				[defaultTheme.breakpoints.only('sm')]: {
					width: '50%',
					transform: 'translate(50%)'
				}
			},
			week: {
				justifyContent: 'space-evenly'
			}
		},
		MuiPickersDay: {
			day: {
				color: 'rgba(255, 255, 255, 0.6)',
				fontSize: '0.875em'
			},
			dayDisabled: {
				color: 'rgba(255, 255, 255, 0.38)'
			},
			daySelected: {
				border: '2px solid #7FB5A8',
				backgroundColor: 'rgba(255, 255, 255, 0.1)',
				'&:hover': {
					backgroundColor: 'rgba(255, 255, 255, 0.1)'
				}
			},
			current: {
				color: 'rgba(255, 255, 255, 0.6)',
				backgroundColor: 'rgba(255, 255, 255, 0.1)'
			}

		}
	}
});


export default TDRTheme;
import React, { createContext, useState } from 'react';
import noop from 'lodash/noop';
import { ProviderProps } from '../common/ProviderProps';

export namespace ModalContext {
	export type Value = {
		openModal: (modal: JSX.Element) => void,
		closeModal: () => void,
		modal: JSX.Element,
		modalOpen: boolean
	};
}

export const ModalContext = createContext<ModalContext.Value>({
	openModal: noop,
	closeModal: noop,
	modal: null,
	modalOpen: false
});
ModalContext.displayName = 'Modal';

export const ModalProvider = ({ children }: ProviderProps) => {
	const [modal, setModal] = useState<JSX.Element>(null);
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const value: ModalContext.Value = {
		openModal: (modal: JSX.Element) => {
			setModalOpen(true);
			setModal(modal);
		},
		closeModal: () => {
			setModalOpen(false);
			setTimeout(() => {
				setModal(null);
			}, 225);
		},
		modal: modal,
		modalOpen: modalOpen
	};

	return (
		<ModalContext.Provider value={value}>
			{children}
		</ModalContext.Provider>
	);
};

import firebase from 'firebase/app';
import 'firebase/firestore';
import { v4 as uuid } from 'uuid';
import { Guest } from '../context/GuestContext';

export default async function createGuestAndUser (firestore: firebase.firestore.Firestore, guestAndUserData: Guest) {
	const { fullName, phoneNumber, email, restaurantId, notes } = guestAndUserData;
	const now = Date.now();

	const userId = uuid();
	const userData = {
		id: userId,
		fullName,
		phoneNumber,
		email,
		sessionTime: now,
		createdTime: now,
		updatedTime: now
	};

	await firestore
		.collection('Users')
		.doc(userId)
		.set(userData);

	const guestId = uuid();
	const guestData = {
		id: guestId,
		userId,
		restaurantId,
		notes: notes || ''
	};
	await firestore
		.collection('Guests')
		.doc(guestId)
		.set(guestData);

	return {
		...userData,
		...guestData
	};
}
import {
	Box, IconButton, Menu, TextField
} from '@material-ui/core';
import {
	AddCircle as AddIcon,
	NotInterested as NotInterestedIcon
} from '@material-ui/icons';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import { DateRange as DateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DATESTR_FMT } from 'tdr-common';
import PillList from './PillList';
import { FirebaseContext } from '../context/FirebaseContext';
import { enUS } from 'date-fns/locale';

namespace DatesPicker {
	export type Props = {
		dates: any[],
		onChange: (dates: string[]) => void
		reload?: () => void,
		allRestaurants?: boolean
	};
}

type DateRange = {
	startDate: Date,
	endDate: Date
};

const format = (dateStr) => {
	const date = DateTime.fromFormat(dateStr, 'MMMM dd, yyyy');
	return date.isValid && date.toFormat(DATESTR_FMT);
};

const DatesPicker = ({ dates, onChange, reload, allRestaurants = false }: DatesPicker.Props) => {
	const sortDates = (a: string, b: string) => {
		return (new Date(a)).getTime() - (new Date(b)).getTime();
	};

	const [dateRange, setDateRange] = useState<DateRange>({
		startDate: new Date(),
		endDate: new Date()
	});

	const [menuAnchorEl, setMenuAnchorEl] = useState<Element>(null);
	const [loading, setLoading] = useState(false);
	const { firestore } = useContext(FirebaseContext);

	const dateList = dates ? dates.map((date) => {
		return{
			label: typeof date === 'string' ? date : date.date,
			description: date.description || ''
		};
	}) : [];

	const batchUpdate = async (all: boolean, scope?: string) => {
		setLoading(true);
		const startTime = dateRange.startDate.getTime();
		const endTime = dateRange.endDate.getTime();
		const datesInRange = [];
		console.log('loading');

		for (let ts = startTime; ts <= endTime; ts += 86400000) {
			datesInRange.push(DateTime.fromMillis(ts).toFormat('MMMM dd, yyyy'));
		}

		const collectionRef = firestore.collection('Restaurants');
		const query = all ? collectionRef : collectionRef.where('stripeRegion', '==', scope);
		const documents = await query.get();
		const writeBatch = firestore.batch();

		for (const doc of documents.docs) {
			const restaurant = doc.data();
			const uniqueClosedDates = uniq([
				...restaurant.closed.dates.map((obj)=>obj.date),
				...datesInRange
			]).filter(format).sort(sortDates);

			const newData = {
				closed: {
					...restaurant.closed,
					dates: uniqueClosedDates.map((date) => ({
						date: date,
						description: restaurant.closed.dates.filter((obj)=>obj.date === date)[0]?.description || ''
					}))
				}
			};

			writeBatch.update(collectionRef.doc(doc.id), newData);
		}
		await writeBatch.commit();
		console.log('done');
		reload();
		setLoading(false);
	};

	return (
		<Box display='flex' flexDirection='row' alignItems='flex-start' style={{ width: '100vw' }}>
			<Box style={{
				marginTop: '0.8em',
				overflowY: 'scroll',
				maxHeight: '15em',
				flexBasis: '60%'
			}}>
				<PillList
					mode='remove'
					items={dateList}
					formatLabelFn={format}
					onChange={onChange}
				/>
			</Box>
			<Box display='flex' flexDirection='row' alignItems='center' style={{ flexWrap: 'wrap', flexBasis: '40%' }}>
				<TextField
					value={`${DateTime.fromISO(dateRange.startDate.toISOString()).toFormat('MMMM dd, yyyy')} - ${DateTime.fromISO(dateRange.endDate.toISOString()).toFormat('MMMM dd, yyyy')}`}
					onClick={(event: React.MouseEvent) => setMenuAnchorEl(event.currentTarget)}
					variant='outlined'
				/>

				<Menu
					anchorEl={menuAnchorEl}
					open={Boolean(menuAnchorEl)}
					onClose={() => setMenuAnchorEl(null)}
				>
					<DateRangePicker
						locale={enUS}
						ranges={[{
							startDate: dateRange.startDate,
							endDate: dateRange.endDate,
							key: 'range'
						}]}
						direction='vertical'
						scroll={{ enabled: true }}
						onChange={(item) => setDateRange(item['range'] as DateRange)}
					/>
				</Menu>
				<div style={{ display:'flex', flexDirection:'row', width:'170px' }}>
					<div>THIS RESTAURANT</div>
					<IconButton onClick={() => {
						const startTime = dateRange.startDate.getTime();
						const endTime = dateRange.endDate.getTime();
						const datesInRange = [];

						for (let ts = startTime; ts <= endTime; ts += 86400000) {
							datesInRange.push(DateTime.fromMillis(ts).toFormat('MMMM dd, yyyy'));
						}

						const res = uniq([
							...typeof dates[0] === 'string' ? dates : dates.map((obj) => obj.date),
							...datesInRange
						]).filter(format).sort(sortDates);

						onChange(res);
					}}>
						<AddIcon />
					</IconButton>
				</div>
				{ allRestaurants &&
				<>
					<div style={{ display:'flex', flexDirection:'row', width:'170px' }}>
						<div> ALL USA RESTAURANTS</div>
						<IconButton disabled={loading} onClick={() => batchUpdate(false, 'usa')}>
							{loading ? <NotInterestedIcon /> : <AddIcon /> }
						</IconButton>
					</div>
					<div style={{ display:'flex', flexDirection:'row', width:'170px' }}>
						<div> ALL CANADIAN RESTAURANTS</div>
						<IconButton disabled={loading} onClick={() => batchUpdate(false, 'can')}>
							{loading ? <NotInterestedIcon /> : <AddIcon /> }
						</IconButton>
					</div>
					<div style={{ display:'flex', flexDirection:'row', width:'170px' }}>
						<div> ALL RESTAURANTS</div>
						<IconButton disabled={loading} onClick={() => batchUpdate(true)}>
							{loading ? <NotInterestedIcon /> : <AddIcon /> }
						</IconButton>
					</div>
				</>
				}


			</Box>
		</Box>
	);
};

export default DatesPicker;
import { Box, FormControl, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import React, { useContext } from 'react';
import { TDR } from 'tdr-common';
import { FirebaseContext } from '../context/FirebaseContext';
import { ModalContext } from '../context/ModalContext';
import { RestaurantContext } from '../context/RestaurantContext';
import Modal from './Modal';

const CONFIGURE_HIDDEN_RESTAURANTS = '__configure-hidden-restaurants-modal__';

const RestaurantSelector = ({ allRestaurantsSelection = false } : {allRestaurantsSelection?: boolean}) => {
	const { restaurants, selectedRestaurantId, setSelectedRestaurantId } = useContext(RestaurantContext);
	const { openModal, closeModal } = useContext(ModalContext);
	const defaultOptions = allRestaurantsSelection ? [{ name: 'None', id: null },{ name: 'All Restaurants', id:'none' }] : [	{ name: 'None', id: null }];

	const menuOptions = [
		...defaultOptions,
		...restaurants,
		{ name: 'Hidden Restaurants', id: CONFIGURE_HIDDEN_RESTAURANTS }
	];

	const renderOption = (option) =>
		['None', 'Hidden Restaurants'].includes(option.name) ? (
			<span style={{ fontStyle: 'italic' }}>{option.name}</span>
		) : (
			option.name
		);

	const renderInput = (params: AutocompleteRenderInputParams) => (
		<TextField
			{...params}
			label='Restaurant'
			variant='outlined'
			style={{ width: '260px', fontStyle: selectedRestaurantId === null ? 'italic' : 'normal' }}
		/>
	);

	const handleChange = (evt, value: TDR.Restaurant) => {
		if (!value) {
			return;
		}

		if (value?.id === CONFIGURE_HIDDEN_RESTAURANTS) {
			openModal(<Modal onClose={() => closeModal()}><RestaurantHider /></Modal>);
		}
		else {
			setSelectedRestaurantId(value?.id || null);
		}
	};

	return (
		<FormControl variant='filled'>
			<Autocomplete
				options={menuOptions}
				getOptionLabel={(option) => option.name}
				renderOption={renderOption}
				renderInput={renderInput}
				value={menuOptions.find((option) => option.id === selectedRestaurantId || null)}
				onChange={handleChange}
			/>
		</FormControl>
	);
};

const RestaurantHider = () => {
	// apparently, modals don't update with the wrapper's context. Mystery for another day.
	// anyways, I'll just move the context INTO the modal too.
	const { allRestaurants } = useContext(RestaurantContext);
	const { firestore } = useContext(FirebaseContext);

	return <Box>
		{allRestaurants.map(restaurant =>
			<FormControlLabel
				key={restaurant.id}
				label={restaurant.name}
				style={{ width: '45%' }}
				control={<Switch
					checked={restaurant.hiddenInAdmin}
					color='primary'
					onChange={(evt) => {
						// console.log(restaurant.name, evt.target.checked);
						firestore.collection('Restaurants').doc(restaurant.id).update({ hiddenInAdmin: evt.target.checked });
					}}
				/>}
			/>)}
	</Box>;
};

export default RestaurantSelector;








import React, { useContext, useState } from 'react';
import { Typography, IconButton, List, ListItem, ButtonGroup, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { capitalize } from 'tdr-common';
import { CapacityExceededModal } from './Modals';
import { ModalContext } from '../context/ModalContext';

namespace AdminTimeSlotSelector {
	export type Props = {
		partySize?: number,
		selectedShift: string,
		setSelectedShift: (shift: string)=>void,
		selectedTimeSlot: string,
		setSelectedTimeSlot: (shift: string)=>void
	};
}

const timeSlotTestData = {
	breakfast: [
		{ timeSlot: '8:00 am', bookedSeats: 17, totalSeats: 35 },
		{ timeSlot: '8:15 am', bookedSeats: 20, totalSeats: 35 },
		{ timeSlot: '8:30 am', bookedSeats: 16, totalSeats: 35 },
		{ timeSlot: '8:45 am', bookedSeats: 25, totalSeats: 35 },
		{ timeSlot: '9:00 am', bookedSeats: 30, totalSeats: 35 },
		{ timeSlot: '9:15 am', bookedSeats: 35, totalSeats: 35 },
		{ timeSlot: '9:30 am', bookedSeats: 30, totalSeats: 35 }
	],
	lunch: [
		{ timeSlot: '11:00 am', bookedSeats: 17, totalSeats: 35 },
		{ timeSlot: '11:15 am', bookedSeats: 20, totalSeats: 35 },
		{ timeSlot: '11:30 am', bookedSeats: 16, totalSeats: 35 },
		{ timeSlot: '11:45 am', bookedSeats: 25, totalSeats: 35 },
		{ timeSlot: '12:00 am', bookedSeats: 30, totalSeats: 35 },
		{ timeSlot: '12:15 am', bookedSeats: 35, totalSeats: 35 },
		{ timeSlot: '12:30 am', bookedSeats: 30, totalSeats: 35 }
	],
	dinner: [
		{ timeSlot: '17:00 am', bookedSeats: 15, totalSeats: 35 },
		{ timeSlot: '17:15 am', bookedSeats: 19, totalSeats: 35 },
		{ timeSlot: '17:30 am', bookedSeats: 10, totalSeats: 35 },
		{ timeSlot: '17:45 am', bookedSeats: 30, totalSeats: 35 },
		{ timeSlot: '18:00 am', bookedSeats: 35, totalSeats: 35 },
		{ timeSlot: '18:15 am', bookedSeats: 25, totalSeats: 35 },
		{ timeSlot: '18:30 am', bookedSeats: 18, totalSeats: 35 },
		{ timeSlot: '18:45 am', bookedSeats: 18, totalSeats: 35 },
		{ timeSlot: '19:00 am', bookedSeats: 35, totalSeats: 35 },
		{ timeSlot: '19:15 am', bookedSeats: 35, totalSeats: 35 },
		{ timeSlot: '19:30 am', bookedSeats: 30, totalSeats: 35 },
		{ timeSlot: '20:45 am', bookedSeats: 30, totalSeats: 35 },
		{ timeSlot: '20:00 am', bookedSeats: 35, totalSeats: 35 },
		{ timeSlot: '20:15 am', bookedSeats: 35, totalSeats: 35 },
		{ timeSlot: '20:30 am', bookedSeats: 30, totalSeats: 35 },
		{ timeSlot: '20:45 am', bookedSeats: 30, totalSeats: 35 }
	]
};

const AdminTimeSlotSelector = ({ partySize, selectedShift, setSelectedShift, selectedTimeSlot, setSelectedTimeSlot }: AdminTimeSlotSelector.Props) => {
	const { openModal } = useContext(ModalContext);
	const [hiddenShift, setHiddenShift] = useState({});
	// const [selectedShift, setSelectedShift] = useState('breakfast');
	// const [selectedTimeSlot, setSelectedTimeSlot] = useState('');

	const renderTimeSlotsList = (timeSlotsList) => (
		<List>
			{timeSlotsList.map((data, index) => {
				const { bookedSeats, totalSeats, timeSlot } = data;
				const disabled = (bookedSeats + partySize) > totalSeats;
				const selected = timeSlot === selectedTimeSlot;
				return (
					<ListItem
						className='always-allow-events'
						key={index}
						button
						divider
						disabled={disabled}
						selected={selected}
						onClick={() => {
							if (disabled) {
								openModal(<CapacityExceededModal/>);
							}
							else {
								setSelectedTimeSlot(selected ? '' : timeSlot);
							}
						}}
					>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
							<div>{timeSlot}</div>
							<div>{bookedSeats}/{totalSeats}</div>
						</div>
					</ListItem>
				);
			})}
		</List>
	);

	return (
		<div className='list-item-selector'>
			<div className='timeslot-selector-md'>
				<ButtonGroup>
					<Button
						variant='contained'
						color={selectedShift==='breakfast' ? 'primary' : 'secondary'}
						onClick={() => setSelectedShift('breakfast')}
						style={{ height: 38, border: '1px solid rgba(255, 255, 255, 0.12)' }}
					>
						Breakfast
					</Button>
					<Button
						variant='contained'
						color={selectedShift==='lunch' ? 'primary' : 'secondary'}
						onClick={() => setSelectedShift('lunch')}
						style={{ height: 38, border: '1px solid rgba(255, 255, 255, 0.12)' }}
					>
						Lunch
					</Button>
					<Button
						variant='contained'
						color={selectedShift==='dinner' ? 'primary' : 'secondary'}
						onClick={() => setSelectedShift('dinner')}
						style={{ height: 38,border: '1px solid rgba(255, 255, 255, 0.12)' }}
					>
						Dinner
					</Button>
				</ButtonGroup>
				<div style={{ marginTop: '39px' }}>
					<Typography variant='h6'>{capitalize(selectedShift)}</Typography>
					{renderTimeSlotsList(timeSlotTestData[selectedShift])}
				</div>
			</div>
			<div className='timeslot-selector-sm'>
				{Object.keys(timeSlotTestData).map((shift) => {
					const isHidden = hiddenShift[shift];

					const shiftData = timeSlotTestData[shift];

					return (
						<div key={shift} style={{ marginBottom: 45 }}>
							<div className='common-header clickable' onClick={() => setHiddenShift({ ...hiddenShift, [shift]: !isHidden })}>
								<Typography variant='h6'>{capitalize(shift)}</Typography>
								<IconButton aria-label='expand' style={{ paddingRight: 0 }}>
									{isHidden ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
								</IconButton>
							</div>
							{!isHidden && renderTimeSlotsList(shiftData)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default AdminTimeSlotSelector;
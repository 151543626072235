// TODO: break this file down so we dont need to disable this
/* eslint-disable max-lines-per-function */
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
	TDR
} from 'tdr-common';
import { useDebounce } from 'use-debounce';
import exportedConfig from '../../common/config';
import { FirebaseContext } from '../../context/FirebaseContext';
import { RestaurantContext } from '../../context/RestaurantContext';
import { CopyCodeBlock } from '../CopyCodeBlock';
import { RestaurantSettingsFormProps, useStyles } from './AdminWidgets';
import { OpenTableSettings } from './OpenTableSettings';
import { TablzAlternateWidgetSettings } from './TablzAlternateWidgetSettings';
import { TablzWidgetSettings } from './TablzWidgetSettings';
import { updateRestaurantSettings } from '../../api/updateLandingPageSettings';

export const WidgetConfig = ({ formData, setFormData }: RestaurantSettingsFormProps) => {
	const classes = useStyles();

	const { firestore } = useContext(FirebaseContext);
	const { selectedRestaurant } = useContext(RestaurantContext);

	const [previewParams, setPreviewParams] = useState(
		new URLSearchParams()
	);

	const [wcParams, setWcParams] = useState({
		l: formData?.widget?.layout || 'default',
		t: formData?.widget?.alternativeWidgetType || 'none'
	});

	const [deboundedPreviewParams] = useDebounce(previewParams, 1000);
	const [deboundedWCParams] = useDebounce(wcParams, 1000);

	const hasOneWidgetOnly = formData.widget.alternativeWidgetType === 'none';
	const scriptSrc = new URL(
		`${exportedConfig.widgetUrl}/loader.js?id=${selectedRestaurant.slug}&${new URLSearchParams(wcParams).toString()}`
	);

	const scriptTagSnippet = `<script src="${scriptSrc}" id="tdr-widget"></script>`;

	const updatePreviewParam = (key: string, value: string | boolean) => {
		const newParams = new URLSearchParams(previewParams);
		newParams.set(key, value?.toString());
		setPreviewParams(newParams);
	};

	const handleSaveChanges = async () => {
		console.log({ formData });
		setSubmissionError(null);
		setSavingChanges(true);
		try {
			await updateRestaurantSettings(firestore, selectedRestaurant.id, formData);
		}
		catch (error) {
			console.error({ error });
			setSubmissionError(error);
		}
		finally {
			setSavingChanges(false);
		}
	};

	/********** FORM SUBMISSION AND VALIDATION ***********/
	const [savingChanges, setSavingChanges] = useState(false);
	const [submissionError, setSubmissionError] = useState();

	const [titleError, setTitleError] = useState(false);
	const [descriptionError, setDescriptionError] = useState(false);
	// TODO: validate other neccessary fields

	const formError = [titleError, descriptionError].some((error) => error);

	// Automatically turn off headers if using standard third party widget
	useEffect(() => {
		if (formData.widget.alternativeWidgetType !== 'tablz') {
			updatePreviewParam('showOptionHeaders', false);
			setFormData({
				...formData,
				widget: {
					...formData.widget,
					showOptionHeaders: false
				}
			});
		}
	}, [formData.widget.alternativeWidgetType]);

	const missingAltPlatformInfo =
    !selectedRestaurant.settings?.alternativePlatform?.vendor ||
    !selectedRestaurant.settings?.alternativePlatform?.href;

	return (
		<>
			<Helmet>
				<link rel='stylesheet' href={`${exportedConfig.widgetUrl}/common.css`} />
				<script
					type='text/javascript'
					src={`${exportedConfig.widgetUrl}/wc.js`}
				/>
			</Helmet>

			<Accordion
				classes={{ root: classes.root }}
				disabled={missingAltPlatformInfo}
			>
				<AccordionSummary
					expandIcon={<ExpandMore />}
					classes={{ content: classes.summaryContent }}
				>
					<h1 className={classes.heading}>
            Embeddable Tablz Widget{' '}
						{missingAltPlatformInfo
							? '(Provide an alternative platform (RMS) and reservation page link above to enable)'
							: ''}
					</h1>
					<p className={classes.subheading}>
            Customizable widget for embedding in restaurant website
					</p>
				</AccordionSummary>

				{missingAltPlatformInfo ? null : (
					<AccordionDetails classes={{ root: classes.summaryContent }}>
						<Grid container spacing={2}>
							<Grid container item xs={8} spacing={2}>
								<Grid item xs={12}>
									<div>
										<h2 style={{ margin: 0 }}>Embeddable script tag</h2>
										<p style={{ margin: '16px' }}>
                      Copy the code snippet below and paste directly into
                      restaurant webpage.
										</p>

										{scriptTagSnippet && (
											<CopyCodeBlock text={scriptTagSnippet} />
										)}
									</div>

									<Button
										disabled={savingChanges || formError}
										classes={{
											root: classes.buttonRoot
										}}
										onClick={handleSaveChanges}
									>
										{savingChanges ? <CircularProgress /> : 'Save Changes'}
									</Button>

									{submissionError && (
										<Alert severity='error'>
											<span>Something went wrong. Your changes were not saved.</span>
											<code style={{ display: 'block' }}>{JSON.stringify(submissionError)}</code>
										</Alert>
									)}

									<h2>Preview</h2>

									<tablz-widget
										id={selectedRestaurant.slug}
										params={new URLSearchParams(deboundedWCParams).toString()}
										preview={encodeURIComponent(deboundedPreviewParams.toString())}
									/>
								</Grid>

								<div
									style={{
										display: 'flex',
										flexDirection: !formData.widget?.layout || formData.widget.layout === 'default'
											? 'row'
											: 'row-reverse',
										gap: '20px'
									}}
								>
									<Grid item xs={!hasOneWidgetOnly ? 6 : 12}>
										<TablzWidgetSettings
											formData={formData}
											setFormData={setFormData}
											updatePreviewParam={updatePreviewParam}
											titleError={titleError}
											descriptionError={descriptionError}
											setTitleError={setTitleError}
											setDescriptionError={setDescriptionError}
											selectedRestaurant={selectedRestaurant}
										/>
									</Grid>

									{!hasOneWidgetOnly && (
										<Grid item xs={6}>
											{formData.widget.alternativeWidgetType === 'tablz' && (
												<TablzAlternateWidgetSettings
													formData={formData}
													setFormData={setFormData}
													updatePreviewParam={updatePreviewParam}
													titleError={titleError}
													descriptionError={descriptionError}
													setTitleError={setTitleError}
													setDescriptionError={setDescriptionError}
													selectedRestaurant={selectedRestaurant}
												/>
											)}

											{formData.widget.alternativeWidgetType === 'opentable' &&
                        formData?.alternativePlatform?.vendor ===
                          TDR.AlternativePlatform.OpenTable && (
												<OpenTableSettings
													formData={formData}
													setFormData={setFormData}
													updatePreviewParam={updatePreviewParam}
												/>
											)}
										</Grid>
									)}
								</div>
							</Grid>

							<Grid
								style={{
									display: 'flex',
									flexDirection: 'column',
									padding: '0 24px'
								}}
								item
								xs={4}
							>
								<h2>Widget Type and Layout</h2>

								<Box
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: '32px'
									}}
								>
									<FormControl>
										<FormLabel>Alternative Widget</FormLabel>
										<RadioGroup
											value={formData?.widget?.alternativeWidgetType || 'none'}
											onChange={(evt) => {
												const newValue = (evt.target.value || 'none') as TDR.AlternativeWidgetType;
												setWcParams({
													...wcParams,
													t: newValue
												});
												updatePreviewParam('t', newValue);
												setFormData({
													...formData,
													widget: {
														...formData.widget,
														alternativeWidgetType: newValue
													}
												});
											}}
										>
											{TDR.ALTERNATIVE_WIDGET_OPTIONS.filter((option) => {
												return option === 'none' || option === 'tablz' || (option === selectedRestaurant.settings.alternativePlatform.vendor);
											}).map((option) => {
												return (
													<FormControlLabel
														key={option}
														value={option}
														control={<Radio />}
														label={option}
													/>
												);
											})}
										</RadioGroup>
									</FormControl>

									<FormControl
										disabled={hasOneWidgetOnly}
										style={{
											opacity: hasOneWidgetOnly ? 0.3 : 1
										}}
									>
										<FormLabel>
                      Layout{' '}
											{hasOneWidgetOnly &&
                        '(Select a side-by-side option to enable)'}
										</FormLabel>
										<RadioGroup
											value={
												formData?.widget?.layout || 'default'
											}
											onChange={(evt) => {
												const newValue = (evt.target.value || 'default') as TDR.WidgetLayout;
												setWcParams({
													...wcParams,
													l: newValue
												});
												updatePreviewParam('l', newValue);
												setFormData({
													...formData,
													widget: {
														...formData.widget,
														layout: newValue
													}
												});
											}}
										>
											<FormControlLabel
												value='default'
												control={<Radio />}
												label='Place Tablz Option first'
											/>
											<FormControlLabel
												value='reversed'
												control={<Radio />}
												label='Place Alternative Option First'
											/>
										</RadioGroup>
									</FormControl>

									<FormControl
										disabled={
											hasOneWidgetOnly ||
                      formData.widget.alternativeWidgetType !== 'tablz'
										}
										style={{
											opacity:
                        hasOneWidgetOnly ||
						formData.widget.alternativeWidgetType !== 'tablz'
                        	? 0.3
                        	: 1
										}}
									>
										<FormLabel>
                      Header{' '}
											{hasOneWidgetOnly &&
                        '(Select side-by-side type to enable)'}
										</FormLabel>
										<RadioGroup
											value={
												formData?.widget?.showOptionHeaders ? 'true' : 'false'
											}
											onChange={(evt) => {
												updatePreviewParam(
													'showOptionHeaders',
													evt.target.value
												);
												setFormData({
													...formData,
													widget: {
														...formData.widget,
														showOptionHeaders: evt.target.value === 'true'
													}
												});
											}}
										>
											<FormControlLabel
												value='true'
												control={<Radio />}
												label='Include Option 1/Option 2 headers'
											/>
											<FormControlLabel
												value='false'
												control={<Radio />}
												label='No headers'
											/>
										</RadioGroup>
									</FormControl>

									<FormControl>
										<FormLabel>Footer</FormLabel>
										<FormControlLabel
											control={
												<Checkbox
													checked={formData.widget?.showFooter}
													onChange={(evt) => {
														updatePreviewParam(
															'showFooter',
															evt.target.checked
														);
														setFormData({
															...formData,
															widget: {
																...formData.widget,
																showFooter: evt.target.checked
															}
														});
													}}
												/>
											}
											label="Include 'Powered By' footer"
										/>
									</FormControl>
								</Box>
							</Grid>
						</Grid>
					</AccordionDetails>
				)}
			</Accordion>
		</>
	);
};

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { TDR } from 'tdr-common';


export default async function deleteMedia(mediaToDelete: TDR.MediaContent, table: TDR.Table) {
	await firebase.firestore().collection('Tables').doc(table.id).update({
		media: (table.media || []).filter(mediaItem => mediaItem.path !== mediaToDelete.path)
	});

	await firebase.storage().ref(mediaToDelete.path).delete();
}


import React, { useContext, useState } from 'react';
import GroupedReservations from './GroupedReservations';
import firebase from 'firebase/app';
import 'firebase/firestore';
import luxonToFirebaseTimestamp from '../helpers/luxonToFirebaseTimestamp';
import { DateTime } from 'luxon';
import { Button, Typography, InputAdornment, IconButton, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import TodayIcon from '@material-ui/icons/Today';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { TDR } from 'tdr-common';
import { ModalContext } from '../context/ModalContext';
import { DateShiftModal } from './Modals';
import CancelIcon from '@material-ui/core/SvgIcon/SvgIcon';

// eslint-disable-next-line max-lines-per-function
const Reservations = () => {
	//const [guestQuery, setGuestQuery] = useState('');
	const { openModal } = useContext(ModalContext);
	const [search, setSearch] = useState('');
	const history = useHistory();

	const currentDate = new Date().toLocaleString('en-US', { month: 'long', day: '2-digit', year: 'numeric' });

	const selectedShifts = {
		breakfast: false,
		lunch: false,
		dinner: true
	};

	const dateFilteredReservations: Partial<TDR.Reservation<firebase.firestore.Timestamp>>[] =
		[
			{
				id: '1',
				fullName: 'John Doe',
				status: TDR.Reservation.Status.Booked,
				tableName: 'My Table',
				tableSlug: 'my-table',
				restaurantId: '123',
				email: 'a@a.com',
				phone: '1234567890',
				time: luxonToFirebaseTimestamp(DateTime.local()),
				guests: 4,
				guestNotes: 'Bla blabl bla bla guestNotes'
			},
			{
				id: '2',
				fullName: 'Bob Smith',
				status: TDR.Reservation.Status.Confirmed,
				tableName: 'My  new Table',
				tableSlug: 'my--new-table',
				restaurantId: '123',
				email: 'a@a.com',
				phone: '1234567890',
				time: luxonToFirebaseTimestamp(DateTime.local()),
				guests: 2,
				guestNotes: 'Bla blabl bla bla guestNotes'
			},
			{
				id: '3',
				fullName: 'generic Name 3',
				status: TDR.Reservation.Status.LateArrival,
				tableName: 'My new new Table',
				tableSlug: 'my-new-new-table',
				restaurantId: '123',
				email: 'a@a.com',
				phone: '1234567890',
				time: luxonToFirebaseTimestamp(DateTime.local()),
				guests: 6,
				guestNotes: 'Bla blabl bla bla guestNotes'
			},
			{
				id: '4',
				fullName: 'Flake Lively',
				status: TDR.Reservation.Status.NoShow,
				tableName: 'My new new Table',
				tableSlug: 'my-new-new-table',
				restaurantId: '123',
				email: 'a@a.com',
				phone: '1234567890',
				time: luxonToFirebaseTimestamp(DateTime.local()),
				guests: 7,
				guestNotes: 'Bla blabl bla bla guestNotes'
			},
			{
				id: '5',
				fullName: 'cancelled guest 1',
				status: TDR.Reservation.Status.Cancelled,
				tableName: 'My new new Table',
				tableSlug: 'my-new-new-table',
				restaurantId: '123',
				email: 'a@a.com',
				phone: '1234567890',
				time: luxonToFirebaseTimestamp(DateTime.local()),
				guests: 3,
				guestNotes: 'Bla blabl bla bla guestNotes'
			},
			{
				id: '6',
				fullName: 'A. Rived',
				status: TDR.Reservation.Status.Arrived,
				tableName: 'My new new Table',
				tableSlug: 'my-new-new-table',
				restaurantId: '123',
				email: 'a@a.com',
				phone: '1234567890',
				time: luxonToFirebaseTimestamp(DateTime.local()),
				guests: 3,
				guestNotes: 'Bla blabl bla bla guestNotes'
			},
			{
				id: '7',
				fullName: 'Sort of here',
				status: TDR.Reservation.Status.PartiallyArrived,
				tableName: 'My new new Table',
				tableSlug: 'my-new-new-table',
				restaurantId: '123',
				email: 'a@a.com',
				phone: '1234567890',
				time: luxonToFirebaseTimestamp(DateTime.local()),
				guests: 6,
				guestNotes: 'Bla blabl bla bla guestNotes'
			}
		];

	const openDateShiftModal = () => openModal(
		<DateShiftModal
			onClose={null}
			onSave={() => console.warn('TODO: SAVE')}
			selectedDate={currentDate}
			selectedShifts={selectedShifts}
		/>
	);

	return (
		<div className='reservation-list-home'>
			<div className='date-container'>
				<div className='date-selectors'>
					<div className='res-count'>
						<PersonIcon/>
						<Typography>{dateFilteredReservations.length}</Typography>
					</div>
					<Button className='date-button'
						onClick={openDateShiftModal}
					>
						<CalendarTodayIcon/>
						<Typography>{currentDate}</Typography>
					</Button>
					<Button className='calendar-button'
						onClick={openDateShiftModal}
					>
						<TodayIcon/>
					</Button>
					<Button className='shift-button'
						onClick={openDateShiftModal}
					>
						<Typography>Lunch</Typography>
					</Button>
				</div>
				<Button className='add-btn' onClick={() => history.push('/reservations/create')}>
					<AddIcon/>
				</Button>
			</div>
			<div className='guest-container'>
				<TextField
					className='guest-search'
					placeholder='Search by name or phone number'
					variant='outlined'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<SearchIcon/>
							</InputAdornment>
						),
						endAdornment: search && (
							<InputAdornment position='end'>
								<IconButton onClick={() => setSearch('')} style={{ padding: 0 }}>
									<CancelIcon style={{ fontSize: '13px' }}/>
								</IconButton>
							</InputAdornment>
						)
					}}
					value={search}
					onChange={(evt) => setSearch(evt.target.value)}
				/>
			</div>
			<div className='reservation-row'>
				<div className='reservation-column'>
					<GroupedReservations
						group={TDR.Reservation.Group.Waitlist}
						reservations={dateFilteredReservations.filter(reservation =>
							reservation.status === TDR.Reservation.Status.Booked || reservation.status === TDR.Reservation.Status.LeftMessage
						)}
					/>
				</div>
				<div className='reservation-column'>
					<GroupedReservations
						group={TDR.Reservation.Group.Reservation}
						reservations={dateFilteredReservations.filter(reservation =>
							reservation.status === TDR.Reservation.Status.Confirmed || reservation.status === TDR.Reservation.Status.LateArrival
						)}
					/>

					<GroupedReservations
						group={TDR.Reservation.Group.NoShow}
						reservations={dateFilteredReservations.filter(reservation =>
							reservation.status === TDR.Reservation.Status.NoShow
						)}
					/>

					<GroupedReservations
						group={TDR.Reservation.Group.Cancellation}
						reservations={dateFilteredReservations.filter(reservation =>
							reservation.status === TDR.Reservation.Status.Cancelled
						)}
					/>
				</div>
				<div className='reservation-column'>
					<GroupedReservations
						group={TDR.Reservation.Group.CurrentlySeated}
						reservations={dateFilteredReservations.filter(reservation =>
							reservation.status === TDR.Reservation.Status.PartiallyArrived || reservation.status === TDR.Reservation.Status.Arrived
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default Reservations;




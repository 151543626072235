import {
	Box, Checkbox, IconButton, TextField
} from '@material-ui/core';
import {
	AddCircle as AddIcon, DeleteForever as DeleteIcon
} from '@material-ui/icons';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { formatPhoneNumber } from 'tdr-common';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const isNumeric = (str: unknown) => {
	if (typeof str !== 'string') {
		return false;
	} // we only process strings!
	return !isNaN(str as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
};

namespace PhoneEditor {
	export type Props = {
		label: string,
		numbers: string[],
		onChange: (numbers: string[]) => void
	};
}

const PhoneEditor = ({ label, numbers, onChange }: PhoneEditor.Props) => {
	const [newNumber, setNewNumber] = useState('');
	const [selectedNumbers, setSelectedNumbers] = useState<string[]>([]);

	return (
		<Box display='flex' flexDirection='column' width='100%' position='relative'>
			<Box display='flex' flexDirection='row' justifyContent='flex-end' width='100%'>
				<Box mr={1}>
					<TextField
						value={newNumber}
						onChange={(e) => {
							if (e.target.value.length <= 10) {
								setNewNumber(e.target.value);
							}
						}}
						variant='outlined'
						type='tel'
						style={{
							width: '300px'
						}}
					/>
				</Box>
				<IconButton onClick={() => {
					if (isEmpty(newNumber) || newNumber.length !== 10 || !isNumeric(newNumber)) {
						return;
					}

					const formattedNewNumber = formatPhoneNumber(newNumber);
					if (!numbers.includes(formattedNewNumber)) {
						onChange([
							...numbers,
							formattedNewNumber
						]);
					}
					setNewNumber('');
				}}>
					<AddIcon />
				</IconButton>
				<IconButton onClick={() => {
					onChange(numbers.filter(number => !selectedNumbers.includes(number)));
					setSelectedNumbers([]);
				}}>
					<DeleteIcon />
				</IconButton>
			</Box>
			<label style={{
				transform: 'translate(0px, 64px) scale(0.75)',
				top: 0,
				left: 0,
				position: 'absolute',
				color: '#7FB5A8'
			}}>{label}</label>
			<Box display='flex' flexDirection='column' width='100%' bgcolor='#333' height='300px' overflow='auto'>
				{numbers.map((number) => (
					<Box display='flex' flexDirection='row' alignItems='center' key={number} py={1}>
						<Checkbox
							color='default'
							checked={selectedNumbers.includes(number)}
							onChange={() => {
								if (selectedNumbers.includes(number)) {
									setSelectedNumbers(selectedNumbers.filter(selectedNumber => selectedNumber !== number));
								}
								else {
									setSelectedNumbers([
										...selectedNumbers,
										number
									]);
								}
							}}
						/>
						<Box>{number}</Box>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default PhoneEditor;
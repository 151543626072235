import { TDR } from 'tdr-common';
import config from '../common/config';

export default async function chargeNoShow (userToken:string, invoice:Partial<TDR.Invoice>) {
	return fetch(`${config.apiHost}/chargeFee`, {
		method: 'POST',
		headers: { 'Authorization': `Bearer ${userToken}` ,
			'Content-Type': 'application/json; charset=UTF-8' },
		body: JSON.stringify({ invoice })
	})
		.then(async response => response.text())
		.then(text => {
			try {
				return JSON.parse(text);
			}
			catch (err) {
				return text;
			}
		});
}

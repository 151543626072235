import firebase from 'firebase';
import { TDR } from 'tdr-common';

export default async function updateLargeGroupCutoffs(
	firestore: firebase.firestore.Firestore,
	restaurantId: string,
	tableId: string,
	cutoff: TDR.ReservationCutoffs
): Promise<void> {
	const docRef = firestore.collection('Restaurants').doc(restaurantId);

	try {
		const docSnapshot = await docRef.get();

		if (docSnapshot.exists) {
			const restaurantData = docSnapshot.data() as TDR.Restaurant ;

			const existingIndex = restaurantData.reservationCutoffs.findIndex(
				(cutoff) =>
					cutoff.condition?.hasItem?.type === TDR.Reservation.ItemType.Table &&
          cutoff.condition?.hasItem?.id === tableId
			);

			if (existingIndex !== -1) {
				const updatedCutoffs = [...restaurantData.reservationCutoffs];
				updatedCutoffs[existingIndex] = cutoff;
				await docRef.update({
					reservationCutoffs: updatedCutoffs
				});
			}
			else {
				await docRef.update({
					reservationCutoffs: firebase.firestore.FieldValue.arrayUnion(cutoff)
				});
			}
		}
	}
	catch (error) {
		console.error('Failed to update large group cutoffs', error);
	}
}
import { useEffect, useState } from 'react';
import { TDR } from 'tdr-common';
import firebase from 'firebase';
import * as Sentry from '@sentry/react';

const invoiceOrder = {
	reservation: 1,
	stripe: 2,
	payout: 3,
	tax: 4
};

const sortInvoices = (invoices: TDR.Invoice[]) =>
	invoices.sort((a, b) => invoiceOrder[a.type] - invoiceOrder[b.type]);

export const useInvoices = (
	firestore: firebase.firestore.Firestore,
	reservationId: string
) => {
	const [invoices, setInvoices] = useState<TDR.Invoice[]>([]);
	const [loading, setLoading] = useState(true);

	const fetchInvoices = async () => {
		setLoading(true);
		try {
			const snapshot = await firestore
				.collection('Invoices')
				.where('reservationId', '==', reservationId)
				.get();

			const invoices = snapshot.docs.map((doc) => doc.data() as TDR.Invoice);
			return sortInvoices(invoices);
		}
		catch (error) {
			Sentry.captureException(error);
			console.error('Error fetching invoices', { error });
			return [];
		}
		finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchInvoices().then(invoices => setInvoices(invoices));
	}, [reservationId]);

	return {
		fetchInvoices,
		setInvoices,
		invoices,
		loading
	};
};

import React, { useState, useContext } from 'react';
import { Tabs, Tab, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PeopleIcon from '@material-ui/icons/People';
import BookIcon from '@material-ui/icons/Book';
import ReservationInformation from './ReservationInformation';
import VisitInformation from './VisitInformation';
import AdminHeader from './AdminHeader';
import { AdminReservationContext } from '../context/AdminReservationContext';
import { GuestContext } from '../context/GuestContext';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ModalControlledTextField from './ModalControlledTextField';
import ContactInformation from './ContactInformation';
import VisitHistory from './VisitHistory';

const AdminReservationDetails = () => {
	const { selectedReservation, updateReservation } = useContext(AdminReservationContext);
	const { selectedGuest, updateUser, updateGuest, selectedGuestReservations } = useContext(GuestContext);

	if (!selectedGuest) {
		return <div>loading... Make sure to move this kind of check closer to the root!</div>;
	}

	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'));

	const history = useHistory();
	const [tab, setTab] = useState(0);

	const setIconColor = (currentTab) => tab === currentTab ? '#7FB5A8' : '';

	return (
		<div className='admin-reservation-details'>
			<div className='navigation'>
				<AdminHeader
					title={selectedGuest.fullName}
					leftIconButton={<ArrowBackIosIcon style={{ fontSize: '17px' }}/>}
					onLeftIconClick={() => history.goBack()}
				/>
				<Tabs
					variant='fullWidth'
					value={tab}
					onChange={(evt: any, tab: number) => setTab(tab)}
					centered={true}
				>
					<Tab label={smUp ? '' : 'Reservation'} icon={smUp ? <BookIcon style={{ color: setIconColor(0) }} /> : null} />
					<Tab label={smUp ? '' : 'Profile'} icon={smUp ? <PeopleIcon style={{ color: setIconColor(1) }} /> : null} />
				</Tabs>
			</div>
			<div className='content'>
				{tab === 0
					? (
						<>
							<ReservationInformation selectedReservation={selectedReservation} />
							<Divider style={{ margin: '24px 0' }} />
							<VisitInformation
								guest={selectedGuest}
								updateGuest={updateGuest}
								reservation={selectedReservation}
								updateReservation={updateReservation}
							/>
						</>
					)
					: (
						<div className='guest-profile'>
							<div>
								<ContactInformation
									guest={selectedGuest}
									onSave={(data) => updateUser(selectedGuest.userId, data)}
								/>
								<div style={{ marginTop: '3em' }}>
									<ModalControlledTextField
										title='Guest Information'
										value={selectedReservation?.guestNotes || ''}
										onDelete={()=> updateReservation(selectedReservation.id, { guestNotes: '' })}
										onSave={(guestNotes)=> {
											updateReservation(selectedReservation.id, { guestNotes });
										}}
									/>
								</div>
								<VisitHistory
									showPastVisitNotes={true}
									guestReservations={selectedGuestReservations}
								/>
							</div>
						</div>
					)}
			</div>
		</div>
	);
};

export default AdminReservationDetails;
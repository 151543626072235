import React from 'react';
import { Typography, IconButton } from '@material-ui/core';

namespace AdminHeader {
	export type Props = {
		title: string,
		leftIconButton?: any,
		onLeftIconClick?: ()=>any,
		rightIconButton?: any
		onRightIconClick?: ()=>any,
	};
}

const AdminHeader = ({ title, leftIconButton = null, onLeftIconClick, rightIconButton = null, onRightIconClick }: AdminHeader.Props) => {
	return (
		<div className='common-header'>
			{leftIconButton && (
				<IconButton aria-label='back' style={{ paddingLeft: 30 }} onClick={onLeftIconClick}>
					{leftIconButton}
				</IconButton>
			)}
			<Typography variant='h6' style={{ marginTop: 8 }}>{title}</Typography>
			{rightIconButton ? (
				<IconButton aria-label='back' style={{ paddingRight: 30 }} disabled={!onRightIconClick} onClick={onRightIconClick}>
					{rightIconButton}
				</IconButton>
			): <div style={{ width: 64 }}/>}
		</div>
	);
};

export default AdminHeader;
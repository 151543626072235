import React, { useContext, useEffect, useReducer, useState, createContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import noop from 'lodash/noop';
import { FirebaseContext } from './FirebaseContext';
import { URLContext } from './URLContext';
import { AdminRestaurantContext } from './AdminRestaurantContext';
import { RestaurantContext } from './RestaurantContext';
import { useLocation } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { ProviderProps } from '../common/ProviderProps';
import {
	legacyCreateReservation,
	updateReservation as _updateReservation
} from '../api';

import { applyLargeGroupFilter, applyLargeGroupStatusFilter } from '../helpers/queryFilters';

type ResArr = TDR.Reservation[]

export namespace AdminReservationContext {
	export type Value = {
		reservations: ResArr,
		selectedReservation: TDR.Reservation,
		setSelectedReservation: (reservation: TDR.Reservation) => void,
		setQueryFilterFn: (q: any) => void,
		unresolvedRequests: number,
		page: number,
		setPage: (page: number) => void,
		pageSize: number,
		//setPageSize: ((s: number) => void)
		updateReservation: (id: string, data: any) => Promise<{ success: boolean }>,
		adminConfirmReservation: (options) => Promise<{ success: boolean }>,
		processing: boolean,
		errorMessage: string,
		clearErrorMessage: () => void
	};
}

export const AdminReservationContext = createContext<AdminReservationContext.Value>({
	reservations: [],
	selectedReservation: null,
	setSelectedReservation: noop,
	setQueryFilterFn: () => { },
	page: 0,
	setPage: () => { },
	unresolvedRequests: 0,
	pageSize: 20,
	//setPageSize: () => { }
	updateReservation: () => Promise.resolve({ success: false }),
	adminConfirmReservation: () => Promise.resolve({ success: false }),
	processing: false,
	errorMessage: '',
	clearErrorMessage: noop
});
AdminReservationContext.displayName = 'Admin Reservation Context';

export const AdminReservationProvider = ({ children }: ProviderProps) => {
	const { reservationId } = useContext(URLContext);
	const { pathname } = useLocation();
	const isNotReservations = !pathname.includes('/reservations') && !pathname.includes('/super-admin-reservations') && !pathname.includes('/booking-requests');

	const { firestore, initialized: firebaseInitialized } = useContext(FirebaseContext);
	const { selectedRestaurantId } = useContext(RestaurantContext);
	const { selectedAdminRestaurant } = useContext(AdminRestaurantContext);

	const [reservations, setReservations] = useState([]);
	const [unresolvedRequests, setUnresolvedRequests] = useState<number>(0);
	// eslint-disable-next-line no-unused-vars
	const [selectedReservation, setSelectedReservation] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [pageSize] = useState(20); // TODO

	const [errorMessage, setErrorMessage] = useState('');
	const [processing, setProcessing] = useState(false);

	const [page, setPage] = useState(0);

	// oh my god, what a pain in the ass
	const [prevPageLastDoc, setPrevPageLastDoc] = useState(null);

	// if we change up the filters, return to page 0
	const [queryFilterFn, setQueryFilterFn] = useReducer((queryFilterFn, newQueryfilterFn) => {
		setPage(0);
		setPrevPageLastDoc(null);
		return newQueryfilterFn;
	}, null);


	async function gotoPage(targetPage: number) {
		if (targetPage === page) {
			return;
		}

		if (targetPage === 0) {
			setPage(0);
			setPrevPageLastDoc(null);
			return;
		}

		const direction: firebase.firestore.OrderByDirection = targetPage > page ? 'asc' : 'desc';
		const pageStep = Math.abs(targetPage - page);

		let query = firestore.collection('Reservations').orderBy('adminSortOrder', direction);
		if (queryFilterFn) {
			query = queryFilterFn(query);
		}
		if (prevPageLastDoc) {
			query = query.startAfter(prevPageLastDoc);
		}
		query = query.limit(pageStep * pageSize);

		const docs = (await query.get()).docs;
		setPage(targetPage);
		setPrevPageLastDoc(docs[docs.length - 1]);
	}

	// listen to new reservations coming in
	useEffect(() => {
		if (!firebaseInitialized || (!selectedAdminRestaurant && !selectedRestaurantId)) {
			return;
		}

		let query: firebase.firestore.Query<TDR.Reservation> | firebase.firestore.CollectionReference<TDR.Reservation> = firestore.collection('Reservations') as any;

		// because we want to sort by adminSortOrder, we can't use any range comparison (<, <=, >, >=)
		// see: https://firebase.google.com/docs/firestore/query-data/order-limit-data#limitations
		if (queryFilterFn) {
			query = queryFilterFn(query);
		}

		if (isNotReservations) {
			query = query.where('restaurantId', '==', selectedRestaurantId);
		}

		query = query.orderBy('adminSortOrder');

		if (prevPageLastDoc) {
			query = query.startAfter(prevPageLastDoc);
		}

		query = query.limit(pageSize);

		const unsub = (query.onSnapshot((snapshot) => {
			setReservations(function (reservations: ResArr): ResArr {
				const newReservations = [...reservations];

				// okay, so HERE's how we do this - when a change comes in,
				// if it's a deletion, we drop that reservation IF it's in the current page, and make no effort to replace it
				// if it's a modification, we add the reservation to the list, stomping if necessary, and then re-sort by adminSortOrder
				// if it's an addition, we add the reservation to the list, and then re-sort by adminSortOrder

				// the idea here is to do something that works more-or-less correctly,
				// regardless of whether firestore's onSnapshot honors orderBy and pagination

				snapshot.docChanges().forEach(change => {
					// console.log('CHANGE', change.type, change.oldIndex, change.newIndex, change.doc.id, change.doc.data())
					if (change.type === 'added') {
						newReservations.push(change.doc.data());
					}

					else {
						const index = newReservations.findIndex(res => res.id === change.doc.id);
						// console.log('       index:', index)

						if (change.type === 'removed' && index !== -1) {
							newReservations.splice(index, 1);
						}

						else if (change.type === 'modified') {
							if (index === -1) {
								newReservations.push(change.doc.data());
							}
							else {
								newReservations[index] = change.doc.data();
							}
						}
					}
				});

				return newReservations.sort((a, b) => a.adminSortOrder > b.adminSortOrder ? 1 : -1);
			});
		})
		);

		return () => {
			unsub();
			// and, because we're aggregating changes up top, when we unsub, we also have to clear the reservations
			setReservations([]);
		};
	}, [firebaseInitialized, prevPageLastDoc, queryFilterFn, selectedAdminRestaurant, selectedRestaurantId]);

	useEffect(() => {
		if (!firebaseInitialized) {
			return;
		}
		let query: firebase.firestore.Query<TDR.Reservation> | firebase.firestore.CollectionReference<TDR.Reservation> = firestore.collection('Reservations') as any;

		query = applyLargeGroupFilter(applyLargeGroupStatusFilter(query, TDR.Reservation.LargeGroupStatus.AdminActionRequired));

		const unsub = (query.onSnapshot((snapshot) => {
			setUnresolvedRequests(function (): number {
				return snapshot.docs.length;
			});
		})
		);

		return () => {
			unsub();
			// and, because we're aggregating changes up top, when we unsub, we also have to clear the reservations
			setUnresolvedRequests(0);
		};
	}, [firebaseInitialized]);


	// Set selected reservation based on the URL
	useEffect(() => {
		if (reservationId && reservations.length) {
			setSelectedReservation(reservations.find((r) => r.id === reservationId));
		}

	}, [reservationId, reservations]);

	const updateReservation = async (id, data) => {
		try {
			// Update the current reservations accordingly
			const updatedReservationIndex = reservations.findIndex((reservation) => reservation.id === id);

			reservations[updatedReservationIndex] = {
				...reservations[updatedReservationIndex],
				...data
			};

			setReservations([...reservations]);

			await _updateReservation(firestore, id, data);

			return {
				success: true
			};

		}
		catch (err) {
			console.warn('Error updating reservation', err);
			setErrorMessage('Error updating reservation');
		}

		return {
			success: false
		};
	};


	// This is the admin counterpart of confirmReservation from the reservation context
	// it doesnt require timeslots or table and currently does not handle payment operations
	const adminConfirmReservation = async (newReservation: any) => {
		try {
			setProcessing(true);
			// TODO: Change this up to the current "createReservation"!
			await legacyCreateReservation(
				firestore,
				newReservation.userId,
				newReservation.fullName,
				newReservation.phoneNumber,
				newReservation.email,
				newReservation.date,
				newReservation.time,
				newReservation.guests,
				newReservation.guestNotes,
				'', // invoidId
				null, // price
				null, //selected table
				selectedAdminRestaurant,
				newReservation.notesByRestaurant,
				newReservation.specialOccasion
			);

			setProcessing(false);
			return {
				success: true
			};

		}
		catch (err) {
			setErrorMessage('Error creating reservation');
			console.warn('Error creating reservation', err);
		}

		setProcessing(false);
		return {
			success: false
		};
	};

	const valueObj = {
		reservations,
		selectedReservation,
		setSelectedReservation,
		setQueryFilterFn,
		page,
		setPage: gotoPage,
		unresolvedRequests,
		pageSize,
		updateReservation,
		adminConfirmReservation,
		processing,
		errorMessage,
		clearErrorMessage: () => setErrorMessage('')
		// setPageSize
	};


	return (
		<AdminReservationContext.Provider value={valueObj}>
			{children}
		</AdminReservationContext.Provider>
	);
};



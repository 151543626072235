import React, { useContext, useMemo, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AdminReservationContext } from '../context/AdminReservationContext';
import { useHistory } from 'react-router-dom';
import { RestaurantContext } from '../context/RestaurantContext';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

const LargeGroupNotification = () => {

	const history = useHistory();
	const { setSelectedRestaurantId } = useContext(RestaurantContext);
	const { unresolvedRequests } = useContext(AdminReservationContext);

	const [hidden, setHidden] = useState(false);

	useMemo(() => {
		setHidden(false);
	}, [unresolvedRequests]);

	return (
		unresolvedRequests > 0 && !hidden &&
        <div className={'notice success'}
			 style={{ zIndex: 0, cursor:'default', width: '100%' }}
        	data-cy='lgb-notification'
        >
        	<div style={{ display: 'flex' }}>
        		<NewReleasesIcon style={{ marginLeft: '16px' }} />
        		<Typography variant='body1'>
        			{`${unresolvedRequests} unresolved large group booking request(s): action required`}
        		</Typography>
        	</div>

        	<div style={{ display:'flex' }}>
        		<Button
        			style={{ height:'5px', padding:'0', marginRight:'16px' }}
        		onClick={() => {
        			history.push('/booking-requests');
        			setSelectedRestaurantId('none');
        		}}
        	>
                Review Requests
        	</Button>

        	<Typography variant='body1'>
        		<CloseIcon fontSize='inherit' style={{ cursor:'pointer' }} onClick={() => {
        			setHidden(true);
        		}}/>
        	</Typography>
        	</div>
        </div>
	);
};

export default LargeGroupNotification;

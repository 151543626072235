import { Box, Container, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { TDR } from 'tdr-common';
import { RestaurantContext } from '../../context/RestaurantContext';
import RestaurantSelector from '../RestaurantSelector';
import { DirectLinkConfig } from './DirectLinkConfig';
import { RMSConfig } from './RMSConfig';
import { WidgetConfig } from './WidgetConfig';
import { LandingPageConfig } from './LandingPageConfig';

export const useStyles = makeStyles(() => ({
	root: {
		background: '#3E3E3E',
		width: '100%'
	},
	heading: {
		margin: 0,
		fontSize: '24px'
	},
	subheading: {
		margin: 0,
		fontSize: '16px'
	},
	summaryContent: {
		display: 'flex',
		flexDirection: 'column'
		// padding: '12px'
	},
	detailsContent: {
		display: 'flex',
		flexDirection: 'column'
	},
	buttonRoot: {
		background: '#CA8A04',
		borderRadius: '6px'
	}
}));

const defaultSettings: TDR.Restaurant['settings'] = {
	alternativePlatform: {
		vendor: null,
		href: ''
	},
	widget: {
		alternativeWidgetType: 'none',
		layout: 'default',
		showOptionHeaders: false,
		showFooter: true,
		tablz: {
			title: '',
			description: '',
			label: '',
			font: '',
			labelColor: '',
			bg: '',
			borderColor: '',
			skipLanding: true
		},
		opentable: null,
		alternate: {
			title: '',
			description: '',
			label: '',
			font: '',
			labelColor: '',
			bg: '',
			borderColor: '',
			dark: true
		}
	}
};

export type RestaurantSettingsFormProps = {
	formData: TDR.Restaurant['settings'];
	setFormData: React.Dispatch<React.SetStateAction<TDR.Restaurant['settings']>>;
};

export const AdminWidgets = () => {
	const { selectedRestaurant } = useContext(RestaurantContext);

	const [formData, setFormData] = useState<TDR.Restaurant['settings']>({
		...defaultSettings,
		...selectedRestaurant?.settings
	});

	return (
		<Container maxWidth='xl' style={{ background: '#26262B', height: '100vh' }}>
			<Box py={2} marginTop={'16px'}>
				<RestaurantSelector />
			</Box>

			{selectedRestaurant ? (
				<>
					<RMSConfig formData={formData} setFormData={setFormData} />
					<DirectLinkConfig />
					<LandingPageConfig formData={formData} setFormData={setFormData} />
					<WidgetConfig formData={formData} setFormData={setFormData} />
				</>
			) : null}
		</Container>
	);
};

import noop from 'lodash/noop';
import { Mattertag, MpSdk, Tour } from 'matterport';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { RestaurantContext } from './RestaurantContext';
import { URLContext } from './URLContext';

export namespace MatterportContext {
	export type Value = {
		sdk: MpSdk,
		setSdk: (sdk: MpSdk) => void,
		tourSnapshots: Tour.Snapshot[],
		tagData: Mattertag.MattertagData[],
		origTagData: Mattertag.MattertagData[], // tagData before being overwritten
		modelId: string,
		setModelId: (modelId: string) => void;
	};
}

export const MatterportContext = createContext<MatterportContext.Value>({
	sdk: null,
	setSdk: noop,
	tagData: [],
	origTagData: [],
	tourSnapshots: [],
	modelId: '',
	setModelId: noop
});
MatterportContext.displayName = 'Matterport Sdk Context';

export namespace MatterportProvider {
	export type Props = {
		children: React.ReactNode,
	};
}

export const MatterportProvider = ({ children }: MatterportProvider.Props) => {
	const [sdk, setSdk] = useState<MpSdk>(null);
	const [tagData, setTagData] = useState<Mattertag.MattertagData[]>([]);
	const [origTagData, setOrigTagData] = useState<Mattertag.MattertagData[]>([]);

	const [tourSnapshots, setTourSnapshots] = useState<Tour.Snapshot[]>([]);
	const { restaurantSlug } = useContext(URLContext);
	const { restaurants } = useContext(RestaurantContext);
	const [modelId, setModelId] = useState<string>(restaurants.find(restaurant => restaurant.slug === restaurantSlug)?.model ?? '');

	useEffect(() => {
		if (sdk) {
			sdk.Mattertag.getData().then((origTags) => {
				setOrigTagData(origTags);
				setTagData(origTags);
			});
			sdk.Tour.getData().then((snapshots) => {
				setTourSnapshots(snapshots);
			});
		}

	}, [sdk]);

	// Reset tagData and tourSnapshots
	useEffect(() => {
		setTagData([]);
		setTourSnapshots([]);
	}, [modelId]);


	const valueObj: MatterportContext.Value = {
		sdk,
		setSdk,
		tagData,
		origTagData,
		tourSnapshots,
		modelId,
		setModelId
	};

	return (
		<MatterportContext.Provider value={valueObj}>
			{children}
		</MatterportContext.Provider>
	);
};



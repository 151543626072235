import React, { useState, useEffect, useContext, createContext } from 'react';
import noop from 'lodash/noop';
import isNull from 'lodash/isNull';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import keys from 'lodash/keys';
import { DateTime } from 'luxon';
import { TDR, priceItemAtTime, querySnapshotToArray, unpackTimeSlots, DATESTR_FMT, parseTimeNoAPM } from 'tdr-common';
import { ProviderProps } from '../common/ProviderProps';
import { FirebaseContext } from '../context/FirebaseContext';
import { TableContext } from '../context/TableContext';
//TODO DELET FILE
export namespace TimeSlotContext {
	export type TimeSlotProviderProps = ProviderProps & {
		restaurant?: TDR.Restaurant,
		table?: TDR.Table,
		reservation?: Partial<TDR.Reservation>,
	}

	export type Value = {
		timeSlotsDoc: TDR.TimeSlotDoc,
		tableTimeSlots: TDR.TimeSlotMap,
		selectedDate: Date,
		setSelectedDate: (date: Date) => void,
		pricingPolicies?: TDR.PricingPolicy[]
	};
}

export const TimeSlotContext = createContext<TimeSlotContext.Value>({
	timeSlotsDoc: null,
	tableTimeSlots: {},
	selectedDate: null,
	setSelectedDate: noop,
	pricingPolicies: null
});
TimeSlotContext.displayName = 'TimeSlot Context';

export const TimeSlotProvider = ({ children, restaurant, reservation }: TimeSlotContext.TimeSlotProviderProps) => {
	const { initialized, firestore } = useContext(FirebaseContext);
	const { selectedTable } = useContext(TableContext);
	const zone = restaurant?.timezone;
	const [timeSlotsDoc, setTimeSlotsDoc] = useState<TDR.TimeSlotDoc>(null);
	const [tableTimeSlots, setTableTimeSlots] = useState<TDR.TimeSlotMap>({});

	const [selectedDate, setSelectedDate] = useState(reservation?.time?.toDate() || DateTime.local().plus({ days: 1 }).toJSDate());

	const [pricingPolicies, setPricingPolicies] = useState<TDR.PricingPolicy[]>(null);
	const [pricedTimeSlots, setPricedTimeSlots] = useState<TDR.TimeSlotMap>({});

	// Fetch timeslots
	useEffect(() => {
		if (initialized && !isNull(firestore) && !isNull(selectedTable)) {

			const day = DateTime.fromJSDate(selectedDate, { zone });
			const dateStr = day.toFormat(DATESTR_FMT);

			const dispose = firestore
				.collection('TimeSlots')
				.where('tableId', '==', selectedTable.id)
				.where('dateStr', '==', dateStr)
				.onSnapshot((querySnapshot) => {
					const changes = (querySnapshot.docChanges() || []).map(c => c.doc.data() as TDR.TimeSlotDocDB);
					if (changes.length > 1) {
						console.error('ReservationContext: Expected at most one timeSlotdoc, but found multiple.', changes);
					}

					const dbTsDoc: Partial<TDR.TimeSlotDocDB> = last(changes) || { dateStr };
					const tsDoc = unpackTimeSlots(restaurant, selectedTable, dbTsDoc);
					setTimeSlotsDoc(tsDoc);
					setTableTimeSlots(tsDoc.timeSlots);
				});

			return () => {
				dispose();
			};
		}
	}, [initialized, selectedTable, restaurant, selectedDate]);


	// Fetch pricing policies
	useEffect(() => {
		if (isNull(firestore) || !restaurant) {
			return;
		}

		firestore.collection('PricingPolicies').where('restaurantId', '==', restaurant.id).get().then(
			snapshot => setPricingPolicies(sortBy(querySnapshotToArray(snapshot), policy => policy.priority))
		);
	}, [initialized, restaurant]);


	// inject prices into the table timeslots
	useEffect(() => {
		console.log('Pricing TimeSlots', !selectedTable, isEmpty(tableTimeSlots), isNull(pricingPolicies), isEmpty(pricingPolicies));

		if (!selectedTable || isEmpty(tableTimeSlots) || isNull(pricingPolicies)) {
			setPricedTimeSlots({});
			return;
		}

		// if we just don't have pricing policies, then assume we've got legacy data
		if (isEmpty(pricingPolicies)) {
			setPricedTimeSlots(tableTimeSlots);
			return;
		}

		const pricedSlots = {};
		const datetime = DateTime.fromJSDate(selectedDate, { zone: zone });

		keys(tableTimeSlots).forEach(time => {
			const lineItem = priceItemAtTime(
				datetime.set(parseTimeNoAPM(time)),
				pricingPolicies,
				reservation,
				{
					type: TDR.Reservation.ItemType.Table,
					id: selectedTable?.id,
					guests: reservation.guests
				}
			);

			console.log('PRICING TIMESLOT', time, lineItem.total, lineItem.charges, lineItem);

			const { charges } = lineItem;
			const totalCharges = charges.find((charge) => charge.target === 'total');
			const deferredCharges = charges.find((charge) => charge.target === 'deferred');
			const deferredAmount = deferredCharges?.amount || 0;

			pricedSlots[time] = {
				...tableTimeSlots[time],

				chargeType: totalCharges?.type || 'add',
				regularPrice: totalCharges?.amount + deferredAmount,
				deferred: deferredAmount
			};
		});

		setPricedTimeSlots(pricedSlots);

	}, [pricingPolicies, tableTimeSlots, reservation, selectedDate, selectedTable]);

	// Properly price the timeSlotsDoc if applicable
	if (timeSlotsDoc) {
		timeSlotsDoc.timeSlots = pricedTimeSlots;
	}

	const valueObj: any = {
		timeSlotsDoc,
		tableTimeSlots: pricedTimeSlots,
		selectedDate, setSelectedDate,
		pricingPolicies
	};

	return (
		<TimeSlotContext.Provider value={valueObj}>
			{children}
		</TimeSlotContext.Provider>
	);
};

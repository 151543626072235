import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { TDR } from 'tdr-common';
import { useStyles, RestaurantSettingsFormProps } from './AdminWidgets';
import { FirebaseContext } from '../../context/FirebaseContext';
import { RestaurantContext } from '../../context/RestaurantContext';
import { updateRestaurantSettings } from '../../api/updateLandingPageSettings';

const URI_SCHEMES = {
	phone: 'tel:',
	email: 'mailto:',
	http: 'http://',
	https: 'https://'
};

export const RMSConfig = ({ formData, setFormData }: RestaurantSettingsFormProps) => {
	const classes = useStyles();

	const { firestore } = useContext(FirebaseContext);
	const { selectedRestaurant } = useContext(RestaurantContext);

	const [savingChanges, setSavingChanges] = useState(false);

	// TODO: better validation and error handling
	// const [hrefError, setHrefError] = useState(false);
	const [, setSubmissionError] = useState(false);
	// const doesRMSMatchUrl = formData.alternativePlatform?.href.includes(
	// 	formData.alternativePlatform?.vendor
	// );

	const handleSaveChanges = async () => {
		setSubmissionError(null);
		setSavingChanges(true);
		formData.alternativePlatform.href = formatHref(formData.alternativePlatform?.vendor, formData.alternativePlatform?.href);

		try {
			await updateRestaurantSettings(firestore, selectedRestaurant.id, formData);
		}
		catch (error) {
			console.error({ error });
			setSubmissionError(error);
		}
		finally {
			setSavingChanges(false);
		}
	};

	const formatHref = (platform: TDR.AlternativePlatform, value: string) => {
		let formatedValue = '';

		switch (platform) {
			case TDR.AlternativePlatform.Phone:
				formatedValue = value.startsWith(URI_SCHEMES.phone) ? value : `${URI_SCHEMES.phone}${value}`;
				break;
			case TDR.AlternativePlatform.Email:
				formatedValue = value.startsWith(URI_SCHEMES.email) ? value : `${URI_SCHEMES.email}${value}`;
				break;
			default:
				formatedValue = value.startsWith(URI_SCHEMES.http) || value.startsWith(URI_SCHEMES.https) ? value : `${URI_SCHEMES.https}${value}`;
		}

		return formatedValue;
	};

	const hrefLabels = {
		phone: 'Reservation Phone Number',
		email: 'Reservation Email Address',
		default: 'Reservation Website'
	};

	return (
		<Accordion classes={{ root: classes.root }}>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				classes={{ content: classes.summaryContent }}
			>
				<h1 className={classes.heading}>Alternative Platform</h1>
				<p className={classes.subheading}>
					Select the restauarant&apos;s current reservation system, and
					provide the link to their reservation page.
				</p>
			</AccordionSummary>

			<AccordionDetails>
				<Grid container alignItems='center' spacing={4}>
					<Grid
						item
						xs={4}
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<FormControl fullWidth>
							<InputLabel id='alternativePlatformLabel'>RMS</InputLabel>
							<Select
								value={formData?.alternativePlatform?.vendor || ''}
								onChange={(evt) => {
									const newFormData = {
										...formData,
										alternativePlatform: {
											...formData?.alternativePlatform,
											vendor: evt.target
												?.value as TDR.AlternativePlatform
										}
									};
									setFormData(newFormData);
								}}
								variant='standard'
								SelectDisplayProps={{
									style: {
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										paddingRight: '45px'
									}
								}}
								MenuProps={{
									style: {
										overflow: 'scroll'
									}
								}}
								labelId='alternativePlatformLabel'
								style={{ width: '100%' }}
							>
								{Object.values(TDR.AlternativePlatform).map(
									(platform) => (
										<MenuItem
											key={platform}
											value={platform}
											style={{
												display: 'flex',
												justifyContent: 'space-between'
											}}
										>
											{TDR.RMSDisplayNameMap[platform]}
											<img
												src={`../../images/logos/${platform}.svg`}
												style={{
													height: '100%',
													maxHeight: '30px',
													maxWidth: '80px'
												}}
											/>
										</MenuItem>
									)
								)}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={8}>
						<TextField
							label={hrefLabels[formData.alternativePlatform?.vendor] || hrefLabels.default}
							variant='outlined'
							multiline
							InputLabelProps={{ shrink: true }}
							maxRows={4}
							value={formData?.alternativePlatform?.href || ''}
							onChange={(evt) => {
								const newFormData = {
									...formData,
									alternativePlatform: {
										...formData?.alternativePlatform,
										href: evt.target.value
									}
								};
								setFormData(newFormData);
							}}
						/>
					</Grid>

					<Grid item xs={9}></Grid>

					<Grid item xs={3} style={{ paddingTop: 0 }}>
						<Button
							disabled={savingChanges}
							classes={{
								root: classes.buttonRoot
							}}
							onClick={handleSaveChanges}
						>
							{savingChanges ? <CircularProgress /> : 'Save Changes'}
						</Button>
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};
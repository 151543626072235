export type RGB = `rgb(${number}, ${number}, ${number})`;
export type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX;


export type DirectLinkSearchParam = 'skip' | 'utm_source' | 'utm_medium' | 'utm_campaign';
export interface WidgetV2Settings extends WidgetV1Settings {
	logo?: boolean;
	title?: string;
	description?: string;
	label?: string;
	label_color?: Color;
	bg?: RGBA;
	v2?: boolean;
}

export interface WidgetV1Settings {
	color?: Color;
	font?: string;
	skipPopup?: boolean;
}

// Runtime validation functions
export function isRGB(color: string): color is RGB {
	return /^rgb\(\d{1,3},\s?\d{1,3},\s?\d{1,3}\)$/.test(color);
}

export function isRGBA(color: string): color is RGBA {
	return /^rgba\(\d{1,3},\s?\d{1,3},\s?\d{1,3},\s?\d(\.\d+)?\)$/.test(color);
}

export function isHEX(color: string): color is HEX {
	return /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/.test(color);
}

export function isColor(color: string): color is Color {
	return isRGB(color) || isRGBA(color) || isHEX(color);
}
import { TDR } from 'tdr-common';
import config from '../common/config';

export default async function getInvoice(reservation: Partial<TDR.Reservation>) {
	const response = await fetch(`${config.apiHost}/invoice`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(reservation)
	});

	return response.json();
}
import { Box, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import React from 'react';
import { TDR, WIDGET_SECONDARY_DESC, WIDGET_SECONDARY_TITLE } from 'tdr-common';
import { HexColorField } from '../hex-color-field/HexColorField';
import { RgbaColorField } from '../rgba-color-field/RgbaColorField';

type TablzAlternateWidgetSettingsProps = {
	formData: TDR.Restaurant['settings'];
	setFormData: React.Dispatch<React.SetStateAction<TDR.Restaurant['settings']>>;
	updatePreviewParam: (key: string, value: string | boolean) => void;
	selectedRestaurant: TDR.Restaurant;
	descriptionError: boolean;
	titleError: boolean;
	setTitleError: (boolean) => void;
	setDescriptionError: (boolean) => void;
};

export const TablzAlternateWidgetSettings = ({
	formData,
	setFormData,
	updatePreviewParam,
	selectedRestaurant,
	descriptionError,
	titleError,
	setTitleError,
	setDescriptionError
}: TablzAlternateWidgetSettingsProps) => {
	return (
		<>
			<h2>Tablz Alternate Widget Settings</h2>

			<FormControlLabel
				control={
					<Checkbox
						name='darkMode'
						checked={!!formData.widget?.alternate?.dark}
						onChange={(evt) => {
							updatePreviewParam('s-dark', evt.target.checked);
							setFormData({
								...formData,
								widget: {
									...formData.widget,
									alternate: {
										...formData.widget?.alternate,
										dark: evt.target.checked
									}
								}
							});
						}}
					/>
				}
				label='Use dark mode'
			/>
			<TextField
				label="Title"
				placeholder={WIDGET_SECONDARY_TITLE}
				variant="outlined"
				value={formData.widget?.alternate?.title}
				InputLabelProps={{ shrink: true }}
				inputProps={{ pattern: '[A-Za-z ]*' }}
				onChange={(evt) => {
					if (evt.target.validity.valid) {
						setTitleError(false);
					}
					else {
						setTitleError(true);
					}
					updatePreviewParam('s-title', evt.target.value);
					setFormData({
						...formData,
						widget: {
							...formData.widget,
							alternate: {
								...formData.widget?.alternate,
								title: evt.target.value
							}
						}
					});
				}}
				error={titleError}
				helperText={titleError ? 'Special characters not allowed' : ''}
			/>
			<TextField
				disabled={!selectedRestaurant}
				label="Description"
				placeholder={WIDGET_SECONDARY_DESC}
				variant="outlined"
				value={formData.widget?.alternate?.description}
				InputLabelProps={{ shrink: true }}
				inputProps={{ pattern: '[A-Za-z ]*' }}
				multiline
				maxRows={3}
				onChange={(evt) => {
					if (evt.target.validity.valid) {
						setDescriptionError(false);
					}
					else {
						setDescriptionError(true);
					}
					updatePreviewParam('s-description', evt.target.value);
					setFormData({
						...formData,
						widget: {
							...formData.widget,
							alternate: {
								...formData.widget?.alternate,
								description: evt.target.value
							}
						}
					});
				}}
				error={descriptionError}
				helperText={descriptionError ? 'Special characters not allowed' : ''}
			/>
			<TextField
				disabled={!selectedRestaurant}
				label="Font Name"
				variant="outlined"
				InputLabelProps={{ shrink: true }}
				value={formData.widget?.alternate?.font}
				onChange={(evt) => {
					updatePreviewParam('s-font', evt.target.value);
					setFormData({
						...formData,
						widget: {
							...formData.widget,
							alternate: {
								...formData.widget?.alternate,
								font: evt.target.value
							}
						}
					});
				}}
			/>
			<TextField
				disabled={!selectedRestaurant}
				label="Call To Action Label"
				variant="outlined"
				InputLabelProps={{ shrink: true }}
				value={formData.widget?.alternate?.label}
				onChange={(evt) => {
					updatePreviewParam('s-label', evt.target.value);
					setFormData({
						...formData,
						widget: {
							...formData.widget,
							alternate: {
								...formData.widget?.alternate,
								label: evt.target.value
							}
						}
					});
				}}
			/>

			<Box
				display="flex"
				style={{
					flexWrap: 'wrap',
					justifyContent: 'space-between'
				}}
			>
				{/* TODO: add a preset value to match opentable color */}
				<HexColorField
					value={formData.widget?.alternate?.color}
					label="CTA Button Color"
					onChange={(color) => {
						updatePreviewParam('s-color', color);
						setFormData({
							...formData,
							widget: {
								...formData.widget,
								alternate: {
									...formData.widget?.alternate,
									color: color
								}
							}
						});
					}}
				/>

				<HexColorField
					value={formData.widget?.alternate?.labelColor}
					label="CTA Text Color"
					onChange={(color) => {
						updatePreviewParam('s-labelColor', color);
						setFormData({
							...formData,
							widget: {
								...formData.widget,
								alternate: {
									...formData.widget?.alternate,
									labelColor: color
								}
							}
						});
					}}
				/>

				<RgbaColorField
					value={formData.widget?.alternate?.bg}
					label="Overlay Color & Opacity"
					onChange={(color) => {
						updatePreviewParam('s-bg', color);
						setFormData({
							...formData,
							widget: {
								...formData.widget,
								alternate: {
									...formData.widget?.alternate,
									bg: color
								}
							}
						});
					}}
				/>
				<HexColorField
					value={formData.widget?.alternate?.borderColor}
					label="Border Color"
					onChange={(color) => {
						updatePreviewParam('s-borderColor', color);
						setFormData({
							...formData,
							widget: {
								...formData.widget,
								alternate: {
									...formData.widget?.alternate,
									borderColor: color
								}
							}
						});
					}}
				/>
			</Box>
		</>
	);
};
